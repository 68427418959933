.becomeMember{
	.alternate{
		padding:90px 0;
		@media (max-width: 767px){
			padding:25px 0 10px;
		}
		.txt{
			float:left;
			width:45%;
			@media (max-width: 767px){
				width:100%;
			}
			h1,h2{
				font-family:$play;
				font-size:58px;
				line-height:63px;
				color:$iconBlue;
				font-weight:400;
				margin:0 0 80px;
				@media (max-width: 1280px){
					font-size:48px;
					line-height:53px;
					@media (max-width: 1023px){
						font-size:38px;
						line-height:43px;
						@media (max-width: 767px){
							font-size:28px;
							line-height:32px;
							margin:0 0 45px;
						}
					}
				}
				span{
					display:block;
					color:$red;
				}
			}
			h2{
				margin:50px 0 80px;
				@media (max-width: 1280px){
					margin:30px 0 50px;
					@media (max-width: 1023px){
						margin:20px 0 40px;
						@media (max-width: 767px){
							margin:15px 0 25px;
						}
					}
				}
			}
			ul{
				li{
					float:left;
					width:50%;
					font-size:18px;
					line-height:26px;
					color:$iconBlue;
					margin:0 0 70px;
					@media (max-width: 1280px){
						width:100%;
						margin:0 0 40px;
						@media (max-width: 767px){
							font-size:14px;
							line-height:18px;
							margin:0 0 30px;
						}
					}
					span{
						display:block;
						color:$red;
					}
					a{
						transform:translateY(-8px);
					}
				}
			}
			p{
				font-size:15px;
				line-height:28px;
				color:$red;
				font-weight:300;
				width:80%;
				@media (max-width: 767px){
					font-size:12px;
					line-height:19px;
					width:100%;
				}
			}
		}
		.image{
			width:55%;
			float:left;
			img{
				float:right;
				width:auto;
				display:block;
				max-width:100%;
				transform:translateX(70px);
				@media (max-width: 1280px){
					transform:translateX(35px);
					@media (max-width: 767px){
			        	transform:translateX(0);
			        	margin:25px 0 20px 0;
			        }
				}
			}
		}
		.scrollTo{
			color:$red;
			cursor:pointer;
			text-align:center;
			width:25px;
			height:70px;
			margin:0 auto -60px;
			clear:both;
			position:relative;
			overflow:hidden;
			@media (max-width: 767px){
				display:none;
			}
			i{
				display:block;
				font-size:28px;
				transform:translateX(-10px);
				&.icon-arrow{
					font-size:24px;
					transform:rotate(90deg);
				}
			}
		}
	}
	.container:first-child{
		.alternate{
			.txt{
				width:55%;
				@media (max-width: 767px){
					width:100%;
				}
			}
			.image{
				width:45%;
				@media (max-width: 767px){
					display:none;
				}
			}
		}
	}
	.beigeBg{
		.alternate{
			.txt{
				text-align:right;
				@media (max-width: 767px){
					text-align:left;
				}
				p{
					float:right;
					@media (max-width: 767px){
						float:left;
					}
				}
			}
			.image{
				img{
					float:left;
					transform:translateX(-70px);
					@media (max-width: 1280px){
						transform:translateX(-35px);
						@media (max-width: 767px){
				        	transform:translateX(0);
				        }
					}
				}
			}
		}
	}
	.theForm{
		border-left:$extraPadding solid $white;
		border-right:$extraPadding solid $white;
		width:100%;
		float:left;
		clear:both;
		background:$paleBlue;
		padding:50px 7% 70px;
		margin:90px 0;
		@media (max-width: 767px){
			border:none;
			margin:45px 0;
			padding:30px 7% 35px;
		}
		h3{
			color:$red;
			font-size:56px;
			font-family:$play;
			font-weight:400;
			text-align:center;
			width:100%;
			margin:0 0 5px;
			@media (max-width: 767px){
				font-size:23px;
			}
		}
		p{
			font-size:22px;
			line-height:38px;
			font-family:$play;
			text-align:center;
			width:90%;
			margin:0 auto 5px;
			@media (max-width: 767px){
				width:100%;
				font-size:14px;
				line-height:22px;
			}
		}
		span{
			font-size:14px;
			display:table;
			margin:0 auto;
			@media (max-width: 767px){
				font-size:12px;
			}
		}
		form{
			padding:55px 0 0 0;
			@media (max-width: 767px){
				padding:35px 0 0 0;
			}
			.field{
				width:48%;
				float:left;
				margin:0 2% 0 0;
				@media (max-width: 767px){
				 	margin:0;
				 	width:100%;
				}
				label{
	                font-size:16px;
	                display:block;
	                @media (max-width: 767px){
	                	font-size:12px;
	                }
	                sup{
	                    color:$red;
	                }
	            }
	            input{
	                height:70px;
	                width:100%;
	                border:none;
	                font-weight:300;
	                font-size:15px;
	                padding:0 25px;
	                margin:15px 0 55px;
	                border-radius:0px;
	                &:disabled{
	                	background:$white;
	                }
	                @media (max-width: 1023px){
	                	padding:0 20px;
	                	font-size:13px;
	                	@media (max-width: 767px){
	                		height:50px;
	                		padding:0 15px;
	                		font-size:12px;
	                		margin:5px 0 20px;
	                	}
	                }
	                &.parsley-error{
                        border:1px solid red;
                    }
                }
                .parsley-errors-list{
                    display:none;
                }
	            .filter{
	            	margin:15px 0 55px;
	            	@media (max-width: 767px){
	            		margin:5px 0 20px;
	            	}
	            }
	            &.radio{
	            	width:100%;
	            }
	            &.address{
	            	width:48%;
	            	@media (max-width: 767px){
					 	width:100%;
					}
	            }
	            &.noApp, &.nocivique{
	            	width:23%;
	           		@media (max-width: 767px){
					 	width:100%;
					}
	            }
	            &.birthday{
	            	@media (max-width: 1280px){
	            		width:73%;
	            		@media (max-width: 767px){
					 		width:100%;
						}
	            	}
	            	.filter{
	            		width:33.33%;
	            		border-right:1px solid $paleBlue;
	            		&:last-child{
	            			border:none;
	            		}
	            	}
	            }
	            &.title{
	            	@media (max-width: 1280px){
	            		width:23%;
	            		@media (max-width: 767px){
					 		width:100%;
						}
	            	}
	            }
	            &.leftM{
	            	margin:0 0 0 2%;
	            	@media (max-width: 767px){
				 		margin:0;
					}
	            }
	          	&.community{
	            	@media (max-width: 1280px){
	            		margin:0 2% 0 0;
	            	}
	            }
	            &.radio{
	            	div{
	            		display:inline-block;
	            		width:auto;
	            		position:relative;
	            		margin:0 30px 50px 0;
	            		@media (max-width: 767px){
	            			margin:0 10px 35px 0;
	            		}
	            		.fakeRadio{
	            			width:14px;
	            			height:14px;
	            			display:block;
	            			position:absolute;
	            			top:4px;
	            			left:0;
	            			background:$white;
	            			border-radius:7px;
	            			&:after{
	            				position:absolute;
	            				content:"";
	            				width:6px;
	            				height:6px;
	            				background:$black;
	            				border-radius:3px;
	            				top:4px;
	            				left:4px;
	            				display:none;
	            			}
	            			&.selected{
	            				&:after{
	            					display:block;
	            				}
	            			}
	            		}
		            	label{
		            		padding:0 0 0 22px;
		            		display:inline-block;
		            		position:relative;
		            		z-index:10;
		            		cursor:pointer;
		            	}
		            	input{
		            		display:none;
		            		width:auto;
		            		height:15px;
		            		margin:0;
		            		border-radius:0px;
		            	}
		            }
	            }
	            &.error{
	            	input{
	            		border:1px solid $red;
	            	}
	            	.filter{
	            		>a{
	            			border:1px solid $red;
	            		}
	            	}
	            }
	            &.hide{
	            	display:none;
	            }
	       	}

            .submit-message {
                display: inline;

                &.hidden {
                    display: none;
                }
                
            }
		}
		.ctaLink{
			clear:both;
			display:table;
			margin:0 auto;
		}
	}
}
