.newsSmall{
    width:33.33%;
    float:left;
    position:relative;
    z-index:10;
    @media (max-width: 1280px){
        width:50%;
        @media (max-width: 767px){
           width:100%; 
        }
    }
    >a{
        position:relative;
        display:block;
        img{
            display:block;
            width:100%;
            height:auto;
            filter: grayscale(1);
            transition:filter $delay $easing;
            .gt-ie9 &{
                filter: url('gray.svg#grayscale');
            }
        }
        .allowHover &:hover{
            img{
                filter: grayscale(0);
                .gt-ie9 &{
                    filter:none;
                }
            }
        }
    }
    &:nth-child(3n-1){
        >a{
            &:before{
                position:absolute;
                content:"";
                left:-1px;
                top:0;
                height:100%;
                width:1px;
                background:$white;
                @media (max-width: 1280px){
                    display:none;
                }
            }
            &:after{
                position:absolute;
                content:"";
                right:0;
                top:0;
                height:100%;
                width:1px;
                background:$white;
                @media (max-width: 1280px){
                    display:none;
                }
            }
        }
    }
    @media (max-width: 1280px){
        &:nth-child(2n){
            >a{
                &:before{
                    position:absolute;
                    content:"";
                    left:-1px;
                    top:0;
                    height:100%;
                    width:1px;
                    background:$white;
                    display:block;
                    @media (max-width: 767px){
                        display:none;
                    }
                }
            }
        }
    }
    .txt{
        padding:0 30px;
        height:300px;
        @media (max-width: 767px){
           padding:0 15px; 
           height:auto;
           margin:0 0 30px;
        }
        .tag{
            margin:-16px 0 20px 0;
        }
        h6{
            text-transform:uppercase;
            font-size:11px;
            font-weight:400;
            margin:0 0 15px;
            @media (max-width: 767px){
                font-size:10px;
                margin:0 0 10px;
            }
        }
        .date{
            font-family:$play;
            color:$charcoal;
            font-size:14px;
            margin:0 0 15px;
            display:inline-block;
            @media (max-width: 767px){
                font-size:12px;
                margin:0 0 10px;
            }
        }
        p{
            font-size:17px;
            line-height:30px;
            font-weight:300;
            @media (max-width: 767px){
                font-size:14px;
                line-height:25px;
            }
            a{
                -webkit-font-smoothing: antialiased;
                color:$black;
                display:inline-block;
                transition:opacity $delay $easing;
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
        }
        .socialMedia{
            transform:translateX(-12px);
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .newsSmall{
        >a{
            img{
                filter: url('gray.svg#grayscale');
            }
            .allowHover &:hover{
                img{
                    filter:none;
                }
            }
        }
    }
}