.singleElu{
	.bannerTitle{
	    .txt{
	        >div{
	        	top:60%;
	        }
	    }
	    .img{
	    	@media (max-width: 767px){
	    		display:none;
	    	}

	    }
			.election {
				margin-left: 16%;
				max-width: 240px;
			}
	}
	.otherInfo{
		width:100%;
		padding:0 0 30px;
		float:left;
		@media (max-width: 1280px){
			padding:0 0 60px;
		}
		.lastNews{
			width:60%;
	        float:left;
	        @media (max-width: 1280px){
	            width:100%;
	        }
	        .blockTitle{
		        margin:0 0 -80px;
		       	img{
		        	width:190px;
		        	height:auto;
		        }
		        div{
		        	top:22%;
		        	@media (max-width: 1023px){
		                top:12%;
		            }
		        }
		    }
		    .newsSmall{
		    	width:50%;
		    	@media (max-width: 767px){
					width:100%;
		    	}
		    }
		}
		.nextEvents{
			width:36%;
	        float:right;
	        @media (max-width: 1280px){
	            width:100%;
	            float:none;
	            clear:both;
	            margin:0 auto;
	            width:65%;
	            @media (max-width: 767px){
	            	width:100%;
	            }
	        }
	        h2{
	        	font-family:$play;
	        	color:$red;
	        	font-size:37px;
	        	line-height:50px;
	        	font-weight:400;
	        	margin:10px 0 30px;
	        	@media (max-width: 1280px){
	        		text-align:center;
	        		@media (max-width: 767px){
	        			text-align:left;
	        			font-size:27px;
	        			line-height:33px;
	        			margin:0 0 20px;
	        		}
	        	}
	        }
	        .event{
	        	position:relative;
	        	width:100%;
	        	float:left;
	        	margin:0 0 25px;
	        	@media (max-width: 767px){
	        		margin:0 0 15px;
	        	}
		        .calendar{
		        	position:absolute;
		        	left:0;
		        	top:0;
		        	width:80px;
		        	@media (max-width: 767px){
		        		width:45px;
		        	}
		        	i{
		        		color:$iconBlue;
		        		font-size:72px;
		        		display:inline-block;
		        		transform:translateX(-15px);
		        		@media (max-width: 767px){
		        			font-size:40px;
		        			transform:translateX(-10px);
		        		}
		        	}
		        	span{
		        		position:absolute;
		        		left:46%;
		        		top:37px;
		        		color:$red;
		        		font-size:31px;
		        		transform:translateX(-50%);
		        		@media (max-width: 767px){
		        			font-size:17px;
		        			left:42%;
		        			top:20px;
		        		}
		        	}
		        }
		        .txt{
		        	float:left;
		        	padding:0 0 0 100px;
		        	width:100%;
		        	@media (max-width: 767px){
		        		padding:0 0 0 65px;
		        	}
		        	span{
		        		font-size:14px;
		        		color:$charcoal;
		        		display:inline-block;
		        		margin:5px 0 0 0;
		        		font-family:$play;
		        		@media (max-width: 767px){
		        			font-size:12px;
		        		}
		        	}
		        	p{
		        		font-weight:300;
		        		font-size:15px;
		        		line-height:28px;
		        		@media (max-width: 767px){
		        			font-size:12px;
		        			line-height:22px;
		        		}
		        	}
		        	a{
		        		color:$red;
		        		font-size:16px;
		        		display:inline-block;
		        		transition:color $delay $easing;
		        		@media (max-width: 767px){
		        			font-size:12px;
		        		}
		        		i{
		        			margin:0 5px 0 0;
		        			display:inline-block;
		        			font-size:22px;
		        			transform:translateY(2px);
		        			transition:transform $delay $easing;
		        			@media (max-width: 767px){
		        				font-size:18px;
		        			}
		        		}
		        		.allowHover &:hover{
		        			color:$redHover;
		        			i{
		        				transform:translate(5px, 2px);
		        			}
		        		}
		        	}
		        }
	        }
	        .ctaLink{
	        	margin:20px 0 0 0;
	        	float:left;
	        	@media (max-width: 1280px){
	        		float:none;
	        		margin:20px auto;
	        		display:table;
	        		@media (max-width: 767px){
	        			float:left;
	        			margin:20px 0 0 0;
	        		}
	        	}
	        }
		}
	}
}
