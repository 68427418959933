footer{
	width:100%;
	float:left;
	clear:both;
	margin:30px 0 0 0;
	@media (max-width: 1023px){
		margin:0;
	}
	.top{
		background:$red;
		width:100%;
		float:left;
		nav{
			padding:60px 0 30px;
			float:left;
			width:70%;
			@media (max-width: 1280px){
				display:none;
			}
			>ul{
				>li{
					width:20%;
					float:left;
					>a,span{
						color:$white;
						font-size:17px;
						font-family:$play;
						transition:opacity $delay $easing;
					}
					>a:hover{
						opacity:0.6;
					}
					ul{
						padding:15px 0 0 0;
						li{
							font-weight:300;
							clear:both;
							font-size:13px;
							margin:0 0 10px;
							a{
								color:$white;
								-webkit-font-smoothing: antialiased;
								transition:opacity $delay $easing;
								.allowHover &:hover{
									opacity:0.6;
								}
							}
						}
					}
				}
			}
		}
		.toolLinks{
			float:right;
			padding:60px 0 30px;
			@media (max-width: 1280px){
				width:195px;
				margin:0 auto;
				display:table;
				float:none;
				@media (max-width: 767px){
					padding:30px 0 0;
				}
			}
			>a{
				float:right;
				color:$white;
				&.social{
					float:left;
					font-size:20px;
					display:inline-block;
					margin:0 3px 0 0;
					transform:translateX(-13px);
				}
				&.contact{
					margin:5px 0 0 15px;
					font-size:13px;
					font-weight:300;
					-webkit-font-smoothing: antialiased;
					@media (max-width: 1280px){
						margin:5px 0 0 0;
					}
				}
				transition:opacity $delay $easing;
				.allowHover &:hover{
					opacity:0.6;
				}
			}
			>ul{
				float:left;
				clear:both;
				margin:40px 0 0 0;
				@media (max-width: 1280px){
					width:100%;
					@media (max-width: 767px){
						margin:20px 0 0 0;
					}
				}
				>li{
					text-align:left;
					@media (max-width: 1280px){
						text-align:center;
						margin:0 0 30px;
					}
					span{
						color:$white;
						font-size:17px;
						font-family:$play;
					}
					ul{
						padding:15px 0 0 0;
						@media (max-width: 767px){
							padding:10px 0 0 0;
						}
						li{
							text-align:left;
							font-weight:300;
							font-size:13px;
							@media (max-width: 1280px){
								text-align:center;
							}
							a{
								color:$white;
								-webkit-font-smoothing: antialiased;
								transition:opacity $delay $easing;
								.allowHover &:hover{
									opacity:0.6;
								}
							}
						}
					}
				}
			}
		}
	}
	.bottom{
		background:$navyBlue;
		float:left;
		width:100%;
		padding:20px 0;
		@media (max-width: 767px){
			padding:12px 0;
		}
		.left{
			float:left;
			color:$greyBlue;
			span{
				font-size:12px;
				display:inline-block;
				@media (max-width: 767px){
					font-size:9px;
				}
				a{
					-webkit-font-smoothing: antialiased;
					color:$greyBlue;
					text-decoration:underline;
					transition:opacity $delay $easing;
					.allowHover &:hover{
						opacity:0.6;
					}
				}
			}
		}
		.right{
			float:right;
			@media (max-width: 1023px){
				float:left;
				clear:both;
				margin:15px 0 0 0;
				@media (max-width: 767px){
					margin:10px 0 0 0;
				}	
			}
			p{
				font-size:12px;
				font-family:$play;
				color:$greyBlue;
				@media (max-width: 767px){
					font-size:9px;
				}
			}
		}
	}
}