body{
    .lightbox, .lightboxSlider, .lightboxForm, .lightboxAchievement{
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        z-index:10000;
        visibility:hidden;
        opacity:0;
        @media (max-width: 1023px){
          z-index:20000;
        }
        .bg{
            position:fixed;
            top:0;
            left:0;
            background:$black;
            opacity:0.9;
            width:100%;
            height:100%;
            .mobileDevice &{
                cursor:pointer;
            }
        }
        .wrapper{
            padding:0 0 0 70px;
            @media (max-width: 1023px){
               padding:0;
            }
        }
        .newsLetterPopUp{
            position:relative;
            width:100%;
            background:$beige;
            padding:65px $defaultPadding 75px;
            margin:80px 0 0 0;
            visibility:hidden;
            opacity:0;
            float:left;
            @media (max-width: 767px){
                padding:35px $defaultPadding 45px;
                margin:40px 0 0 0;
            }
            h3{
                color:$red;
                width:100%;
                font-family:$play;
                font-size:56px;
                text-align:center;
                font-weight:400;
                @media (max-width: 767px){
                    font-size:32px;
                }
            }
            p{
                font-size:22px;
                line-height:38px;
                font-family:$play;
                text-align:center;
                width:100%;
                margin:0 0 60px;
                @media (max-width: 767px){
                    font-size:14px;
                    line-height:22px;
                    margin:10px 0 20px;
                }
                &.error{
                    display:none;
                    color:red;
                    margin:-40px 0 40px;
                    @media (max-width: 767px){
                        margin:20px 0;
                    }
                }
            }
            div{
                &.success{
                    display:none;
                }
                p{
                    color:$darkBlue;
                    margin:0;
                    @media (max-width: 767px){
                        margin:20px 0 0;
                    }
                }
            }
            form{
                label{
                    font-size:16px;
                    @media (max-width: 767px){
                        font-size:12px;
                    }
                    sup{
                        color:$red;
                    }
                }
                input{
                    height:70px;
                    width:100%;
                    border:none;
                    font-weight:300;
                    font-size:15px;
                    padding:0 25px;
                    margin:15px 0 55px;
                    border-radius:0px;
                    @media (max-width: 1023px){
                        padding:0 20px;
                        font-size:13px;
                        @media (max-width: 767px){
                            padding:0 15px;
                            font-size:12px;
                            height:50px;
                            margin:5px 0 20px;
                        }
                    }
                    &.parsley-error{
                        border:1px solid red;
                    }
                }
                .parsley-errors-list{
                    display:none;
                }
                .title{
                    width:23%;
                    float:left;
                    @media (max-width: 767px){
                       width:100%;
                    }
                    .filter{
                        margin:15px 0 55px;
                        @media (max-width: 767px){
                            margin:5px 0 20px;
                        }
                    }
                }
                .email{
                    width:73%;
                    float:right;
                    @media (max-width: 767px){
                       width:100%;
                    }
                }
                .firstName{
                    width:48%;
                    float:left;
                    @media (max-width: 767px){
                       width:100%;
                    }
                }
                .lastName{
                    width:48%;
                    float:right;
                    @media (max-width: 767px){
                       width:100%;
                    }
                }
                .error{
                    input{
                        border:1px solid $red;
                    }
                }
                .ctaMoreInfo{
                    color:$greyBlue;
                    font-size:14px;
                    text-decoration:underline;
                    margin:-35px 0 50px;
                    float:left;
                    cursor:pointer;
                    transition:color $delay $easing;
                    @media (max-width: 767px){
                        margin:0 0 30px;
                        font-size:12px;
                    }
                    .allowHover &:hover{
                        color:$greyBlueHover;
                    }
                }
                .interest{
                    float:left;
                    width:100%;
                    overflow:hidden;
                    max-height:0;
                    margin:-40px 0 0 0;
                    ransition:max-heights $delay $easing;
                    &.open{
                        max-height:400px;
                    }
                    >div{
                        float:left;
                        width:100%;
                        padding:0 0 30px;
                        >label{
                            margin:0 0 30px;
                            float:left;
                            width:100%;
                            @media (max-width: 767px){
                                margin:0 0 15px;
                            }
                        }
                        .element{
                            width:25%;
                            float:left;
                            position:relative;
                            @media (max-width: 1280px){
                                width:33.33%;
                                @media (max-width: 1023px){
                                    width:50%;
                                    @media (max-width: 767px){
                                        width:100%;
                                    }
                                }
                            }
                            span{
                                width:16px;
                                height:16px;
                                background:$white;
                                display:inline-block;
                                position:absolute;
                                left:0;
                                top:3px;
                                border:1px solid $greyBlue;
                                &.checked:after{
                                    width:8px;
                                    height:8px;
                                    background:$greyBlue;
                                    content:"";
                                    position:absolute;
                                    left:3px;
                                    top:3px;
                                }
                            }
                            label{
                                display:inline-block;
                                text-align:left;
                                font-size:14px;
                                margin:0 0 20px;
                                padding:0 0 0 30px;
                                cursor:pointer;
                                z-index:10;
                                position:relative;
                                @media (max-width: 767px){
                                    font-size:12px;
                                    margin:0 0 10px;
                                }
                                input{
                                    height:0;
                                    width:0;
                                    border:none;
                                    padding:0px;
                                    margin:0px;
                                    border-radius:0px;
                                    position:absolute;
                                    top:2px;
                                    left:0;
                                    visibility:hidden;
                                }
                            }
                        }
                    }
                }
            }
            .ctaLink{
                display:table;
                margin:0 auto;
                clear:both;
            }
        }
        .customForm{
            position:relative;
            width:100%;
            background:$beige;
            padding:65px 5% 75px;
            margin:100px 0 0 0;
            visibility:hidden;
            opacity:0;
            @media (max-width: 767px){
                padding:35px 5% 45px;
                margin:60px 0 0 0;
            }
            .gform_heading{
                h3{
                    color:$red;
                    width:100%;
                    font-family:$play;
                    font-size:56px;
                    text-align:center;
                    font-weight:400;
                    @media (max-width: 767px){
                        margin:0 0 30px;
                        font-size:12px;
                    }
                    .allowHover &:hover{
                        color:$greyBlueHover;
                    }
                }
                .interest{
                    float:left;
                    width:100%;
                    overflow:hidden;
                    max-height:0;
                    margin:-40px 0 0 0;
                    ransition:max-heights $delay $easing;
                    &.open{
                        max-height:400px;
                    }
                    >div{
                        float:left;
                        width:100%;
                        padding:0 0 30px;
                        >label{
                            margin:0 0 30px;
                            float:left;
                            margin:0;
                        }
                        &.gform_hidden{
                            display:none;
                        }
                        &.gsection, &.fullWidth{
                            width:100%;
                            padding:0 4% 0 0;
                            >div{
                                height:auto;
                            }
                        }
                        &.nameField{
                            div{
                                margin:15px 0 0 0;
                                width:100%;
                                @media (max-width: 767px){
                                    margin:5px 0 0 0;
                                }
                                span{
                                    width:48%;
                                    margin:0 2% 0 0!important;
                                    float:left;
                                    padding:0!important;
                                    @media (max-width: 767px){
                                        width:100%;
                                        margin:0;
                                    }
                                    &.ginput_left{
                                        padding:0!important;
                                    }
                                    label{
                                        margin:5px 0 45px 0;
                                        display:inline-block;
                                        font-size:12px;
                                        width:100%;
                                        text-align:center;
                                        @media (max-width: 767px){
                                           font-size:11px;
                                           margin:5px 0 20px 0;
                                        }
                                    }
                                }
                                span:nth-child(even){
                                    margin:0 0 0 2%!important;
                                    @media (max-width: 767px){
                                        margin:0;
                                    }
                                }
                            }
                        }
                        .element{
                            width:25%;
                            float:left;
                            position:relative;
                            @media (max-width: 1280px){
                                width:33.33%;
                                @media (max-width: 1023px){
                                    width:50%;
                                    @media (max-width: 767px){
                                        width:100%;
                                    }
                                }
                            }
                            span{
                                width:16px;
                                height:16px;
                                background:$white;
                                display:inline-block;
                                position:absolute;
                                left:0;
                                top:3px;
                                border:1px solid $greyBlue;
                                &.checked:after{
                                    width:8px;
                                    height:8px;
                                    background:$greyBlue;
                                    content:"";
                                    position:absolute;
                                    left:3px;
                                    top:3px;
                                }
                            }
                            label{
                                display:inline-block;
                                text-align:left;
                                font-size:14px;
                                margin:0 0 20px;
                                padding:0 0 0 30px;
                                cursor:pointer;
                                z-index:10;
                                position:relative;
                                @media (max-width: 767px){
                                    font-size:12px;
                                    margin:0 0 10px;
                                }
                                input{
                                    height:0;
                                    width:0;
                                    border:none;
                                    padding:0px;
                                    margin:0px;
                                    border-radius:0px;
                                    position:absolute;
                                    top:2px;
                                    left:0;
                                    visibility:hidden;
                                }
                            }
                        }
                    }
                }
            }
            .ctaLink{
                display:table;
                margin:0 auto;
                clear:both;
            }
        }
        .customForm{
            visibility:hidden;
            opacity:0;
            .gform_wrapper{
                background:$beige;
            }
        }
        .playerVideo{
            position:relative;
            width:100%;
            padding:65px $defaultPadding 75px;
            margin:100px 0 0 0;
            visibility:hidden;
            opacity:0;
            top:0;
            iframe{
                position:absolute;
                left:0;
                top:0;
            }
        }
        .achievementPopup{
          position: absolute;
          visibility: hidden;
          opacity: 0;
          top: 100px;
          margin-left: 16%;
          @media (max-width: 767px){
              padding:35px $defaultPadding 45px;
              margin:40px 0 0 0;
              margin-left: 0;
          }
          .container{
            @media (max-width: 767px){
                padding:0;

            }
          }
          .lightboxPagination{
            position: absolute;
            bottom: -51px;
            right: 0;
            .btnPagination{
              padding: 15px;

              background: red;
              display: block;
              float: left;
              transition: background-color 0.2s ease;
              &:hover{
                background:$redHover;
              }
            }
            i {
              display: inline-block;
              color: #fff;
            }
            .prev{
                  margin-right: 1px;
              i{
                transform: rotate(180deg) translate(3px, -2px);
                line-height: 0;
              }
            }
          }
          .btnClose{
            position: absolute;
            right: 0;
            top: -48px;
            padding: 15px;
            background: #7895a1;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            transition: background-color .2s ease, color .2s ease;
            &:hover{
              background-color: #637e89;
              color: #c7c7c7;
            }
          }
          .achievementPopup-content {
            background: #f8f7f3;
            width: 720px;
            margin: 0 auto;
            @media (max-width: 767px){
              width: 100%;
            }
          }
          .achievementPopup-info{
            padding: 20px 30px 40px;
            span{
              font-family: $play;
              color: $charcoal;
              font-size: 14px;
            }
            h2{
              font-family: $play;
              font-size: 37px;
              color: $red;
              margin-top: 5px;
              margin-bottom: 20px;
            }
          }
        }
        .sliderImage{
            position:relative;
            width:100%;
            padding:0 $defaultPadding 0;
            margin:150px 0 0 0;
            @media (max-width: 1023px){
                padding:0;
            }
            .btnClose{
                width:71px;
                height:68px;
                position:absolute;
                top:-68px;
                right:$defaultPadding;
                z-index:100;
                cursor:pointer;
                background:$greyBlue;
                transition:background $delay $easing;
                @media (max-width: 1023px){
                    right:0;
                    @media (max-width: 767px){
                        width:56px;
                        height:54px;
                        top:-54px;
                    }
                }
                i{
                    color:$white;
                    font-size:18px;
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translate(-50%,-50%);
                    transition:transform $delay $easing;
                    @media (max-width: 767px){
                        font-size:15px;
                    }
                }
                .allowHover &:hover{
                    background:$greyBlueHover;
                    i{
                        transform:translate(-50%,-50%) scale(1.1);
                    }
                }
            }
            .bx-viewport{
                background:$black;
                overflow:visible!important;
                .bx-caption{
                    font-weight:300;
                    font-size:13px;
                    color:$white;
                    top:105%;
                    padding:0 200px 0 0;
                    @media (max-width: 767px){
                        font-size:12px;
                        padding:0 125px 0 0;
                    }
                }
                img{
                    margin:0 auto;
                }
            }
            .bx-pager{
                display:none;
            }
            .bx-controls{
                position:absolute;
                bottom:-69px;
                right:0;
                @media (max-width: 767px){
                    bottom:-54px;
                }
                .bx-controls-direction{
                    width:100%;
                    a{
                        padding:20px;
                        background:$red;
                        color:$white;
                        bottom:0;
                        transition:background $delay $easing;
                        @media (max-width: 767px){
                            padding:15px;
                        }
                        i{
                            font-size:22px;
                            display:inline-block;
                            transition:transform $delay $easing;
                            @media (max-width: 767px){
                                font-size:18px;
                            }
                        }
                        &.bx-prev{
                            right:72px;
                            left:auto;
                            @media (max-width: 767px){
                                right:57px;
                            }
                            i{
                                transform:rotate(180deg);
                            }
                        }
                        &.bx-next{
                            right:0;
                        }
                        .allowHover &:hover{
                            background:$redHover;
                            i{
                                transform:translate(5px, 0);
                            }
                            &.bx-prev{
                                i{
                                    transform:translateX(-5px) rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .lightbox{
        .mobileDevice &{
            position:absolute;
        }
        .bg{
           .mobileDevice &{
            position:fixed;
           }
        }
    }
    .lightboxForm{
        position:absolute;
        .bg{
            position:fixed;
        }
    }
}
