
/*** COLOR ***/

$paleBlue:#dfeef4;
$paleBlueHover:#d4e5ec;
$iconBlue:#c4e3f0;
$darkBlue:#007ac2;
$darkBlueHover:#0068a5;
$navyBlue:#000d23;
$greyBlue:#7b97a3;
$greyBlueHover:#69808b;
$grey:#aec4cd;
$paleGrey:#b2b2b2;
$charcoal:#666666;
$red:#ed1b2e;
$redHover:#ca1727;
$beige:#f8f7f3;
$black:#252525;
$white:#ffffff;

/*** FONT ***/

$muli:'Muli', sans-serif;
$play:'Playfair Display', serif;

/*** SIZE VALUE ***/

$defaultPadding:7%;
$extraPadding:3.5vw;

/*** ANIMATION CSS ***/

$delay:0.3s;
$easing:cubic-bezier(.55,0,.1,1);