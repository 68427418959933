.callToAction {
  background: #f8f7f3;
  clear: both;
  padding: 40px;
  margin: 0 0 60px;
  position: relative;
  float: left;
  width: 100%;

  @media (max-width: 767px) {
    padding: 20px 15px 60px;
    margin: 0 0 35px;
  }

  .success {
    display: none;
  }

  .ctaLink {
    clear: both;
    margin: 0 auto;
    font-family: "Muli", sans-serif;
  }

  input {
    height: 70px;
    width: 100%;
    border: none;
    font-weight: 300;
    font-size: 15px;
    padding: 0 25px;
    margin: 15px 0 25px;
    border-radius: 0px;
  }

  .title {
    color: #ed1b2e;
    font-size: 26px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
