header{
	width:100%;
	height:120px;
	padding:0 195px 0 40px;
	position:relative;
	z-index:10000;
	@media (max-width: 1280px){
		padding:0 20px;
		@media (max-width: 1023px){
			height:60px;
		}
    }
	.logo{
		float:left;
		width:122px;
		height:78px;
		margin:20px 0 0 0;
		@media (max-width: 1023px){
			width:30px;
			height:40px;
			margin:10px 0 0 0;
			overflow:hidden;
			position:relative;
		}
		img{
			height:auto;
			width:100%;
			display:block;
			@media (max-width: 1023px){
				height:43px;
				width:67px;
				max-width:none;
			}
		}
	}
	.ctaMenu{
		display:none;
		@media (max-width: 1023px){
			display:block;
			position:absolute;
			top:24px;
			left:72px;
			cursor:pointer;
			span{
				display:block;
				background:$greyBlue;
				width:16px;
				height:2px;
				margin:0 0 3px;
				transition:opacity $delay $easing;
			}
			>i{
				opacity:0;
				display:inline-block;
				position:absolute;
				top:-2px;
				left:-1px;
				font-size:13px;
				color:$greyBlue;
				transition:opacity $delay $easing;
			}
		}
		&.open{
			span{
				opacity:0;
			}
			>i{
				opacity:1;
			}
		}
	}
	.headerElement{
		@media (max-width: 1023px){
			position:absolute;
			left:0;
			width:100%;
			top:60px;
			background:$white;
			overflow:hidden;
			opacity:0;
			max-height:0;
			transition:max-height $delay $easing, opacity $delay $easing, box-shadow $delay $easing;
			&.open{
				opacity:1;
				max-height:1200px;
				box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.25);
			}
		}
		nav{
			float:left;
			margin:0 0 0 40px;
			padding:50px 0 0 0;
			@media (max-width: 1280px){
				margin:0 0 0 30px;
				@media (max-width: 1023px){
					width:100%;
					margin:0;
					padding:30px 0 20px;
					text-align:center;
				}
			}
			>ul{
				>li{
					float:left;
					font-size:16px;
					margin:0 25px 0 0;
					position:relative;
					padding:0 0 2px;
					@media (max-width: 1280px){
						margin:0 20px 0 0;
						@media (max-width: 1023px){
							clear:both;
							width:100%;
							margin:0 0 13px;
							padding:0;
							font-size:20px;
						}
					}
					>a,span{
						color:$black;
						cursor:pointer;
						transition:color $delay $easing;
						i{
							color:$red;
							font-size:10px;
							margin:0 0 0 3px;
							display:inline-block;
							@media (max-width: 1023px){
								font-size:12px;
							}
						}
					}
					ul{
						position:absolute;
						top:20px;
						left:-30px;
						padding:0 30px;
						background:$white;
						width:215px;
						overflow:hidden;
						height:auto;
						max-height:0;
						opacity:0;
						transition:max-height 0.5s $easing, opacity 0.5s $easing;
						@media (max-width: 1023px){
							position:relative;
							width:100%;
							top:auto;
							left:auto;
							padding:0;
						}
						li{
							font-size:14px;
							clear:both;
							margin:12px 0;
							@media (max-width: 1023px){
								font-size:17px;
							}
							a{
								color:$black;
								transition:color $delay $easing;
								@media (max-width: 1023px){
									transform:translateX(-3px);
									display:inline-block;
								}
							}
							&:first-child{
								margin:27px 0 12px;
								@media (max-width: 1023px){
									margin:10px 0;
								}
							}
							&:last-child{
								margin:12px 0 27px;
								@media (max-width: 1023px){
									margin:10px 0;
								}
							}
							.allowHover &:hover, &.active{
								a{
									color:$red;
								}
							}
						}
					}
					.allowHover &:hover, &.open{
						>a,span{
							color:$red;
							i{
								transform:rotate(180deg);
							}
						}
						ul{
							z-index:10;
							opacity:1;
							max-height:475px;
						}
					}
					&.active{
						border-bottom:1px solid $red;
						@media (max-width: 1023px){
							border-bottom:none;
						}
						>a,span{
							color:$red;
							@media (max-width: 1023px){
								border-bottom:1px solid $red;
							}
						}
					}
				}
			}
		}
		&.beigeBg{
			nav{
				> ul{
					li{
						ul{
							background:$beige;
						}
					}
				}
			}
			@media (max-width: 1023px){
				background:$beige;
			}
		}
		.toolLinks{
			float:right;
			padding:45px 0 0 0;
			.social{
				color:$grey;
				font-size:20px;
				display:inline-block;
				transform:translateY(2px);
				transition:color $delay $easing;
				@media (max-width: 1023px){
					margin:0 10px;
				}
				.allowHover &:hover{
					color:$navyBlue;
				}
			}
			.lang{
				display:inline-block;
				color:$red;
				font-size:14px;
				margin:0 25px 0 15px;
				border-bottom:1px solid transparent;
				padding:0 0 2px;
				transition:border $delay $easing;
				@media (max-width: 1280px){
					margin:0 0 0 15px;
					@media (max-width: 1023px){
						margin:0 15px;
					}
				}
				.allowHover &:hover{
					border-bottom:1px solid $red;
				}
			}
			@media (max-width: 1023px){
				position:absolute;
				bottom:30px;
				width:100%;
				left:0;
				text-align:center;
			}
		}
		.ctaButtons {
			position: absolute;
			right: 0;
			top: 0;
			width: 195px;
			height: 145px;
			background: #ed1b2e;
			transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1);
			@media (max-width: 1023px){
				display: none;
			}
			a {
				width: 100%;
				display: block;
				height: 33.333%;
				text-align: center;
				font-size: 14px;
				padding: 10px 0px 0 0;
				color: #fff;
				vertical-align: middle;
				border-bottom: 1px solid #ca1727;
				&:hover {
					background: #ca1727;
				}
				&:last-child{
					border-bottom: none;
				}
				i {
					margin-right: 3px;
					font-size: 20px;
					font-weight: 600;
				}
			}
		}
		.cta{
			display: none;
			position:absolute;
			right:0;
			top:0;
			width:195px;
			height:145px;
			background:$red;
			transition:background $delay $easing;
			@media (max-width: 1280px){
				height:38px;
				@media (max-width: 1023px){
					position:relative;
					right:auto;
					top:auto;
					display:table;
					margin:0 auto 80px;
					height:115px;
					width:275px;
				}
			}
			a{
				display:block;
				width:100%;
				height:100%;
				color:$white;
				text-align:center;
				padding: 50px 0 0 0;
				@media (max-width: 1280px){
					padding:8px 0 0 0;
					@media (max-width: 1023px){
						padding:30px 0 0 0;
					}
				}
				span{
					display:block;
					font-size:16px;
					i{
						display:none;
					}
				}
				i{
					margin:18px 0 0 0;
					display:inline-block;
					font-size:22px;
					transform:rotate(270deg);
					transition:transform $delay $easing;
					@media (max-width: 1280px){
						display:none;
						@media (max-width: 1023px){
							display:inline-block;
						}
					}
				}
			}
			.allowHover &:hover{
				background:$redHover;
				a{
					i{
						transform:rotate(270deg) translateX(5px);
					}
				}
			}
			&.active, .allowHover &.active:hover{
				background:$greyBlue;
				a{
					i{
						transform:rotate(270deg);
					}
				}
			}
		}
	}
	.stickyNav &{
		position:fixed;
		top:0;
		left:70px;
		height:70px;
		box-shadow: -10px 5px 5px -5px rgba(0,0,0,0.25);
		padding:0 300px 0 40px;
		background:$white;
		@media (max-width: 1280px){
			padding:0 190px 0 20px;
			@media (max-width: 1023px){
				left:0px;
				height:60px;
				padding:0 20px;
			}
		}
		.logo{
			width:37px;
			height:48px;
			margin:10px 0 0 0;
			overflow:hidden;
			position:relative;
			@media (max-width: 1023px){
				width:30px;
				height:40px;
			}
			img{
				height:48px;
				width:75px;
				max-width:none;
				@media (max-width: 1023px){
					height:43px;
					width:67px;
				}
			}
		}
		.headerElement{

			@media (max-width: 1023px){
				text-align: center;
			}
			nav{
				padding:25px 0 0 0;
				@media (max-width: 1023px){
					padding:30px 0 20px 0;
				}
			}
			&.beigeBg{
				nav{
					> ul{
						li{
							ul{
								background:$white;
							}
						}
					}
				}
				@media (max-width: 1023px){
					background:$white;
				}
			}
			.toolLinks{
				padding:20px 0 0 0;
			}
			.ctaButtons {
				display: none;
			}
			.cta{
				display: block;
				right:70px;
				width:230px;
				height:70px;
				@media (max-width: 1280px){
					width:100px;
					@media (max-width: 1023px){
						height:115px;
						width:275px;
						display: inline-block;
						right: auto;
					}
				}
				a{
					padding:19px 0 0 0;
					@media (max-width: 1280px){
						padding:14px 0 0 0;
						@media (max-width: 1023px){
							padding:30px 0 0 0;
						}
					}
					span{
						i{
							margin:0px 0 0 0;
							display:inline-block;
							transform:rotate(0deg) translateY(3px);
							@media (max-width: 1280px){
								display:none;
							}
						}
					}
					i{
						display:none;
						@media (max-width: 1023px){
							display:inline-block;
						}
					}
				}
				.allowHover &:hover{
					background:$redHover;
					a{
						i{
							transform:rotate(0deg) translate(5px,3px);
							@media (max-width: 1023px){
								transform:rotate(270deg) translateX(5px);
							}
						}
					}
				}
			}
		}
	}
}
