.singleDossier{
	margin:-120px 0 0 0;
	.beigeBg:first-child{
		position:relative;
		&:after{
			content:"";
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
			height:70px;
			background:$white;
			@media (max-width: 767px){
				display:none;
			}
		}
	}
	.bannerTitle{
		z-index:10;
		.txt{
			@media (max-width: 767px){
				margin:20px 0 0 0;
			}
			> div{
				top:50%;
				.tag{
					margin:0 0 40px;
					@media (max-width: 767px){
						margin:0 0 25px;
					}
				}
			}
		}
	}
	.faq{
		width:100%;
		margin:0 0 60px;
		@media (max-width: 767px){
			margin:0 0 40px;
		}
		>div{
			margin:0 0 40px;
			@media (max-width: 767px){
				margin:0 0 25px;
			}
			.question{
				color:$darkBlue;
				font-size:17px;
				line-height:28px;
				cursor:pointer;
				transition:color $delay $easing;
				@media (max-width: 767px){
					font-size:15px;
					line-height:25px;
				}
				i{
					display:inline-block;
					padding:0 5px 0 0;
					&.icon-moins{
						display:none;
					}
				}
				.allowHover &:hover{
					color:$darkBlueHover;
				}
			}
			.answer{
				color:$charcoal;
				overflow:hidden;
				height:0;
				div{
					padding:15px 0 0 0;
				}
			}
			&.open{
				.question{
					i{
						&.icon-moins{
							display:inline-block;
						}
						&.icon-plus{
							display:none;
						}
					}
				}
			}
		}
	}
	.quote{
		width:80%;
		margin:30px auto;
		text-align:center;
		padding:60px 0;
		@media (max-width: 767px){
			padding:40px 0;
		}
		blockquote{
			color:$greyBlue;
            font-family:$play;
            font-size:49px;
            line-height:60px;
            position:relative;
            @media (max-width: 1023px){
            	font-size:39px;
            	line-height:50px;
            	@media (max-width: 767px){
            		font-size:26px;
            		line-height:32px;
            	}
            }
            span{
            	font-size:56px;
            	color:$red;
            	margin:0;
            	display:inline-block;
            	font-weight:700;
            	transform:translate(-15px, -20px);
            	@media (max-width: 1023px){
            		font-size:46px;
            		transform:translate(-15px, -15px);
            		@media (max-width: 767px){
            			font-size:36px;
            			transform:translate(-10px, -12px);
            		}
            	}
            }
		}
		span{
			display:inline-block;
			font-size:18px;
			margin:45px 0 0 0;
			@media (max-width: 767px){
				font-size:14px;
				margin:25px 0 0 0;
			}
		}
	}
	.realisationList{
		border-left:$extraPadding solid $white;
		border-right:$extraPadding solid $white;
		clear:both;
		width:100%;
		float:left;
		margin:60px 0;
		@media (max-width: 1280px){
			margin:60px 0 0;
			@media (max-width: 767px){
				border:none;
				margin:20px 0 0;
			}
		}
		>div{
			float:left;
			width:100%;
			clear:both;
			.blockTitle{
		        margin: 0 0 -10px;
		        img{
		        	width:240px;
		        	height:auto;
		        	margin:0 0 0 -40px;
		        	@media (max-width: 767px){
		        		margin:0 0 0 -10px;
		        		width:200px;
		        	}
		        }
		       	div{
		        	top:30px;
		        	@media (max-width: 767px){
		        		top:10px;
		        	}
		        }
		    }
			.realisation{
				position:relative;
				z-index:10;
				width:100%;
				float:left;
				margin:0 0 50px;
				@media (max-width: 1280px){
					margin:0 0 -30px;
					@media (max-width: 767px){
						margin:0 0 30px;
					}
				}
				.thumb{
					float:left;
					width:60%;
					@media (max-width: 1280px){
						width:80%;
						@media (max-width: 767px){
							width:100%;
						}
					}
					img{
						width:100%;
						height:auto;
						display:block;
					}
				}
				.txt{
					width:50%;
					position:absolute;
					top:50%;
					right:0;
					background:$beige;
					padding:50px 60px;
					transform:translateY(-50%);
					@media (max-width: 1280px){
						position:relative;
						width:80%;
						clear:both;
						float:right;
						top:auto;
						transform:translateY(-100px);
						@media (max-width: 767px){
							width:100%;
							transform:translateY(0);
							padding:20px;
						}
					}
					>span{
						color:$charcoal;
						font-family:$play;
						font-size:14px;
						margin:0 0 5px;
						display:inline-block;
						@media (max-width: 767px){
							font-size:12px;
						}
					}
					h4{
						font-size:37px;
						line-height:49px;
						font-weight:400;
						font-family:$play;
						margin:0 0 15px;
						@media (max-width: 1440px){
							font-size:27px;
							line-height:39px;
							@media (max-width: 767px){
								font-size:17px;
								line-height:25px;
							}
						}
						a{
							color:$black;
							transition:opacity $delay $easing;
							.allowHover &:hover{
				            	opacity:0.6;
				            }
						}
					}
					p, p span{
						font-weight:300;
						font-size:14px;
						line-height:24px;
						margin:0 0 45px;
						@media (max-width: 1440px){
							font-size:13px;
							line-height:18px;
							margin:0 0 35px;
							@media (max-width: 767px){
								font-size:12px;
								line-height:16px;
								margin:0 0 25px;
							}
						}
					}
					p a{
						margin:30px 0 0 0;
						@media (max-width: 1440px){
							margin:20px 0 0 0;
							@media (max-width: 767px){
								margin:10px 0 0 0;
							}
						}
					}
					>a, p a{
			            color:$red;
			            font-size:16px;
			            display:table;
			            transition:color $delay $easing;
			            @media (max-width: 767px){
			            	font-size:12px;
			            }
			            i{
			                margin:0 5px 0 0;
			                display:inline-block;
			                font-size:22px;
			                transform:translateY(2px);
			                transition:transform $delay $easing;
			                @media (max-width: 767px){
			                	font-size:18px;
			                }
			            }
			            .allowHover &:hover{
			            	color:$redHover;
			            	i{
			            		transform:translate(5px,2px);
			            	}
			            }
					}
				}
				&:nth-child(2n+1){
					.thumb{
						float:right;
					}
					.txt{
						right:auto;
						left:0;
					}
					@media (max-width: 1280px){
						.txt{
							float:left;
						}
					}
				}
			}
			&.now{
				.blockTitle{
					margin:0 0 -60px;
			        img{
			        	width:180px;
			        	@media (max-width: 767px){
			        		width:125px;
			        	}
			        }
			       	div{
			        	top:60px;
			        	@media (max-width: 767px){
			        		top:20px;
			        	}
			        }
			    }
			}
		}
	}
	.wouldLike{
		border-left:$extraPadding solid $white;
		border-right:$extraPadding solid $white;
		clear:both;
		width:100%;
		float:left;
		padding:10px 0 30px;
		@media (max-width: 767px){
			border:none;
			padding:10px 0 0;
		}
		.list{
			padding:90px 0 0 0;
			@media (max-width: 1280px){
				.newsSmall:nth-child(3){
					display:none;
				}
				@media (max-width: 767px){
					padding:60px 0 0 0;
				}
			}
		}
	}
	.lawProject{
		width:48%;
		clear:both;
		float:left;
		margin:0 0 60px;
		@media (max-width: 1280px){
			width:100%;
		}
		.blockTitle{
	        margin:0;
	        img{
	        	width:250px;
	        	@media (max-width: 767px){
	        		width:175px;
	        	}
	        }
	        div{
	        	top:70px;
	        	@media (max-width: 767px){
	        		top:45px;
	        	}
	        }
	    }
		.webArticle{
			width:100%;
			border-top:1px solid $paleGrey;
			&:last-child{
				border-bottom:1px solid $paleGrey;
			}
			a{
				display:block;
				padding:20px 40px;
				@media (max-width: 767px){
					padding:10px 30px 20px;
				}
				.law{
					color:$charcoal;
					font-size:14px;
					font-family:$play;
					display:inline-block;
					margin:0 0 5px;
					@media (max-width: 767px){
						font-size:12px;
					}
					.status{
						color:$darkBlue;
						font-size:11px;
						display:inline-block;
						text-transform:uppercase;
						font-family:$muli;
						margin:0 0 0 15px;
						@media (max-width: 767px){
							font-size:10px;
						}
					}
				}
				p{
					color:$black;
					font-size:14px;
					line-height:28px;
					padding:0 0 0 40px;
					position:relative;
					width:100%;
					box-sizing:content-box;
					transform:translateX(-40px);
					@media (max-width: 767px){
						font-size:12px;
						line-height:22px;
					}
					i{
						color:$red;
						display:inline-block;
						font-size:22px;
						position:absolute;
						left:0;
						top:-1px;
						transition:left $delay $easing;
						@media (max-width: 767px){
							font-size:18px;
							left:10px;
						}
					}
				}
				.goTo{
					margin:10px 0 0 0;
					background:$grey;
					display:inline-block;
					color:$white;
					text-transform:uppercase;
					font-size:12px;
					padding:7px 10px 10px;
					transition:background $delay $easing;
					@media (max-width: 767px){
						font-size:10px;
						padding:5px 8px 8px;
					}
					i{
						font-size:18px;
						margin:0 2px 0 0;
						display:inline-block;
						@media (max-width: 767px){
							font-size:14px;
						}
					}
				}
				.allowHover &:hover{
					p{
						i{
							left:5px;
							@media (max-width: 767px){
								left:15px;
							}
						}
					}
					.goTo{
						background:$greyBlue;
					}
				}
			}
		}
	}
	.dossierNav{
		width:100%;
		float:left;
		clear:both;
		padding:55px 0;
		border-top:1px solid $paleGrey;
		border-bottom:1px solid $paleGrey;
		margin:0 0 70px;
		position:relative;
		@media (max-width: 767px){
			margin:0 0 50px;
			padding:15px 0 10px;
		}
		&:after{
			content:"";
			position:absolute;
			left:50%;
			top:30%;
			height:40%;
			width:1px;
			background:$paleGrey;
			transform:translateX(-50%);
			@media (max-width: 767px){
				display:none;
			}
		}
		>div{
			width:50%;
			float:left;
			position:relative;
			@media (max-width: 767px){
				margin:8px 0 12px;
			}
			.thumb{
				width:125px;
				display:inline-block;
				position:relative;
				z-index:10;
				@media (max-width: 1023px){
					width:100px;
					@media (max-width: 767px){
						display:none;
					}
				}
				img{
					width:100%;
					height:auto;
					display:inline-block;
				}
			}
			.txt{
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				padding:0 25px 0 150px;
				transform:translateY(-50%);
				@media (max-width: 1023px){
					padding:0 20px 0 120px;
					@media (max-width: 767px){
						padding:0;
					}
				}
				a{
					display:inline-block;
					span{
						font-size:14px;
						color:$charcoal;
						font-family:$play;
						margin:0 0 5px;
						display:inline-block;
						@media (max-width: 767px){
							font-size:12px;
							margin:0 0 5px 5px;
						}
					}
					p{
						font-size:17px;
						line-height:24px;
						font-weight:300;
						color:$black;
						transition:opacity $delay $easing;
						@media (max-width: 1023px){
							font-size:15px;
							line-height:22px;
							@media (max-width: 767px){
								display:none;
							}
						}
					}
					i{
						display:inline-block;
						color:$red;
						font-size:22px;
						transform:rotate(180deg);
						transition:transform $delay $easing;
						@media (max-width: 767px){
							float:left;
							font-size:18px;
							transform:rotate(180deg);
						}
					}
					.allowHover &:hover{
						p{
							opacity:0.6;
						}
						i{
							transform:rotate(180deg) translateX(3px);
						}
					}
				}
			}
			&.next{
				text-align:right;
				.txt{
					padding:0 150px 0 25px;
					@media (max-width: 1023px){
						padding:0 120px 0 20px;
						@media (max-width: 767px){
							padding:0;
						}
					}
					a{
						span{
							@media (max-width: 767px){
								margin:0 0 6px 0;
							}
						}
						i{
							transform:rotate(0deg);
							@media (max-width: 767px){
								float:none;
								transform:rotate(0deg) translateY(3px);
							}
						}
						.allowHover &:hover{
							i{
								transform:rotate(0deg) translate(3px,0);
								@media (max-width: 767px){
									transform:rotate(0deg) translate(3px,3px);
								}
							}
						}
					}
				}
			}
		}
	}
}
