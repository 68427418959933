/*
    HTML5 Reset :: style.css
    ----------------------------------------------------------
    We have learned much from/been inspired by/taken code where offered from:

    Eric Meyer                  :: http://ericmeyer.com
    HTML5 Doctor                :: http://html5doctor.com
    and the HTML5 Boilerplate   :: http://html5boilerplate.com

-------------------------------------------------------------------------------*/

/* Let's default this puppy out
-------------------------------------------------------------------------------*/

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, a, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article, aside, figure, footer, header, hgroup, nav, section {display: block;}

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {max-width: 100%;}

/* force a vertical scrollbar to prevent a jumpy page */
//html {overflow-y: scroll;}

/* we use a lot of ULs that aren't bulleted.
  don't forget to restore the bullets within content. */
ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before,
blockquote:after,
q:before,
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

/* tables still need cellspacing="0" in the markup */
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle; box-sizing: border-box;}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type="radio"] {vertical-align: text-bottom;}
input[type="checkbox"] {vertical-align: bottom; *vertical-align: baseline;}
//.ie6 input {vertical-align: text-bottom;}

select, input, textarea {font: 99% sans-serif;}

table {font-size: inherit; font: 100%;}

/* Accessible focus treatment
    people.opera.com/patrickl/experiments/keyboard/test
a:hover,
a:focus,
a:active {
  outline: none;
}*/

small {font-size: 85%;}

strong, th {font-weight: bold;}

td, td img {vertical-align: top;}

/* Make sure sup and sub don't screw with your line-heights
    gist.github.com/413930 */
sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

/* standardize any monospaced elements */
pre, code, kbd, samp {font-family: monospace, sans-serif;}

/* hand cursor on clickable elements */
.clickable,
input[type=button],
input[type=submit],
button {cursor: pointer;}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* Style clickable inputs in iOS */
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0;}

/* make buttons play nice in IE */
/* button {width: auto; overflow: visible;} */
button,
input {
  *overflow: visible; /* Inner spacing ie IE6/7 */
  line-height: normal; /* FF3/4 have !important on line-height in UA stylesheet */
}
button::-moz-focus-inner,
input::-moz-focus-inner { /* Inner padding and border oddities in FF3/4 */
  padding: 0;
  border: 0;
}

/* scale images in IE7 more attractively */
//.ie7 img {-ms-interpolation-mode: bicubic;}

/* prevent BG image flicker upon hover */
//.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}

/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }



/* Ok, this is where the fun starts.
-------------------------------------------------------------------------------*/

/* new Linux- and Windows-friendly sans-serif font stack: http://mky.be/fontstack */
body {font: 16px Helmet, Freesans, sans-serif;}

/* using local fonts? make sure to read up on Paul Irish's
  Bulletproof @font-face syntax: http://mky.be/font-face/bulletproof/ */

/* we like off-black for text */
/*body, select, input, textarea {color: #333;}*/

/*a {color: #03f;}
a:hover {color: #69f;}*/

/* Custom text-selection colors (remove any text shadows: twitter.com/miketaylr/status/12228805301) */
/*::-moz-selection{background: #fcd700; color: #fff; text-shadow: none;}
::selection {background: #fcd700; color: #fff; text-shadow: none;}*/

/*  j.mp/webkit-tap-highlight-color */
/*a:link {-webkit-tap-highlight-color: #fcd700;}*/

/*ins {background-color: #fcd700; color: #000; text-decoration: none;}
mark {background-color: #fcd700; color: #000; font-style: italic; font-weight: bold;}*/


body,html{width:100%;height:100%;}
a{text-decoration: none}
a,button{ cursor: pointer; font-size:100%;}
ul,li{padding:0;margin:0;}
button{border:0;padding:0;margin:0;}