.bannerTitle{
    position:relative;
    margin:0 0 80px;
    @media (max-width: 1023px){
        margin:0 0 60px;
        @media (max-width: 767px){
            margin:0 0 25px;
        }
    }
    .txt{
        left:0;
        top:0;
        width:60%;
        z-index:10;
        position:absolute;
        height:100%;
        @media (max-width: 767px){
            width:100%;
            position:relative;
        }
        .back{
            color:$black;
            font-size:14px;
            display:inline-block;
            margin:35px 0 0 0;
            transition:opacity $delay $easing;
            @media (max-width: 1280px){
                margin:5px 0 0 0;
                @media (max-width: 767px){
                    font-size:12px;
                    margin:0;
                }
            }
            i{
                margin:0 10px 0 0;
                font-size:20px;
                display:inline-block;
                transform:rotate(180deg) translate(3px, -1px);
                @media (max-width: 767px){
                    font-size:18px;
                }
                @-moz-document url-prefix(){
                    transform:rotate(180deg) translate(3px, -2px);
                }
            }
            .allowHover &:hover{
                opacity:0.6;
            }
        }
        >div{
            position:absolute;
            top:55%;
            width:100%;
            transform:translateY(-50%);
            @media (max-width: 1023px){
                top:53%;
                @media (max-width: 767px){
                    position:relative;
                    top:auto;
                    transform:translateY(0);
                    margin:15px 0 0 0;
                }
            }
            img{
                width:65%;
                height:auto;
                display:block;
                margin:0 0 20px;
                @media (max-width: 767px){
                    width:100%;
                    margin:0 0 15px;
                }
            }
            .icon{
                font-size:19px;
                display:block;
                margin:0 0 15px;
                transform:translateX(-5px);
                @media (max-width: 767px){
                   font-size:15px;
                   transform:translateX(-10px);
                }
                i{
                    display:inline-block;
                    color:$iconBlue;
                    font-size:35px;
                    transform:translate(-5px, 4px);
                    @media (max-width: 767px){
                       font-size:25px;
                       transform:translate(0px, 2px);
                    }
                }
            }
            .date{
                font-family:$play;
                color:$charcoal;
                font-size:16px;
                margin:0 0 10px;
                display:inline-block;
                @media (max-width: 767px){
                    font-size:14px;
                }
            }
            .dateAndIcon{
                .calendar{
                    display:inline-block;
                    width:51px;
                    height:43px;
                    position:relative;
                    i{
                        position:absolute;
                        left:-4px;
                        top:-8px;
                        color:$iconBlue;
                        font-size:43px;
                        @media (max-width: 767px){
                            font-size:34px;
                            top:-2px;
                            left:-5px;
                        }
                    }
                    .digit{
                        color:$red;
                        position:absolute;
                        left:50%;
                        top:15px;
                        font-size:17px;
                        transform:translateX(-50%);
                        @media (max-width: 767px){
                            font-size:13px;
                            left:36%;
                            top:16px;
                        }
                    }
                }
                .full{
                    font-size:16px;
                    color:$charcoal;
                    display:inline-block;
                    font-family:$play;
                    margin:0 0 0 5px;
                    transform:translateY(-15px);
                    @media (max-width: 767px){
                        font-size:14px;
                        margin:0;
                    }
                }
            }
            h1{
                font-size:50px;
                line-height:55px;
                font-weight:400;
                font-family:$play;
                margin:0 0 35px;
                @media (max-width: 1280px){
                    font-size:40px;
                    line-height:45px;
                    margin:0 0 25px;
                    @media (max-width: 1023px){
                        font-size:28px;
                        line-height:31px;
                        margin:0 0 20px;
                        @media (max-width: 767px){
                            margin:0 0 15px;
                        }
                    }
                }
            }
            h6{
                text-transform:uppercase;
                font-size:12px;
                font-weight:400;
                margin:25px 0 15px;
                @media (max-width: 767px){
                    margin:20px 0 10px;
                    font-size:11px;
                }
            }
            .ctaLink{
                float:left;
                margin:20px 0 0 0;
                @media (max-width: 1280px){
                    margin:10px 0 0 0;
                }
            }
            .socialMedia{
                margin:10px 0 0 -10px;
            }
        }
    }
    .img{
        transform:translateX(70px);
        width:69%;
        float:right;
        position:relative;
        @media (max-width: 1280px){
            transform:translateX(35px);
            @media (max-width: 767px){
                width:100%;
                float:none;
                margin:20px 0 20px 0;
                transform:translateX(0px);
                &:before{
                    display:none;
                }
            }
        }
        &:before{
            content:"";
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:10;
            opacity:0.6;
            background: linear-gradient(to right, rgba(229,241,246,1) 0%,rgba(255,255,255,0) 100%);
        }
        img{
            display:block;
        }
    }
}
