.widget{
	width:70px;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	background:$paleBlue;
	cursor:pointer;
	z-index:15000;
	transition:width 0.2s $easing, background-color $delay $easing;
	@media (max-width: 1023px){
		left:auto;
		right:0;
		width:0;
		position:absolute;
		.stickyNav &{
			position:fixed;
		}
	}
	.allowHover &:hover{
		background-color:$paleBlueHover;
		.alert{
			i{
				animation: ring 6s ease-in-out infinite;
  				transform-origin: 50% 4px;
			}
		}
	}
	.alert{
		position:absolute;
		top:85px;
		right:24px;
		@media (max-width: 1023px){
			right:17px;
			top:17px;
		}
		i{
			display:inline-block;
			font-size:19px;
			color:$greyBlue;
		}
		.box{
			position:absolute;
			top:-5px;
			left:34px;
			width:34px;
			height:34px;
			background:$red;
			@media (max-width: 1023px){
				width:22px;
				height:22px;
				left:-22px;
				top:1px;
			}
			span{
				text-align:center;
				font-size:11px;
				color:$white;
				display:block;
				line-height:34px;
				@media (max-width: 1023px){
					line-height:22px;
					font-size:10px;
				}
			}
			&:before{
				position:absolute;
				left:-5px;
				top:12px;
				content:"";
				border-bottom: 5px solid transparent;
   		 		border-right: 5px solid $red;
    			border-top: 5px solid transparent;
    			@media (max-width: 1023px){
    				border-bottom: 4px solid transparent;
    				border-right: none;
				    border-left: 4px solid $red;
				    border-top: 4px solid transparent;
				    content: "";
				    position: absolute;
				    left:auto;
				    right: -4px;
				    top: 9px;
				}
			}
		}
	}
	.content{
		visibility:hidden;
		opacity:0;
		width:100%;
		padding:0;
		position:absolute;
		top:0;
		height:100%;
		left:0;
		cursor:default;
		>.btnClose{
			cursor:pointer;
			position:absolute;
			top:0;
			left:0;
			width:70px;
			height:100%;
			border-right:1px solid $greyBlue;
			transition:background $delay $easing;
			@media (max-width: 1023px){
				display:none;
			}
			i{
				left:50%;
				top:50%;
				font-size:15px;
				position:absolute;
				display:inline-block;
				transform:translate(-50%,-50%);
				color:$greyBlue;
			}
			.allowHover &:hover{
				background:$paleBlueHover;
			}
		}
		.widgetCta{
			width:430px;
			position:absolute;
			top:0;
			right:0;
			overflow:scroll;
			padding:150px 0 0 0;
			height:100%;
			@media (max-width: 1023px){
				padding:0;
				@media (max-width: 767px){
					width:260px;
				}
			}
			.notifications{
				width:100%;
				.title{
					position:relative;
					background:$paleBlueHover;
					padding:30px 120px 28px 50px;
					@media (max-width: 767px){
						padding:25px 60px 23px 50px;
					}
					>i{
						position:absolute;
						left:13px;
						top:50%;
						font-size:19px;
						color:$greyBlue;
						transform:translateY(-50%);
					}
					h5{
						color:$greyBlue;
						font-weight:400;
						font-size:12px;
						text-transform:uppercase;
						@media (max-width: 767px){
							font-size:10px;
						}
					}
					.btnClose{
						position:absolute;
						top:50%;
						right:45px;
						cursor:pointer;
						color:$greyBlue;
						transform:translateY(-50%);
						transition:color $delay $easing;
						display:none;
						@media (max-width: 1023px){
							display:block;
							@media (max-width: 767px){
								right:20px;
							}
						}
						i{
							font-size:12px;
							.allowHover &:hover{
								color:$greyBlueHover;
							}
						}	
					}
					.box{
						position:absolute;
						top:50%;
						right:50px;
						width:34px;
						height:34px;
						background:$red;
						transform:translateY(-50%);
						@media (max-width: 1023px){
							display:none;
						}
						span{
							text-align:center;
							font-size:11px;
							color:$white;
							display:block;
							line-height:34px;
						}
						&:before{
							position:absolute;
							left:-5px;
							top:12px;
							content:"";
							border-bottom: 5px solid transparent;
			   		 		border-right: 5px solid $red;
			    			border-top: 5px solid transparent;
						}
					}
				}
				.notification{
					position:relative;
					height:95px;
					padding:0 120px 0 50px;
					display:block;
					transition:background $delay $easing;
					@media (max-width: 767px){
						height:75px;
						padding:0 70px 0 30px;
					}
					.txt{
						position:absolute;
						top:50%;
						width:60%;
						transform:translateY(-50%);
						h6{
							font-size:15px;
							font-weight:400;
							color:$greyBlue;
							margin:0 0 5px;
							@media (max-width: 767px){
								font-size:12px;
							}
						}
						p{
							color:$greyBlue;
							font-size:14px;
							font-weight:300;
							@media (max-width: 767px){
								font-size:12px;
							}
						}
					}
					.moment{
						position:absolute;
						right:50px;
						width:70px;
						text-align:right;
						top:50%;
						transform:translateY(-50%);
						@media (max-width: 767px){
							right:20px;
							width:60px;
						}
						span{
							color:$greyBlue;
							font-size:14px;
							font-family:$play;
							@media (max-width: 767px){
								font-size:12px;
							}
						}
					}
					&.unseen{
						background:#dae9f0;
						margin:1px 0 0 0;
						&:after{
							position:absolute;
							content:"";
							background:$red;
							width:8px;
							height:8px;
							border-radius:4px;
							left:21px;
							top:42px;
							@media (max-width: 767px){
								left:12px;
							}
						}
						.txt{
							h6{
								color:$black;
							}
							p{
								color:$black;
							}
						}
						.moment{
							span{
								color:$black;
							}
						}
					}
					.allowHover &:hover{
						background:$paleBlueHover;
					}
				}
			}
			.subjectFollow{
				margin:60px 0 0 0;
				padding:0 50px 50px;
				@media (max-width: 767px){
					padding:0 25px 25px;
					margin:40px 0 0 0;
				}
				.title{
					padding:0 0 15px;
					border-bottom:1px solid $greyBlue;
					h5{
						font-weight:400;
						font-size:12px;
						color:$greyBlue;
						text-transform:uppercase;
						@media (max-width: 767px){
							font-size:10px;
						}
					}
					i{
						font-size:23px;
						display:inline-block;
						transform:translate(-4px, 2px);
						@media (max-width: 767px){
							font-size:18px;
						}
					}
				}
				.subject{
					width:100%;
					margin:25px 0 0 0;
					position:relative;
					@media (max-width: 767px){
						margin:20px 0 0 0;
					}
					p{
						font-size:14px;
						color:$greyBlue;
						@media (max-width: 767px){
							font-size:12px;
						}
					}
					.switch{
						cursor:pointer;
						width:24px;
						height:16px;
						border-radius:8px;
						background:$greyBlue;
						position:absolute;
						right:0;
						top:1px;
						transition:background 0.2s $easing;
						span{
							position:absolute;
							background:$paleBlue;
							top:1px;
							left:1px;
							width:14px;
							height:14px;
							border-radius:7px;
							display:inline-block;
							transition:left 0.2s $easing;
						}
						&.active{
							background:$red;
							span{
								left:9px;
							}
						}
					}
				}
			}
		}
	}
	&.open{
		width:500px;
		@media (max-width: 1023px){
			position:fixed;
			width:430px;
			@media (max-width: 767px){
				width:260px;
			}
		}
		.allowHover &:hover{
			background:$paleBlue;
		}
	}
	&.noNotif{
		.content{
			.widgetCta{
				.notifications{
					.title{
						background:transparent;
						display:none;
						@media (max-width: 1023px){
							display:block;
						}
					}
				} 
			} 
		} 
	}
}


@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}
