@charset "UTF-8";
/*** UTILS ***/
/*
    HTML5 Reset :: style.css
    ----------------------------------------------------------
    We have learned much from/been inspired by/taken code where offered from:

    Eric Meyer                  :: http://ericmeyer.com
    HTML5 Doctor                :: http://html5doctor.com
    and the HTML5 Boilerplate   :: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, a, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box; }

article, aside, figure, footer, header, hgroup, nav, section {
  display: block; }

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%; }

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
  don't forget to restore the bullets within content. */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle;
  box-sizing: border-box; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom;
  *vertical-align: baseline; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

/* Accessible focus treatment
    people.opera.com/patrickl/experiments/keyboard/test
a:hover,
a:focus,
a:active {
  outline: none;
}*/
small {
  font-size: 85%; }

strong, th {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't screw with your line-heights
    gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
input[type=button],
input[type=submit],
button {
  cursor: pointer; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* Style clickable inputs in iOS */ }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
/* button {width: auto; overflow: visible;} */
button,
input {
  *overflow: visible;
  /* Inner spacing ie IE6/7 */
  line-height: normal;
  /* FF3/4 have !important on line-height in UA stylesheet */ }

button::-moz-focus-inner,
input::-moz-focus-inner {
  /* Inner padding and border oddities in FF3/4 */
  padding: 0;
  border: 0; }

/* scale images in IE7 more attractively */
/* prevent BG image flicker upon hover */
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

/* Ok, this is where the fun starts.
-------------------------------------------------------------------------------*/
/* new Linux- and Windows-friendly sans-serif font stack: http://mky.be/fontstack */
body {
  font: 16px Helmet, Freesans, sans-serif; }

/* using local fonts? make sure to read up on Paul Irish's
  Bulletproof @font-face syntax: http://mky.be/font-face/bulletproof/ */
/* we like off-black for text */
/*body, select, input, textarea {color: #333;}*/
/*a {color: #03f;}
a:hover {color: #69f;}*/
/* Custom text-selection colors (remove any text shadows: twitter.com/miketaylr/status/12228805301) */
/*::-moz-selection{background: #fcd700; color: #fff; text-shadow: none;}
::selection {background: #fcd700; color: #fff; text-shadow: none;}*/
/*  j.mp/webkit-tap-highlight-color */
/*a:link {-webkit-tap-highlight-color: #fcd700;}*/
/*ins {background-color: #fcd700; color: #000; text-decoration: none;}
mark {background-color: #fcd700; color: #000; font-style: italic; font-weight: bold;}*/
body, html {
  width: 100%;
  height: 100%; }

a {
  text-decoration: none; }

a, button {
  cursor: pointer;
  font-size: 100%; }

ul, li {
  padding: 0;
  margin: 0; }

button {
  border: 0;
  padding: 0;
  margin: 0; }

/* RESPONSIVE
===========================
*/
@font-face {
  font-family: 'fontello';
  src: url("font/fontello.eot?34358135");
  src: url("font/fontello.eot?34358135#iefix") format("embedded-opentype"), url("font/fontello.woff2?34358135") format("woff2"), url("font/fontello.woff?34358135") format("woff"), url("font/fontello.ttf?34358135") format("truetype"), url("font/fontello.svg?34358135#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?54150336#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-404:before {
  content: '\e800'; }

/* '' */
.icon-arrow:before {
  content: '\e801'; }

/* '' */
.icon-badge:before {
  content: '\e802'; }

/* '' */
.icon-benevole:before {
  content: '\e803'; }

/* '' */
.icon-calendar-01:before {
  content: '\e804'; }

/* '' */
.icon-calendar:before {
  content: '\e805'; }

/* '' */
.icon-chevron:before {
  content: '\e806'; }

/* '' */
.icon-close:before {
  content: '\e807'; }

/* '' */
.icon-communique:before {
  content: '\e808'; }

/* '' */
.icon-compass:before {
  content: '\e809'; }

/* '' */
.icon-contact:before {
  content: '\e80a'; }

/* '' */
.icon-don:before {
  content: '\e80b'; }

/* '' */
.icon-dossier:before {
  content: '\e80c'; }

/* '' */
.icon-engrenage:before {
  content: '\e80d'; }

/* '' */
.icon-envelope:before {
  content: '\e80e'; }

/* '' */
.icon-facebook:before {
  content: '\e80f'; }

/* '' */
.icon-flag:before {
  content: '\e810'; }

/* '' */
.icon-fullscreen:before {
  content: '\e811'; }

/* '' */
.icon-instagram:before {
  content: '\e812'; }

/* '' */
.icon-law:before {
  content: '\e813'; }

/* '' */
.icon-like:before {
  content: '\e814'; }

/* '' */
.icon-link:before {
  content: '\e815'; }

/* '' */
.icon-microphone:before {
  content: '\e816'; }

/* '' */
.icon-monument:before {
  content: '\e817'; }

/* '' */
.icon-mouse:before {
  content: '\e818'; }

/* '' */
.icon-notification:before {
  content: '\e819'; }

/* '' */
.icon-pin:before {
  content: '\e81a'; }

/* '' */
.icon-play:before {
  content: '\e81b'; }

/* '' */
.icon-plus:before {
  content: '\e81c'; }

/* '' */
.icon-presidents:before {
  content: '\e81d'; }

/* '' */
.icon-reply:before {
  content: '\e81e'; }

/* '' */
.icon-retweet:before {
  content: '\e81f'; }

/* '' */
.icon-search:before {
  content: '\e820'; }

/* '' */
.icon-social_media:before {
  content: '\e821'; }

/* '' */
.icon-tool:before {
  content: '\e822'; }

/* '' */
.icon-twitter:before {
  content: '\e823'; }

/* '' */
.icon-valeurs_appartenance_canadienne:before {
  content: '\e824'; }

/* '' */
.icon-valeurs_developpement_economique:before {
  content: '\e825'; }

/* '' */
.icon-valeurs_equite_intergenerationnelle:before {
  content: '\e826'; }

/* '' */
.icon-valeurs_identification_quebec:before {
  content: '\e827'; }

/* '' */
.icon-valeurs_justice_sociale:before {
  content: '\e828'; }

/* '' */
.icon-valeurs_liberte_individuelle:before {
  content: '\e829'; }

/* '' */
.icon-valeurs_respect_societe:before {
  content: '\e82a'; }

/* '' */
.icon-valeurs_vie_politique:before {
  content: '\e82b'; }

/* '' */
.icon-vision:before {
  content: '\e82c'; }

/* '' */
.icon-web:before {
  content: '\e82d'; }

/* '' */
.icon-youtube:before {
  content: '\e82e'; }

/* '' */
.icon-moins:before {
  content: '\e82f'; }

/* '' */
/*** COLOR ***/
/*** FONT ***/
/*** SIZE VALUE ***/
/*** ANIMATION CSS ***/
/*** LIBS ***/
/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1; }

.bx-wrapper img {
  max-width: 100%;
  display: block; }

/** THEME
===================================*/
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%; }

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000; }

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px; }

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline; }

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000; }

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px; }

.bx-wrapper .bx-next {
  right: 10px; }

.bx-wrapper .bx-prev:hover {
  background-position: 0 0; }

.bx-wrapper .bx-next:hover {
  background-position: -43px 0; }

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  outline: 0;
  z-index: 9999; }

.bx-wrapper .bx-controls-direction a.disabled {
  display: none; }

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center; }

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0; }

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px; }

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px; }

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

/*** CORE ***/
body {
  width: 100%;
  color: #252525;
  font-family: "Muli", sans-serif;
  overflow-x: hidden;
  max-width: 1920px;
  margin: 0 auto; }
  body .wrapper {
    padding: 0 0 0 70px; }
    @media (max-width: 1023px) {
      body .wrapper {
        padding: 0; } }
    body .wrapper .container {
      padding: 0 7%; }
      body .wrapper .container.beigeBg {
        background: #f8f7f3; }
        body .wrapper .container.beigeBg:first-child {
          padding: 120px 7% 0 7%; }
  body.stickyNav .wrapper {
    padding: 120px 0 0 70px; }
    @media (max-width: 1023px) {
      body.stickyNav .wrapper {
        padding: 60px 0 0 0px; } }

article .intro {
  float: left;
  width: 45%;
  padding: 0 50px 0 0; }
  @media (max-width: 1023px) {
    article .intro {
      width: 100%;
      padding: 0 0 20px; } }
  article .intro .hashtags {
    background: #c4e3f0;
    padding: 45px 40px 40px;
    width: 100%;
    margin: 0 0 45px; }
    @media (max-width: 767px) {
      article .intro .hashtags {
        padding: 25px 20px 20px;
        margin: 0 0 30px; } }
    article .intro .hashtags p {
      font-size: 17px;
      line-height: 26px;
      font-weight: 300;
      margin: 0 0 20px; }
      @media (max-width: 767px) {
        article .intro .hashtags p {
          font-size: 14px;
          line-height: 20px;
          margin: 0 0 15px; } }
    article .intro .hashtags a {
      display: inline-block;
      color: #ed1b2e;
      font-size: 17px;
      font-weight: 300;
      margin: 0 15px 10px 0;
      transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 767px) {
        article .intro .hashtags a {
          font-size: 14px;
          margin: 0 10px 10px 0; } }
      .allowHover article .intro .hashtags a:hover {
        opacity: 0.6; }

article .mosaique {
  float: left;
  width: 45%;
  padding: 140px 50px 0 0; }
  @media (max-width: 1023px) {
    article .mosaique {
      width: 100%;
      padding: 0px 0 40px 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    article .mosaique {
      padding: 20px 0 40px 0; } }
  article .mosaique img {
    display: block;
    float: left;
    width: 50%;
    height: auto;
    position: relative; }
    article .mosaique img:first-child {
      width: 100%;
      border-bottom: 1px solid #ffffff; }
    article .mosaique img:nth-child(2) {
      border-right: 1px solid #ffffff; }

article .content {
  float: right;
  width: 55%; }
  @media (max-width: 1023px) {
    article .content {
      width: 100%; } }

.blockTitle {
  position: relative; }
  .blockTitle > img {
    display: block;
    margin: 0 0 0 30px; }
    @media (max-width: 767px) {
      .blockTitle > img {
        margin: 0 0 0 15px; } }
  .blockTitle div {
    position: absolute;
    left: 0; }
    .blockTitle div h1, .blockTitle div h2, .blockTitle div h3 {
      color: #ed1b2e;
      font-size: 37px;
      font-family: "Playfair Display", serif;
      display: inline-block;
      font-weight: 400; }
      @media (max-width: 1023px) {
        .blockTitle div h1, .blockTitle div h2, .blockTitle div h3 {
          width: 100%; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .blockTitle div h1, .blockTitle div h2, .blockTitle div h3 {
      font-size: 25px;
      line-height: 30px; } }
    .blockTitle div a {
      -webkit-font-smoothing: antialiased;
      font-size: 13px;
      color: #252525;
      display: inline-block;
      border-bottom: 1px solid #252525;
      margin: 0 0 0 30px;
      transform: translateY(-5px); }
      @media (max-width: 1023px) {
        .blockTitle div a {
          margin: 5px 0 0 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .blockTitle div a {
      margin: 10px 0 0 0; } }
  .blockTitle p {
    width: 60%;
    font-size: 17px;
    line-height: 31px;
    font-weight: 300; }
    @media (max-width: 767px) {
      .blockTitle p {
        width: 100%;
        font-size: 14px;
        line-height: 26px; } }
  .blockTitle a {
    color: #ed1b2e;
    font-size: 16px;
    display: inline-block;
    margin: 10px 0 0 0;
    transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .blockTitle a i {
      display: inline-block;
      font-size: 22px;
      transform: translateY(2px);
      transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .allowHover .blockTitle a:hover {
      opacity: 0.6; }
      .allowHover .blockTitle a:hover i {
        transform: translate(5px, 2px); }

.filter {
  width: 100%;
  float: left;
  position: relative; }
  .filter > a {
    background: white;
    height: 70px;
    display: block;
    padding: 0 40px 0 25px;
    width: 100%;
    font-size: 15px;
    line-height: 70px;
    position: relative;
    overflow: hidden;
    font-weight: 300;
    color: #252525;
    transition: box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    @media (max-width: 1280px) {
      .filter > a {
        font-size: 12px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .filter > a {
      padding: 0 40px 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .filter > a {
      height: 50px;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      padding: 0 30px 0 15px; } }
    .filter > a > span {
      position: relative;
      z-index: 15;
      display: inline-block;
      width: 100%;
      text-align: left; }
    .filter > a i {
      right: 12px;
      position: absolute;
      z-index: 20;
      top: 0;
      font-size: 22px;
      transform: rotate(90deg); }
      @media (max-width: 767px) {
        .filter > a i {
          font-size: 18px;
          right: 7px; } }
    .allowHover .filter > a:hover {
      box-shadow: 0px 10px 10px -2px rgba(0, 0, 0, 0.1); }
  .filter.open {
    z-index: 100; }
    .filter.open > a {
      box-shadow: 0px 10px 10px -2px rgba(0, 0, 0, 0.1); }
      .filter.open > a i {
        transform: rotate(270deg); }
    .filter.open ul {
      box-shadow: 0px 10px 10px -2px rgba(0, 0, 0, 0.1); }
  .filter ul {
    clear: both;
    float: left;
    width: 100%;
    height: 0;
    top: 70px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    max-height: 355px;
    background: #ffffff;
    overflow-y: scroll;
    transition: box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    @media (max-width: 767px) {
      .filter ul {
        top: 50px; } }
    .filter ul li {
      cursor: pointer;
      padding: 0 25px;
      font-size: 14px;
      font-weight: 300;
      clear: both;
      float: left;
      width: 100%;
      overflow: hidden;
      margin: 0 0 20px; }
      @media (max-width: 1023px) {
        .filter ul li {
          font-size: 13px;
          padding: 0 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .filter ul li {
      font-size: 12px;
      padding: 0 15px; } }
      .filter ul li a {
        display: block;
        color: #252525;
        transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .allowHover .filter ul li a:hover {
          color: #ed1b2e; }

.filters-btn-menu .filters-btn-list {
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
  display: flex; }
  .filters-btn-menu .filters-btn-list .filters-btn-item {
    flex: 1 1;
    margin-right: 1px; }
    .filters-btn-menu .filters-btn-list .filters-btn-item:last-child {
      margin-right: 0; }
    .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link {
      background-color: #ffffff;
      color: #252525;
      font-size: 14px;
      padding: 8px 5px;
      min-height: 50px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-evenly;
      position: relative;
      transition: color 500ms ease;
      outline: none !important; }
      .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link .filters-btn-logo {
        margin-bottom: 10px; }
      .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:after {
        content: "";
        height: 2px;
        width: 0;
        background-color: #ed1b2e;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transition: width 500ms ease, background-color 500ms ease; }
      .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link.active, .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:hover, .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:focus {
        color: #ed1b2e; }
        .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link.active:after, .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:hover:after, .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:focus:after {
          width: 100%; }
      .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:active, .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:active:focus {
        color: #cf1022; }
        .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:active:after, .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link:active:focus:after {
          background-color: #cf1022; }
  .filters-btn-menu .filters-btn-list.filters-btn-main {
    margin-bottom: 15px; }
    .filters-btn-menu .filters-btn-list.filters-btn-main .filters-btn-item .filters-btn-link {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase; }
  .filters-btn-menu .filters-btn-list.filters-btn-sub .filters-btn-item .filters-btn-link {
    font-size: 12px; }

@media (max-width: 1280px) {
  .filters-btn-menu .filters-btn-list {
    flex-wrap: wrap; }
    .filters-btn-menu .filters-btn-list .filters-btn-item {
      margin-bottom: 1px;
      min-width: 100px; }
      .filters-btn-menu .filters-btn-list .filters-btn-item .filters-btn-link {
        font-size: 12px; }
    .filters-btn-menu .filters-btn-list.filters-btn-sub .filters-btn-item {
      flex-basis: calc(20% - 1px); }
      .filters-btn-menu .filters-btn-list.filters-btn-sub .filters-btn-item .filters-btn-link {
        font-size: 11px; } }

.tag span, .tag a {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  padding: 7px 12px;
  display: inline-block;
  background: #ed1b2e;
  color: #ffffff;
  position: relative;
  z-index: 10;
  transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  @media (max-width: 767px) {
    .tag span, .tag a {
      padding: 5px 7px;
      font-size: 11px; } }
  .tag span:nth-child(2), .tag a:nth-child(2) {
    transform: translate(-15px, 10px);
    padding: 7px 12px 7px 17px; }
    @media (max-width: 767px) {
      .tag span:nth-child(2), .tag a:nth-child(2) {
        padding: 5px 7px 5px 10px;
        transform: translate(-9px, 10px); } }
  .tag span:last-child, .tag a:last-child {
    z-index: 5;
    background: #007ac2; }

.tag a:hover {
  background: #0068a5; }

.tag span:nth-child(2) {
  transform: translate(-10px, 10px); }
  @media (max-width: 767px) {
    .tag span:nth-child(2) {
      transform: translate(-7px, 10px); } }

.follow {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  color: #252525;
  display: inline-block;
  transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  @media (max-width: 767px) {
    .follow {
      font-size: 12px; } }
  .follow i {
    font-size: 20px;
    display: inline-block;
    margin: 0 5px 0 0;
    transform: translateY(1px); }
    @media (max-width: 767px) {
      .follow i {
        font-size: 18px; } }
  .allowHover .follow:hover {
    opacity: 0.6; }

.socialMedia {
  margin: 10px 0 0 0; }
  .socialMedia a {
    color: #aec4cd;
    font-size: 18px;
    margin: 0 2px;
    display: inline-block;
    transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .allowHover .socialMedia a:hover {
      color: #7b97a3; }

.ctaLink {
  background: #ed1b2e;
  color: #ffffff;
  padding: 18px 30px 23px 35px;
  display: table;
  font-size: 16px;
  transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  @media (max-width: 1023px) {
    .ctaLink {
      padding: 13px 25px 18px 30px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .ctaLink {
      padding: 10px 18px 15px 22px;
      font-size: 12px; } }
  .ctaLink i {
    font-size: 22px;
    display: inline-block;
    margin: 0 0 0 10px;
    transform: translateY(4px);
    transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    @media (max-width: 767px) {
      .ctaLink i {
        font-size: 18px;
        transform: translateY(3px); } }
  .allowHover .ctaLink:hover {
    background: #ca1727; }
    .allowHover .ctaLink:hover i {
      transform: translate(5px, 4px); }

.newsletter {
  width: 48%;
  float: right;
  position: relative;
  margin: 0 0 60px;
  -webkit-font-smoothing: antialiased; }
  @media (max-width: 1280px) {
    .newsletter {
      float: none;
      width: 65%;
      margin: 0 auto 60px;
      clear: both; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .newsletter {
      width: 100%; } }
  .newsletter img {
    width: 100%;
    height: auto; }
  .newsletter .txt {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%); }
    @media (max-width: 767px) {
      .newsletter .txt {
        width: 85%; } }
    .newsletter .txt h2 {
      -webkit-transform: translateZ(0);
      font-size: 35px;
      line-height: 50px;
      text-align: center;
      color: #ed1b2e;
      font-family: "Playfair Display", serif;
      margin: 0 0 50px;
      font-weight: 400; }
      @media (max-width: 1023px) {
        .newsletter .txt h2 {
          font-size: 30px;
          line-height: 40px;
          margin: 0 0 40px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .newsletter .txt h2 {
      font-size: 20px;
      line-height: 26px;
      margin: 0 0 25px; } }
    .newsletter .txt .ctaLink {
      -webkit-transform: translateZ(0);
      margin: 0 auto;
      display: table; }

.newsletter-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px; }

.startPlayer {
  position: relative;
  margin: 0 0 50px;
  background: #252525;
  cursor: pointer; }
  .startPlayer img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0.5; }
  .startPlayer .btnPlay {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    @media (max-width: 767px) {
      .startPlayer .btnPlay {
        width: 50px;
        height: 50px; } }
    .startPlayer .btnPlay i {
      display: inline-block;
      color: #ffffff;
      font-size: 78px;
      margin: -8px 0 0 -13px;
      transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 767px) {
        .startPlayer .btnPlay i {
          font-size: 58px; } }
  .startPlayer iframe {
    position: absolute;
    top: 0;
    left: 0; }
  .allowHover .startPlayer:hover .btnPlay {
    transform: translate(-50%, -50%) scale(1.1); }
    .allowHover .startPlayer:hover .btnPlay i {
      color: #ed1b2e; }

body .wrapper .gform_wrapper {
  position: relative;
  width: 100%;
  background: #dfeef4;
  padding: 65px 5% 75px;
  margin: 100px 0 0 0; }
  @media (max-width: 767px) {
    body .wrapper .gform_wrapper {
      padding: 35px 5% 45px;
      margin: 60px 0 0 0; } }
  body .wrapper .gform_wrapper form .gform_heading h3 {
    color: #ed1b2e;
    width: 100%;
    font-family: "Playfair Display", serif;
    font-size: 56px;
    text-align: center;
    font-weight: 400; }
    @media (max-width: 767px) {
      body .wrapper .gform_wrapper form .gform_heading h3 {
        font-size: 32px; } }
  body .wrapper .gform_wrapper form .gform_heading span {
    font-size: 22px;
    line-height: 38px;
    font-family: "Playfair Display", serif;
    text-align: center;
    width: 100%;
    margin: 0 0 60px;
    display: block; }
    @media (max-width: 767px) {
      body .wrapper .gform_wrapper form .gform_heading span {
        font-size: 14px;
        line-height: 22px;
        margin: 10px 0 20px; } }
  body .wrapper .gform_wrapper form .validation_error {
    border: none;
    font-size: 22px;
    line-height: 38px;
    font-family: "Playfair Display", serif;
    text-align: center;
    width: 100%;
    margin: 0 0 60px;
    display: block;
    color: red; }
    @media (max-width: 767px) {
      body .wrapper .gform_wrapper form .validation_error {
        font-size: 14px;
        line-height: 22px;
        margin: 10px 0 20px; } }
  body .wrapper .gform_wrapper form .gform_confirmation_wrapper {
    color: #007ac2;
    font-size: 22px;
    line-height: 38px;
    font-family: "Playfair Display", serif;
    text-align: center;
    width: 100%;
    display: block;
    margin: 10px 0 0 0; }
    @media (max-width: 767px) {
      body .wrapper .gform_wrapper form .gform_confirmation_wrapper {
        font-size: 14px;
        line-height: 22px; } }
  body .wrapper .gform_wrapper form .gform_body > ul > li {
    width: 46%;
    float: left;
    margin: 0 2% !important;
    padding: 0; }
    @media (max-width: 767px) {
      body .wrapper .gform_wrapper form .gform_body > ul > li {
        width: 100%;
        float: left;
        margin: 0; } }
    body .wrapper .gform_wrapper form .gform_body > ul > li.gform_hidden {
      display: none; }
    body .wrapper .gform_wrapper form .gform_body > ul > li.gsection, body .wrapper .gform_wrapper form .gform_body > ul > li.fullWidth {
      width: 100%;
      padding: 0 4% 0 0; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.gsection > div, body .wrapper .gform_wrapper form .gform_body > ul > li.fullWidth > div {
        height: auto; }
    body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div {
      margin: 15px 0 0 0;
      width: 100%; }
      @media (max-width: 767px) {
        body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div {
          margin: 5px 0 0 0; } }
      body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span {
        width: 48%;
        margin: 0 2% 0 0 !important;
        float: left;
        padding: 0 !important; }
        @media (max-width: 767px) {
          body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span {
            width: 100%;
            margin: 0; } }
        body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span.ginput_left {
          padding: 0 !important; }
        body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span label {
          margin: 5px 0 45px 0;
          display: inline-block;
          font-size: 12px;
          width: 100%;
          text-align: center; }
          @media (max-width: 767px) {
            body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span label {
              font-size: 11px;
              margin: 5px 0 20px 0; } }
      body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span:nth-child(even) {
        margin: 0 0 0 2% !important; }
        @media (max-width: 767px) {
          body .wrapper .gform_wrapper form .gform_body > ul > li.nameField div span:nth-child(even) {
            margin: 0; } }
    body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div {
      margin: 0;
      width: 100%; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div {
        width: 38%;
        margin: 15px 2% 0 0;
        float: left; }
        @media (max-width: 767px) {
          body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div {
            width: 100%;
            margin: 5px 0; } }
        body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div i {
          display: none; }
        body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div label {
          margin: 5px 0 45px 0;
          display: inline-block;
          font-size: 12px;
          width: 100%;
          text-align: center; }
          @media (max-width: 767px) {
            body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div label {
              font-size: 11px;
              margin: 5px 0 20px 0; } }
        body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div input {
          width: 100% !important; }
        body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div select {
          width: 100% !important; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div:nth-child(3) {
        width: 20%;
        margin: 15px 0 0 0; }
        @media (max-width: 767px) {
          body .wrapper .gform_wrapper form .gform_body > ul > li.timeField > div div:nth-child(3) {
            width: 100%;
            margin: 5px 0 20px 0; } }
    body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_radio li, body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_checkbox li {
      margin: 0 0 10px; }
      @media (max-width: 767px) {
        body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_radio li, body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_checkbox li {
          margin: 0 0 5px; } }
      body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_radio li input, body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_checkbox li input {
        display: inline-block;
        margin: 0 10px 0 0; }
        @media (max-width: 767px) {
          body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_radio li input, body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_checkbox li input {
            margin: 0 5px 0 0; } }
      body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_radio li label, body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_checkbox li label {
        font-size: 14px; }
        @media (max-width: 767px) {
          body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_radio li label, body .wrapper .gform_wrapper form .gform_body > ul > li .gfield_checkbox li label {
            font-size: 12px; } }
    body .wrapper .gform_wrapper form .gform_body > ul > li.gfield_error {
      background: transparent;
      border: none;
      margin-bottom: 0 !important; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.gfield_error label {
        color: #252525; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.gfield_error .gfield_label {
        color: red; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.gfield_error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
        border: 1px solid red; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.gfield_error textarea {
        border: 1px solid red; }
      body .wrapper .gform_wrapper form .gform_body > ul > li.gfield_error select {
        border: 1px solid red; }
    body .wrapper .gform_wrapper form .gform_body > ul > li .validation_message {
      display: none; }
    body .wrapper .gform_wrapper form .gform_body > ul > li > div, body .wrapper .gform_wrapper form .gform_body > ul > li > h2 {
      height: 70px;
      margin: 15px 0 45px;
      float: left;
      width: 100%; }
      @media (max-width: 767px) {
        body .wrapper .gform_wrapper form .gform_body > ul > li > div, body .wrapper .gform_wrapper form .gform_body > ul > li > h2 {
          height: 50px;
          margin: 5px 0 20px; } }
    body .wrapper .gform_wrapper form .gform_body > ul > li label {
      font-size: 16px; }
      @media (max-width: 767px) {
        body .wrapper .gform_wrapper form .gform_body > ul > li label {
          font-size: 12px; } }
      body .wrapper .gform_wrapper form .gform_body > ul > li label span {
        color: #ed1b2e; }
    body .wrapper .gform_wrapper form .gform_body > ul > li h2 {
      text-align: center;
      font-size: 22px;
      line-height: 38px;
      font-family: "Playfair Display", serif;
      text-align: center;
      width: 100%;
      font-weight: normal; }
      @media (max-width: 767px) {
        body .wrapper .gform_wrapper form .gform_body > ul > li h2 {
          font-size: 14px;
          line-height: 22px; } }
    body .wrapper .gform_wrapper form .gform_body > ul > li textarea {
      width: 100%;
      border: none;
      border-radius: 0px;
      resize: none; }
    body .wrapper .gform_wrapper form .gform_body > ul > li input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), body .wrapper .gform_wrapper form .gform_body > ul > li select {
      height: 70px;
      width: 100%;
      border: none;
      font-weight: 300;
      font-size: 15px;
      padding: 0 25px;
      border-radius: 0px; }
      @media (max-width: 1023px) {
        body .wrapper .gform_wrapper form .gform_body > ul > li input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), body .wrapper .gform_wrapper form .gform_body > ul > li select {
          padding: 0 20px;
          font-size: 13px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    body .wrapper .gform_wrapper form .gform_body > ul > li input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), body .wrapper .gform_wrapper form .gform_body > ul > li select {
      padding: 0 15px;
      font-size: 12px;
      height: 50px; } }
  body .wrapper .gform_wrapper form .gform_footer {
    clear: both; }
    body .wrapper .gform_wrapper form .gform_footer input[type="submit"] {
      background: #ed1b2e;
      color: #ffffff;
      padding: 18px 30px;
      display: table;
      font-size: 16px;
      margin: 0 auto;
      border: none;
      transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1023px) {
        body .wrapper .gform_wrapper form .gform_footer input[type="submit"] {
          padding: 13px 25px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    body .wrapper .gform_wrapper form .gform_footer input[type="submit"] {
      padding: 10px 20px;
      font-size: 12px; } }
      .allowHover body .wrapper .gform_wrapper form .gform_footer input[type="submit"]:hover {
        background: #ca1727; }

br.clear {
  clear: both; }

.mobileOnly {
  display: none; }
  @media (max-width: 767px) {
    .mobileOnly {
      display: block; } }

.desktopOnly {
  display: block; }
  @media (max-width: 767px) {
    .desktopOnly {
      display: none; } }

@media (max-width: 1023px) {
  .mobileDevice article .mosaique {
    display: none; } }

.share-alt {
  margin-bottom: 80px;
  text-align: center; }
  .share-alt p {
    margin-bottom: 20px; }
  @media (max-width: 1280px) {
    .share-alt {
      margin-bottom: 60px; } }
  @media (max-width: 767px) {
    .share-alt {
      margin-bottom: 40px;
      text-align: left; } }

.hero .hero-img {
  width: 100%; }
  .hero .hero-img.mobile {
    display: none; }

@media (max-width: 767px) {
  .hero .hero-img {
    width: 100%; }
    .hero .hero-img.mobile {
      display: block; }
    .hero .hero-img.desktop {
      display: none; } }

::-webkit-input-placeholder {
  color: #252525;
  opacity: 1; }

::-moz-placeholder {
  color: #252525;
  opacity: 1; }

:-ms-input-placeholder {
  color: #252525;
  opacity: 1; }

:-moz-placeholder {
  color: #252525;
  opacity: 1; }

body .tiny h1 {
  font-size: 58px;
  line-height: 63px;
  font-family: "Playfair Display", serif;
  margin: 0 0 25px;
  font-weight: 400;
  color: #ed1b2e; }
  @media (max-width: 767px) {
    body .tiny h1 {
      font-size: 38px;
      line-height: 43px;
      margin: 0 0 20px; } }

body .tiny h2 {
  font-size: 37px;
  line-height: 49px;
  font-family: "Playfair Display", serif;
  margin: 0 0 10px;
  font-weight: 400; }
  @media (max-width: 767px) {
    body .tiny h2 {
      font-size: 27px;
      line-height: 35px;
      margin: 0 0 10px; } }

body .tiny h3 {
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 45px;
  font-weight: 400; }
  @media (max-width: 767px) {
    body .tiny h3 {
      font-size: 15px;
      line-height: 24px;
      margin: 0 0 30px; } }

body .tiny h4 {
  color: #ed1b2e;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  font-size: 23px;
  line-height: 38px; }
  @media (max-width: 767px) {
    body .tiny h4 {
      font-size: 18px;
      line-height: 30px; } }

body .tiny h5 {
  font-size: 19px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  margin: 20px 0 0 0; }
  @media (max-width: 767px) {
    body .tiny h5 {
      font-size: 15px;
      margin: 15px 0 0 0; } }

body .tiny h6 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  color: #666666;
  margin: 5px 0 5px 0; }
  @media (max-width: 767px) {
    body .tiny h6 {
      font-size: 12px; } }

body .tiny p {
  font-size: 14px;
  line-height: 28px;
  margin: 0 0 45px;
  font-weight: 300; }
  @media (max-width: 767px) {
    body .tiny p {
      font-size: 12px;
      line-height: 22px;
      margin: 0 0 30px; } }
  body .tiny p a {
    color: #ed1b2e;
    border-bottom: 1px solid #ed1b2e;
    transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .allowHover body .tiny p a:hover {
      opacity: 0.6; }
  body .tiny p span.blue-question {
    color: #007bc3;
    font-weight: bold;
    font-size: 16px; }
  body .tiny p span.gray-answer {
    color: #797979; }

body .tiny blockquote {
  color: #7b97a3;
  padding: 0 16%;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 0 0 45px;
  position: relative; }
  @media (max-width: 767px) {
    body .tiny blockquote {
      font-size: 14px;
      line-height: 26px;
      margin: 0 0 30px;
      padding: 0 8%; } }
  body .tiny blockquote:before {
    font-style: normal;
    position: absolute;
    content: "«";
    color: #ed1b2e;
    top: -25px;
    left: 8%;
    font-size: 50px;
    font-weight: 700; }
    @media (max-width: 767px) {
      body .tiny blockquote:before {
        font-size: 32px;
        left: 1%;
        top: -5px; } }
  body .tiny blockquote p {
    font-size: 18px;
    line-height: 32px; }
    @media (max-width: 767px) {
      body .tiny blockquote p {
        font-size: 14px;
        line-height: 26px; } }
  body .tiny blockquote a {
    color: #7b97a3;
    text-decoration: underline;
    transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .allowHover body .tiny blockquote a:hover {
      opacity: 0.6; }

body .tiny ul, body .tiny ol {
  margin: 0 0 55px; }
  @media (max-width: 767px) {
    body .tiny ul, body .tiny ol {
      margin: 0 0 40px; } }
  body .tiny ul li, body .tiny ol li {
    padding: 0 0 0 25px;
    margin: 0 0 15px;
    font-size: 14px;
    line-height: 28px;
    position: relative;
    font-weight: 300; }
    @media (max-width: 767px) {
      body .tiny ul li, body .tiny ol li {
        font-size: 12px;
        line-height: 22px;
        margin: 0 0 10px;
        padding: 0 0 0 20px; } }
    body .tiny ul li:before, body .tiny ol li:before {
      position: absolute;
      content: "";
      top: 12px;
      left: 5px;
      width: 4px;
      height: 4px;
      background: #ed1b2e;
      border-radius: 2px; }
      @media (max-width: 767px) {
        body .tiny ul li:before, body .tiny ol li:before {
          top: 9px;
          left: 0px; } }
    body .tiny ul li a, body .tiny ol li a {
      color: #ed1b2e;
      text-decoration: underline;
      transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      .allowHover body .tiny ul li a:hover, .allowHover body .tiny ol li a:hover {
        opacity: 0.6; }
    body .tiny ul li span.gray-answer, body .tiny ol li span.gray-answer {
      color: #797979; }

body .tiny ol {
  padding: 0 0 0 15px;
  counter-reset: item;
  list-style: none; }
  body .tiny ol li {
    padding: 0 0 0 10px; }
    body .tiny ol li:before {
      display: none; }
    body .tiny ol li:after {
      content: counter(item) ". ";
      counter-increment: item;
      color: #ed1b2e;
      position: absolute;
      top: 0;
      left: -15px; }

body .tiny > a {
  color: #ed1b2e;
  border-bottom: 1px solid #ed1b2e;
  font-size: 13px;
  transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  .allowHover body .tiny > a:hover {
    opacity: 0.6; }
  @media (max-width: 767px) {
    body .tiny > a {
      font-size: 11px; } }

body .tiny img {
  max-width: 100%;
  display: block;
  margin: 0 0 45px; }
  @media (max-width: 767px) {
    body .tiny img {
      margin: 0 0 30px; } }

body .tiny iframe {
  max-width: 100%; }

body .tiny hr {
  border-color: #252525;
  margin: 55px 0 45px; }

body .tiny .ctaSliderImage {
  position: relative;
  margin: 0 0 50px;
  cursor: pointer;
  background: #252525; }
  @media (max-width: 767px) {
    body .tiny .ctaSliderImage {
      margin: 0 0 35px; } }
  body .tiny .ctaSliderImage img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0.6;
    -webkit-backface-visibility: hidden;
    transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  .allowHover body .tiny .ctaSliderImage:hover img {
    opacity: 0.3; }

body .tiny div.half {
  float: left;
  width: calc(50% - 20px); }
  @media (max-width: 1023px) {
    body .tiny div.half {
      float: none;
      width: 100%; } }

body .tiny div.half + .half {
  float: right; }
  @media (max-width: 1023px) {
    body .tiny div.half + .half {
      float: none; } }

/*** PARTIAL ***/
header {
  width: 100%;
  height: 120px;
  padding: 0 195px 0 40px;
  position: relative;
  z-index: 10000; }
  @media (max-width: 1280px) {
    header {
      padding: 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header {
      height: 60px; } }
  header .logo {
    float: left;
    width: 122px;
    height: 78px;
    margin: 20px 0 0 0; }
    @media (max-width: 1023px) {
      header .logo {
        width: 30px;
        height: 40px;
        margin: 10px 0 0 0;
        overflow: hidden;
        position: relative; } }
    header .logo img {
      height: auto;
      width: 100%;
      display: block; }
      @media (max-width: 1023px) {
        header .logo img {
          height: 43px;
          width: 67px;
          max-width: none; } }
  header .ctaMenu {
    display: none; }
    @media (max-width: 1023px) {
      header .ctaMenu {
        display: block;
        position: absolute;
        top: 24px;
        left: 72px;
        cursor: pointer; }
        header .ctaMenu span {
          display: block;
          background: #7b97a3;
          width: 16px;
          height: 2px;
          margin: 0 0 3px;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        header .ctaMenu > i {
          opacity: 0;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: -1px;
          font-size: 13px;
          color: #7b97a3;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); } }
    header .ctaMenu.open span {
      opacity: 0; }
    header .ctaMenu.open > i {
      opacity: 1; }
  @media (max-width: 1023px) {
    header .headerElement {
      position: absolute;
      left: 0;
      width: 100%;
      top: 60px;
      background: #ffffff;
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      transition: max-height 0.3s cubic-bezier(0.55, 0, 0.1, 1), opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1), box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      header .headerElement.open {
        opacity: 1;
        max-height: 1200px;
        box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.25); } }
  header .headerElement nav {
    float: left;
    margin: 0 0 0 40px;
    padding: 50px 0 0 0; }
    @media (max-width: 1280px) {
      header .headerElement nav {
        margin: 0 0 0 30px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header .headerElement nav {
      width: 100%;
      margin: 0;
      padding: 30px 0 20px;
      text-align: center; } }
    header .headerElement nav > ul > li {
      float: left;
      font-size: 16px;
      margin: 0 25px 0 0;
      position: relative;
      padding: 0 0 2px; }
      @media (max-width: 1280px) {
        header .headerElement nav > ul > li {
          margin: 0 20px 0 0; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header .headerElement nav > ul > li {
      clear: both;
      width: 100%;
      margin: 0 0 13px;
      padding: 0;
      font-size: 20px; } }
      header .headerElement nav > ul > li > a, header .headerElement nav > ul > li span {
        color: #252525;
        cursor: pointer;
        transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        header .headerElement nav > ul > li > a i, header .headerElement nav > ul > li span i {
          color: #ed1b2e;
          font-size: 10px;
          margin: 0 0 0 3px;
          display: inline-block; }
          @media (max-width: 1023px) {
            header .headerElement nav > ul > li > a i, header .headerElement nav > ul > li span i {
              font-size: 12px; } }
      header .headerElement nav > ul > li ul {
        position: absolute;
        top: 20px;
        left: -30px;
        padding: 0 30px;
        background: #ffffff;
        width: 215px;
        overflow: hidden;
        height: auto;
        max-height: 0;
        opacity: 0;
        transition: max-height 0.5s cubic-bezier(0.55, 0, 0.1, 1), opacity 0.5s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 1023px) {
          header .headerElement nav > ul > li ul {
            position: relative;
            width: 100%;
            top: auto;
            left: auto;
            padding: 0; } }
        header .headerElement nav > ul > li ul li {
          font-size: 14px;
          clear: both;
          margin: 12px 0; }
          @media (max-width: 1023px) {
            header .headerElement nav > ul > li ul li {
              font-size: 17px; } }
          header .headerElement nav > ul > li ul li a {
            color: #252525;
            transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 1023px) {
              header .headerElement nav > ul > li ul li a {
                transform: translateX(-3px);
                display: inline-block; } }
          header .headerElement nav > ul > li ul li:first-child {
            margin: 27px 0 12px; }
            @media (max-width: 1023px) {
              header .headerElement nav > ul > li ul li:first-child {
                margin: 10px 0; } }
          header .headerElement nav > ul > li ul li:last-child {
            margin: 12px 0 27px; }
            @media (max-width: 1023px) {
              header .headerElement nav > ul > li ul li:last-child {
                margin: 10px 0; } }
          .allowHover header .headerElement nav > ul > li ul li:hover a, header .headerElement nav > ul > li ul li.active a {
            color: #ed1b2e; }
      .allowHover header .headerElement nav > ul > li:hover > a, .allowHover header .headerElement nav > ul > li:hover span, header .headerElement nav > ul > li.open > a, header .headerElement nav > ul > li.open span {
        color: #ed1b2e; }
        .allowHover header .headerElement nav > ul > li:hover > a i, .allowHover header .headerElement nav > ul > li:hover span i, header .headerElement nav > ul > li.open > a i, header .headerElement nav > ul > li.open span i {
          transform: rotate(180deg); }
      .allowHover header .headerElement nav > ul > li:hover ul, header .headerElement nav > ul > li.open ul {
        z-index: 10;
        opacity: 1;
        max-height: 475px; }
      header .headerElement nav > ul > li.active {
        border-bottom: 1px solid #ed1b2e; }
        @media (max-width: 1023px) {
          header .headerElement nav > ul > li.active {
            border-bottom: none; } }
        header .headerElement nav > ul > li.active > a, header .headerElement nav > ul > li.active span {
          color: #ed1b2e; }
          @media (max-width: 1023px) {
            header .headerElement nav > ul > li.active > a, header .headerElement nav > ul > li.active span {
              border-bottom: 1px solid #ed1b2e; } }
  header .headerElement.beigeBg nav > ul li ul {
    background: #f8f7f3; }
  @media (max-width: 1023px) {
    header .headerElement.beigeBg {
      background: #f8f7f3; } }
  header .headerElement .toolLinks {
    float: right;
    padding: 45px 0 0 0; }
    header .headerElement .toolLinks .social {
      color: #aec4cd;
      font-size: 20px;
      display: inline-block;
      transform: translateY(2px);
      transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1023px) {
        header .headerElement .toolLinks .social {
          margin: 0 10px; } }
      .allowHover header .headerElement .toolLinks .social:hover {
        color: #000d23; }
    header .headerElement .toolLinks .lang {
      display: inline-block;
      color: #ed1b2e;
      font-size: 14px;
      margin: 0 25px 0 15px;
      border-bottom: 1px solid transparent;
      padding: 0 0 2px;
      transition: border 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1280px) {
        header .headerElement .toolLinks .lang {
          margin: 0 0 0 15px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header .headerElement .toolLinks .lang {
      margin: 0 15px; } }
      .allowHover header .headerElement .toolLinks .lang:hover {
        border-bottom: 1px solid #ed1b2e; }
    @media (max-width: 1023px) {
      header .headerElement .toolLinks {
        position: absolute;
        bottom: 30px;
        width: 100%;
        left: 0;
        text-align: center; } }
  header .headerElement .ctaButtons {
    position: absolute;
    right: 0;
    top: 0;
    width: 195px;
    height: 145px;
    background: #ed1b2e;
    transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    @media (max-width: 1023px) {
      header .headerElement .ctaButtons {
        display: none; } }
    header .headerElement .ctaButtons a {
      width: 100%;
      display: block;
      height: 33.333%;
      text-align: center;
      font-size: 14px;
      padding: 10px 0px 0 0;
      color: #fff;
      vertical-align: middle;
      border-bottom: 1px solid #ca1727; }
      header .headerElement .ctaButtons a:hover {
        background: #ca1727; }
      header .headerElement .ctaButtons a:last-child {
        border-bottom: none; }
      header .headerElement .ctaButtons a i {
        margin-right: 3px;
        font-size: 20px;
        font-weight: 600; }
  header .headerElement .cta {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 195px;
    height: 145px;
    background: #ed1b2e;
    transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    @media (max-width: 1280px) {
      header .headerElement .cta {
        height: 38px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header .headerElement .cta {
      position: relative;
      right: auto;
      top: auto;
      display: table;
      margin: 0 auto 80px;
      height: 115px;
      width: 275px; } }
    header .headerElement .cta a {
      display: block;
      width: 100%;
      height: 100%;
      color: #ffffff;
      text-align: center;
      padding: 50px 0 0 0; }
      @media (max-width: 1280px) {
        header .headerElement .cta a {
          padding: 8px 0 0 0; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header .headerElement .cta a {
      padding: 30px 0 0 0; } }
      header .headerElement .cta a span {
        display: block;
        font-size: 16px; }
        header .headerElement .cta a span i {
          display: none; }
      header .headerElement .cta a i {
        margin: 18px 0 0 0;
        display: inline-block;
        font-size: 22px;
        transform: rotate(270deg);
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 1280px) {
          header .headerElement .cta a i {
            display: none; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    header .headerElement .cta a i {
      display: inline-block; } }
    .allowHover header .headerElement .cta:hover {
      background: #ca1727; }
      .allowHover header .headerElement .cta:hover a i {
        transform: rotate(270deg) translateX(5px); }
    header .headerElement .cta.active, .allowHover header .headerElement .cta.active:hover {
      background: #7b97a3; }
      header .headerElement .cta.active a i, .allowHover header .headerElement .cta.active:hover a i {
        transform: rotate(270deg); }
  .stickyNav header {
    position: fixed;
    top: 0;
    left: 70px;
    height: 70px;
    box-shadow: -10px 5px 5px -5px rgba(0, 0, 0, 0.25);
    padding: 0 300px 0 40px;
    background: #ffffff; }
    @media (max-width: 1280px) {
      .stickyNav header {
        padding: 0 190px 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .stickyNav header {
      left: 0px;
      height: 60px;
      padding: 0 20px; } }
    .stickyNav header .logo {
      width: 37px;
      height: 48px;
      margin: 10px 0 0 0;
      overflow: hidden;
      position: relative; }
      @media (max-width: 1023px) {
        .stickyNav header .logo {
          width: 30px;
          height: 40px; } }
      .stickyNav header .logo img {
        height: 48px;
        width: 75px;
        max-width: none; }
        @media (max-width: 1023px) {
          .stickyNav header .logo img {
            height: 43px;
            width: 67px; } }
    @media (max-width: 1023px) {
      .stickyNav header .headerElement {
        text-align: center; } }
    .stickyNav header .headerElement nav {
      padding: 25px 0 0 0; }
      @media (max-width: 1023px) {
        .stickyNav header .headerElement nav {
          padding: 30px 0 20px 0; } }
    .stickyNav header .headerElement.beigeBg nav > ul li ul {
      background: #ffffff; }
    @media (max-width: 1023px) {
      .stickyNav header .headerElement.beigeBg {
        background: #ffffff; } }
    .stickyNav header .headerElement .toolLinks {
      padding: 20px 0 0 0; }
    .stickyNav header .headerElement .ctaButtons {
      display: none; }
    .stickyNav header .headerElement .cta {
      display: block;
      right: 70px;
      width: 230px;
      height: 70px; }
      @media (max-width: 1280px) {
        .stickyNav header .headerElement .cta {
          width: 100px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .stickyNav header .headerElement .cta {
      height: 115px;
      width: 275px;
      display: inline-block;
      right: auto; } }
      .stickyNav header .headerElement .cta a {
        padding: 19px 0 0 0; }
        @media (max-width: 1280px) {
          .stickyNav header .headerElement .cta a {
            padding: 14px 0 0 0; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .stickyNav header .headerElement .cta a {
      padding: 30px 0 0 0; } }
        .stickyNav header .headerElement .cta a span i {
          margin: 0px 0 0 0;
          display: inline-block;
          transform: rotate(0deg) translateY(3px); }
          @media (max-width: 1280px) {
            .stickyNav header .headerElement .cta a span i {
              display: none; } }
        .stickyNav header .headerElement .cta a i {
          display: none; }
          @media (max-width: 1023px) {
            .stickyNav header .headerElement .cta a i {
              display: inline-block; } }
      .allowHover .stickyNav header .headerElement .cta:hover {
        background: #ca1727; }
        .allowHover .stickyNav header .headerElement .cta:hover a i {
          transform: rotate(0deg) translate(5px, 3px); }
          @media (max-width: 1023px) {
            .allowHover .stickyNav header .headerElement .cta:hover a i {
              transform: rotate(270deg) translateX(5px); } }

footer {
  width: 100%;
  float: left;
  clear: both;
  margin: 30px 0 0 0; }
  @media (max-width: 1023px) {
    footer {
      margin: 0; } }
  footer .top {
    background: #ed1b2e;
    width: 100%;
    float: left; }
    footer .top nav {
      padding: 60px 0 30px;
      float: left;
      width: 70%; }
      @media (max-width: 1280px) {
        footer .top nav {
          display: none; } }
      footer .top nav > ul > li {
        width: 20%;
        float: left; }
        footer .top nav > ul > li > a, footer .top nav > ul > li span {
          color: #ffffff;
          font-size: 17px;
          font-family: "Playfair Display", serif;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        footer .top nav > ul > li > a:hover {
          opacity: 0.6; }
        footer .top nav > ul > li ul {
          padding: 15px 0 0 0; }
          footer .top nav > ul > li ul li {
            font-weight: 300;
            clear: both;
            font-size: 13px;
            margin: 0 0 10px; }
            footer .top nav > ul > li ul li a {
              color: #ffffff;
              -webkit-font-smoothing: antialiased;
              transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              .allowHover footer .top nav > ul > li ul li a:hover {
                opacity: 0.6; }
    footer .top .toolLinks {
      float: right;
      padding: 60px 0 30px; }
      @media (max-width: 1280px) {
        footer .top .toolLinks {
          width: 195px;
          margin: 0 auto;
          display: table;
          float: none; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    footer .top .toolLinks {
      padding: 30px 0 0; } }
      footer .top .toolLinks > a {
        float: right;
        color: #ffffff;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        footer .top .toolLinks > a.social {
          float: left;
          font-size: 20px;
          display: inline-block;
          margin: 0 3px 0 0;
          transform: translateX(-13px); }
        footer .top .toolLinks > a.contact {
          margin: 5px 0 0 15px;
          font-size: 13px;
          font-weight: 300;
          -webkit-font-smoothing: antialiased; }
          @media (max-width: 1280px) {
            footer .top .toolLinks > a.contact {
              margin: 5px 0 0 0; } }
        .allowHover footer .top .toolLinks > a:hover {
          opacity: 0.6; }
      footer .top .toolLinks > ul {
        float: left;
        clear: both;
        margin: 40px 0 0 0; }
        @media (max-width: 1280px) {
          footer .top .toolLinks > ul {
            width: 100%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    footer .top .toolLinks > ul {
      margin: 20px 0 0 0; } }
        footer .top .toolLinks > ul > li {
          text-align: left; }
          @media (max-width: 1280px) {
            footer .top .toolLinks > ul > li {
              text-align: center;
              margin: 0 0 30px; } }
          footer .top .toolLinks > ul > li span {
            color: #ffffff;
            font-size: 17px;
            font-family: "Playfair Display", serif; }
          footer .top .toolLinks > ul > li ul {
            padding: 15px 0 0 0; }
            @media (max-width: 767px) {
              footer .top .toolLinks > ul > li ul {
                padding: 10px 0 0 0; } }
            footer .top .toolLinks > ul > li ul li {
              text-align: left;
              font-weight: 300;
              font-size: 13px; }
              @media (max-width: 1280px) {
                footer .top .toolLinks > ul > li ul li {
                  text-align: center; } }
              footer .top .toolLinks > ul > li ul li a {
                color: #ffffff;
                -webkit-font-smoothing: antialiased;
                transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
                .allowHover footer .top .toolLinks > ul > li ul li a:hover {
                  opacity: 0.6; }
  footer .bottom {
    background: #000d23;
    float: left;
    width: 100%;
    padding: 20px 0; }
    @media (max-width: 767px) {
      footer .bottom {
        padding: 12px 0; } }
    footer .bottom .left {
      float: left;
      color: #7b97a3; }
      footer .bottom .left span {
        font-size: 12px;
        display: inline-block; }
        @media (max-width: 767px) {
          footer .bottom .left span {
            font-size: 9px; } }
        footer .bottom .left span a {
          -webkit-font-smoothing: antialiased;
          color: #7b97a3;
          text-decoration: underline;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .allowHover footer .bottom .left span a:hover {
            opacity: 0.6; }
    footer .bottom .right {
      float: right; }
      @media (max-width: 1023px) {
        footer .bottom .right {
          float: left;
          clear: both;
          margin: 15px 0 0 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    footer .bottom .right {
      margin: 10px 0 0 0; } }
      footer .bottom .right p {
        font-size: 12px;
        font-family: "Playfair Display", serif;
        color: #7b97a3; }
        @media (max-width: 767px) {
          footer .bottom .right p {
            font-size: 9px; } }

.widget {
  width: 70px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #dfeef4;
  cursor: pointer;
  z-index: 15000;
  transition: width 0.2s cubic-bezier(0.55, 0, 0.1, 1), background-color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  @media (max-width: 1023px) {
    .widget {
      left: auto;
      right: 0;
      width: 0;
      position: absolute; }
      .stickyNav .widget {
        position: fixed; } }
  .allowHover .widget:hover {
    background-color: #d4e5ec; }
    .allowHover .widget:hover .alert i {
      animation: ring 6s ease-in-out infinite;
      transform-origin: 50% 4px; }
  .widget .alert {
    position: absolute;
    top: 85px;
    right: 24px; }
    @media (max-width: 1023px) {
      .widget .alert {
        right: 17px;
        top: 17px; } }
    .widget .alert i {
      display: inline-block;
      font-size: 19px;
      color: #7b97a3; }
    .widget .alert .box {
      position: absolute;
      top: -5px;
      left: 34px;
      width: 34px;
      height: 34px;
      background: #ed1b2e; }
      @media (max-width: 1023px) {
        .widget .alert .box {
          width: 22px;
          height: 22px;
          left: -22px;
          top: 1px; } }
      .widget .alert .box span {
        text-align: center;
        font-size: 11px;
        color: #ffffff;
        display: block;
        line-height: 34px; }
        @media (max-width: 1023px) {
          .widget .alert .box span {
            line-height: 22px;
            font-size: 10px; } }
      .widget .alert .box:before {
        position: absolute;
        left: -5px;
        top: 12px;
        content: "";
        border-bottom: 5px solid transparent;
        border-right: 5px solid #ed1b2e;
        border-top: 5px solid transparent; }
        @media (max-width: 1023px) {
          .widget .alert .box:before {
            border-bottom: 4px solid transparent;
            border-right: none;
            border-left: 4px solid #ed1b2e;
            border-top: 4px solid transparent;
            content: "";
            position: absolute;
            left: auto;
            right: -4px;
            top: 9px; } }
  .widget .content {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    cursor: default; }
    .widget .content > .btnClose {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      height: 100%;
      border-right: 1px solid #7b97a3;
      transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1023px) {
        .widget .content > .btnClose {
          display: none; } }
      .widget .content > .btnClose i {
        left: 50%;
        top: 50%;
        font-size: 15px;
        position: absolute;
        display: inline-block;
        transform: translate(-50%, -50%);
        color: #7b97a3; }
      .allowHover .widget .content > .btnClose:hover {
        background: #d4e5ec; }
    .widget .content .widgetCta {
      width: 430px;
      position: absolute;
      top: 0;
      right: 0;
      overflow: scroll;
      padding: 150px 0 0 0;
      height: 100%; }
      @media (max-width: 1023px) {
        .widget .content .widgetCta {
          padding: 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .widget .content .widgetCta {
      width: 260px; } }
      .widget .content .widgetCta .notifications {
        width: 100%; }
        .widget .content .widgetCta .notifications .title {
          position: relative;
          background: #d4e5ec;
          padding: 30px 120px 28px 50px; }
          @media (max-width: 767px) {
            .widget .content .widgetCta .notifications .title {
              padding: 25px 60px 23px 50px; } }
          .widget .content .widgetCta .notifications .title > i {
            position: absolute;
            left: 13px;
            top: 50%;
            font-size: 19px;
            color: #7b97a3;
            transform: translateY(-50%); }
          .widget .content .widgetCta .notifications .title h5 {
            color: #7b97a3;
            font-weight: 400;
            font-size: 12px;
            text-transform: uppercase; }
            @media (max-width: 767px) {
              .widget .content .widgetCta .notifications .title h5 {
                font-size: 10px; } }
          .widget .content .widgetCta .notifications .title .btnClose {
            position: absolute;
            top: 50%;
            right: 45px;
            cursor: pointer;
            color: #7b97a3;
            transform: translateY(-50%);
            transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1);
            display: none; }
            @media (max-width: 1023px) {
              .widget .content .widgetCta .notifications .title .btnClose {
                display: block; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .widget .content .widgetCta .notifications .title .btnClose {
      right: 20px; } }
            .widget .content .widgetCta .notifications .title .btnClose i {
              font-size: 12px; }
              .allowHover .widget .content .widgetCta .notifications .title .btnClose i:hover {
                color: #69808b; }
          .widget .content .widgetCta .notifications .title .box {
            position: absolute;
            top: 50%;
            right: 50px;
            width: 34px;
            height: 34px;
            background: #ed1b2e;
            transform: translateY(-50%); }
            @media (max-width: 1023px) {
              .widget .content .widgetCta .notifications .title .box {
                display: none; } }
            .widget .content .widgetCta .notifications .title .box span {
              text-align: center;
              font-size: 11px;
              color: #ffffff;
              display: block;
              line-height: 34px; }
            .widget .content .widgetCta .notifications .title .box:before {
              position: absolute;
              left: -5px;
              top: 12px;
              content: "";
              border-bottom: 5px solid transparent;
              border-right: 5px solid #ed1b2e;
              border-top: 5px solid transparent; }
        .widget .content .widgetCta .notifications .notification {
          position: relative;
          height: 95px;
          padding: 0 120px 0 50px;
          display: block;
          transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .widget .content .widgetCta .notifications .notification {
              height: 75px;
              padding: 0 70px 0 30px; } }
          .widget .content .widgetCta .notifications .notification .txt {
            position: absolute;
            top: 50%;
            width: 60%;
            transform: translateY(-50%); }
            .widget .content .widgetCta .notifications .notification .txt h6 {
              font-size: 15px;
              font-weight: 400;
              color: #7b97a3;
              margin: 0 0 5px; }
              @media (max-width: 767px) {
                .widget .content .widgetCta .notifications .notification .txt h6 {
                  font-size: 12px; } }
            .widget .content .widgetCta .notifications .notification .txt p {
              color: #7b97a3;
              font-size: 14px;
              font-weight: 300; }
              @media (max-width: 767px) {
                .widget .content .widgetCta .notifications .notification .txt p {
                  font-size: 12px; } }
          .widget .content .widgetCta .notifications .notification .moment {
            position: absolute;
            right: 50px;
            width: 70px;
            text-align: right;
            top: 50%;
            transform: translateY(-50%); }
            @media (max-width: 767px) {
              .widget .content .widgetCta .notifications .notification .moment {
                right: 20px;
                width: 60px; } }
            .widget .content .widgetCta .notifications .notification .moment span {
              color: #7b97a3;
              font-size: 14px;
              font-family: "Playfair Display", serif; }
              @media (max-width: 767px) {
                .widget .content .widgetCta .notifications .notification .moment span {
                  font-size: 12px; } }
          .widget .content .widgetCta .notifications .notification.unseen {
            background: #dae9f0;
            margin: 1px 0 0 0; }
            .widget .content .widgetCta .notifications .notification.unseen:after {
              position: absolute;
              content: "";
              background: #ed1b2e;
              width: 8px;
              height: 8px;
              border-radius: 4px;
              left: 21px;
              top: 42px; }
              @media (max-width: 767px) {
                .widget .content .widgetCta .notifications .notification.unseen:after {
                  left: 12px; } }
            .widget .content .widgetCta .notifications .notification.unseen .txt h6 {
              color: #252525; }
            .widget .content .widgetCta .notifications .notification.unseen .txt p {
              color: #252525; }
            .widget .content .widgetCta .notifications .notification.unseen .moment span {
              color: #252525; }
          .allowHover .widget .content .widgetCta .notifications .notification:hover {
            background: #d4e5ec; }
      .widget .content .widgetCta .subjectFollow {
        margin: 60px 0 0 0;
        padding: 0 50px 50px; }
        @media (max-width: 767px) {
          .widget .content .widgetCta .subjectFollow {
            padding: 0 25px 25px;
            margin: 40px 0 0 0; } }
        .widget .content .widgetCta .subjectFollow .title {
          padding: 0 0 15px;
          border-bottom: 1px solid #7b97a3; }
          .widget .content .widgetCta .subjectFollow .title h5 {
            font-weight: 400;
            font-size: 12px;
            color: #7b97a3;
            text-transform: uppercase; }
            @media (max-width: 767px) {
              .widget .content .widgetCta .subjectFollow .title h5 {
                font-size: 10px; } }
          .widget .content .widgetCta .subjectFollow .title i {
            font-size: 23px;
            display: inline-block;
            transform: translate(-4px, 2px); }
            @media (max-width: 767px) {
              .widget .content .widgetCta .subjectFollow .title i {
                font-size: 18px; } }
        .widget .content .widgetCta .subjectFollow .subject {
          width: 100%;
          margin: 25px 0 0 0;
          position: relative; }
          @media (max-width: 767px) {
            .widget .content .widgetCta .subjectFollow .subject {
              margin: 20px 0 0 0; } }
          .widget .content .widgetCta .subjectFollow .subject p {
            font-size: 14px;
            color: #7b97a3; }
            @media (max-width: 767px) {
              .widget .content .widgetCta .subjectFollow .subject p {
                font-size: 12px; } }
          .widget .content .widgetCta .subjectFollow .subject .switch {
            cursor: pointer;
            width: 24px;
            height: 16px;
            border-radius: 8px;
            background: #7b97a3;
            position: absolute;
            right: 0;
            top: 1px;
            transition: background 0.2s cubic-bezier(0.55, 0, 0.1, 1); }
            .widget .content .widgetCta .subjectFollow .subject .switch span {
              position: absolute;
              background: #dfeef4;
              top: 1px;
              left: 1px;
              width: 14px;
              height: 14px;
              border-radius: 7px;
              display: inline-block;
              transition: left 0.2s cubic-bezier(0.55, 0, 0.1, 1); }
            .widget .content .widgetCta .subjectFollow .subject .switch.active {
              background: #ed1b2e; }
              .widget .content .widgetCta .subjectFollow .subject .switch.active span {
                left: 9px; }
  .widget.open {
    width: 500px; }
    @media (max-width: 1023px) {
      .widget.open {
        position: fixed;
        width: 430px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .widget.open {
      width: 260px; } }
    .allowHover .widget.open:hover {
      background: #dfeef4; }
  .widget.noNotif .content .widgetCta .notifications .title {
    background: transparent;
    display: none; }
    @media (max-width: 1023px) {
      .widget.noNotif .content .widgetCta .notifications .title {
        display: block; } }

@keyframes ring {
  0% {
    transform: rotate(0); }
  1% {
    transform: rotate(30deg); }
  3% {
    transform: rotate(-28deg); }
  5% {
    transform: rotate(34deg); }
  7% {
    transform: rotate(-32deg); }
  9% {
    transform: rotate(30deg); }
  11% {
    transform: rotate(-28deg); }
  13% {
    transform: rotate(26deg); }
  15% {
    transform: rotate(-24deg); }
  17% {
    transform: rotate(22deg); }
  19% {
    transform: rotate(-20deg); }
  21% {
    transform: rotate(18deg); }
  23% {
    transform: rotate(-16deg); }
  25% {
    transform: rotate(14deg); }
  27% {
    transform: rotate(-12deg); }
  29% {
    transform: rotate(10deg); }
  31% {
    transform: rotate(-8deg); }
  33% {
    transform: rotate(6deg); }
  35% {
    transform: rotate(-4deg); }
  37% {
    transform: rotate(2deg); }
  39% {
    transform: rotate(-1deg); }
  41% {
    transform: rotate(1deg); }
  43% {
    transform: rotate(0); }
  100% {
    transform: rotate(0); } }

body .lightbox, body .lightboxSlider, body .lightboxForm, body .lightboxAchievement {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  visibility: hidden;
  opacity: 0; }
  @media (max-width: 1023px) {
    body .lightbox, body .lightboxSlider, body .lightboxForm, body .lightboxAchievement {
      z-index: 20000; } }
  body .lightbox .bg, body .lightboxSlider .bg, body .lightboxForm .bg, body .lightboxAchievement .bg {
    position: fixed;
    top: 0;
    left: 0;
    background: #252525;
    opacity: 0.9;
    width: 100%;
    height: 100%; }
    .mobileDevice body .lightbox .bg, .mobileDevice body .lightboxSlider .bg, .mobileDevice body .lightboxForm .bg, .mobileDevice body .lightboxAchievement .bg {
      cursor: pointer; }
  body .lightbox .wrapper, body .lightboxSlider .wrapper, body .lightboxForm .wrapper, body .lightboxAchievement .wrapper {
    padding: 0 0 0 70px; }
    @media (max-width: 1023px) {
      body .lightbox .wrapper, body .lightboxSlider .wrapper, body .lightboxForm .wrapper, body .lightboxAchievement .wrapper {
        padding: 0; } }
  body .lightbox .newsLetterPopUp, body .lightboxSlider .newsLetterPopUp, body .lightboxForm .newsLetterPopUp, body .lightboxAchievement .newsLetterPopUp {
    position: relative;
    width: 100%;
    background: #f8f7f3;
    padding: 65px 7% 75px;
    margin: 80px 0 0 0;
    visibility: hidden;
    opacity: 0;
    float: left; }
    @media (max-width: 767px) {
      body .lightbox .newsLetterPopUp, body .lightboxSlider .newsLetterPopUp, body .lightboxForm .newsLetterPopUp, body .lightboxAchievement .newsLetterPopUp {
        padding: 35px 7% 45px;
        margin: 40px 0 0 0; } }
    body .lightbox .newsLetterPopUp h3, body .lightboxSlider .newsLetterPopUp h3, body .lightboxForm .newsLetterPopUp h3, body .lightboxAchievement .newsLetterPopUp h3 {
      color: #ed1b2e;
      width: 100%;
      font-family: "Playfair Display", serif;
      font-size: 56px;
      text-align: center;
      font-weight: 400; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp h3, body .lightboxSlider .newsLetterPopUp h3, body .lightboxForm .newsLetterPopUp h3, body .lightboxAchievement .newsLetterPopUp h3 {
          font-size: 32px; } }
    body .lightbox .newsLetterPopUp p, body .lightboxSlider .newsLetterPopUp p, body .lightboxForm .newsLetterPopUp p, body .lightboxAchievement .newsLetterPopUp p {
      font-size: 22px;
      line-height: 38px;
      font-family: "Playfair Display", serif;
      text-align: center;
      width: 100%;
      margin: 0 0 60px; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp p, body .lightboxSlider .newsLetterPopUp p, body .lightboxForm .newsLetterPopUp p, body .lightboxAchievement .newsLetterPopUp p {
          font-size: 14px;
          line-height: 22px;
          margin: 10px 0 20px; } }
      body .lightbox .newsLetterPopUp p.error, body .lightboxSlider .newsLetterPopUp p.error, body .lightboxForm .newsLetterPopUp p.error, body .lightboxAchievement .newsLetterPopUp p.error {
        display: none;
        color: red;
        margin: -40px 0 40px; }
        @media (max-width: 767px) {
          body .lightbox .newsLetterPopUp p.error, body .lightboxSlider .newsLetterPopUp p.error, body .lightboxForm .newsLetterPopUp p.error, body .lightboxAchievement .newsLetterPopUp p.error {
            margin: 20px 0; } }
    body .lightbox .newsLetterPopUp div.success, body .lightboxSlider .newsLetterPopUp div.success, body .lightboxForm .newsLetterPopUp div.success, body .lightboxAchievement .newsLetterPopUp div.success {
      display: none; }
    body .lightbox .newsLetterPopUp div p, body .lightboxSlider .newsLetterPopUp div p, body .lightboxForm .newsLetterPopUp div p, body .lightboxAchievement .newsLetterPopUp div p {
      color: #007ac2;
      margin: 0; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp div p, body .lightboxSlider .newsLetterPopUp div p, body .lightboxForm .newsLetterPopUp div p, body .lightboxAchievement .newsLetterPopUp div p {
          margin: 20px 0 0; } }
    body .lightbox .newsLetterPopUp form label, body .lightboxSlider .newsLetterPopUp form label, body .lightboxForm .newsLetterPopUp form label, body .lightboxAchievement .newsLetterPopUp form label {
      font-size: 16px; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp form label, body .lightboxSlider .newsLetterPopUp form label, body .lightboxForm .newsLetterPopUp form label, body .lightboxAchievement .newsLetterPopUp form label {
          font-size: 12px; } }
      body .lightbox .newsLetterPopUp form label sup, body .lightboxSlider .newsLetterPopUp form label sup, body .lightboxForm .newsLetterPopUp form label sup, body .lightboxAchievement .newsLetterPopUp form label sup {
        color: #ed1b2e; }
    body .lightbox .newsLetterPopUp form input, body .lightboxSlider .newsLetterPopUp form input, body .lightboxForm .newsLetterPopUp form input, body .lightboxAchievement .newsLetterPopUp form input {
      height: 70px;
      width: 100%;
      border: none;
      font-weight: 300;
      font-size: 15px;
      padding: 0 25px;
      margin: 15px 0 55px;
      border-radius: 0px; }
      @media (max-width: 1023px) {
        body .lightbox .newsLetterPopUp form input, body .lightboxSlider .newsLetterPopUp form input, body .lightboxForm .newsLetterPopUp form input, body .lightboxAchievement .newsLetterPopUp form input {
          padding: 0 20px;
          font-size: 13px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    body .lightbox .newsLetterPopUp form input, body .lightboxSlider .newsLetterPopUp form input, body .lightboxForm .newsLetterPopUp form input, body .lightboxAchievement .newsLetterPopUp form input {
      padding: 0 15px;
      font-size: 12px;
      height: 50px;
      margin: 5px 0 20px; } }
      body .lightbox .newsLetterPopUp form input.parsley-error, body .lightboxSlider .newsLetterPopUp form input.parsley-error, body .lightboxForm .newsLetterPopUp form input.parsley-error, body .lightboxAchievement .newsLetterPopUp form input.parsley-error {
        border: 1px solid red; }
    body .lightbox .newsLetterPopUp form .parsley-errors-list, body .lightboxSlider .newsLetterPopUp form .parsley-errors-list, body .lightboxForm .newsLetterPopUp form .parsley-errors-list, body .lightboxAchievement .newsLetterPopUp form .parsley-errors-list {
      display: none; }
    body .lightbox .newsLetterPopUp form .title, body .lightboxSlider .newsLetterPopUp form .title, body .lightboxForm .newsLetterPopUp form .title, body .lightboxAchievement .newsLetterPopUp form .title {
      width: 23%;
      float: left; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp form .title, body .lightboxSlider .newsLetterPopUp form .title, body .lightboxForm .newsLetterPopUp form .title, body .lightboxAchievement .newsLetterPopUp form .title {
          width: 100%; } }
      body .lightbox .newsLetterPopUp form .title .filter, body .lightboxSlider .newsLetterPopUp form .title .filter, body .lightboxForm .newsLetterPopUp form .title .filter, body .lightboxAchievement .newsLetterPopUp form .title .filter {
        margin: 15px 0 55px; }
        @media (max-width: 767px) {
          body .lightbox .newsLetterPopUp form .title .filter, body .lightboxSlider .newsLetterPopUp form .title .filter, body .lightboxForm .newsLetterPopUp form .title .filter, body .lightboxAchievement .newsLetterPopUp form .title .filter {
            margin: 5px 0 20px; } }
    body .lightbox .newsLetterPopUp form .email, body .lightboxSlider .newsLetterPopUp form .email, body .lightboxForm .newsLetterPopUp form .email, body .lightboxAchievement .newsLetterPopUp form .email {
      width: 73%;
      float: right; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp form .email, body .lightboxSlider .newsLetterPopUp form .email, body .lightboxForm .newsLetterPopUp form .email, body .lightboxAchievement .newsLetterPopUp form .email {
          width: 100%; } }
    body .lightbox .newsLetterPopUp form .firstName, body .lightboxSlider .newsLetterPopUp form .firstName, body .lightboxForm .newsLetterPopUp form .firstName, body .lightboxAchievement .newsLetterPopUp form .firstName {
      width: 48%;
      float: left; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp form .firstName, body .lightboxSlider .newsLetterPopUp form .firstName, body .lightboxForm .newsLetterPopUp form .firstName, body .lightboxAchievement .newsLetterPopUp form .firstName {
          width: 100%; } }
    body .lightbox .newsLetterPopUp form .lastName, body .lightboxSlider .newsLetterPopUp form .lastName, body .lightboxForm .newsLetterPopUp form .lastName, body .lightboxAchievement .newsLetterPopUp form .lastName {
      width: 48%;
      float: right; }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp form .lastName, body .lightboxSlider .newsLetterPopUp form .lastName, body .lightboxForm .newsLetterPopUp form .lastName, body .lightboxAchievement .newsLetterPopUp form .lastName {
          width: 100%; } }
    body .lightbox .newsLetterPopUp form .error input, body .lightboxSlider .newsLetterPopUp form .error input, body .lightboxForm .newsLetterPopUp form .error input, body .lightboxAchievement .newsLetterPopUp form .error input {
      border: 1px solid #ed1b2e; }
    body .lightbox .newsLetterPopUp form .ctaMoreInfo, body .lightboxSlider .newsLetterPopUp form .ctaMoreInfo, body .lightboxForm .newsLetterPopUp form .ctaMoreInfo, body .lightboxAchievement .newsLetterPopUp form .ctaMoreInfo {
      color: #7b97a3;
      font-size: 14px;
      text-decoration: underline;
      margin: -35px 0 50px;
      float: left;
      cursor: pointer;
      transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 767px) {
        body .lightbox .newsLetterPopUp form .ctaMoreInfo, body .lightboxSlider .newsLetterPopUp form .ctaMoreInfo, body .lightboxForm .newsLetterPopUp form .ctaMoreInfo, body .lightboxAchievement .newsLetterPopUp form .ctaMoreInfo {
          margin: 0 0 30px;
          font-size: 12px; } }
      .allowHover body .lightbox .newsLetterPopUp form .ctaMoreInfo:hover, .allowHover body .lightboxSlider .newsLetterPopUp form .ctaMoreInfo:hover, .allowHover body .lightboxForm .newsLetterPopUp form .ctaMoreInfo:hover, .allowHover body .lightboxAchievement .newsLetterPopUp form .ctaMoreInfo:hover {
        color: #69808b; }
    body .lightbox .newsLetterPopUp form .interest, body .lightboxSlider .newsLetterPopUp form .interest, body .lightboxForm .newsLetterPopUp form .interest, body .lightboxAchievement .newsLetterPopUp form .interest {
      float: left;
      width: 100%;
      overflow: hidden;
      max-height: 0;
      margin: -40px 0 0 0;
      ransition: max-heights 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      body .lightbox .newsLetterPopUp form .interest.open, body .lightboxSlider .newsLetterPopUp form .interest.open, body .lightboxForm .newsLetterPopUp form .interest.open, body .lightboxAchievement .newsLetterPopUp form .interest.open {
        max-height: 400px; }
      body .lightbox .newsLetterPopUp form .interest > div, body .lightboxSlider .newsLetterPopUp form .interest > div, body .lightboxForm .newsLetterPopUp form .interest > div, body .lightboxAchievement .newsLetterPopUp form .interest > div {
        float: left;
        width: 100%;
        padding: 0 0 30px; }
        body .lightbox .newsLetterPopUp form .interest > div > label, body .lightboxSlider .newsLetterPopUp form .interest > div > label, body .lightboxForm .newsLetterPopUp form .interest > div > label, body .lightboxAchievement .newsLetterPopUp form .interest > div > label {
          margin: 0 0 30px;
          float: left;
          width: 100%; }
          @media (max-width: 767px) {
            body .lightbox .newsLetterPopUp form .interest > div > label, body .lightboxSlider .newsLetterPopUp form .interest > div > label, body .lightboxForm .newsLetterPopUp form .interest > div > label, body .lightboxAchievement .newsLetterPopUp form .interest > div > label {
              margin: 0 0 15px; } }
        body .lightbox .newsLetterPopUp form .interest > div .element, body .lightboxSlider .newsLetterPopUp form .interest > div .element, body .lightboxForm .newsLetterPopUp form .interest > div .element, body .lightboxAchievement .newsLetterPopUp form .interest > div .element {
          width: 25%;
          float: left;
          position: relative; }
          @media (max-width: 1280px) {
            body .lightbox .newsLetterPopUp form .interest > div .element, body .lightboxSlider .newsLetterPopUp form .interest > div .element, body .lightboxForm .newsLetterPopUp form .interest > div .element, body .lightboxAchievement .newsLetterPopUp form .interest > div .element {
              width: 33.33%; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    body .lightbox .newsLetterPopUp form .interest > div .element, body .lightboxSlider .newsLetterPopUp form .interest > div .element, body .lightboxForm .newsLetterPopUp form .interest > div .element, body .lightboxAchievement .newsLetterPopUp form .interest > div .element {
      width: 50%; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    body .lightbox .newsLetterPopUp form .interest > div .element, body .lightboxSlider .newsLetterPopUp form .interest > div .element, body .lightboxForm .newsLetterPopUp form .interest > div .element, body .lightboxAchievement .newsLetterPopUp form .interest > div .element {
      width: 100%; } }
          body .lightbox .newsLetterPopUp form .interest > div .element span, body .lightboxSlider .newsLetterPopUp form .interest > div .element span, body .lightboxForm .newsLetterPopUp form .interest > div .element span, body .lightboxAchievement .newsLetterPopUp form .interest > div .element span {
            width: 16px;
            height: 16px;
            background: #ffffff;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 3px;
            border: 1px solid #7b97a3; }
            body .lightbox .newsLetterPopUp form .interest > div .element span.checked:after, body .lightboxSlider .newsLetterPopUp form .interest > div .element span.checked:after, body .lightboxForm .newsLetterPopUp form .interest > div .element span.checked:after, body .lightboxAchievement .newsLetterPopUp form .interest > div .element span.checked:after {
              width: 8px;
              height: 8px;
              background: #7b97a3;
              content: "";
              position: absolute;
              left: 3px;
              top: 3px; }
          body .lightbox .newsLetterPopUp form .interest > div .element label, body .lightboxSlider .newsLetterPopUp form .interest > div .element label, body .lightboxForm .newsLetterPopUp form .interest > div .element label, body .lightboxAchievement .newsLetterPopUp form .interest > div .element label {
            display: inline-block;
            text-align: left;
            font-size: 14px;
            margin: 0 0 20px;
            padding: 0 0 0 30px;
            cursor: pointer;
            z-index: 10;
            position: relative; }
            @media (max-width: 767px) {
              body .lightbox .newsLetterPopUp form .interest > div .element label, body .lightboxSlider .newsLetterPopUp form .interest > div .element label, body .lightboxForm .newsLetterPopUp form .interest > div .element label, body .lightboxAchievement .newsLetterPopUp form .interest > div .element label {
                font-size: 12px;
                margin: 0 0 10px; } }
            body .lightbox .newsLetterPopUp form .interest > div .element label input, body .lightboxSlider .newsLetterPopUp form .interest > div .element label input, body .lightboxForm .newsLetterPopUp form .interest > div .element label input, body .lightboxAchievement .newsLetterPopUp form .interest > div .element label input {
              height: 0;
              width: 0;
              border: none;
              padding: 0px;
              margin: 0px;
              border-radius: 0px;
              position: absolute;
              top: 2px;
              left: 0;
              visibility: hidden; }
    body .lightbox .newsLetterPopUp .ctaLink, body .lightboxSlider .newsLetterPopUp .ctaLink, body .lightboxForm .newsLetterPopUp .ctaLink, body .lightboxAchievement .newsLetterPopUp .ctaLink {
      display: table;
      margin: 0 auto;
      clear: both; }
  body .lightbox .customForm, body .lightboxSlider .customForm, body .lightboxForm .customForm, body .lightboxAchievement .customForm {
    position: relative;
    width: 100%;
    background: #f8f7f3;
    padding: 65px 5% 75px;
    margin: 100px 0 0 0;
    visibility: hidden;
    opacity: 0; }
    @media (max-width: 767px) {
      body .lightbox .customForm, body .lightboxSlider .customForm, body .lightboxForm .customForm, body .lightboxAchievement .customForm {
        padding: 35px 5% 45px;
        margin: 60px 0 0 0; } }
    body .lightbox .customForm .gform_heading h3, body .lightboxSlider .customForm .gform_heading h3, body .lightboxForm .customForm .gform_heading h3, body .lightboxAchievement .customForm .gform_heading h3 {
      color: #ed1b2e;
      width: 100%;
      font-family: "Playfair Display", serif;
      font-size: 56px;
      text-align: center;
      font-weight: 400; }
      @media (max-width: 767px) {
        body .lightbox .customForm .gform_heading h3, body .lightboxSlider .customForm .gform_heading h3, body .lightboxForm .customForm .gform_heading h3, body .lightboxAchievement .customForm .gform_heading h3 {
          margin: 0 0 30px;
          font-size: 12px; } }
      .allowHover body .lightbox .customForm .gform_heading h3:hover, .allowHover body .lightboxSlider .customForm .gform_heading h3:hover, .allowHover body .lightboxForm .customForm .gform_heading h3:hover, .allowHover body .lightboxAchievement .customForm .gform_heading h3:hover {
        color: #69808b; }
    body .lightbox .customForm .gform_heading .interest, body .lightboxSlider .customForm .gform_heading .interest, body .lightboxForm .customForm .gform_heading .interest, body .lightboxAchievement .customForm .gform_heading .interest {
      float: left;
      width: 100%;
      overflow: hidden;
      max-height: 0;
      margin: -40px 0 0 0;
      ransition: max-heights 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      body .lightbox .customForm .gform_heading .interest.open, body .lightboxSlider .customForm .gform_heading .interest.open, body .lightboxForm .customForm .gform_heading .interest.open, body .lightboxAchievement .customForm .gform_heading .interest.open {
        max-height: 400px; }
      body .lightbox .customForm .gform_heading .interest > div, body .lightboxSlider .customForm .gform_heading .interest > div, body .lightboxForm .customForm .gform_heading .interest > div, body .lightboxAchievement .customForm .gform_heading .interest > div {
        float: left;
        width: 100%;
        padding: 0 0 30px; }
        body .lightbox .customForm .gform_heading .interest > div > label, body .lightboxSlider .customForm .gform_heading .interest > div > label, body .lightboxForm .customForm .gform_heading .interest > div > label, body .lightboxAchievement .customForm .gform_heading .interest > div > label {
          margin: 0 0 30px;
          float: left;
          margin: 0; }
        body .lightbox .customForm .gform_heading .interest > div.gform_hidden, body .lightboxSlider .customForm .gform_heading .interest > div.gform_hidden, body .lightboxForm .customForm .gform_heading .interest > div.gform_hidden, body .lightboxAchievement .customForm .gform_heading .interest > div.gform_hidden {
          display: none; }
        body .lightbox .customForm .gform_heading .interest > div.gsection, body .lightbox .customForm .gform_heading .interest > div.fullWidth, body .lightboxSlider .customForm .gform_heading .interest > div.gsection, body .lightboxSlider .customForm .gform_heading .interest > div.fullWidth, body .lightboxForm .customForm .gform_heading .interest > div.gsection, body .lightboxForm .customForm .gform_heading .interest > div.fullWidth, body .lightboxAchievement .customForm .gform_heading .interest > div.gsection, body .lightboxAchievement .customForm .gform_heading .interest > div.fullWidth {
          width: 100%;
          padding: 0 4% 0 0; }
          body .lightbox .customForm .gform_heading .interest > div.gsection > div, body .lightbox .customForm .gform_heading .interest > div.fullWidth > div, body .lightboxSlider .customForm .gform_heading .interest > div.gsection > div, body .lightboxSlider .customForm .gform_heading .interest > div.fullWidth > div, body .lightboxForm .customForm .gform_heading .interest > div.gsection > div, body .lightboxForm .customForm .gform_heading .interest > div.fullWidth > div, body .lightboxAchievement .customForm .gform_heading .interest > div.gsection > div, body .lightboxAchievement .customForm .gform_heading .interest > div.fullWidth > div {
            height: auto; }
        body .lightbox .customForm .gform_heading .interest > div.nameField div, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div, body .lightboxForm .customForm .gform_heading .interest > div.nameField div, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div {
          margin: 15px 0 0 0;
          width: 100%; }
          @media (max-width: 767px) {
            body .lightbox .customForm .gform_heading .interest > div.nameField div, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div, body .lightboxForm .customForm .gform_heading .interest > div.nameField div, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div {
              margin: 5px 0 0 0; } }
          body .lightbox .customForm .gform_heading .interest > div.nameField div span, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span, body .lightboxForm .customForm .gform_heading .interest > div.nameField div span, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span {
            width: 48%;
            margin: 0 2% 0 0 !important;
            float: left;
            padding: 0 !important; }
            @media (max-width: 767px) {
              body .lightbox .customForm .gform_heading .interest > div.nameField div span, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span, body .lightboxForm .customForm .gform_heading .interest > div.nameField div span, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span {
                width: 100%;
                margin: 0; } }
            body .lightbox .customForm .gform_heading .interest > div.nameField div span.ginput_left, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span.ginput_left, body .lightboxForm .customForm .gform_heading .interest > div.nameField div span.ginput_left, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span.ginput_left {
              padding: 0 !important; }
            body .lightbox .customForm .gform_heading .interest > div.nameField div span label, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span label, body .lightboxForm .customForm .gform_heading .interest > div.nameField div span label, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span label {
              margin: 5px 0 45px 0;
              display: inline-block;
              font-size: 12px;
              width: 100%;
              text-align: center; }
              @media (max-width: 767px) {
                body .lightbox .customForm .gform_heading .interest > div.nameField div span label, body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span label, body .lightboxForm .customForm .gform_heading .interest > div.nameField div span label, body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span label {
                  font-size: 11px;
                  margin: 5px 0 20px 0; } }
          body .lightbox .customForm .gform_heading .interest > div.nameField div span:nth-child(even), body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span:nth-child(even), body .lightboxForm .customForm .gform_heading .interest > div.nameField div span:nth-child(even), body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span:nth-child(even) {
            margin: 0 0 0 2% !important; }
            @media (max-width: 767px) {
              body .lightbox .customForm .gform_heading .interest > div.nameField div span:nth-child(even), body .lightboxSlider .customForm .gform_heading .interest > div.nameField div span:nth-child(even), body .lightboxForm .customForm .gform_heading .interest > div.nameField div span:nth-child(even), body .lightboxAchievement .customForm .gform_heading .interest > div.nameField div span:nth-child(even) {
                margin: 0; } }
        body .lightbox .customForm .gform_heading .interest > div .element, body .lightboxSlider .customForm .gform_heading .interest > div .element, body .lightboxForm .customForm .gform_heading .interest > div .element, body .lightboxAchievement .customForm .gform_heading .interest > div .element {
          width: 25%;
          float: left;
          position: relative; }
          @media (max-width: 1280px) {
            body .lightbox .customForm .gform_heading .interest > div .element, body .lightboxSlider .customForm .gform_heading .interest > div .element, body .lightboxForm .customForm .gform_heading .interest > div .element, body .lightboxAchievement .customForm .gform_heading .interest > div .element {
              width: 33.33%; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    body .lightbox .customForm .gform_heading .interest > div .element, body .lightboxSlider .customForm .gform_heading .interest > div .element, body .lightboxForm .customForm .gform_heading .interest > div .element, body .lightboxAchievement .customForm .gform_heading .interest > div .element {
      width: 50%; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    body .lightbox .customForm .gform_heading .interest > div .element, body .lightboxSlider .customForm .gform_heading .interest > div .element, body .lightboxForm .customForm .gform_heading .interest > div .element, body .lightboxAchievement .customForm .gform_heading .interest > div .element {
      width: 100%; } }
          body .lightbox .customForm .gform_heading .interest > div .element span, body .lightboxSlider .customForm .gform_heading .interest > div .element span, body .lightboxForm .customForm .gform_heading .interest > div .element span, body .lightboxAchievement .customForm .gform_heading .interest > div .element span {
            width: 16px;
            height: 16px;
            background: #ffffff;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 3px;
            border: 1px solid #7b97a3; }
            body .lightbox .customForm .gform_heading .interest > div .element span.checked:after, body .lightboxSlider .customForm .gform_heading .interest > div .element span.checked:after, body .lightboxForm .customForm .gform_heading .interest > div .element span.checked:after, body .lightboxAchievement .customForm .gform_heading .interest > div .element span.checked:after {
              width: 8px;
              height: 8px;
              background: #7b97a3;
              content: "";
              position: absolute;
              left: 3px;
              top: 3px; }
          body .lightbox .customForm .gform_heading .interest > div .element label, body .lightboxSlider .customForm .gform_heading .interest > div .element label, body .lightboxForm .customForm .gform_heading .interest > div .element label, body .lightboxAchievement .customForm .gform_heading .interest > div .element label {
            display: inline-block;
            text-align: left;
            font-size: 14px;
            margin: 0 0 20px;
            padding: 0 0 0 30px;
            cursor: pointer;
            z-index: 10;
            position: relative; }
            @media (max-width: 767px) {
              body .lightbox .customForm .gform_heading .interest > div .element label, body .lightboxSlider .customForm .gform_heading .interest > div .element label, body .lightboxForm .customForm .gform_heading .interest > div .element label, body .lightboxAchievement .customForm .gform_heading .interest > div .element label {
                font-size: 12px;
                margin: 0 0 10px; } }
            body .lightbox .customForm .gform_heading .interest > div .element label input, body .lightboxSlider .customForm .gform_heading .interest > div .element label input, body .lightboxForm .customForm .gform_heading .interest > div .element label input, body .lightboxAchievement .customForm .gform_heading .interest > div .element label input {
              height: 0;
              width: 0;
              border: none;
              padding: 0px;
              margin: 0px;
              border-radius: 0px;
              position: absolute;
              top: 2px;
              left: 0;
              visibility: hidden; }
    body .lightbox .customForm .ctaLink, body .lightboxSlider .customForm .ctaLink, body .lightboxForm .customForm .ctaLink, body .lightboxAchievement .customForm .ctaLink {
      display: table;
      margin: 0 auto;
      clear: both; }
  body .lightbox .customForm, body .lightboxSlider .customForm, body .lightboxForm .customForm, body .lightboxAchievement .customForm {
    visibility: hidden;
    opacity: 0; }
    body .lightbox .customForm .gform_wrapper, body .lightboxSlider .customForm .gform_wrapper, body .lightboxForm .customForm .gform_wrapper, body .lightboxAchievement .customForm .gform_wrapper {
      background: #f8f7f3; }
  body .lightbox .playerVideo, body .lightboxSlider .playerVideo, body .lightboxForm .playerVideo, body .lightboxAchievement .playerVideo {
    position: relative;
    width: 100%;
    padding: 65px 7% 75px;
    margin: 100px 0 0 0;
    visibility: hidden;
    opacity: 0;
    top: 0; }
    body .lightbox .playerVideo iframe, body .lightboxSlider .playerVideo iframe, body .lightboxForm .playerVideo iframe, body .lightboxAchievement .playerVideo iframe {
      position: absolute;
      left: 0;
      top: 0; }
  body .lightbox .achievementPopup, body .lightboxSlider .achievementPopup, body .lightboxForm .achievementPopup, body .lightboxAchievement .achievementPopup {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 100px;
    margin-left: 16%; }
    @media (max-width: 767px) {
      body .lightbox .achievementPopup, body .lightboxSlider .achievementPopup, body .lightboxForm .achievementPopup, body .lightboxAchievement .achievementPopup {
        padding: 35px 7% 45px;
        margin: 40px 0 0 0;
        margin-left: 0; } }
    @media (max-width: 767px) {
      body .lightbox .achievementPopup .container, body .lightboxSlider .achievementPopup .container, body .lightboxForm .achievementPopup .container, body .lightboxAchievement .achievementPopup .container {
        padding: 0; } }
    body .lightbox .achievementPopup .lightboxPagination, body .lightboxSlider .achievementPopup .lightboxPagination, body .lightboxForm .achievementPopup .lightboxPagination, body .lightboxAchievement .achievementPopup .lightboxPagination {
      position: absolute;
      bottom: -51px;
      right: 0; }
      body .lightbox .achievementPopup .lightboxPagination .btnPagination, body .lightboxSlider .achievementPopup .lightboxPagination .btnPagination, body .lightboxForm .achievementPopup .lightboxPagination .btnPagination, body .lightboxAchievement .achievementPopup .lightboxPagination .btnPagination {
        padding: 15px;
        background: red;
        display: block;
        float: left;
        transition: background-color 0.2s ease; }
        body .lightbox .achievementPopup .lightboxPagination .btnPagination:hover, body .lightboxSlider .achievementPopup .lightboxPagination .btnPagination:hover, body .lightboxForm .achievementPopup .lightboxPagination .btnPagination:hover, body .lightboxAchievement .achievementPopup .lightboxPagination .btnPagination:hover {
          background: #ca1727; }
      body .lightbox .achievementPopup .lightboxPagination i, body .lightboxSlider .achievementPopup .lightboxPagination i, body .lightboxForm .achievementPopup .lightboxPagination i, body .lightboxAchievement .achievementPopup .lightboxPagination i {
        display: inline-block;
        color: #fff; }
      body .lightbox .achievementPopup .lightboxPagination .prev, body .lightboxSlider .achievementPopup .lightboxPagination .prev, body .lightboxForm .achievementPopup .lightboxPagination .prev, body .lightboxAchievement .achievementPopup .lightboxPagination .prev {
        margin-right: 1px; }
        body .lightbox .achievementPopup .lightboxPagination .prev i, body .lightboxSlider .achievementPopup .lightboxPagination .prev i, body .lightboxForm .achievementPopup .lightboxPagination .prev i, body .lightboxAchievement .achievementPopup .lightboxPagination .prev i {
          transform: rotate(180deg) translate(3px, -2px);
          line-height: 0; }
    body .lightbox .achievementPopup .btnClose, body .lightboxSlider .achievementPopup .btnClose, body .lightboxForm .achievementPopup .btnClose, body .lightboxAchievement .achievementPopup .btnClose {
      position: absolute;
      right: 0;
      top: -48px;
      padding: 15px;
      background: #7895a1;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      transition: background-color .2s ease, color .2s ease; }
      body .lightbox .achievementPopup .btnClose:hover, body .lightboxSlider .achievementPopup .btnClose:hover, body .lightboxForm .achievementPopup .btnClose:hover, body .lightboxAchievement .achievementPopup .btnClose:hover {
        background-color: #637e89;
        color: #c7c7c7; }
    body .lightbox .achievementPopup .achievementPopup-content, body .lightboxSlider .achievementPopup .achievementPopup-content, body .lightboxForm .achievementPopup .achievementPopup-content, body .lightboxAchievement .achievementPopup .achievementPopup-content {
      background: #f8f7f3;
      width: 720px;
      margin: 0 auto; }
      @media (max-width: 767px) {
        body .lightbox .achievementPopup .achievementPopup-content, body .lightboxSlider .achievementPopup .achievementPopup-content, body .lightboxForm .achievementPopup .achievementPopup-content, body .lightboxAchievement .achievementPopup .achievementPopup-content {
          width: 100%; } }
    body .lightbox .achievementPopup .achievementPopup-info, body .lightboxSlider .achievementPopup .achievementPopup-info, body .lightboxForm .achievementPopup .achievementPopup-info, body .lightboxAchievement .achievementPopup .achievementPopup-info {
      padding: 20px 30px 40px; }
      body .lightbox .achievementPopup .achievementPopup-info span, body .lightboxSlider .achievementPopup .achievementPopup-info span, body .lightboxForm .achievementPopup .achievementPopup-info span, body .lightboxAchievement .achievementPopup .achievementPopup-info span {
        font-family: "Playfair Display", serif;
        color: #666666;
        font-size: 14px; }
      body .lightbox .achievementPopup .achievementPopup-info h2, body .lightboxSlider .achievementPopup .achievementPopup-info h2, body .lightboxForm .achievementPopup .achievementPopup-info h2, body .lightboxAchievement .achievementPopup .achievementPopup-info h2 {
        font-family: "Playfair Display", serif;
        font-size: 37px;
        color: #ed1b2e;
        margin-top: 5px;
        margin-bottom: 20px; }
  body .lightbox .sliderImage, body .lightboxSlider .sliderImage, body .lightboxForm .sliderImage, body .lightboxAchievement .sliderImage {
    position: relative;
    width: 100%;
    padding: 0 7% 0;
    margin: 150px 0 0 0; }
    @media (max-width: 1023px) {
      body .lightbox .sliderImage, body .lightboxSlider .sliderImage, body .lightboxForm .sliderImage, body .lightboxAchievement .sliderImage {
        padding: 0; } }
    body .lightbox .sliderImage .btnClose, body .lightboxSlider .sliderImage .btnClose, body .lightboxForm .sliderImage .btnClose, body .lightboxAchievement .sliderImage .btnClose {
      width: 71px;
      height: 68px;
      position: absolute;
      top: -68px;
      right: 7%;
      z-index: 100;
      cursor: pointer;
      background: #7b97a3;
      transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1023px) {
        body .lightbox .sliderImage .btnClose, body .lightboxSlider .sliderImage .btnClose, body .lightboxForm .sliderImage .btnClose, body .lightboxAchievement .sliderImage .btnClose {
          right: 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    body .lightbox .sliderImage .btnClose, body .lightboxSlider .sliderImage .btnClose, body .lightboxForm .sliderImage .btnClose, body .lightboxAchievement .sliderImage .btnClose {
      width: 56px;
      height: 54px;
      top: -54px; } }
      body .lightbox .sliderImage .btnClose i, body .lightboxSlider .sliderImage .btnClose i, body .lightboxForm .sliderImage .btnClose i, body .lightboxAchievement .sliderImage .btnClose i {
        color: #ffffff;
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          body .lightbox .sliderImage .btnClose i, body .lightboxSlider .sliderImage .btnClose i, body .lightboxForm .sliderImage .btnClose i, body .lightboxAchievement .sliderImage .btnClose i {
            font-size: 15px; } }
      .allowHover body .lightbox .sliderImage .btnClose:hover, .allowHover body .lightboxSlider .sliderImage .btnClose:hover, .allowHover body .lightboxForm .sliderImage .btnClose:hover, .allowHover body .lightboxAchievement .sliderImage .btnClose:hover {
        background: #69808b; }
        .allowHover body .lightbox .sliderImage .btnClose:hover i, .allowHover body .lightboxSlider .sliderImage .btnClose:hover i, .allowHover body .lightboxForm .sliderImage .btnClose:hover i, .allowHover body .lightboxAchievement .sliderImage .btnClose:hover i {
          transform: translate(-50%, -50%) scale(1.1); }
    body .lightbox .sliderImage .bx-viewport, body .lightboxSlider .sliderImage .bx-viewport, body .lightboxForm .sliderImage .bx-viewport, body .lightboxAchievement .sliderImage .bx-viewport {
      background: #252525;
      overflow: visible !important; }
      body .lightbox .sliderImage .bx-viewport .bx-caption, body .lightboxSlider .sliderImage .bx-viewport .bx-caption, body .lightboxForm .sliderImage .bx-viewport .bx-caption, body .lightboxAchievement .sliderImage .bx-viewport .bx-caption {
        font-weight: 300;
        font-size: 13px;
        color: #ffffff;
        top: 105%;
        padding: 0 200px 0 0; }
        @media (max-width: 767px) {
          body .lightbox .sliderImage .bx-viewport .bx-caption, body .lightboxSlider .sliderImage .bx-viewport .bx-caption, body .lightboxForm .sliderImage .bx-viewport .bx-caption, body .lightboxAchievement .sliderImage .bx-viewport .bx-caption {
            font-size: 12px;
            padding: 0 125px 0 0; } }
      body .lightbox .sliderImage .bx-viewport img, body .lightboxSlider .sliderImage .bx-viewport img, body .lightboxForm .sliderImage .bx-viewport img, body .lightboxAchievement .sliderImage .bx-viewport img {
        margin: 0 auto; }
    body .lightbox .sliderImage .bx-pager, body .lightboxSlider .sliderImage .bx-pager, body .lightboxForm .sliderImage .bx-pager, body .lightboxAchievement .sliderImage .bx-pager {
      display: none; }
    body .lightbox .sliderImage .bx-controls, body .lightboxSlider .sliderImage .bx-controls, body .lightboxForm .sliderImage .bx-controls, body .lightboxAchievement .sliderImage .bx-controls {
      position: absolute;
      bottom: -69px;
      right: 0; }
      @media (max-width: 767px) {
        body .lightbox .sliderImage .bx-controls, body .lightboxSlider .sliderImage .bx-controls, body .lightboxForm .sliderImage .bx-controls, body .lightboxAchievement .sliderImage .bx-controls {
          bottom: -54px; } }
      body .lightbox .sliderImage .bx-controls .bx-controls-direction, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction {
        width: 100%; }
        body .lightbox .sliderImage .bx-controls .bx-controls-direction a, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a {
          padding: 20px;
          background: #ed1b2e;
          color: #ffffff;
          bottom: 0;
          transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            body .lightbox .sliderImage .bx-controls .bx-controls-direction a, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a {
              padding: 15px; } }
          body .lightbox .sliderImage .bx-controls .bx-controls-direction a i, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a i, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a i, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a i {
            font-size: 22px;
            display: inline-block;
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              body .lightbox .sliderImage .bx-controls .bx-controls-direction a i, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a i, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a i, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a i {
                font-size: 18px; } }
          body .lightbox .sliderImage .bx-controls .bx-controls-direction a.bx-prev, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a.bx-prev, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a.bx-prev, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a.bx-prev {
            right: 72px;
            left: auto; }
            @media (max-width: 767px) {
              body .lightbox .sliderImage .bx-controls .bx-controls-direction a.bx-prev, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a.bx-prev, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a.bx-prev, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a.bx-prev {
                right: 57px; } }
            body .lightbox .sliderImage .bx-controls .bx-controls-direction a.bx-prev i, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a.bx-prev i, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a.bx-prev i, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a.bx-prev i {
              transform: rotate(180deg); }
          body .lightbox .sliderImage .bx-controls .bx-controls-direction a.bx-next, body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a.bx-next, body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a.bx-next, body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a.bx-next {
            right: 0; }
          .allowHover body .lightbox .sliderImage .bx-controls .bx-controls-direction a:hover, .allowHover body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a:hover, .allowHover body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a:hover, .allowHover body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a:hover {
            background: #ca1727; }
            .allowHover body .lightbox .sliderImage .bx-controls .bx-controls-direction a:hover i, .allowHover body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a:hover i, .allowHover body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a:hover i, .allowHover body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a:hover i {
              transform: translate(5px, 0); }
            .allowHover body .lightbox .sliderImage .bx-controls .bx-controls-direction a:hover.bx-prev i, .allowHover body .lightboxSlider .sliderImage .bx-controls .bx-controls-direction a:hover.bx-prev i, .allowHover body .lightboxForm .sliderImage .bx-controls .bx-controls-direction a:hover.bx-prev i, .allowHover body .lightboxAchievement .sliderImage .bx-controls .bx-controls-direction a:hover.bx-prev i {
              transform: translateX(-5px) rotate(180deg); }

.mobileDevice body .lightbox {
  position: absolute; }

.mobileDevice body .lightbox .bg {
  position: fixed; }

body .lightboxForm {
  position: absolute; }
  body .lightboxForm .bg {
    position: fixed; }

.bannerTitle {
  position: relative;
  margin: 0 0 80px; }
  @media (max-width: 1023px) {
    .bannerTitle {
      margin: 0 0 60px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .bannerTitle {
      margin: 0 0 25px; } }
  .bannerTitle .txt {
    left: 0;
    top: 0;
    width: 60%;
    z-index: 10;
    position: absolute;
    height: 100%; }
    @media (max-width: 767px) {
      .bannerTitle .txt {
        width: 100%;
        position: relative; } }
    .bannerTitle .txt .back {
      color: #252525;
      font-size: 14px;
      display: inline-block;
      margin: 35px 0 0 0;
      transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1280px) {
        .bannerTitle .txt .back {
          margin: 5px 0 0 0; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .bannerTitle .txt .back {
      font-size: 12px;
      margin: 0; } }
      .bannerTitle .txt .back i {
        margin: 0 10px 0 0;
        font-size: 20px;
        display: inline-block;
        transform: rotate(180deg) translate(3px, -1px); }
        @media (max-width: 767px) {
          .bannerTitle .txt .back i {
            font-size: 18px; } }

@-moz-document url-prefix() {
  .bannerTitle .txt .back i {
    transform: rotate(180deg) translate(3px, -2px); } }
      .allowHover .bannerTitle .txt .back:hover {
        opacity: 0.6; }
    .bannerTitle .txt > div {
      position: absolute;
      top: 55%;
      width: 100%;
      transform: translateY(-50%); }
      @media (max-width: 1023px) {
        .bannerTitle .txt > div {
          top: 53%; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .bannerTitle .txt > div {
      position: relative;
      top: auto;
      transform: translateY(0);
      margin: 15px 0 0 0; } }
      .bannerTitle .txt > div img {
        width: 65%;
        height: auto;
        display: block;
        margin: 0 0 20px; }
        @media (max-width: 767px) {
          .bannerTitle .txt > div img {
            width: 100%;
            margin: 0 0 15px; } }
      .bannerTitle .txt > div .icon {
        font-size: 19px;
        display: block;
        margin: 0 0 15px;
        transform: translateX(-5px); }
        @media (max-width: 767px) {
          .bannerTitle .txt > div .icon {
            font-size: 15px;
            transform: translateX(-10px); } }
        .bannerTitle .txt > div .icon i {
          display: inline-block;
          color: #c4e3f0;
          font-size: 35px;
          transform: translate(-5px, 4px); }
          @media (max-width: 767px) {
            .bannerTitle .txt > div .icon i {
              font-size: 25px;
              transform: translate(0px, 2px); } }
      .bannerTitle .txt > div .date {
        font-family: "Playfair Display", serif;
        color: #666666;
        font-size: 16px;
        margin: 0 0 10px;
        display: inline-block; }
        @media (max-width: 767px) {
          .bannerTitle .txt > div .date {
            font-size: 14px; } }
      .bannerTitle .txt > div .dateAndIcon .calendar {
        display: inline-block;
        width: 51px;
        height: 43px;
        position: relative; }
        .bannerTitle .txt > div .dateAndIcon .calendar i {
          position: absolute;
          left: -4px;
          top: -8px;
          color: #c4e3f0;
          font-size: 43px; }
          @media (max-width: 767px) {
            .bannerTitle .txt > div .dateAndIcon .calendar i {
              font-size: 34px;
              top: -2px;
              left: -5px; } }
        .bannerTitle .txt > div .dateAndIcon .calendar .digit {
          color: #ed1b2e;
          position: absolute;
          left: 50%;
          top: 15px;
          font-size: 17px;
          transform: translateX(-50%); }
          @media (max-width: 767px) {
            .bannerTitle .txt > div .dateAndIcon .calendar .digit {
              font-size: 13px;
              left: 36%;
              top: 16px; } }
      .bannerTitle .txt > div .dateAndIcon .full {
        font-size: 16px;
        color: #666666;
        display: inline-block;
        font-family: "Playfair Display", serif;
        margin: 0 0 0 5px;
        transform: translateY(-15px); }
        @media (max-width: 767px) {
          .bannerTitle .txt > div .dateAndIcon .full {
            font-size: 14px;
            margin: 0; } }
      .bannerTitle .txt > div h1 {
        font-size: 50px;
        line-height: 55px;
        font-weight: 400;
        font-family: "Playfair Display", serif;
        margin: 0 0 35px; }
        @media (max-width: 1280px) {
          .bannerTitle .txt > div h1 {
            font-size: 40px;
            line-height: 45px;
            margin: 0 0 25px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .bannerTitle .txt > div h1 {
      font-size: 28px;
      line-height: 31px;
      margin: 0 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .bannerTitle .txt > div h1 {
      margin: 0 0 15px; } }
      .bannerTitle .txt > div h6 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        margin: 25px 0 15px; }
        @media (max-width: 767px) {
          .bannerTitle .txt > div h6 {
            margin: 20px 0 10px;
            font-size: 11px; } }
      .bannerTitle .txt > div .ctaLink {
        float: left;
        margin: 20px 0 0 0; }
        @media (max-width: 1280px) {
          .bannerTitle .txt > div .ctaLink {
            margin: 10px 0 0 0; } }
      .bannerTitle .txt > div .socialMedia {
        margin: 10px 0 0 -10px; }
  .bannerTitle .img {
    transform: translateX(70px);
    width: 69%;
    float: right;
    position: relative; }
    @media (max-width: 1280px) {
      .bannerTitle .img {
        transform: translateX(35px); } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .bannerTitle .img {
      width: 100%;
      float: none;
      margin: 20px 0 20px 0;
      transform: translateX(0px); }
      .bannerTitle .img:before {
        display: none; } }
    .bannerTitle .img:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0.6;
      background: linear-gradient(to right, #e5f1f6 0%, rgba(255, 255, 255, 0) 100%); }
    .bannerTitle .img img {
      display: block; }

.newsSmall {
  width: 33.33%;
  float: left;
  position: relative;
  z-index: 10; }
  @media (max-width: 1280px) {
    .newsSmall {
      width: 50%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .newsSmall {
      width: 100%; } }
  .newsSmall > a {
    position: relative;
    display: block; }
    .newsSmall > a img {
      display: block;
      width: 100%;
      height: auto;
      filter: grayscale(1);
      transition: filter 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      .gt-ie9 .newsSmall > a img {
        filter: url("gray.svg#grayscale"); }
    .allowHover .newsSmall > a:hover img {
      filter: grayscale(0); }
      .gt-ie9 .allowHover .newsSmall > a:hover img {
        filter: none; }
  .newsSmall:nth-child(3n-1) > a:before {
    position: absolute;
    content: "";
    left: -1px;
    top: 0;
    height: 100%;
    width: 1px;
    background: #ffffff; }
    @media (max-width: 1280px) {
      .newsSmall:nth-child(3n-1) > a:before {
        display: none; } }
  .newsSmall:nth-child(3n-1) > a:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: #ffffff; }
    @media (max-width: 1280px) {
      .newsSmall:nth-child(3n-1) > a:after {
        display: none; } }
  @media (max-width: 1280px) {
    .newsSmall:nth-child(2n) > a:before {
      position: absolute;
      content: "";
      left: -1px;
      top: 0;
      height: 100%;
      width: 1px;
      background: #ffffff;
      display: block; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .newsSmall:nth-child(2n) > a:before {
      display: none; } }
  .newsSmall .txt {
    padding: 0 30px;
    height: 300px; }
    @media (max-width: 767px) {
      .newsSmall .txt {
        padding: 0 15px;
        height: auto;
        margin: 0 0 30px; } }
    .newsSmall .txt .tag {
      margin: -16px 0 20px 0; }
    .newsSmall .txt h6 {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 400;
      margin: 0 0 15px; }
      @media (max-width: 767px) {
        .newsSmall .txt h6 {
          font-size: 10px;
          margin: 0 0 10px; } }
    .newsSmall .txt .date {
      font-family: "Playfair Display", serif;
      color: #666666;
      font-size: 14px;
      margin: 0 0 15px;
      display: inline-block; }
      @media (max-width: 767px) {
        .newsSmall .txt .date {
          font-size: 12px;
          margin: 0 0 10px; } }
    .newsSmall .txt p {
      font-size: 17px;
      line-height: 30px;
      font-weight: 300; }
      @media (max-width: 767px) {
        .newsSmall .txt p {
          font-size: 14px;
          line-height: 25px; } }
      .newsSmall .txt p a {
        -webkit-font-smoothing: antialiased;
        color: #252525;
        display: inline-block;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .allowHover .newsSmall .txt p a:hover {
          opacity: 0.6; }
    .newsSmall .txt .socialMedia {
      transform: translateX(-12px); }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .newsSmall > a img {
    filter: url("gray.svg#grayscale"); }
  .allowHover .newsSmall > a:hover img {
    filter: none; } }

.eventsSmall {
  width: 25%;
  border-right: 1px solid #ffffff;
  float: left; }
  @media (max-width: 1280px) {
    .eventsSmall {
      width: 33.33%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .eventsSmall {
      width: 50%; } }
  .eventsSmall img {
    width: 100%;
    height: auto;
    display: block; }
  .eventsSmall .txt {
    padding: 25px;
    height: 175px;
    position: relative;
    margin: 0 0 50px; }
    @media (max-width: 1023px) {
      .eventsSmall .txt {
        height: 225px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .eventsSmall .txt {
      padding: 20px 10px 10px 5px;
      height: 175px; } }
    .eventsSmall .txt .dateAndIcon .calendar {
      display: inline-block;
      width: 33px;
      height: 33px;
      position: relative; }
      @media (max-width: 767px) {
        .eventsSmall .txt .dateAndIcon .calendar {
          width: 25px;
          height: 25px; } }
      .eventsSmall .txt .dateAndIcon .calendar i {
        position: absolute;
        left: -6px;
        top: -9px;
        color: #c4e3f0;
        font-size: 32px; }
        @media (max-width: 767px) {
          .eventsSmall .txt .dateAndIcon .calendar i {
            font-size: 25px;
            top: -11px; } }
      .eventsSmall .txt .dateAndIcon .calendar .digit {
        color: #ed1b2e;
        position: absolute;
        left: 50%;
        top: 8px;
        font-size: 13px;
        transform: translateX(-50%); }
        @media (max-width: 767px) {
          .eventsSmall .txt .dateAndIcon .calendar .digit {
            font-size: 10px;
            left: 44%;
            top: 2px; } }
    .eventsSmall .txt .dateAndIcon .full {
      font-size: 14px;
      color: #666666;
      display: inline-block;
      font-family: "Playfair Display", serif;
      margin: 0 0 0 5px;
      transform: translateY(-15px); }
      @media (max-width: 767px) {
        .eventsSmall .txt .dateAndIcon .full {
          font-size: 11px;
          margin: 0; } }
    .eventsSmall .txt p {
      font-size: 17px;
      line-height: 26px;
      font-weight: 300;
      margin: -5px 0 0 0; }
      @media (max-width: 767px) {
        .eventsSmall .txt p {
          font-size: 14px;
          line-height: 20px;
          margin: 0; } }
      .eventsSmall .txt p a {
        color: #252525;
        display: inline-block;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .allowHover .eventsSmall .txt p a:hover {
          opacity: 0.6; }
    .eventsSmall .txt > a {
      position: absolute;
      bottom: 0;
      left: 25px;
      color: #ed1b2e;
      font-size: 16px;
      display: inline-block;
      transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 767px) {
        .eventsSmall .txt > a {
          font-size: 12px;
          left: 10px; } }
      .eventsSmall .txt > a i {
        margin: 0 5px 0 0;
        display: inline-block;
        font-size: 22px;
        transform: translateY(2px);
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .eventsSmall .txt > a i {
            font-size: 18px; } }
      .allowHover .eventsSmall .txt > a:hover {
        color: #ca1727; }
        .allowHover .eventsSmall .txt > a:hover i {
          transform: translate(5px, 2px); }

.articleBy {
  width: 55%;
  float: right;
  text-align: center;
  padding: 10px 0 0 0; }
  @media (max-width: 1023px) {
    .articleBy {
      width: 100%; } }
  .articleBy h3 {
    color: #ed1b2e;
    font-size: 23px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    margin: 0 0 25px; }
    @media (max-width: 767px) {
      .articleBy h3 {
        margin: 0 0 20px;
        font-size: 18px; } }
  .articleBy .author {
    display: inline-block;
    margin: 0 25px 50px;
    width: 25%;
    vertical-align: top; }
    @media (max-width: 767px) {
      .articleBy .author {
        margin: 0 15px 30px; } }
    .articleBy .author .thumb {
      display: table;
      position: relative;
      border-radius: 50px;
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin: 0 auto 15px;
      transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 767px) {
        .articleBy .author .thumb {
          width: 70px;
          height: 70px;
          border-radius: 35px; } }
      .articleBy .author .thumb img {
        display: block; }
      .allowHover .articleBy .author .thumb:hover {
        opacity: 0.6; }
    .articleBy .author h5 {
      font-size: 15px;
      font-weight: 300;
      text-align: center;
      width: 100%; }
      @media (max-width: 767px) {
        .articleBy .author h5 {
          font-size: 12px; } }
      .articleBy .author h5 a {
        color: #252525;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .allowHover .articleBy .author h5 a:hover {
          opacity: 0.6; }
    .articleBy .author span {
      margin: 5px 0 0 0;
      display: inline-block;
      color: #666666;
      font-size: 14px;
      font-family: "Playfair Display", serif; }
      @media (max-width: 767px) {
        .articleBy .author span {
          font-size: 12px; } }
  .articleBy .share {
    padding: 10px 0 60px 0;
    border-top: 1px solid #b2b2b2; }
    @media (max-width: 767px) {
      .articleBy .share {
        padding: 10px 0 40px 0; } }
    .articleBy .share p {
      display: inline-block;
      font-size: 12px;
      color: #252525;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .articleBy .share p {
          font-size: 10px; } }
    .articleBy .share .socialMedia {
      display: inline-block; }

.reachFollow {
  padding: 60px 0;
  width: 100%;
  float: left; }
  @media (max-width: 1280px) {
    .reachFollow {
      padding: 60px 0 100px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .reachFollow {
      padding: 30px 0 60px; } }
  .reachFollow .onSocialMedia {
    width: 60%;
    float: left; }
    @media (max-width: 1280px) {
      .reachFollow .onSocialMedia {
        width: 100%;
        margin: 0 0 60px; } }
    .reachFollow .onSocialMedia .blockTitle > img {
      width: 271px;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 767px) {
        .reachFollow .onSocialMedia .blockTitle > img {
          width: 170px; } }
    .reachFollow .onSocialMedia .blockTitle div {
      top: 90px;
      width: 100%; }
      @media (max-width: 767px) {
        .reachFollow .onSocialMedia .blockTitle div {
          top: 50px; } }
      .reachFollow .onSocialMedia .blockTitle div h2 {
        width: 100%;
        text-align: center;
        padding: 0 20px; }
        @media (max-width: 767px) {
          .reachFollow .onSocialMedia .blockTitle div h2 {
            top: 50px;
            padding: 0; } }
    .reachFollow .onSocialMedia .socialMosaic {
      margin: 30px 0 0 0; }
      .reachFollow .onSocialMedia .socialMosaic.fullWidth > div:first-child {
        width: 100%; }
      .reachFollow .onSocialMedia .socialMosaic .twitter {
        height: 355px;
        background: #dfeef4;
        width: 50%;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        padding: 40px 50px;
        text-align: center;
        position: relative;
        float: left; }
        @media (max-width: 767px) {
          .reachFollow .onSocialMedia .socialMosaic .twitter {
            width: 100%;
            height: 250px;
            border-right: none;
            padding: 25px 20px; } }
        .reachFollow .onSocialMedia .socialMosaic .twitter > i {
          color: #ed1b2e;
          font-size: 22px;
          display: inline-block; }
          @media (max-width: 767px) {
            .reachFollow .onSocialMedia .socialMosaic .twitter > i {
              font-size: 18px; } }
        .reachFollow .onSocialMedia .socialMosaic .twitter h5 {
          font-size: 24px;
          font-weight: normal;
          font-family: "Playfair Display", serif;
          margin: 0 0 18px; }
          @media (max-width: 767px) {
            .reachFollow .onSocialMedia .socialMosaic .twitter h5 {
              font-size: 18px;
              margin: 0 0 13px; } }
          .reachFollow .onSocialMedia .socialMosaic .twitter h5 a {
            color: #ed1b2e;
            transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            .allowHover .reachFollow .onSocialMedia .socialMosaic .twitter h5 a:hover {
              color: #ca1727; }
        .reachFollow .onSocialMedia .socialMosaic .twitter p {
          font-size: 17px;
          line-height: 24px;
          margin: 0 0 15px; }
          @media (max-width: 767px) {
            .reachFollow .onSocialMedia .socialMosaic .twitter p {
              font-size: 12px;
              line-height: 18px;
              margin: 0 0 10px; } }
          .reachFollow .onSocialMedia .socialMosaic .twitter p a {
            color: #252525;
            text-decoration: underline;
            transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            .allowHover .reachFollow .onSocialMedia .socialMosaic .twitter p a:hover {
              opacity: 0.6; }
        .reachFollow .onSocialMedia .socialMosaic .twitter .action a {
          font-size: 17px;
          color: #7b97a3;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .reachFollow .onSocialMedia .socialMosaic .twitter .action a:nth-child(2) {
            transform: translatey(2px);
            display: inline-block;
            font-size: 22px; }
          .allowHover .reachFollow .onSocialMedia .socialMosaic .twitter .action a:hover {
            color: #69808b; }
        .reachFollow .onSocialMedia .socialMosaic .twitter > a {
          position: absolute;
          width: 100%;
          display: inline-block;
          left: 50%;
          bottom: 40px;
          font-size: 14px;
          color: #ed1b2e;
          transform: translateX(-50%); }
          @media (max-width: 767px) {
            .reachFollow .onSocialMedia .socialMosaic .twitter > a {
              font-size: 12px;
              bottom: 25px; } }
          .reachFollow .onSocialMedia .socialMosaic .twitter > a i {
            display: inline-block;
            font-size: 22px;
            transform: translateY(3px);
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .twitter > a i {
                font-size: 18px; } }
          .allowHover .reachFollow .onSocialMedia .socialMosaic .twitter > a:hover i {
            transform: translate(5px, 3px); }
      .reachFollow .onSocialMedia .socialMosaic .facebook, .reachFollow .onSocialMedia .socialMosaic .youtube {
        width: 50%;
        height: 355px;
        float: left;
        background: #007ac2;
        text-align: center;
        position: relative;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .reachFollow .onSocialMedia .socialMosaic .facebook, .reachFollow .onSocialMedia .socialMosaic .youtube {
            width: 100%;
            height: 250px;
            border-right: none; } }
        .reachFollow .onSocialMedia .socialMosaic .facebook a, .reachFollow .onSocialMedia .socialMosaic .youtube a {
          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
          .reachFollow .onSocialMedia .socialMosaic .facebook a img, .reachFollow .onSocialMedia .socialMosaic .youtube a img {
            width: 120px;
            display: block;
            margin: 70px auto 0;
            border-radius: 60px; }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .facebook a img, .reachFollow .onSocialMedia .socialMosaic .youtube a img {
                margin: 30px auto 0;
                width: 90px;
                border-radius: 45px; } }
          .reachFollow .onSocialMedia .socialMosaic .facebook a > i, .reachFollow .onSocialMedia .socialMosaic .youtube a > i {
            margin: 15px 0 0 0;
            font-size: 18px;
            display: inline-block; }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .facebook a > i, .reachFollow .onSocialMedia .socialMosaic .youtube a > i {
                font-size: 16px;
                margin: 10px 0 0 0; } }
            .reachFollow .onSocialMedia .socialMosaic .facebook a > i.icon-youtube, .reachFollow .onSocialMedia .socialMosaic .youtube a > i.icon-youtube {
              font-size: 22px; }
              @media (max-width: 767px) {
                .reachFollow .onSocialMedia .socialMosaic .facebook a > i.icon-youtube, .reachFollow .onSocialMedia .socialMosaic .youtube a > i.icon-youtube {
                  font-size: 18px; } }
          .reachFollow .onSocialMedia .socialMosaic .facebook a h5, .reachFollow .onSocialMedia .socialMosaic .youtube a h5 {
            margin: 5px 0 0 0;
            font-weight: 400;
            font-family: "Playfair Display", serif;
            font-size: 22px; }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .facebook a h5, .reachFollow .onSocialMedia .socialMosaic .youtube a h5 {
                font-size: 16px; } }
          .reachFollow .onSocialMedia .socialMosaic .facebook a span, .reachFollow .onSocialMedia .socialMosaic .youtube a span {
            position: absolute;
            width: 100%;
            display: inline-block;
            left: 50%;
            bottom: 40px;
            font-size: 14px;
            transform: translateX(-50%); }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .facebook a span, .reachFollow .onSocialMedia .socialMosaic .youtube a span {
                font-size: 12px;
                bottom: 25px; } }
            .reachFollow .onSocialMedia .socialMosaic .facebook a span i, .reachFollow .onSocialMedia .socialMosaic .youtube a span i {
              display: inline-block;
              font-size: 22px;
              transform: translateY(3px);
              transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              @media (max-width: 767px) {
                .reachFollow .onSocialMedia .socialMosaic .facebook a span i, .reachFollow .onSocialMedia .socialMosaic .youtube a span i {
                  font-size: 18px; } }
        .allowHover .reachFollow .onSocialMedia .socialMosaic .facebook:hover, .allowHover .reachFollow .onSocialMedia .socialMosaic .youtube:hover {
          background: #0068a5; }
          .allowHover .reachFollow .onSocialMedia .socialMosaic .facebook:hover a span i, .allowHover .reachFollow .onSocialMedia .socialMosaic .youtube:hover a span i {
            transform: translate(5px, 3px); }
      .reachFollow .onSocialMedia .socialMosaic .youtube {
        background: #ed1b2e; }
        .allowHover .reachFollow .onSocialMedia .socialMosaic .youtube:hover {
          background: #ca1727; }
      .reachFollow .onSocialMedia .socialMosaic .instagram {
        width: 50%;
        float: left;
        height: 355px;
        text-align: center;
        position: relative;
        overflow: hidden;
        background: #252525;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        box-shadow: inset 0px -60px 90px -40px rgba(0, 0, 0, 0.75); }
        @media (max-width: 767px) {
          .reachFollow .onSocialMedia .socialMosaic .instagram {
            width: 100%;
            height: 250px;
            border-right: none; } }
        .reachFollow .onSocialMedia .socialMosaic .instagram a {
          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
          .reachFollow .onSocialMedia .socialMosaic .instagram a:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 5;
            box-shadow: inset 0px -120px 125px -135px rgba(0, 0, 0, 0.75); }
          .reachFollow .onSocialMedia .socialMosaic .instagram a img {
            position: absolute;
            display: block;
            transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .reachFollow .onSocialMedia .socialMosaic .instagram a > i {
            margin: 260px 0 0 0;
            font-size: 18px;
            display: inline-block;
            position: relative;
            z-index: 10; }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .instagram a > i {
                font-size: 16px;
                margin: 170px 0 0 0; } }
          .reachFollow .onSocialMedia .socialMosaic .instagram a span {
            position: absolute;
            width: 100%;
            display: inline-block;
            left: 50%;
            bottom: 40px;
            font-size: 14px;
            z-index: 10;
            transform: translateX(-50%); }
            @media (max-width: 767px) {
              .reachFollow .onSocialMedia .socialMosaic .instagram a span {
                font-size: 12px;
                bottom: 25px; } }
            .reachFollow .onSocialMedia .socialMosaic .instagram a span i {
              display: inline-block;
              font-size: 22px;
              transform: translateY(3px);
              transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              @media (max-width: 767px) {
                .reachFollow .onSocialMedia .socialMosaic .instagram a span i {
                  font-size: 18px; } }
        .allowHover .reachFollow .onSocialMedia .socialMosaic .instagram:hover a img {
          opacity: 0.6; }
        .allowHover .reachFollow .onSocialMedia .socialMosaic .instagram:hover a span i {
          transform: translate(5px, 3px); }
  .reachFollow .otherWay {
    width: 36%;
    float: right; }
    @media (max-width: 1280px) {
      .reachFollow .otherWay {
        width: 100%;
        float: none;
        clear: both;
        margin: 0 auto;
        width: 65%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .reachFollow .otherWay {
      width: 100%; } }
    .reachFollow .otherWay .contact {
      background: #f8f7f3;
      padding: 65px 0 0 0;
      float: left;
      width: 100%; }
      @media (max-width: 767px) {
        .reachFollow .otherWay .contact {
          padding: 30px 0 0 0; } }
      .reachFollow .otherWay .contact .blockTitle > img {
        width: 185px;
        height: auto;
        margin: 0 auto; }
        @media (max-width: 767px) {
          .reachFollow .otherWay .contact .blockTitle > img {
            width: 105px; } }
      .reachFollow .otherWay .contact .blockTitle div {
        top: 45px;
        width: 100%; }
        @media (max-width: 767px) {
          .reachFollow .otherWay .contact .blockTitle div {
            top: 20px; } }
        .reachFollow .otherWay .contact .blockTitle div h2 {
          width: 100%;
          text-align: center;
          padding: 0 20px; }
      .reachFollow .otherWay .contact h4 {
        width: 100%;
        text-align: center;
        font-family: "Playfair Display", serif;
        font-size: 19px;
        padding: 0 20px;
        margin: 30px 0 0 0;
        font-weight: 400; }
        @media (max-width: 767px) {
          .reachFollow .otherWay .contact h4 {
            font-size: 15px;
            margin: 20px 0 0 0; } }
      .reachFollow .otherWay .contact p {
        margin: 15px 0 50px 0;
        text-align: center;
        font-size: 13px;
        line-height: 22px; }
        @media (max-width: 767px) {
          .reachFollow .otherWay .contact p {
            font-size: 12px;
            line-height: 20px;
            margin: 10px 0 30px 0; } }
        .reachFollow .otherWay .contact p a {
          color: #252525;
          text-decoration: underline;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .allowHover .reachFollow .otherWay .contact p a:hover {
            opacity: 0.6; }
      .reachFollow .otherWay .contact .ctaLink {
        display: table;
        margin: 0 auto -35px; }
        @media (max-width: 1023px) {
          .reachFollow .otherWay .contact .ctaLink {
            margin: 0 auto -30px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .reachFollow .otherWay .contact .ctaLink {
      margin: 0 auto -22px; } }
    .reachFollow .otherWay .writeToPrez {
      float: left;
      width: 100%;
      margin: 90px 0 0 0;
      padding: 0 40px; }
      @media (max-width: 1023px) {
        .reachFollow .otherWay .writeToPrez {
          margin: 70px 0 0 0;
          padding: 0 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .reachFollow .otherWay .writeToPrez {
      padding: 0 15px; } }
      .reachFollow .otherWay .writeToPrez h2 {
        font-family: "Playfair Display", serif;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #ed1b2e;
        text-align: center;
        width: 80%;
        margin: 0 auto 15px; }
        @media (max-width: 1023px) {
          .reachFollow .otherWay .writeToPrez h2 {
            font-size: 17px;
            line-height: 21px;
            width: 100%; } }
      .reachFollow .otherWay .writeToPrez .tiny p {
        font-weight: 300;
        text-align: center;
        margin: 0; }
        @media (max-width: 767px) {
          .reachFollow .otherWay .writeToPrez .tiny p {
            margin: 0 auto;
            max-width: 400px; } }
      .reachFollow .otherWay .writeToPrez .tiny h5 {
        text-align: center; }
      .reachFollow .otherWay .writeToPrez .tiny h6 {
        text-align: center; }
      .reachFollow .otherWay .writeToPrez .tiny > a {
        margin: 0 auto;
        display: table; }

.pagination {
  float: right;
  margin: 0 0 90px;
  clear: both; }
  @media (max-width: 767px) {
    .pagination {
      margin: 0 0 60px; } }
  .pagination .page {
    width: auto;
    float: left;
    padding: 26px 35px 0 0; }
    @media (max-width: 1023px) {
      .pagination .page {
        padding: 21px 25px 0 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .pagination .page {
      padding: 18px 20px 0 0; } }
    .pagination .page a, .pagination .page span {
      float: left;
      margin: 0 10px;
      padding: 0 5px;
      color: #252525;
      position: relative;
      font-size: 13px;
      transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1023px) {
        .pagination .page a, .pagination .page span {
          margin: 0 3px 30px; } }
      .allowHover .pagination .page a.active:hover, .allowHover .pagination .page span.active:hover {
        opacity: 1; }
      .pagination .page a.active:after, .pagination .page span.active:after {
        position: absolute;
        bottom: -12px;
        left: 50%;
        content: "";
        background: #ed1b2e;
        height: 3px;
        width: 28px;
        transform: translateX(-50%); }
    .pagination .page a:hover {
      opacity: 0.6; }
  .pagination .controls {
    float: left; }
    @media (max-width: 767px) {
      .pagination .controls {
        float: right; } }
    .pagination .controls a {
      padding: 20px;
      background: #ed1b2e;
      color: #ffffff;
      float: left;
      transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 1023px) {
        .pagination .controls a {
          padding: 15px; } }
      .pagination .controls a i {
        font-size: 22px;
        display: inline-block;
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .pagination .controls a i {
            font-size: 18px; } }
      .pagination .controls a.prev {
        margin: 0 1px 0 0; }
        .pagination .controls a.prev i {
          transform: rotate(180deg); }
      .allowHover .pagination .controls a:hover {
        background: #ca1727; }
        .allowHover .pagination .controls a:hover i {
          transform: translate(5px, 0); }
        .allowHover .pagination .controls a:hover.prev i {
          transform: translateX(-5px) rotate(180deg); }

.callToAction {
  background: #f8f7f3;
  clear: both;
  padding: 40px;
  margin: 0 0 60px;
  position: relative;
  float: left;
  width: 100%; }
  @media (max-width: 767px) {
    .callToAction {
      padding: 20px 15px 60px;
      margin: 0 0 35px; } }
  .callToAction .success {
    display: none; }
  .callToAction .ctaLink {
    clear: both;
    margin: 0 auto;
    font-family: "Muli", sans-serif; }
  .callToAction input {
    height: 70px;
    width: 100%;
    border: none;
    font-weight: 300;
    font-size: 15px;
    padding: 0 25px;
    margin: 15px 0 25px;
    border-radius: 0px; }
  .callToAction .title {
    color: #ed1b2e;
    font-size: 26px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }

/*** TEMPLATE ***/
.home {
  overflow-x: ddeg; }
  .home .slider {
    margin: 0 -70px 30px 0;
    position: relative; }
    @media (max-width: 1280px) {
      .home .slider {
        margin: 0 -35px 30px 0; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .home .slider {
      margin: 0 0 30px 0; } }
    .home .slider .bx-wrapper {
      width: 100%; }
      @media (max-width: 767px) {
        .home .slider .bx-wrapper .bx-viewport {
          overflow: visible !important; } }
      .home .slider .bx-wrapper .bx-viewport ul li {
        position: relative; }
        .home .slider .bx-wrapper .bx-viewport ul li:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 65%;
          height: 100%;
          z-index: 10;
          opacity: 0.6;
          background: linear-gradient(to right, #e5f1f6 0%, rgba(255, 255, 255, 0) 100%); }
        @media (max-width: 767px) {
          .home .slider .bx-wrapper .bx-viewport ul li {
            padding: 0 0 90px; }
            .home .slider .bx-wrapper .bx-viewport ul li:before {
              display: none; } }
        .home .slider .bx-wrapper .bx-viewport ul li img {
          float: right;
          width: 65%;
          display: block; }
          @media (max-width: 767px) {
            .home .slider .bx-wrapper .bx-viewport ul li img {
              width: 100%; } }
        .home .slider .bx-wrapper .bx-viewport ul li .videoZone {
          width: 65%;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          z-index: 20; }
          @media (max-width: 767px) {
            .home .slider .bx-wrapper .bx-viewport ul li .videoZone {
              width: 100%;
              height: auto; }
              .home .slider .bx-wrapper .bx-viewport ul li .videoZone:before {
                display: block;
                content: " ";
                width: 100%;
                padding-top: 86%; } }
          .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo {
            width: 80px;
            height: 80px;
            position: absolute;
            left: 50%;
            top: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo {
                width: 50px;
                height: 50px; } }
            .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo .btnPlay {
              width: 100%;
              height: 100%;
              transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo .btnPlay i {
                display: inline-block;
                color: #ffffff;
                font-size: 78px;
                margin: -8px 0 0 -13px;
                transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
                @media (max-width: 767px) {
                  .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo .btnPlay i {
                    font-size: 58px; } }
              .allowHover .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo .btnPlay:hover {
                transform: scale(1.1); }
                .allowHover .home .slider .bx-wrapper .bx-viewport ul li .videoZone .openVideo .btnPlay:hover i {
                  color: #ed1b2e; }
        .home .slider .bx-wrapper .bx-viewport ul li .bx-caption {
          width: 55%;
          top: 0;
          padding: 95px 0 0 0;
          z-index: 15; }
          @media (max-width: 1440px) {
            .home .slider .bx-wrapper .bx-viewport ul li .bx-caption {
              padding: 30px 0 0 0; } }
  @media (max-width: 1440px) and (max-width: 1280px) {
    .home .slider .bx-wrapper .bx-viewport ul li .bx-caption {
      width: 60%; } }
  @media (max-width: 1440px) and (max-width: 1280px) and (max-width: 1023px) {
    .home .slider .bx-wrapper .bx-viewport ul li .bx-caption {
      padding: 15px 0 0 0; } }
  @media (max-width: 1440px) and (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .slider .bx-wrapper .bx-viewport ul li .bx-caption {
      width: 100%;
      top: auto;
      padding: 20px 0 0 0;
      position: relative;
      clear: both; } }
          .home .slider .bx-wrapper .bx-viewport ul li .bx-caption span h2 {
            font-size: 50px;
            line-height: 55px;
            font-family: "Playfair Display", serif;
            margin: 35px 0 55px;
            font-weight: 400; }
            @media (max-width: 1280px) {
              .home .slider .bx-wrapper .bx-viewport ul li .bx-caption span h2 {
                font-size: 40px;
                line-height: 45px;
                margin: 25px 0 45px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .home .slider .bx-wrapper .bx-viewport ul li .bx-caption span h2 {
      font-size: 28px;
      line-height: 31px;
      margin: 15px 0 25px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .slider .bx-wrapper .bx-viewport ul li .bx-caption span h2 {
      margin: 20px 0; } }
      .home .slider .bx-wrapper .bx-controls-direction {
        width: 100%; }
        .home .slider .bx-wrapper .bx-controls-direction a {
          padding: 20px;
          background: #ed1b2e;
          color: #ffffff;
          bottom: 95px;
          transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .mobileDevice .home .slider .bx-wrapper .bx-controls-direction a {
            padding: 20px 20px 18px; }
          @media (max-width: 1280px) {
            .home .slider .bx-wrapper .bx-controls-direction a {
              bottom: 15px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .home .slider .bx-wrapper .bx-controls-direction a {
      padding: 15px; }
      .mobileDevice .home .slider .bx-wrapper .bx-controls-direction a {
        padding: 15px 15px 13px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .slider .bx-wrapper .bx-controls-direction a {
      bottom: 0px; } }
          .home .slider .bx-wrapper .bx-controls-direction a i {
            font-size: 22px;
            display: inline-block;
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .home .slider .bx-wrapper .bx-controls-direction a i {
                font-size: 18px; } }
          .home .slider .bx-wrapper .bx-controls-direction a.bx-prev {
            left: 35%;
            transform: translateX(-101%); }
            .home .slider .bx-wrapper .bx-controls-direction a.bx-prev i {
              transform: rotate(180deg) translate(0, 2px); }

@-moz-document url-prefix() {
  .home .slider .bx-wrapper .bx-controls-direction a.bx-prev i {
    transform: rotate(180deg) translate(0, 0); } }
            @media (max-width: 767px) {
              .home .slider .bx-wrapper .bx-controls-direction a.bx-prev {
                left: auto;
                right: 0;
                transform: translateX(-100%); } }
          .home .slider .bx-wrapper .bx-controls-direction a.bx-next {
            left: 35%;
            right: auto; }
            @media (max-width: 767px) {
              .home .slider .bx-wrapper .bx-controls-direction a.bx-next {
                left: auto;
                right: 0; } }
            .mobileDevice .home .slider .bx-wrapper .bx-controls-direction a.bx-next i {
              transform: translate(0, -1px); }
          .allowHover .home .slider .bx-wrapper .bx-controls-direction a:hover {
            background: #ca1727; }
            .allowHover .home .slider .bx-wrapper .bx-controls-direction a:hover i {
              transform: translate(5px, 0); }
            .allowHover .home .slider .bx-wrapper .bx-controls-direction a:hover.bx-prev i {
              transform: rotate(180deg) translate(5px, 2px); }

@-moz-document url-prefix() {
  .allowHover .home .slider .bx-wrapper .bx-controls-direction a:hover.bx-prev i {
    transform: rotate(180deg) translate(5px, 0); } }
    .home .slider #bx-pager {
      width: 22%;
      border-bottom: 1px solid #252525;
      position: absolute;
      bottom: 130px;
      left: 0;
      z-index: 50; }
      @media (max-width: 1280px) {
        .home .slider #bx-pager {
          bottom: 50px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .home .slider #bx-pager {
      bottom: 40px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .slider #bx-pager {
      bottom: 25px;
      width: 50%; } }
      .home .slider #bx-pager a {
        -webkit-font-smoothing: antialiased;
        color: #252525;
        font-size: 13px;
        padding: 15px 12px;
        display: inline-block;
        position: relative;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 1023px) {
          .home .slider #bx-pager a {
            padding: 15px 8px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .home .slider #bx-pager a {
      font-size: 11px;
      padding: 7px; } }
        .allowHover .home .slider #bx-pager a:hover {
          opacity: 0.6; }
        .home .slider #bx-pager a.active {
          opacity: 1; }
        .home .slider #bx-pager a.active:after {
          position: absolute;
          bottom: -1px;
          width: 100%;
          height: 2px;
          background: #ed1b2e;
          left: -2px;
          content: '';
          border-left: 2px solid #ffffff;
          border-right: 2px solid #ffffff; }
  .home .bigSubject {
    width: 100%;
    margin: 0 0 40px;
    float: left; }
    @media (max-width: 767px) {
      .home .bigSubject {
        margin: 0 0 20px; } }
    .home .bigSubject .blockTitle {
      margin: 0 0 -25px; }
      .home .bigSubject .blockTitle div {
        top: 30%; }
        @media (max-width: 1023px) {
          .home .bigSubject .blockTitle div {
            top: 20%; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .home .bigSubject .blockTitle div {
      top: 8%; } }
      @media (max-width: 767px) {
        .home .bigSubject .blockTitle img {
          width: 175px; } }
    .home .bigSubject .subject {
      width: 48%;
      position: relative;
      z-index: 10;
      float: left; }
      @media (max-width: 767px) {
        .home .bigSubject .subject {
          width: 100%;
          margin: 0 0 15px; } }
      .home .bigSubject .subject img {
        width: 100%;
        height: auto;
        display: block; }
      .home .bigSubject .subject .txt {
        padding: 30px 20px; }
        @media (max-width: 767px) {
          .home .bigSubject .subject .txt {
            padding: 20px 15px; } }
        .home .bigSubject .subject .txt h4 {
          font-size: 26px;
          line-height: 36px;
          font-family: "Playfair Display", serif;
          margin: 30px 0 25px 0;
          font-weight: 400; }
          @media (max-width: 767px) {
            .home .bigSubject .subject .txt h4 {
              font-size: 18px;
              line-height: 23px;
              margin: 25px 0 20px 0; } }
          .home .bigSubject .subject .txt h4 a {
            -webkit-font-smoothing: antialiased;
            color: #252525;
            transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            .allowHover .home .bigSubject .subject .txt h4 a:hover {
              opacity: 0.6; }
      .home .bigSubject .subject:nth-child(3), .home .bigSubject .subject.right {
        float: right; }
  .home .register {
    background: #f8f7f3;
    border-left: 3.5vw solid #ffffff;
    border-right: 3.5vw solid #ffffff;
    clear: both;
    padding: 40px 40px 70px;
    margin: 0 0 60px;
    position: relative;
    float: left;
    width: 100%; }
    @media (max-width: 767px) {
      .home .register {
        border: none;
        padding: 20px 15px 60px;
        margin: 0 0 35px; } }
    .home .register .title {
      position: relative;
      margin: 0 auto;
      width: 100%; }
      .home .register .title > img {
        display: block;
        margin: 0 auto; }
        @media (max-width: 767px) {
          .home .register .title > img {
            width: 125px; } }
      .home .register .title div {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%); }
        .home .register .title div h3 {
          color: #ed1b2e;
          font-size: 56px;
          font-family: "Playfair Display", serif;
          font-weight: 400;
          width: 100%;
          text-align: center; }
          @media (max-width: 1280px) {
            .home .register .title div h3 {
              font-size: 46px;
              line-height: 53px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .home .register .title div h3 {
      font-size: 28px;
      line-height: 31px; } }
    .home .register .find p {
      font-size: 22px;
      line-height: 38px;
      max-width: 725px;
      text-align: center;
      margin: 10px auto 40px;
      font-family: "Playfair Display", serif; }
      @media (max-width: 767px) {
        .home .register .find p {
          font-size: 16px;
          line-height: 24px; } }
    .home .register .find .form {
      width: 730px;
      margin: 0 auto 25px;
      position: relative;
      height: 70px; }
      @media (max-width: 1280px) {
        .home .register .find .form {
          width: 650px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .home .register .find .form {
      width: 520px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .register .find .form {
      width: 100%;
      height: 0; } }
      .home .register .find .form .filter {
        width: 335px; }
        @media (max-width: 1280px) {
          .home .register .find .form .filter {
            width: 290px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .home .register .find .form .filter {
      width: 230px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .register .find .form .filter {
      width: 100%;
      margin: 0 0 25px; } }
      .home .register .find .form div.or {
        text-align: center;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 28px;
        height: 28px;
        left: 50%;
        top: 50%; }
        @media (max-width: 767px) {
          .home .register .find .form div.or {
            position: relative;
            margin: 25px auto;
            left: auto;
            clear: both;
            transform: translate(0, 0); } }
        .home .register .find .form div.or span {
          display: block;
          color: #ffffff;
          font-size: 13px;
          font-weight: 300;
          z-index: 10;
          position: relative;
          line-height: 27px; }
        .home .register .find .form div.or:after {
          background: #007ac2;
          width: 28px;
          height: 28px;
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          transform: rotate(45deg); }
      .home .register .find .form input {
        height: 70px;
        width: 335px;
        border: none;
        float: right;
        font-weight: 300;
        text-align: center;
        font-size: 15px;
        border-radius: 0px; }
        @media (max-width: 1280px) {
          .home .register .find .form input {
            width: 290px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .home .register .find .form input {
      width: 230px;
      font-size: 13px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .home .register .find .form input {
      width: 100%;
      height: 50px;
      margin: 0 0 25px;
      font-size: 12px; } }
    .home .register .find .ctaLink {
      clear: both;
      margin: 0 auto; }
      @media (max-width: 767px) {
        .home .register .find .ctaLink {
          width: 100%;
          text-align: center; } }
    .home .register .find #response_circonscription {
      text-align: center;
      margin: 30px 0 0 0;
      color: #ed1b2e;
      font-size: 16px; }
      @media (max-width: 767px) {
        .home .register .find #response_circonscription {
          margin: 25px 0 0 0;
          font-size: 14px; } }
    .home .register .info .change {
      position: absolute;
      top: 45px;
      right: 55px;
      cursor: pointer;
      color: #7b97a3;
      font-size: 12px; }
      .home .register .info .change i {
        font-size: 18px;
        display: inline-block;
        transform: translateY(3px); }
      @media (max-width: 1023px) {
        .home .register .info .change {
          top: 25px;
          right: 25px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .home .register .info .change {
      top: 10px;
      right: 10px; } }
    .home .register .info .col {
      float: left;
      width: 33.33%;
      padding: 0 25px;
      margin: 20px 0 0 0; }
      @media (max-width: 1023px) {
        .home .register .info .col {
          width: 100%;
          padding: 0px; } }
      .home .register .info .col h4 {
        color: #ed1b2e;
        font-size: 23px;
        text-align: center;
        font-family: "Playfair Display", serif;
        font-weight: 400;
        margin: 0 0 20px; }
        @media (max-width: 767px) {
          .home .register .info .col h4 {
            font-size: 18px;
            margin: 0 0 15px; } }
      .home .register .info .col .socialMedia {
        text-align: center; }
      .home .register .info .col.depute .thumb {
        display: table;
        position: relative;
        border-radius: 75px;
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin: 0 auto 15px;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .home .register .info .col.depute .thumb img {
          display: block; }
        .allowHover .home .register .info .col.depute .thumb:hover {
          opacity: 0.6; }
      .home .register .info .col.depute h5 {
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        width: 100%; }
        @media (max-width: 767px) {
          .home .register .info .col.depute h5 {
            font-size: 12px; } }
        .home .register .info .col.depute h5 a {
          color: #252525;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .allowHover .home .register .info .col.depute h5 a:hover {
            opacity: 0.6; }
      .home .register .info .col.tiny {
        text-align: center; }
        .home .register .info .col.tiny p {
          font-weight: 300;
          margin: 0; }
        .home .register .info .col.tiny > a {
          margin: 0 auto;
          display: table; }
      .home .register .info .col.depLastNews .tag a {
        display: table;
        margin: 0 auto; }
      .home .register .info .col.depLastNews h6 {
        font-size: 11px;
        text-transform: uppercase;
        text-align: center;
        margin: 20px 0 0 0; }
        @media (max-width: 767px) {
          .home .register .info .col.depLastNews h6 {
            font-size: 10px;
            margin: 15px 0 0 0; } }
      .home .register .info .col.depLastNews .date {
        text-align: center;
        color: #666666;
        font-size: 14px;
        font-family: "Playfair Display", serif;
        display: block;
        margin: 5px 0 0 0; }
        @media (max-width: 767px) {
          .home .register .info .col.depLastNews .date {
            font-size: 12px; } }
      .home .register .info .col.depLastNews p {
        text-align: center;
        font-size: 15px;
        line-height: 28px;
        margin: 5px 0 0 0;
        font-weight: 300; }
        @media (max-width: 767px) {
          .home .register .info .col.depLastNews p {
            font-size: 14px;
            line-height: 25px; } }
        .home .register .info .col.depLastNews p a {
          color: #252525;
          display: inline-block;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .allowHover .home .register .info .col.depLastNews p a:hover {
            opacity: 0.6; }
  .home .lastNews {
    clear: both;
    margin: 0 0 10px;
    float: left;
    width: 100%;
    border-left: 3.5vw solid #ffffff;
    border-right: 3.5vw solid #ffffff; }
    @media (max-width: 767px) {
      .home .lastNews {
        border: none; } }
    .home .lastNews .blockTitle {
      margin: 0 0 -80px; }
      .home .lastNews .blockTitle img {
        width: 190px;
        height: auto; }
      .home .lastNews .blockTitle div {
        top: 22%; }
        @media (max-width: 1023px) {
          .home .lastNews .blockTitle div {
            top: 12%; } }
    @media (max-width: 1280px) {
      .home .lastNews .newsSmall:nth-child(3n) {
        display: none; } }
  .home .banner {
    margin: 40px 0 60px 0;
    clear: both;
    float: left;
    width: 100%;
    position: relative; }
    @media (max-width: 767px) {
      .home .banner {
        margin: 20px 0 30px 0;
        overflow: hidden; } }
    .home .banner img {
      width: 100%;
      display: block; }
      @media (max-width: 767px) {
        .home .banner img {
          height: 350px;
          width: auto;
          max-width: none; } }
    .home .banner .txt {
      width: 65%;
      position: absolute;
      left: 8%;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        .home .banner .txt {
          width: 80%; } }
      .home .banner .txt h2 {
        font-size: 58px;
        line-height: 63px;
        color: #ffffff;
        font-weight: 400;
        font-family: "Playfair Display", serif;
        margin: 0 0 55px; }
        @media (max-width: 1280px) {
          .home .banner .txt h2 {
            font-size: 48px;
            line-height: 53px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .home .banner .txt h2 {
      font-size: 34px;
      line-height: 36px;
      margin: 0 0 45px; } }
  .home .elsewhere {
    width: 48%;
    clear: both;
    float: left;
    margin: 0 0 60px; }
    @media (max-width: 1280px) {
      .home .elsewhere {
        width: 100%; } }
    .home .elsewhere .blockTitle {
      margin: 0 0 25px; }
      .home .elsewhere .blockTitle img {
        width: 200px; }
        @media (max-width: 767px) {
          .home .elsewhere .blockTitle img {
            width: 130px; } }
      .home .elsewhere .blockTitle div {
        top: 40%; }
        @media (max-width: 1023px) {
          .home .elsewhere .blockTitle div {
            top: 30%; } }
    .home .elsewhere .webArticle {
      width: 100%;
      border-top: 1px solid #b2b2b2; }
      .home .elsewhere .webArticle:last-child {
        border-bottom: 1px solid #b2b2b2; }
      .home .elsewhere .webArticle a {
        display: block;
        padding: 20px 40px; }
        @media (max-width: 767px) {
          .home .elsewhere .webArticle a {
            padding: 10px 30px 20px; } }
        .home .elsewhere .webArticle a .date {
          color: #666666;
          font-size: 14px;
          font-family: "Playfair Display", serif;
          display: inline-block;
          margin: 0 0 5px; }
          @media (max-width: 767px) {
            .home .elsewhere .webArticle a .date {
              font-size: 12px; } }
        .home .elsewhere .webArticle a p {
          color: #252525;
          font-size: 14px;
          line-height: 28px;
          padding: 0 0 0 40px;
          position: relative;
          width: 100%;
          box-sizing: content-box;
          transform: translateX(-40px); }
          @media (max-width: 767px) {
            .home .elsewhere .webArticle a p {
              font-size: 12px;
              line-height: 22px; } }
          .home .elsewhere .webArticle a p i {
            color: #ed1b2e;
            display: inline-block;
            font-size: 22px;
            position: absolute;
            left: 0;
            top: -1px;
            transition: left 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .home .elsewhere .webArticle a p i {
                font-size: 18px;
                left: 10px; } }
        .home .elsewhere .webArticle a .goTo {
          margin: 10px 0 0 0;
          background: #aec4cd;
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 12px;
          padding: 3px 12px 6px 8px;
          transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .home .elsewhere .webArticle a .goTo {
              font-size: 10px;
              padding: 5px 8px 8px; } }
          .home .elsewhere .webArticle a .goTo i {
            font-size: 18px;
            margin: 0 2px 0 0;
            display: inline-block; }
            @media (max-width: 767px) {
              .home .elsewhere .webArticle a .goTo i {
                font-size: 14px; } }
        .allowHover .home .elsewhere .webArticle a:hover p i {
          left: 5px; }
          @media (max-width: 767px) {
            .allowHover .home .elsewhere .webArticle a:hover p i {
              left: 15px; } }
        .allowHover .home .elsewhere .webArticle a:hover .goTo {
          background: #7b97a3; }

.listingCommuniques {
  margin: -120px 0 0 0; }
  @media (max-width: 1023px) {
    .listingCommuniques {
      margin: -100px 0 0 0; } }
  .listingCommuniques .highlight {
    position: relative;
    margin: 30px 0 75px; }
    @media (max-width: 1023px) {
      .listingCommuniques .highlight {
        margin: 0 0 75px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .listingCommuniques .highlight {
      margin: 0 0 40px; } }
    .listingCommuniques .highlight > a {
      width: 48%;
      float: left; }
      @media (max-width: 767px) {
        .listingCommuniques .highlight > a {
          width: 100%; } }
    .listingCommuniques .highlight .txt {
      width: 48%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        .listingCommuniques .highlight .txt {
          width: 100%;
          position: relative;
          top: auto;
          padding: 15px 0 0 0;
          clear: both;
          transform: translateY(0); } }
      .listingCommuniques .highlight .txt h6 {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 400;
        margin: 25px 0 15px; }
        @media (max-width: 767px) {
          .listingCommuniques .highlight .txt h6 {
            font-size: 10px; } }
      .listingCommuniques .highlight .txt .date {
        font-family: "Playfair Display", serif;
        color: #666666;
        font-size: 14px;
        margin: 0 0 10px;
        display: inline-block; }
        @media (max-width: 767px) {
          .listingCommuniques .highlight .txt .date {
            font-size: 12px;
            margin: 0 0 5px; } }
      .listingCommuniques .highlight .txt h2 {
        font-size: 38px;
        line-height: 48px;
        font-weight: 400;
        font-family: "Playfair Display", serif;
        margin: 0 0 15px; }
        @media (max-width: 1023px) {
          .listingCommuniques .highlight .txt h2 {
            font-size: 28px;
            line-height: 31px; } }
        .listingCommuniques .highlight .txt h2 a {
          color: #252525;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .allowHover .listingCommuniques .highlight .txt h2 a:hover {
            opacity: 0.6; }
      .listingCommuniques .highlight .txt .socialMedia {
        transform: translateX(-12px); }
  .listingCommuniques .filterTools {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 0 0 190px; }
    @media (max-width: 767px) {
      .listingCommuniques .filterTools {
        border: none;
        padding: 0 0 180px; } }
    .listingCommuniques .filterTools h1 {
      font-weight: 400;
      color: #ed1b2e;
      font-size: 37px;
      font-family: "Playfair Display", serif; }
    .listingCommuniques .filterTools .filters {
      margin: 40px 0 0 0; }
      .listingCommuniques .filterTools .filters .filter {
        width: 33.33%;
        border-right: 1px solid #f8f7f3; }
        @media (max-width: 1023px) {
          .listingCommuniques .filterTools .filters .filter {
            width: 100%;
            border: none;
            margin: 0 0 15px; } }
      .listingCommuniques .filterTools .filters .search {
        width: 33.33%;
        float: left;
        position: relative; }
        @media (max-width: 1023px) {
          .listingCommuniques .filterTools .filters .search {
            width: 100%; } }
        .listingCommuniques .filterTools .filters .search input {
          padding: 0 70px 0 40px;
          height: 70px;
          width: 100%;
          border: none;
          font-weight: 300;
          font-size: 15px;
          border-radius: 0px; }
          @media (max-width: 1280px) {
            .listingCommuniques .filterTools .filters .search input {
              font-size: 12px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .listingCommuniques .filterTools .filters .search input {
      padding: 0 60px 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .listingCommuniques .filterTools .filters .search input {
      height: 50px; } }
        .listingCommuniques .filterTools .filters .search a {
          position: absolute;
          right: 25px;
          top: 20px;
          font-size: 25px;
          color: #252525;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 1023px) {
            .listingCommuniques .filterTools .filters .search a {
              right: 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .listingCommuniques .filterTools .filters .search a {
      font-size: 20px;
      top: 12px; } }
          .allowHover .listingCommuniques .filterTools .filters .search a:hover {
            color: #ed1b2e; }
  .listingCommuniques .listing {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    margin: -125px 0 0 0; }
    @media (max-width: 767px) {
      .listingCommuniques .listing {
        border: none; } }
    .listingCommuniques .listing .noResultMessage {
      margin: 0 0 80px;
      font-family: "Playfair Display", serif;
      font-size: 20px; }
      @media (max-width: 767px) {
        .listingCommuniques .listing .noResultMessage {
          font-size: 16px;
          margin: 0 0 60px; } }

.listingBBQ {
  margin: -120px 0 0 0; }
  @media (max-width: 1023px) {
    .listingBBQ {
      margin: -100px 0 0 0; } }
  .listingBBQ .highlight {
    position: relative;
    margin: 100px 0 75px;
    height: 608px; }
    @media (max-width: 1023px) {
      .listingBBQ .highlight {
        margin: 0 0 75px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .listingBBQ .highlight {
      margin: 0 0 40px; } }
    .listingBBQ .highlight > .logo-bbq {
      width: 28%;
      float: left;
      text-align: center;
      margin-top: 95px; }
      @media (max-width: 767px) {
        .listingBBQ .highlight > .logo-bbq {
          width: 100%; } }
    .listingBBQ .highlight .map {
      width: 68%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        .listingBBQ .highlight .map {
          width: 100%;
          position: relative;
          top: auto;
          padding: 15px 0 0 0;
          clear: both;
          transform: translateY(0); } }
      .listingBBQ .highlight .map h6 {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 400;
        margin: 25px 0 15px; }
        @media (max-width: 767px) {
          .listingBBQ .highlight .map h6 {
            font-size: 10px; } }
      .listingBBQ .highlight .map .date {
        font-family: "Playfair Display", serif;
        color: #666666;
        font-size: 14px;
        margin: 0 0 10px;
        display: inline-block; }
        @media (max-width: 767px) {
          .listingBBQ .highlight .map .date {
            font-size: 12px;
            margin: 0 0 5px; } }
      .listingBBQ .highlight .map h2 {
        font-size: 38px;
        line-height: 48px;
        font-weight: 400;
        font-family: "Playfair Display", serif;
        margin: 0 0 15px; }
        @media (max-width: 1023px) {
          .listingBBQ .highlight .map h2 {
            font-size: 28px;
            line-height: 31px; } }
        .listingBBQ .highlight .map h2 a {
          color: #252525;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .allowHover .listingBBQ .highlight .map h2 a:hover {
            opacity: 0.6; }
      .listingBBQ .highlight .map .socialMedia {
        transform: translateX(-12px); }
      .listingBBQ .highlight .map .map-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0; }
        .listingBBQ .highlight .map .map-responsive iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute; }
  .listingBBQ .filterTools {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 0 0 190px; }
    @media (max-width: 767px) {
      .listingBBQ .filterTools {
        border: none;
        padding: 106px 0 180px; } }
    .listingBBQ .filterTools h1 {
      font-weight: 400;
      color: #ed1b2e;
      font-size: 37px;
      font-family: "Playfair Display", serif; }
    .listingBBQ .filterTools .filters {
      margin: 40px 0 0 0; }
      .listingBBQ .filterTools .filters .filter {
        width: 33.33%;
        border-right: 1px solid #f8f7f3; }
        @media (max-width: 1023px) {
          .listingBBQ .filterTools .filters .filter {
            width: 100%;
            border: none;
            margin: 0 0 15px; } }
      .listingBBQ .filterTools .filters .search {
        width: 66.66%;
        float: left;
        position: relative; }
        @media (max-width: 1023px) {
          .listingBBQ .filterTools .filters .search {
            width: 100%; } }
        .listingBBQ .filterTools .filters .search input {
          padding: 0 70px 0 40px;
          height: 70px;
          width: 100%;
          border: none;
          font-weight: 300;
          font-size: 15px;
          border-radius: 0px; }
          @media (max-width: 1280px) {
            .listingBBQ .filterTools .filters .search input {
              font-size: 12px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .listingBBQ .filterTools .filters .search input {
      padding: 0 60px 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .listingBBQ .filterTools .filters .search input {
      height: 50px; } }
        .listingBBQ .filterTools .filters .search a {
          position: absolute;
          right: 25px;
          top: 20px;
          font-size: 25px;
          color: #252525;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 1023px) {
            .listingBBQ .filterTools .filters .search a {
              right: 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .listingBBQ .filterTools .filters .search a {
      font-size: 20px;
      top: 12px; } }
          .allowHover .listingBBQ .filterTools .filters .search a:hover {
            color: #ed1b2e; }
  .listingBBQ .listing {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    margin: -125px 0 0 0; }
    @media (max-width: 767px) {
      .listingBBQ .listing {
        border: none; } }
    .listingBBQ .listing .noResultMessage {
      margin: 0 0 80px;
      font-family: "Playfair Display", serif;
      font-size: 20px; }
      @media (max-width: 767px) {
        .listingBBQ .listing .noResultMessage {
          font-size: 16px;
          margin: 0 0 60px; } }

.singleCommunique .wouldLike {
  border-left: 3.5vw solid #ffffff;
  border-right: 3.5vw solid #ffffff;
  clear: both;
  width: 100%;
  float: left;
  padding: 10px 0 30px; }
  @media (max-width: 767px) {
    .singleCommunique .wouldLike {
      border: none; } }
  .singleCommunique .wouldLike .list {
    padding: 90px 0 0 0; }
    @media (max-width: 1280px) {
      .singleCommunique .wouldLike .list .newsSmall:nth-child(3) {
        display: none; } }

@media (max-width: 1280px) and (max-width: 767px) {
  .singleCommunique .wouldLike .list {
    padding: 60px 0 0 0; } }

.ailleursWeb, .amisEnFace {
  margin: -120px 0 0 0; }
  .ailleursWeb .headerTitle, .amisEnFace .headerTitle {
    background: #f8f7f3;
    padding: 110px 0 45px;
    overflow: hidden;
    position: relative;
    clear: both;
    min-height: 275px; }
    @media (max-width: 1023px) {
      .ailleursWeb .headerTitle, .amisEnFace .headerTitle {
        padding: 90px 0 45px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .ailleursWeb .headerTitle, .amisEnFace .headerTitle {
      padding: 30px 0 45px;
      min-height: 100px; } }
    .ailleursWeb .headerTitle .txt, .amisEnFace .headerTitle .txt {
      position: relative;
      z-index: 10; }
      .ailleursWeb .headerTitle .txt h1, .amisEnFace .headerTitle .txt h1 {
        font-size: 49px;
        line-height: 55px;
        color: #ed1b2e;
        font-family: "Playfair Display", serif;
        font-weight: 400; }
        @media (max-width: 767px) {
          .ailleursWeb .headerTitle .txt h1, .amisEnFace .headerTitle .txt h1 {
            font-size: 38px;
            line-height: 45px; } }
      .ailleursWeb .headerTitle .txt p, .amisEnFace .headerTitle .txt p {
        margin: 20px 0 0 0;
        width: 60%;
        font-size: 17px;
        line-height: 31px;
        font-weight: 300; }
        @media (max-width: 767px) {
          .ailleursWeb .headerTitle .txt p, .amisEnFace .headerTitle .txt p {
            font-size: 14px;
            line-height: 26px;
            width: 100%;
            margin: 15px 0 0 0; } }
    .ailleursWeb .headerTitle img, .amisEnFace .headerTitle img {
      position: absolute;
      right: 20%;
      bottom: -15px; }
      @media (max-width: 1023px) {
        .ailleursWeb .headerTitle img, .amisEnFace .headerTitle img {
          right: 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .ailleursWeb .headerTitle img, .amisEnFace .headerTitle img {
      width: 250px; } }
  .ailleursWeb .linkList, .amisEnFace .linkList {
    padding: 60px 0; }
    .ailleursWeb .linkList .webArticle, .amisEnFace .linkList .webArticle {
      width: 100%;
      border-top: 1px solid #b2b2b2; }
      .ailleursWeb .linkList .webArticle:last-child, .amisEnFace .linkList .webArticle:last-child {
        border-bottom: 1px solid #b2b2b2; }
      .ailleursWeb .linkList .webArticle a, .amisEnFace .linkList .webArticle a {
        display: block;
        padding: 20px 40px;
        position: relative; }
        @media (max-width: 767px) {
          .ailleursWeb .linkList .webArticle a, .amisEnFace .linkList .webArticle a {
            padding: 10px 30px 20px; } }
        .ailleursWeb .linkList .webArticle a .date, .amisEnFace .linkList .webArticle a .date {
          color: #666666;
          font-size: 14px;
          font-family: "Playfair Display", serif;
          display: inline-block;
          margin: 0 0 5px; }
          @media (max-width: 767px) {
            .ailleursWeb .linkList .webArticle a .date, .amisEnFace .linkList .webArticle a .date {
              font-size: 12px; } }
        .ailleursWeb .linkList .webArticle a p, .amisEnFace .linkList .webArticle a p {
          color: #252525;
          font-size: 14px;
          line-height: 28px;
          padding: 0 0 0 40px;
          position: relative;
          width: 60%;
          box-sizing: content-box;
          transform: translateX(-40px); }
          @media (max-width: 767px) {
            .ailleursWeb .linkList .webArticle a p, .amisEnFace .linkList .webArticle a p {
              font-size: 12px;
              line-height: 22px;
              width: 100%; } }
          .ailleursWeb .linkList .webArticle a p i, .amisEnFace .linkList .webArticle a p i {
            color: #ed1b2e;
            display: inline-block;
            font-size: 22px;
            position: absolute;
            left: 0;
            top: -1px;
            transition: left 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .ailleursWeb .linkList .webArticle a p i, .amisEnFace .linkList .webArticle a p i {
                font-size: 18px;
                left: 10px; } }
        .ailleursWeb .linkList .webArticle a .goTo, .amisEnFace .linkList .webArticle a .goTo {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          background: #aec4cd;
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 12px;
          padding: 7px 10px 10px;
          transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .ailleursWeb .linkList .webArticle a .goTo, .amisEnFace .linkList .webArticle a .goTo {
              font-size: 10px;
              padding: 5px 8px 7px;
              position: relative;
              top: auto;
              transform: translateY(0);
              margin: 10px 0 0 0; } }
          .ailleursWeb .linkList .webArticle a .goTo i, .amisEnFace .linkList .webArticle a .goTo i {
            font-size: 18px;
            margin: 0 2px 0 0;
            display: inline-block; }
            @media (max-width: 767px) {
              .ailleursWeb .linkList .webArticle a .goTo i, .amisEnFace .linkList .webArticle a .goTo i {
                font-size: 14px; } }
        .allowHover .ailleursWeb .linkList .webArticle a:hover p i, .allowHover .amisEnFace .linkList .webArticle a:hover p i {
          left: 5px; }
          @media (max-width: 767px) {
            .allowHover .ailleursWeb .linkList .webArticle a:hover p i, .allowHover .amisEnFace .linkList .webArticle a:hover p i {
              left: 15px; } }
        .allowHover .ailleursWeb .linkList .webArticle a:hover .goTo, .allowHover .amisEnFace .linkList .webArticle a:hover .goTo {
          background: #7b97a3; }

.equipe {
  margin: -120px 0 0 0; }
  .equipe .blockTitle {
    padding: 60px 0 50px 0;
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent; }
    @media (max-width: 767px) {
      .equipe .blockTitle {
        border: none;
        padding: 20px 0 50px 0; } }
    .equipe .blockTitle h1 {
      font-size: 49px; }
      @media (max-width: 1023px) {
        .equipe .blockTitle h1 {
          font-size: 37px;
          line-height: 40px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .equipe .blockTitle h1 {
      font-size: 25px;
      line-height: 30px; } }
    .equipe .blockTitle > img {
      width: 175px;
      height: auto;
      margin: 0 0 0 -30px; }
      @media (max-width: 767px) {
        .equipe .blockTitle > img {
          margin: 0 0 0 -10px; } }
    .equipe .blockTitle div {
      top: 100px; }
      @media (max-width: 767px) {
        .equipe .blockTitle div {
          top: 50px; } }
  .equipe .teamList {
    padding: 60px 0 40px;
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent; }
    @media (max-width: 767px) {
      .equipe .teamList {
        padding: 50px 0 40px;
        border: none; } }
    .equipe .teamList .member {
      float: left;
      width: 25%;
      height: 95px;
      margin: 0 0 10px;
      padding: 0 20px 0 0; }
      @media (max-width: 1023px) {
        .equipe .teamList .member {
          width: 33.33%; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .equipe .teamList .member {
      width: 50%; } }
      .equipe .teamList .member h5 {
        font-size: 18px;
        font-weight: 400; }
        @media (max-width: 767px) {
          .equipe .teamList .member h5 {
            font-size: 16px; } }
      .equipe .teamList .member span {
        font-size: 14px;
        color: #666666;
        font-family: "Playfair Display", serif; }
        @media (max-width: 767px) {
          .equipe .teamList .member span {
            font-size: 12px; } }
      .equipe .teamList .member .socialMedia {
        transform: translateX(-12px); }
  .equipe .president .blockTitle > img {
    width: 100px; }
  .equipe .president .blockTitle p {
    margin: -35px 0 0 0; }
  .equipe .president .teamList .member {
    height: 115px; }

.elus {
  margin: -120px 0 0 0; }
  .elus .filterTools {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 110px 0 110px; }
    @media (max-width: 1023px) {
      .elus .filterTools {
        padding: 70px 0 110px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .elus .filterTools {
      border: none;
      padding: 20px 0 100px; } }
    .elus .filterTools h1 {
      font-weight: 400;
      color: #ed1b2e;
      font-size: 49px;
      font-family: "Playfair Display", serif; }
      @media (max-width: 767px) {
        .elus .filterTools h1 {
          font-size: 39px; } }
    .elus .filterTools .filters {
      margin: 40px 0 0 0; }
      .elus .filterTools .filters .filter {
        width: 33.33%;
        border-right: 1px solid #f8f7f3; }
        @media (max-width: 1023px) {
          .elus .filterTools .filters .filter {
            width: 100%;
            border: none;
            margin: 0 0 15px; } }
      .elus .filterTools .filters .search {
        width: 33.33%;
        float: left;
        position: relative; }
        @media (max-width: 1023px) {
          .elus .filterTools .filters .search {
            width: 100%; } }
        .elus .filterTools .filters .search input {
          padding: 0 70px 0 40px;
          height: 70px;
          width: 100%;
          border: none;
          font-weight: 300;
          font-size: 15px;
          border-radius: 0px; }
          @media (max-width: 1280px) {
            .elus .filterTools .filters .search input {
              font-size: 12px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .elus .filterTools .filters .search input {
      padding: 0 60px 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .elus .filterTools .filters .search input {
      height: 50px; } }
        .elus .filterTools .filters .search a {
          position: absolute;
          right: 25px;
          top: 20px;
          font-size: 25px;
          color: #252525;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 1023px) {
            .elus .filterTools .filters .search a {
              right: 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .elus .filterTools .filters .search a {
      font-size: 20px;
      top: 12px; } }
          .allowHover .elus .filterTools .filters .search a:hover {
            color: #ed1b2e; }
  .elus .linstings {
    margin: -40px 0 0 0;
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 0 0 60px; }
    @media (max-width: 767px) {
      .elus .linstings {
        border: none;
        padding: 0; } }
    .elus .linstings .noResultMessage {
      margin: 0 0 80px;
      font-family: "Playfair Display", serif;
      font-size: 20px; }
      @media (max-width: 767px) {
        .elus .linstings .noResultMessage {
          font-size: 16px;
          margin: 0 0 60px; } }
    .elus .linstings .elu {
      width: 33.33%;
      float: left;
      text-align: center; }
      @media (max-width: 1280px) {
        .elus .linstings .elu {
          width: 50%; } }
      .elus .linstings .elu > a {
        position: relative;
        display: block; }
        .elus .linstings .elu > a img {
          width: 100%;
          height: auto;
          display: block; }
      .elus .linstings .elu:nth-child(3n-1) > a:before {
        position: absolute;
        content: "";
        left: -1px;
        top: 0;
        height: 100%;
        width: 1px;
        background: #ffffff; }
        @media (max-width: 1280px) {
          .elus .linstings .elu:nth-child(3n-1) > a:before {
            display: none; } }
      .elus .linstings .elu:nth-child(3n-1) > a:after {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: #ffffff; }
        @media (max-width: 1280px) {
          .elus .linstings .elu:nth-child(3n-1) > a:after {
            display: none; } }
      @media (max-width: 1280px) {
        .elus .linstings .elu:nth-child(2n) > a:before {
          position: absolute;
          content: "";
          left: -1px;
          top: 0;
          height: 100%;
          width: 1px;
          background: #ffffff;
          display: block; } }
      .elus .linstings .elu .txt {
        height: 170px;
        padding: 20px 0 0 0; }
        .elus .linstings .elu .txt h5 {
          font-size: 19px;
          line-height: 31px;
          font-weight: 300; }
          @media (max-width: 767px) {
            .elus .linstings .elu .txt h5 {
              font-size: 16px;
              line-height: 24px; } }
          .elus .linstings .elu .txt h5 a {
            color: #252525;
            transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            .allowHover .elus .linstings .elu .txt h5 a:hover {
              opacity: 0.6; }
        .elus .linstings .elu .txt span {
          font-size: 14px;
          color: #666666;
          font-family: "Playfair Display", serif;
          margin: 0 0 5px;
          display: inline-block; }
          @media (max-width: 767px) {
            .elus .linstings .elu .txt span {
              font-size: 12px; } }

.singleElu .bannerTitle .txt > div {
  top: 60%; }

@media (max-width: 767px) {
  .singleElu .bannerTitle .img {
    display: none; } }

.singleElu .bannerTitle .election {
  margin-left: 16%;
  max-width: 240px; }

.singleElu .otherInfo {
  width: 100%;
  padding: 0 0 30px;
  float: left; }
  @media (max-width: 1280px) {
    .singleElu .otherInfo {
      padding: 0 0 60px; } }
  .singleElu .otherInfo .lastNews {
    width: 60%;
    float: left; }
    @media (max-width: 1280px) {
      .singleElu .otherInfo .lastNews {
        width: 100%; } }
    .singleElu .otherInfo .lastNews .blockTitle {
      margin: 0 0 -80px; }
      .singleElu .otherInfo .lastNews .blockTitle img {
        width: 190px;
        height: auto; }
      .singleElu .otherInfo .lastNews .blockTitle div {
        top: 22%; }
        @media (max-width: 1023px) {
          .singleElu .otherInfo .lastNews .blockTitle div {
            top: 12%; } }
    .singleElu .otherInfo .lastNews .newsSmall {
      width: 50%; }
      @media (max-width: 767px) {
        .singleElu .otherInfo .lastNews .newsSmall {
          width: 100%; } }
  .singleElu .otherInfo .nextEvents {
    width: 36%;
    float: right; }
    @media (max-width: 1280px) {
      .singleElu .otherInfo .nextEvents {
        width: 100%;
        float: none;
        clear: both;
        margin: 0 auto;
        width: 65%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleElu .otherInfo .nextEvents {
      width: 100%; } }
    .singleElu .otherInfo .nextEvents h2 {
      font-family: "Playfair Display", serif;
      color: #ed1b2e;
      font-size: 37px;
      line-height: 50px;
      font-weight: 400;
      margin: 10px 0 30px; }
      @media (max-width: 1280px) {
        .singleElu .otherInfo .nextEvents h2 {
          text-align: center; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleElu .otherInfo .nextEvents h2 {
      text-align: left;
      font-size: 27px;
      line-height: 33px;
      margin: 0 0 20px; } }
    .singleElu .otherInfo .nextEvents .event {
      position: relative;
      width: 100%;
      float: left;
      margin: 0 0 25px; }
      @media (max-width: 767px) {
        .singleElu .otherInfo .nextEvents .event {
          margin: 0 0 15px; } }
      .singleElu .otherInfo .nextEvents .event .calendar {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px; }
        @media (max-width: 767px) {
          .singleElu .otherInfo .nextEvents .event .calendar {
            width: 45px; } }
        .singleElu .otherInfo .nextEvents .event .calendar i {
          color: #c4e3f0;
          font-size: 72px;
          display: inline-block;
          transform: translateX(-15px); }
          @media (max-width: 767px) {
            .singleElu .otherInfo .nextEvents .event .calendar i {
              font-size: 40px;
              transform: translateX(-10px); } }
        .singleElu .otherInfo .nextEvents .event .calendar span {
          position: absolute;
          left: 46%;
          top: 37px;
          color: #ed1b2e;
          font-size: 31px;
          transform: translateX(-50%); }
          @media (max-width: 767px) {
            .singleElu .otherInfo .nextEvents .event .calendar span {
              font-size: 17px;
              left: 42%;
              top: 20px; } }
      .singleElu .otherInfo .nextEvents .event .txt {
        float: left;
        padding: 0 0 0 100px;
        width: 100%; }
        @media (max-width: 767px) {
          .singleElu .otherInfo .nextEvents .event .txt {
            padding: 0 0 0 65px; } }
        .singleElu .otherInfo .nextEvents .event .txt span {
          font-size: 14px;
          color: #666666;
          display: inline-block;
          margin: 5px 0 0 0;
          font-family: "Playfair Display", serif; }
          @media (max-width: 767px) {
            .singleElu .otherInfo .nextEvents .event .txt span {
              font-size: 12px; } }
        .singleElu .otherInfo .nextEvents .event .txt p {
          font-weight: 300;
          font-size: 15px;
          line-height: 28px; }
          @media (max-width: 767px) {
            .singleElu .otherInfo .nextEvents .event .txt p {
              font-size: 12px;
              line-height: 22px; } }
        .singleElu .otherInfo .nextEvents .event .txt a {
          color: #ed1b2e;
          font-size: 16px;
          display: inline-block;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .singleElu .otherInfo .nextEvents .event .txt a {
              font-size: 12px; } }
          .singleElu .otherInfo .nextEvents .event .txt a i {
            margin: 0 5px 0 0;
            display: inline-block;
            font-size: 22px;
            transform: translateY(2px);
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .singleElu .otherInfo .nextEvents .event .txt a i {
                font-size: 18px; } }
          .allowHover .singleElu .otherInfo .nextEvents .event .txt a:hover {
            color: #ca1727; }
            .allowHover .singleElu .otherInfo .nextEvents .event .txt a:hover i {
              transform: translate(5px, 2px); }
    .singleElu .otherInfo .nextEvents .ctaLink {
      margin: 20px 0 0 0;
      float: left; }
      @media (max-width: 1280px) {
        .singleElu .otherInfo .nextEvents .ctaLink {
          float: none;
          margin: 20px auto;
          display: table; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleElu .otherInfo .nextEvents .ctaLink {
      float: left;
      margin: 20px 0 0 0; } }

.engagementMedium, .engagementLarge {
  margin-bottom: 20px; }
  .engagementMedium .txt, .engagementLarge .txt {
    padding-top: 10px;
    padding-bottom: 10px; }
    .engagementMedium .txt h6, .engagementLarge .txt h6 {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 400;
      margin: 0 0 15px; }
      @media (max-width: 767px) {
        .engagementMedium .txt h6, .engagementLarge .txt h6 {
          font-size: 10px;
          margin: 0 0 10px; } }
    .engagementMedium .txt .date, .engagementLarge .txt .date {
      font-family: "Playfair Display", serif;
      color: #666666;
      font-size: 14px;
      margin: 0 0 5px;
      display: inline-block; }
      @media (max-width: 767px) {
        .engagementMedium .txt .date, .engagementLarge .txt .date {
          font-size: 12px; } }
    .engagementMedium .txt p, .engagementLarge .txt p {
      font-size: 17px;
      line-height: 30px;
      font-weight: 300; }
      @media (max-width: 767px) {
        .engagementMedium .txt p, .engagementLarge .txt p {
          font-size: 14px;
          line-height: 25px; } }
      .engagementMedium .txt p a, .engagementLarge .txt p a {
        -webkit-font-smoothing: antialiased;
        color: #252525;
        display: inline-block;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .allowHover .engagementMedium .txt p a:hover, .allowHover .engagementLarge .txt p a:hover {
          opacity: 0.6; }
  .engagementMedium .engagement-img, .engagementLarge .engagement-img {
    width: 100%;
    height: 230px;
    background-position: center center;
    background-size: cover; }

.grid-sizer, .grid-item {
  width: calc(25% - 22.5px); }
  @media (max-width: 1280px) {
    .grid-sizer, .grid-item {
      width: calc(33.33% - 20px); } }
  @media (max-width: 1023px) {
    .grid-sizer, .grid-item {
      width: calc(50% - 15px); } }
  @media (max-width: 480px) {
    .grid-sizer, .grid-item {
      width: 100%; } }

.gutter-sizer {
  width: 30px; }

.singleEngagements .bannerTitle {
  align-items: center; }
  .singleEngagements .bannerTitle .back {
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    left: calc(45% + 56px);
    top: 43px; }
  .singleEngagements .bannerTitle .txt, .singleEngagements .bannerTitle .img {
    position: inherit;
    float: none;
    transform: inherit; }
  .singleEngagements .bannerTitle .img {
    height: 550px;
    background-size: cover;
    background-position: center center;
    padding: 20px;
    display: flex;
    align-items: flex-end; }
    .singleEngagements .bannerTitle .img .img-category-container {
      display: flex;
      flex-wrap: wrap; }
      .singleEngagements .bannerTitle .img .img-category-container .img-category {
        position: relative;
        z-index: 10;
        padding: 10px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        margin-right: 1px;
        margin-bottom: 1px; }
        .singleEngagements .bannerTitle .img .img-category-container .img-category:last-child {
          margin-right: 0; }
        .singleEngagements .bannerTitle .img .img-category-container .img-category img {
          max-width: 100%; }
  .singleEngagements .bannerTitle .txt {
    padding-top: 75px;
    padding-bottom: 75px; }
    .singleEngagements .bannerTitle .txt > div {
      position: inherit;
      top: inherit;
      transform: none; }
      .singleEngagements .bannerTitle .txt > div h1 {
        margin-bottom: 0; }

@media (max-width: 1440px) {
  .singleEngagements .bannerTitle .img {
    height: 400px; }
  .singleEngagements .bannerTitle .txt > div h1 {
    font-size: 40px;
    line-height: 45px; } }

@media (max-width: 1280px) {
  .singleEngagements .bannerTitle .back {
    top: 5px; }
  .singleEngagements .bannerTitle .img {
    height: 360px; }
  .singleEngagements .bannerTitle .txt {
    padding-top: 35px;
    padding-bottom: 35px; } }

@media (max-width: 1023px) {
  .singleEngagements .bannerTitle .back {
    left: calc(45% + 25px); }
  .singleEngagements .bannerTitle .img {
    height: 280px; }
    .singleEngagements .bannerTitle .img .img-category-container .img-category {
      width: 40px;
      height: 40px; }
  .singleEngagements .bannerTitle .txt > div h1 {
    font-size: 28px;
    line-height: 31px; } }

@media (max-width: 767px) {
  .singleEngagements .bannerTitle {
    align-items: flex-start; }
    .singleEngagements .bannerTitle .back {
      position: initial;
      margin-top: 15px; }
    .singleEngagements .bannerTitle .txt, .singleEngagements .bannerTitle .img {
      flex: auto; }
    .singleEngagements .bannerTitle .txt {
      padding-top: 0;
      padding-bottom: 0; } }

@media (max-width: 480px) {
  .singleEngagements .bannerTitle .img {
    height: 180px; } }

.flex-double-container {
  display: flex;
  align-items: flex-start; }
  .flex-double-container .flex-double-sm, .flex-double-container .flex-double-lg {
    flex-grow: 0;
    flex-shrink: 0; }
  .flex-double-container .flex-double-sm {
    width: 45%; }
  .flex-double-container .flex-double-lg {
    width: 55%;
    padding-left: 56px; }
  @media (max-width: 1023px) {
    .flex-double-container .flex-double-lg {
      padding-left: 25px; } }
  @media (max-width: 767px) {
    .flex-double-container {
      align-items: initial;
      flex-direction: column; }
      .flex-double-container .flex-double-sm, .flex-double-container .flex-double-lg {
        width: 100%; }
      .flex-double-container .flex-double-lg {
        padding-left: 0; } }

.chef .bannerTitle .txt > div {
  top: 50%; }

.chef .vision {
  float: left;
  width: 100%;
  background: #f8f7f3;
  clear: both;
  padding: 50px 0 0 0; }
  .chef .vision .blockTitle {
    height: 130px;
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent; }
    @media (max-width: 767px) {
      .chef .vision .blockTitle {
        border: none; } }
    .chef .vision .blockTitle > img {
      width: 214px;
      height: auto;
      margin: 0 0 0 30px; }
      @media (max-width: 767px) {
        .chef .vision .blockTitle > img {
          width: 150px;
          margin: 0 0 0 10px; } }
    .chef .vision .blockTitle div {
      top: 35px; }
      @media (max-width: 767px) {
        .chef .vision .blockTitle div {
          top: 20px; } }
  .chef .vision .videoVision {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent; }
    @media (max-width: 767px) {
      .chef .vision .videoVision {
        border: none; } }
  .chef .vision .content {
    width: 70%;
    margin: 0 auto; }
    @media (max-width: 1280px) {
      .chef .vision .content {
        width: 80%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .chef .vision .content {
      width: 100%; } }
  .chef .vision .whiteBg {
    width: 100%;
    float: left;
    background: #ffffff;
    padding: 120px 0 100px;
    margin: -45px 0 0 0;
    border-top: 45px solid #f8f7f3;
    border-bottom: 45px solid #f8f7f3; }
    @media (max-width: 767px) {
      .chef .vision .whiteBg {
        padding: 60px 0 50px; } }
    .chef .vision .whiteBg .quote {
      width: 70%;
      margin: 0 auto;
      text-align: center; }
      @media (max-width: 1280px) {
        .chef .vision .whiteBg .quote {
          width: 80%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .chef .vision .whiteBg .quote {
      width: 90%; } }
      .chef .vision .whiteBg .quote blockquote {
        color: #7b97a3;
        font-family: "Playfair Display", serif;
        font-size: 58px;
        line-height: 63px;
        position: relative; }
        @media (max-width: 1280px) {
          .chef .vision .whiteBg .quote blockquote {
            font-size: 48px;
            line-height: 53px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .chef .vision .whiteBg .quote blockquote {
      font-size: 26px;
      line-height: 32px; } }
        .chef .vision .whiteBg .quote blockquote span {
          font-size: 60px;
          color: #ed1b2e;
          margin: 0;
          display: inline-block;
          font-weight: 700;
          transform: translateY(-30px); }
          @media (max-width: 1280px) {
            .chef .vision .whiteBg .quote blockquote span {
              font-size: 50px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .chef .vision .whiteBg .quote blockquote span {
      font-size: 36px;
      transform: translateY(-12px); } }
      .chef .vision .whiteBg .quote span {
        display: inline-block;
        font-size: 18px;
        margin: 45px 0 0 0; }
        @media (max-width: 767px) {
          .chef .vision .whiteBg .quote span {
            font-size: 14px;
            margin: 25px 0 0 0; } }

.histoire .realisation  {
  width: 25%;
  float: left;
  display: block; }

.histoire .bannerTitle .txt {
  width: 50%; }
  @media (max-width: 767px) {
    .histoire .bannerTitle .txt {
      width: 100%; } }
  .histoire .bannerTitle .txt > div {
    top: 45%; }
  .histoire .bannerTitle .txt p {
    width: 67%;
    line-height: 1.9; }
    @media (max-width: 767px) {
      .histoire .bannerTitle .txt p {
        width: 100%; } }
  .histoire .bannerTitle .txt a {
    margin-top: 40px;
    color: #ed1b2e;
    font-size: 16px;
    display: inline-block;
    transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .histoire .bannerTitle .txt a i {
      display: block;
      margin: 0 5px 0 0;
      display: inline-block;
      font-size: 22px;
      transform: rotate(90deg) translate(0px, 7px);
      transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .histoire .bannerTitle .txt a:hover {
      color: #ca1727; }
      .histoire .bannerTitle .txt a:hover i {
        transform: rotate(90deg) translate(7px, 7px); }
    @media (max-width: 767px) {
      .histoire .bannerTitle .txt a {
        display: none; } }

.histoire .bannerTitle .img {
  width: 72%; }
  @media (max-width: 767px) {
    .histoire .bannerTitle .img {
      width: 100%;
      margin: 15px 0; } }

.histoire .chefList {
  position: relative; }
  .histoire .chefList .chef {
    padding: 100px 0; }
    @media (max-width: 767px) {
      .histoire .chefList .chef {
        padding: 30px 0; } }
    .histoire .chefList .chef:nth-child(2n + 1) {
      background-color: #f8f7f3; }
    .histoire .chefList .chef .container {
      width: 85%; }
      @media (max-width: 767px) {
        .histoire .chefList .chef .container {
          width: 100%; } }
    .histoire .chefList .chef .chef-info {
      position: relative; }
      .histoire .chefList .chef .chef-info .thumb {
        position: relative;
        width: 40%;
        float: left; }
        @media (max-width: 767px) {
          .histoire .chefList .chef .chef-info .thumb {
            width: 100%;
            clear: both; } }
        .histoire .chefList .chef .chef-info .thumb span {
          font-family: "Playfair Display", serif;
          color: #ed1b2e;
          font-size: 58px;
          position: absolute;
          top: -55px;
          width: 100%;
          text-align: center; }
          @media (max-width: 767px) {
            .histoire .chefList .chef .chef-info .thumb span {
              font-size: 38px;
              top: -30px; } }
      .histoire .chefList .chef .chef-info .txt {
        left: 40%;
        top: 0;
        width: 60%;
        z-index: 10;
        position: absolute;
        height: 100%; }
        @media (max-width: 767px) {
          .histoire .chefList .chef .chef-info .txt {
            left: 0%;
            width: 100%;
            position: relative; } }
        .histoire .chefList .chef .chef-info .txt > div {
          position: absolute;
          top: 12%;
          width: 100%;
          padding-left: 40px; }
          @media (max-width: 767px) {
            .histoire .chefList .chef .chef-info .txt > div {
              padding-left: 0;
              position: relative; } }
        .histoire .chefList .chef .chef-info .txt h4 a {
          font-family: "Playfair Display", serif;
          font-size: 37px;
          font-weight: normal;
          margin-bottom: 25px;
          color: #252525; }
          @media (max-width: 767px) {
            .histoire .chefList .chef .chef-info .txt h4 a {
              font-size: 28px; } }
        .histoire .chefList .chef .chef-info .txt p {
          line-height: 1.6; }
          @media (max-width: 767px) {
            .histoire .chefList .chef .chef-info .txt p {
              font-size: 14px;
              line-height: 25px; } }
        .histoire .chefList .chef .chef-info .txt a {
          color: #ed1b2e;
          font-size: 16px;
          display: inline-block;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1);
          margin-top: 6px; }
          @media (max-width: 767px) {
            .histoire .chefList .chef .chef-info .txt a {
              font-size: 12px; } }
          .histoire .chefList .chef .chef-info .txt a i {
            margin: 0 5px 0 0;
            display: inline-block;
            font-size: 22px;
            transform: translateY(2px);
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .histoire .chefList .chef .chef-info .txt a:hover {
            color: #ca1727; }
            .histoire .chefList .chef .chef-info .txt a:hover i {
              transform: translate(5px, 2px); }
    .histoire .chefList .chef .realisations {
      margin-top: 30px; }
      @media (max-width: 767px) {
        .histoire .chefList .chef .realisations {
          margin-top: 10px; } }
      .histoire .chefList .chef .realisations h5 {
        font-family: "Playfair Display", serif;
        color: #ed1b2e;
        font-size: 23px;
        display: inline-block;
        font-weight: normal; }
        @media (max-width: 767px) {
          .histoire .chefList .chef .realisations h5 {
            font-size: 25px;
            line-height: 30px; } }
      .histoire .chefList .chef .realisations > a {
        float: right;
        display: inline-block;
        color: #252525;
        margin-top: 12px;
        font-size: 14px; }
        @media (max-width: 767px) {
          .histoire .chefList .chef .realisations > a {
            float: none; } }
      .histoire .chefList .chef .realisations ul {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #666666; }
        .histoire .chefList .chef .realisations ul li {
          position: relative;
          display: inline-block;
          width: 25%;
          float: left;
          cursor: pointer;
          transition: opacity 0.3s ease;
          border-right: 1px solid #fff; }
          @media (max-width: 767px) {
            .histoire .chefList .chef .realisations ul li {
              width: 50%;
              border-bottom: 1px solid #fff; } }
          .histoire .chefList .chef .realisations ul li .hover {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #dfeef4;
            padding: 35px;
            font-size: 15px;
            line-height: 1.6;
            opacity: 0;
            transition: opacity 0.3s ease;
            font-family: "Muli", sans-serif; }
            .histoire .chefList .chef .realisations ul li .hover span {
              font-family: "Playfair Display", serif;
              color: #666666;
              font-size: 14px;
              display: block;
              margin-bottom: 10px; }
            .histoire .chefList .chef .realisations ul li .hover > .achievementBtn {
              font-size: 16px;
              position: absolute;
              bottom: 25px;
              display: block;
              color: #ed1b2e;
              font-family: "Muli", sans-serif; }
          .histoire .chefList .chef .realisations ul li img {
            display: block; }
          .histoire .chefList .chef .realisations ul li:hover .hover {
            opacity: 1; }
          .histoire .chefList .chef .realisations ul li:hover .openVideo {
            background-color: rgba(0, 0, 0, 0.4); }
          .histoire .chefList .chef .realisations ul li .openVideo {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            cursor: pointer; }
            .histoire .chefList .chef .realisations ul li .openVideo:hover .btnPlay {
              transform: translate(-50%, -50%) scale(1.1); }
              .histoire .chefList .chef .realisations ul li .openVideo:hover .btnPlay i {
                color: #ed1b2e; }
            .histoire .chefList .chef .realisations ul li .openVideo .btnPlay {
              width: 81px;
              height: 74px;
              position: absolute;
              left: 50%;
              top: 48%;
              transform: translate(-50%, -50%);
              transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              .histoire .chefList .chef .realisations ul li .openVideo .btnPlay i {
                display: inline-block;
                color: #ffffff;
                font-size: 58px;
                transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  .histoire .chefList .sidebarNav {
    opacity: 0;
    position: fixed;
    right: 90px;
    z-index: 1;
    top: 25%;
    transition: opacity 0.3s ease; }
    @media (max-width: 767px) {
      .histoire .chefList .sidebarNav {
        display: none; } }
    .histoire .chefList .sidebarNav.visible {
      opacity: 1; }
    .histoire .chefList .sidebarNav a {
      position: relative;
      z-index: 1;
      display: block;
      padding-top: 15px;
      padding-bottom: 15px; }
      .histoire .chefList .sidebarNav a span {
        margin-left: 1px;
        display: block;
        height: 7px;
        width: 7px;
        background-color: #a8a8a8;
        border-radius: 50%;
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      .histoire .chefList .sidebarNav a div {
        transition: opacity 0.3s ease; }
      .histoire .chefList .sidebarNav a .sidebarNav__name {
        opacity: 0;
        color: #b2b2b2;
        position: absolute;
        right: 26px;
        top: 9px;
        width: 255px;
        text-align: right; }
      .histoire .chefList .sidebarNav a .sidebarNav__date {
        opacity: 0;
        color: #b2b2b2;
        position: absolute;
        left: 26px;
        top: 9px; }
      .histoire .chefList .sidebarNav a.active span {
        background: #ed1b2e;
        transform: scale(1.3); }
      .histoire .chefList .sidebarNav a.active .sidebarNav__date {
        opacity: 1;
        color: #252525; }
      .histoire .chefList .sidebarNav a.active:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        /* background-color: #ed1b2e; */
        border: 1px solid #ed1b2e;
        border-radius: 50%;
        top: 27%;
        left: -4px; }
      .histoire .chefList .sidebarNav a:hover span {
        transform: scale(1.3); }
      .histoire .chefList .sidebarNav a:hover .sidebarNav__name {
        opacity: 1; }
      .histoire .chefList .sidebarNav a:hover .sidebarNav__date {
        opacity: 1; }
    .histoire .chefList .sidebarNav:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 0%;
      height: 100%;
      width: 1px;
      border-right: 1px solid #a8a8a8;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }

.heightValues {
  margin: -120px 0 0 0; }
  .heightValues .headerValue {
    position: relative;
    float: left;
    width: 100%;
    margin: 0 0 50px;
    clear: both; }
    @media (max-width: 767px) {
      .heightValues .headerValue {
        margin: 20px 0 40px; } }
    .heightValues .headerValue .txt {
      width: 40%;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 15;
      transform: translateY(-50%); }
      @media (max-width: 1280px) {
        .heightValues .headerValue .txt {
          width: 50%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .heightValues .headerValue .txt {
      position: relative;
      width: 100%;
      top: auto;
      transform: translateY(0); } }
      .heightValues .headerValue .txt span {
        font-size: 19px;
        display: inline-block;
        margin: 0 0 20px; }
        @media (max-width: 767px) {
          .heightValues .headerValue .txt span {
            font-size: 15px;
            margin: 0 0 10px; } }
        .heightValues .headerValue .txt span i {
          display: inline-block;
          color: #c4e3f0;
          font-size: 35px;
          transform: translate(-10px, 4px); }
          @media (max-width: 767px) {
            .heightValues .headerValue .txt span i {
              font-size: 25px;
              transform: translate(-5px, 2px); } }
      .heightValues .headerValue .txt h1 {
        font-size: 50px;
        line-height: 55px;
        font-weight: 400;
        font-family: "Playfair Display", serif;
        margin: 0 0 45px; }
        @media (max-width: 1280px) {
          .heightValues .headerValue .txt h1 {
            font-size: 40px;
            line-height: 45px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .heightValues .headerValue .txt h1 {
      font-size: 28px;
      line-height: 31px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .heightValues .headerValue .txt h1 {
      margin: 0 0 20px; } }
    .heightValues .headerValue .image {
      width: 83%;
      float: right;
      position: relative; }
      .heightValues .headerValue .image:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0.6;
        background: linear-gradient(to right, #e5f1f6 0%, rgba(255, 255, 255, 0) 100%); }
      @media (max-width: 767px) {
        .heightValues .headerValue .image {
          width: 100%;
          margin: 0 0 20px 0; }
          .heightValues .headerValue .image:before {
            display: none; } }
      .heightValues .headerValue .image img {
        width: 100%;
        display: block;
        height: auto; }
  .heightValues .valuesList {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 60px 0 0 0; }
    @media (max-width: 767px) {
      .heightValues .valuesList {
        padding: 40px 0 0 0;
        border: none; } }
    .heightValues .valuesList .value {
      margin: 0 0 80px;
      float: left;
      width: 100%; }
      @media (max-width: 767px) {
        .heightValues .valuesList .value {
          margin: 0 0 50px; } }
      .heightValues .valuesList .value .blockTitle {
        max-height: 160px;
        margin: 0 0 0 -40px; }
        @media (max-width: 767px) {
          .heightValues .valuesList .value .blockTitle {
            max-height: 95px;
            margin: 0 0 0 -10px; } }
        .heightValues .valuesList .value .blockTitle img {
          margin: 0; }
          @media (max-width: 767px) {
            .heightValues .valuesList .value .blockTitle img {
              width: 125px; } }
        .heightValues .valuesList .value .blockTitle div {
          top: 65px; }
          @media (max-width: 767px) {
            .heightValues .valuesList .value .blockTitle div {
              top: 15px; } }
      .heightValues .valuesList .value .content {
        position: relative;
        z-index: 5; }
        .heightValues .valuesList .value .content .vid {
          float: left;
          width: 70%; }
          @media (max-width: 767px) {
            .heightValues .valuesList .value .content .vid {
              width: 100%; } }
          .heightValues .valuesList .value .content .vid > div {
            margin: 0; }
          .heightValues .valuesList .value .content .vid .txt {
            border-top: 1px solid #ffffff;
            background: #dfeef4;
            padding: 50px; }
            @media (max-width: 767px) {
              .heightValues .valuesList .value .content .vid .txt {
                padding: 20px; } }
            .heightValues .valuesList .value .content .vid .txt p {
              font-size: 15px;
              line-height: 28px;
              font-weight: 300; }
              @media (max-width: 767px) {
                .heightValues .valuesList .value .content .vid .txt p {
                  font-size: 12px;
                  line-height: 22px; } }
        .heightValues .valuesList .value .content .list {
          border-left: 1px solid #ffffff;
          float: left;
          width: 30%;
          padding: 55px 0 0 0; }
          @media (max-width: 767px) {
            .heightValues .valuesList .value .content .list {
              width: 100%;
              padding: 25px 0 0 0; } }
          .heightValues .valuesList .value .content .list img {
            display: block;
            width: 100%;
            height: auto; }
          .heightValues .valuesList .value .content .list .txt {
            padding: 20px 10px 0 30px; }
            @media (max-width: 767px) {
              .heightValues .valuesList .value .content .list .txt {
                padding: 20px; } }
            .heightValues .valuesList .value .content .list .txt h3 {
              font-family: "Playfair Display", serif;
              font-size: 19px;
              font-weight: 400;
              margin: 0 0 20px; }
              @media (max-width: 767px) {
                .heightValues .valuesList .value .content .list .txt h3 {
                  font-size: 15px;
                  margin: 0 0 15px; } }
            .heightValues .valuesList .value .content .list .txt ul li {
              font-size: 13px;
              line-height: 22px;
              position: relative;
              padding: 0 0 0 35px;
              margin: 0 0 10px; }
              @media (max-width: 767px) {
                .heightValues .valuesList .value .content .list .txt ul li {
                  font-size: 12px;
                  line-height: 20px; } }
              .heightValues .valuesList .value .content .list .txt ul li i {
                position: absolute;
                left: 0;
                top: 0;
                color: #ed1b2e;
                content: "";
                font-size: 22px; }
                @media (max-width: 767px) {
                  .heightValues .valuesList .value .content .list .txt ul li i {
                    font-size: 18px; } }
      .heightValues .valuesList .value:nth-child(2n) .content .list {
        border-left: none;
        border-right: 1px solid #ffffff; }

.listingEvents {
  margin: -120px 0 0 0; }
  .listingEvents .filterTools {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 90px 0 60px; }
    @media (max-width: 1023px) {
      .listingEvents .filterTools {
        padding: 70px 0 60px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .listingEvents .filterTools {
      border: none;
      padding: 20px 0 60px; } }
    .listingEvents .filterTools h1 {
      font-weight: 400;
      color: #ed1b2e;
      font-size: 49px;
      font-family: "Playfair Display", serif; }
      @media (max-width: 767px) {
        .listingEvents .filterTools h1 {
          font-size: 39px;
          line-height: 45px; } }
    .listingEvents .filterTools .filters {
      margin: 40px 0 0 0; }
      .listingEvents .filterTools .filters .filter {
        width: 33.33%;
        border-right: 1px solid #f8f7f3; }
        @media (max-width: 1023px) {
          .listingEvents .filterTools .filters .filter {
            width: 100%;
            border: none;
            margin: 0 0 15px; } }
      .listingEvents .filterTools .filters .search {
        width: 33.33%;
        float: left;
        position: relative; }
        @media (max-width: 1023px) {
          .listingEvents .filterTools .filters .search {
            width: 100%; } }
        .listingEvents .filterTools .filters .search input {
          padding: 0 70px 0 40px;
          height: 70px;
          width: 100%;
          border: none;
          font-weight: 300;
          font-size: 15px;
          border-radius: 0px; }
          @media (max-width: 1280px) {
            .listingEvents .filterTools .filters .search input {
              font-size: 12px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .listingEvents .filterTools .filters .search input {
      padding: 0 60px 0 20px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .listingEvents .filterTools .filters .search input {
      height: 50px; } }
        .listingEvents .filterTools .filters .search a {
          position: absolute;
          right: 25px;
          top: 20px;
          font-size: 25px;
          color: #252525;
          transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 1023px) {
            .listingEvents .filterTools .filters .search a {
              right: 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .listingEvents .filterTools .filters .search a {
      font-size: 20px;
      top: 12px; } }
          .allowHover .listingEvents .filterTools .filters .search a:hover {
            color: #ed1b2e; }
  .listingEvents .listing {
    border-left: 3.5vw solid transparent;
    border-right: 3.5vw solid transparent;
    padding: 60px 0 90px; }
    @media (max-width: 767px) {
      .listingEvents .listing {
        border: none;
        padding: 60px 0 0; } }
    .listingEvents .listing .noResultMessage {
      margin: 0 0 80px;
      font-family: "Playfair Display", serif;
      font-size: 20px; }
      @media (max-width: 767px) {
        .listingEvents .listing .noResultMessage {
          font-size: 16px;
          margin: 0 0 60px; } }
    .listingEvents .listing .ctaLink {
      margin: 0 auto 60px;
      display: table;
      clear: both; }
      .listingEvents .listing .ctaLink i {
        transform: rotate(90deg) translate(3px, 5px);
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      .allowHover .listingEvents .listing .ctaLink:hover i {
        transform: rotate(90deg) translate(8px, 5px); }

.singleEvent .eventsToCome {
  border-left: 3.5vw solid #ffffff;
  border-right: 3.5vw solid #ffffff;
  clear: both;
  width: 100%;
  float: left;
  padding: 10px 0 30px; }
  @media (max-width: 767px) {
    .singleEvent .eventsToCome {
      border: none; } }
  .singleEvent .eventsToCome .list {
    padding: 90px 0 0 0; }
    @media (max-width: 767px) {
      .singleEvent .eventsToCome .list {
        padding: 120px 0 0 0; } }
    @media (max-width: 1280px) {
      .singleEvent .eventsToCome .list .eventsSmall:nth-child(4) {
        display: none; } }

.files {
  margin: -120px 0 0 0; }
  .files .headerTitle {
    background: #f8f7f3;
    padding: 110px 0 150px;
    overflow: hidden;
    position: relative;
    clear: both; }
    @media (max-width: 1023px) {
      .files .headerTitle {
        padding: 70px 0 150px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .files .headerTitle {
      padding: 30px 0 150px; } }
    .files .headerTitle .txt {
      position: relative;
      z-index: 10; }
      .files .headerTitle .txt h1 {
        font-size: 49px;
        line-height: 55px;
        color: #ed1b2e;
        font-family: "Playfair Display", serif;
        font-weight: 400; }
        @media (max-width: 767px) {
          .files .headerTitle .txt h1 {
            font-size: 38px;
            line-height: 45px; } }
      .files .headerTitle .txt p {
        margin: 20px 0 0 0;
        width: 60%;
        font-size: 17px;
        line-height: 31px;
        font-weight: 300; }
        @media (max-width: 767px) {
          .files .headerTitle .txt p {
            font-size: 14px;
            line-height: 26px;
            width: 100%;
            margin: 15px 0 0 0; } }
    .files .headerTitle img {
      position: absolute;
      right: 20%;
      bottom: -40px; }
      @media (max-width: 1023px) {
        .files .headerTitle img {
          right: 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .files .headerTitle img {
      width: 250px; } }
  .files .listingFiles {
    margin: -90px 0 0 0;
    padding: 0 0 90px; }
    .files .listingFiles .file {
      width: 33.33%;
      float: left;
      position: relative; }
      @media (max-width: 1280px) {
        .files .listingFiles .file {
          width: 50%; } }
  @media (max-width: 1280px) and (max-width: 575px) {
    .files .listingFiles .file {
      width: 100%; } }
      .files .listingFiles .file .bgImg {
        position: relative;
        padding: 40px 40px 0;
        display: block;
        overflow: hidden; }
        @media (max-width: 767px) {
          .files .listingFiles .file .bgImg {
            padding: 25px 25px 0; } }
        .files .listingFiles .file .bgImg img {
          position: absolute;
          top: 50%;
          width: 100%;
          height: auto;
          left: 50%;
          transform: translate(-50%, -50%); }
        .files .listingFiles .file .bgImg:before {
          display: block;
          content: " ";
          width: 100%;
          padding-top: 114.59854%; }
      .files .listingFiles .file .thumb {
        position: relative;
        background: url(../../images/bgFiles.png) center bottom no-repeat #007ac2;
        background-size: 100%;
        border-top: 40px solid #007ac2;
        border-left: 40px solid #007ac2;
        border-right: 40px solid #007ac2;
        display: block; }
        @media (max-width: 767px) {
          .files .listingFiles .file .thumb {
            border-top: 25px solid #007ac2;
            border-left: 25px solid #007ac2;
            border-right: 25px solid #007ac2; } }
        .files .listingFiles .file .thumb:before {
          display: block;
          content: " ";
          width: 100%;
          padding-top: 114.59854%; }
        .files .listingFiles .file .thumb .name {
          position: absolute;
          top: 40%;
          z-index: 10;
          left: 0;
          padding: 0 20px;
          width: 100%; }
          .files .listingFiles .file .thumb .name h2 {
            color: #ed1b2e;
            font-weight: 400;
            font-size: 37px;
            line-height: 38px;
            padding: 0 0 10px;
            font-family: "Playfair Display", serif;
            border-bottom: 1px solid #ed1b2e; }
            @media (max-width: 1023px) {
              .files .listingFiles .file .thumb .name h2 {
                font-size: 32px;
                line-height: 33px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .files .listingFiles .file .thumb .name h2 {
      font-size: 28px;
      line-height: 30px; } }
          .files .listingFiles .file .thumb .name h3 {
            color: #ed1b2e;
            font-weight: 400;
            font-size: 18px;
            padding: 5px 0 0;
            font-family: "Playfair Display", serif; }
            @media (max-width: 767px) {
              .files .listingFiles .file .thumb .name h3 {
                font-size: 14px; } }
      .files .listingFiles .file .txt {
        height: 135px;
        margin: 0 0 55px;
        padding: 20px 40px 0;
        text-align: center;
        position: relative; }
        @media (max-width: 767px) {
          .files .listingFiles .file .txt {
            height: 120px;
            margin: 0 0 35px;
            padding: 20px 20px 0; } }
        .files .listingFiles .file .txt h4 {
          font-weight: 300;
          font-size: 19px;
          line-height: 31px; }
          @media (max-width: 767px) {
            .files .listingFiles .file .txt h4 {
              font-size: 15px;
              line-height: 24px; } }
        .files .listingFiles .file .txt span {
          color: #666666;
          font-family: "Playfair Display", serif;
          display: inline-block;
          font-size: 14px; }
          @media (max-width: 767px) {
            .files .listingFiles .file .txt span {
              font-size: 12px; } }
        .files .listingFiles .file .txt a {
          display: inline-block;
          color: #ed1b2e;
          position: absolute;
          font-size: 14px;
          bottom: 0;
          left: 50%;
          border-bottom: 1px solid #ed1b2e;
          transform: translateX(-50%);
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .files .listingFiles .file .txt a {
              font-size: 12px; } }
          .allowHover .files .listingFiles .file .txt a:hover {
            opacity: 0.5; }
      .files .listingFiles .file:nth-child(3n-1):before {
        position: absolute;
        content: "";
        left: -1px;
        top: 0;
        height: 100%;
        width: 1px;
        background: #ffffff; }
        @media (max-width: 1280px) {
          .files .listingFiles .file:nth-child(3n-1):before {
            display: none; } }
      .files .listingFiles .file:nth-child(3n-1):after {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: #ffffff; }
        @media (max-width: 1280px) {
          .files .listingFiles .file:nth-child(3n-1):after {
            display: none; } }
      @media (max-width: 1280px) {
        .files .listingFiles .file:nth-child(2n):before {
          position: absolute;
          content: "";
          left: -1px;
          top: 0;
          height: 100%;
          width: 1px;
          background: #ffffff;
          display: block; } }
  @media (max-width: 1280px) and (max-width: 575px) {
    .files .listingFiles .file:nth-child(2n):before {
      display: none; } }

.singleDossier {
  margin: -120px 0 0 0; }
  .singleDossier .beigeBg:first-child {
    position: relative; }
    .singleDossier .beigeBg:first-child:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      background: #ffffff; }
      @media (max-width: 767px) {
        .singleDossier .beigeBg:first-child:after {
          display: none; } }
  .singleDossier .bannerTitle {
    z-index: 10; }
    @media (max-width: 767px) {
      .singleDossier .bannerTitle .txt {
        margin: 20px 0 0 0; } }
    .singleDossier .bannerTitle .txt > div {
      top: 50%; }
      .singleDossier .bannerTitle .txt > div .tag {
        margin: 0 0 40px; }
        @media (max-width: 767px) {
          .singleDossier .bannerTitle .txt > div .tag {
            margin: 0 0 25px; } }
  .singleDossier .faq {
    width: 100%;
    margin: 0 0 60px; }
    @media (max-width: 767px) {
      .singleDossier .faq {
        margin: 0 0 40px; } }
    .singleDossier .faq > div {
      margin: 0 0 40px; }
      @media (max-width: 767px) {
        .singleDossier .faq > div {
          margin: 0 0 25px; } }
      .singleDossier .faq > div .question {
        color: #007ac2;
        font-size: 17px;
        line-height: 28px;
        cursor: pointer;
        transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .singleDossier .faq > div .question {
            font-size: 15px;
            line-height: 25px; } }
        .singleDossier .faq > div .question i {
          display: inline-block;
          padding: 0 5px 0 0; }
          .singleDossier .faq > div .question i.icon-moins {
            display: none; }
        .allowHover .singleDossier .faq > div .question:hover {
          color: #0068a5; }
      .singleDossier .faq > div .answer {
        color: #666666;
        overflow: hidden;
        height: 0; }
        .singleDossier .faq > div .answer div {
          padding: 15px 0 0 0; }
      .singleDossier .faq > div.open .question i.icon-moins {
        display: inline-block; }
      .singleDossier .faq > div.open .question i.icon-plus {
        display: none; }
  .singleDossier .quote {
    width: 80%;
    margin: 30px auto;
    text-align: center;
    padding: 60px 0; }
    @media (max-width: 767px) {
      .singleDossier .quote {
        padding: 40px 0; } }
    .singleDossier .quote blockquote {
      color: #7b97a3;
      font-family: "Playfair Display", serif;
      font-size: 49px;
      line-height: 60px;
      position: relative; }
      @media (max-width: 1023px) {
        .singleDossier .quote blockquote {
          font-size: 39px;
          line-height: 50px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleDossier .quote blockquote {
      font-size: 26px;
      line-height: 32px; } }
      .singleDossier .quote blockquote span {
        font-size: 56px;
        color: #ed1b2e;
        margin: 0;
        display: inline-block;
        font-weight: 700;
        transform: translate(-15px, -20px); }
        @media (max-width: 1023px) {
          .singleDossier .quote blockquote span {
            font-size: 46px;
            transform: translate(-15px, -15px); } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleDossier .quote blockquote span {
      font-size: 36px;
      transform: translate(-10px, -12px); } }
    .singleDossier .quote span {
      display: inline-block;
      font-size: 18px;
      margin: 45px 0 0 0; }
      @media (max-width: 767px) {
        .singleDossier .quote span {
          font-size: 14px;
          margin: 25px 0 0 0; } }
  .singleDossier .realisationList {
    border-left: 3.5vw solid #ffffff;
    border-right: 3.5vw solid #ffffff;
    clear: both;
    width: 100%;
    float: left;
    margin: 60px 0; }
    @media (max-width: 1280px) {
      .singleDossier .realisationList {
        margin: 60px 0 0; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleDossier .realisationList {
      border: none;
      margin: 20px 0 0; } }
    .singleDossier .realisationList > div {
      float: left;
      width: 100%;
      clear: both; }
      .singleDossier .realisationList > div .blockTitle {
        margin: 0 0 -10px; }
        .singleDossier .realisationList > div .blockTitle img {
          width: 240px;
          height: auto;
          margin: 0 0 0 -40px; }
          @media (max-width: 767px) {
            .singleDossier .realisationList > div .blockTitle img {
              margin: 0 0 0 -10px;
              width: 200px; } }
        .singleDossier .realisationList > div .blockTitle div {
          top: 30px; }
          @media (max-width: 767px) {
            .singleDossier .realisationList > div .blockTitle div {
              top: 10px; } }
      .singleDossier .realisationList > div .realisation {
        position: relative;
        z-index: 10;
        width: 100%;
        float: left;
        margin: 0 0 50px; }
        @media (max-width: 1280px) {
          .singleDossier .realisationList > div .realisation {
            margin: 0 0 -30px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleDossier .realisationList > div .realisation {
      margin: 0 0 30px; } }
        .singleDossier .realisationList > div .realisation .thumb {
          float: left;
          width: 60%; }
          @media (max-width: 1280px) {
            .singleDossier .realisationList > div .realisation .thumb {
              width: 80%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleDossier .realisationList > div .realisation .thumb {
      width: 100%; } }
          .singleDossier .realisationList > div .realisation .thumb img {
            width: 100%;
            height: auto;
            display: block; }
        .singleDossier .realisationList > div .realisation .txt {
          width: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          background: #f8f7f3;
          padding: 50px 60px;
          transform: translateY(-50%); }
          @media (max-width: 1280px) {
            .singleDossier .realisationList > div .realisation .txt {
              position: relative;
              width: 80%;
              clear: both;
              float: right;
              top: auto;
              transform: translateY(-100px); } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .singleDossier .realisationList > div .realisation .txt {
      width: 100%;
      transform: translateY(0);
      padding: 20px; } }
          .singleDossier .realisationList > div .realisation .txt > span {
            color: #666666;
            font-family: "Playfair Display", serif;
            font-size: 14px;
            margin: 0 0 5px;
            display: inline-block; }
            @media (max-width: 767px) {
              .singleDossier .realisationList > div .realisation .txt > span {
                font-size: 12px; } }
          .singleDossier .realisationList > div .realisation .txt h4 {
            font-size: 37px;
            line-height: 49px;
            font-weight: 400;
            font-family: "Playfair Display", serif;
            margin: 0 0 15px; }
            @media (max-width: 1440px) {
              .singleDossier .realisationList > div .realisation .txt h4 {
                font-size: 27px;
                line-height: 39px; } }
  @media (max-width: 1440px) and (max-width: 767px) {
    .singleDossier .realisationList > div .realisation .txt h4 {
      font-size: 17px;
      line-height: 25px; } }
            .singleDossier .realisationList > div .realisation .txt h4 a {
              color: #252525;
              transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              .allowHover .singleDossier .realisationList > div .realisation .txt h4 a:hover {
                opacity: 0.6; }
          .singleDossier .realisationList > div .realisation .txt p, .singleDossier .realisationList > div .realisation .txt p span {
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            margin: 0 0 45px; }
            @media (max-width: 1440px) {
              .singleDossier .realisationList > div .realisation .txt p, .singleDossier .realisationList > div .realisation .txt p span {
                font-size: 13px;
                line-height: 18px;
                margin: 0 0 35px; } }
  @media (max-width: 1440px) and (max-width: 767px) {
    .singleDossier .realisationList > div .realisation .txt p, .singleDossier .realisationList > div .realisation .txt p span {
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 25px; } }
          .singleDossier .realisationList > div .realisation .txt p a {
            margin: 30px 0 0 0; }
            @media (max-width: 1440px) {
              .singleDossier .realisationList > div .realisation .txt p a {
                margin: 20px 0 0 0; } }
  @media (max-width: 1440px) and (max-width: 767px) {
    .singleDossier .realisationList > div .realisation .txt p a {
      margin: 10px 0 0 0; } }
          .singleDossier .realisationList > div .realisation .txt > a, .singleDossier .realisationList > div .realisation .txt p a {
            color: #ed1b2e;
            font-size: 16px;
            display: table;
            transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .singleDossier .realisationList > div .realisation .txt > a, .singleDossier .realisationList > div .realisation .txt p a {
                font-size: 12px; } }
            .singleDossier .realisationList > div .realisation .txt > a i, .singleDossier .realisationList > div .realisation .txt p a i {
              margin: 0 5px 0 0;
              display: inline-block;
              font-size: 22px;
              transform: translateY(2px);
              transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
              @media (max-width: 767px) {
                .singleDossier .realisationList > div .realisation .txt > a i, .singleDossier .realisationList > div .realisation .txt p a i {
                  font-size: 18px; } }
            .allowHover .singleDossier .realisationList > div .realisation .txt > a:hover, .allowHover .singleDossier .realisationList > div .realisation .txt p a:hover {
              color: #ca1727; }
              .allowHover .singleDossier .realisationList > div .realisation .txt > a:hover i, .allowHover .singleDossier .realisationList > div .realisation .txt p a:hover i {
                transform: translate(5px, 2px); }
        .singleDossier .realisationList > div .realisation:nth-child(2n+1) .thumb {
          float: right; }
        .singleDossier .realisationList > div .realisation:nth-child(2n+1) .txt {
          right: auto;
          left: 0; }
        @media (max-width: 1280px) {
          .singleDossier .realisationList > div .realisation:nth-child(2n+1) .txt {
            float: left; } }
      .singleDossier .realisationList > div.now .blockTitle {
        margin: 0 0 -60px; }
        .singleDossier .realisationList > div.now .blockTitle img {
          width: 180px; }
          @media (max-width: 767px) {
            .singleDossier .realisationList > div.now .blockTitle img {
              width: 125px; } }
        .singleDossier .realisationList > div.now .blockTitle div {
          top: 60px; }
          @media (max-width: 767px) {
            .singleDossier .realisationList > div.now .blockTitle div {
              top: 20px; } }
  .singleDossier .wouldLike {
    border-left: 3.5vw solid #ffffff;
    border-right: 3.5vw solid #ffffff;
    clear: both;
    width: 100%;
    float: left;
    padding: 10px 0 30px; }
    @media (max-width: 767px) {
      .singleDossier .wouldLike {
        border: none;
        padding: 10px 0 0; } }
    .singleDossier .wouldLike .list {
      padding: 90px 0 0 0; }
      @media (max-width: 1280px) {
        .singleDossier .wouldLike .list .newsSmall:nth-child(3) {
          display: none; } }

@media (max-width: 1280px) and (max-width: 767px) {
  .singleDossier .wouldLike .list {
    padding: 60px 0 0 0; } }
  .singleDossier .lawProject {
    width: 48%;
    clear: both;
    float: left;
    margin: 0 0 60px; }
    @media (max-width: 1280px) {
      .singleDossier .lawProject {
        width: 100%; } }
    .singleDossier .lawProject .blockTitle {
      margin: 0; }
      .singleDossier .lawProject .blockTitle img {
        width: 250px; }
        @media (max-width: 767px) {
          .singleDossier .lawProject .blockTitle img {
            width: 175px; } }
      .singleDossier .lawProject .blockTitle div {
        top: 70px; }
        @media (max-width: 767px) {
          .singleDossier .lawProject .blockTitle div {
            top: 45px; } }
    .singleDossier .lawProject .webArticle {
      width: 100%;
      border-top: 1px solid #b2b2b2; }
      .singleDossier .lawProject .webArticle:last-child {
        border-bottom: 1px solid #b2b2b2; }
      .singleDossier .lawProject .webArticle a {
        display: block;
        padding: 20px 40px; }
        @media (max-width: 767px) {
          .singleDossier .lawProject .webArticle a {
            padding: 10px 30px 20px; } }
        .singleDossier .lawProject .webArticle a .law {
          color: #666666;
          font-size: 14px;
          font-family: "Playfair Display", serif;
          display: inline-block;
          margin: 0 0 5px; }
          @media (max-width: 767px) {
            .singleDossier .lawProject .webArticle a .law {
              font-size: 12px; } }
          .singleDossier .lawProject .webArticle a .law .status {
            color: #007ac2;
            font-size: 11px;
            display: inline-block;
            text-transform: uppercase;
            font-family: "Muli", sans-serif;
            margin: 0 0 0 15px; }
            @media (max-width: 767px) {
              .singleDossier .lawProject .webArticle a .law .status {
                font-size: 10px; } }
        .singleDossier .lawProject .webArticle a p {
          color: #252525;
          font-size: 14px;
          line-height: 28px;
          padding: 0 0 0 40px;
          position: relative;
          width: 100%;
          box-sizing: content-box;
          transform: translateX(-40px); }
          @media (max-width: 767px) {
            .singleDossier .lawProject .webArticle a p {
              font-size: 12px;
              line-height: 22px; } }
          .singleDossier .lawProject .webArticle a p i {
            color: #ed1b2e;
            display: inline-block;
            font-size: 22px;
            position: absolute;
            left: 0;
            top: -1px;
            transition: left 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .singleDossier .lawProject .webArticle a p i {
                font-size: 18px;
                left: 10px; } }
        .singleDossier .lawProject .webArticle a .goTo {
          margin: 10px 0 0 0;
          background: #aec4cd;
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 12px;
          padding: 7px 10px 10px;
          transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .singleDossier .lawProject .webArticle a .goTo {
              font-size: 10px;
              padding: 5px 8px 8px; } }
          .singleDossier .lawProject .webArticle a .goTo i {
            font-size: 18px;
            margin: 0 2px 0 0;
            display: inline-block; }
            @media (max-width: 767px) {
              .singleDossier .lawProject .webArticle a .goTo i {
                font-size: 14px; } }
        .allowHover .singleDossier .lawProject .webArticle a:hover p i {
          left: 5px; }
          @media (max-width: 767px) {
            .allowHover .singleDossier .lawProject .webArticle a:hover p i {
              left: 15px; } }
        .allowHover .singleDossier .lawProject .webArticle a:hover .goTo {
          background: #7b97a3; }
  .singleDossier .dossierNav {
    width: 100%;
    float: left;
    clear: both;
    padding: 55px 0;
    border-top: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
    margin: 0 0 70px;
    position: relative; }
    @media (max-width: 767px) {
      .singleDossier .dossierNav {
        margin: 0 0 50px;
        padding: 15px 0 10px; } }
    .singleDossier .dossierNav:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 30%;
      height: 40%;
      width: 1px;
      background: #b2b2b2;
      transform: translateX(-50%); }
      @media (max-width: 767px) {
        .singleDossier .dossierNav:after {
          display: none; } }
    .singleDossier .dossierNav > div {
      width: 50%;
      float: left;
      position: relative; }
      @media (max-width: 767px) {
        .singleDossier .dossierNav > div {
          margin: 8px 0 12px; } }
      .singleDossier .dossierNav > div .thumb {
        width: 125px;
        display: inline-block;
        position: relative;
        z-index: 10; }
        @media (max-width: 1023px) {
          .singleDossier .dossierNav > div .thumb {
            width: 100px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleDossier .dossierNav > div .thumb {
      display: none; } }
        .singleDossier .dossierNav > div .thumb img {
          width: 100%;
          height: auto;
          display: inline-block; }
      .singleDossier .dossierNav > div .txt {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        padding: 0 25px 0 150px;
        transform: translateY(-50%); }
        @media (max-width: 1023px) {
          .singleDossier .dossierNav > div .txt {
            padding: 0 20px 0 120px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleDossier .dossierNav > div .txt {
      padding: 0; } }
        .singleDossier .dossierNav > div .txt a {
          display: inline-block; }
          .singleDossier .dossierNav > div .txt a span {
            font-size: 14px;
            color: #666666;
            font-family: "Playfair Display", serif;
            margin: 0 0 5px;
            display: inline-block; }
            @media (max-width: 767px) {
              .singleDossier .dossierNav > div .txt a span {
                font-size: 12px;
                margin: 0 0 5px 5px; } }
          .singleDossier .dossierNav > div .txt a p {
            font-size: 17px;
            line-height: 24px;
            font-weight: 300;
            color: #252525;
            transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 1023px) {
              .singleDossier .dossierNav > div .txt a p {
                font-size: 15px;
                line-height: 22px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleDossier .dossierNav > div .txt a p {
      display: none; } }
          .singleDossier .dossierNav > div .txt a i {
            display: inline-block;
            color: #ed1b2e;
            font-size: 22px;
            transform: rotate(180deg);
            transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
            @media (max-width: 767px) {
              .singleDossier .dossierNav > div .txt a i {
                float: left;
                font-size: 18px;
                transform: rotate(180deg); } }
          .allowHover .singleDossier .dossierNav > div .txt a:hover p {
            opacity: 0.6; }
          .allowHover .singleDossier .dossierNav > div .txt a:hover i {
            transform: rotate(180deg) translateX(3px); }
      .singleDossier .dossierNav > div.next {
        text-align: right; }
        .singleDossier .dossierNav > div.next .txt {
          padding: 0 150px 0 25px; }
          @media (max-width: 1023px) {
            .singleDossier .dossierNav > div.next .txt {
              padding: 0 120px 0 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleDossier .dossierNav > div.next .txt {
      padding: 0; } }
          @media (max-width: 767px) {
            .singleDossier .dossierNav > div.next .txt a span {
              margin: 0 0 6px 0; } }
          .singleDossier .dossierNav > div.next .txt a i {
            transform: rotate(0deg); }
            @media (max-width: 767px) {
              .singleDossier .dossierNav > div.next .txt a i {
                float: none;
                transform: rotate(0deg) translateY(3px); } }
          .allowHover .singleDossier .dossierNav > div.next .txt a:hover i {
            transform: rotate(0deg) translate(3px, 0); }
            @media (max-width: 767px) {
              .allowHover .singleDossier .dossierNav > div.next .txt a:hover i {
                transform: rotate(0deg) translate(3px, 3px); } }

.takeAStand {
  margin: -120px 0 0 0; }
  .takeAStand .actions {
    padding: 150px 0;
    height: 500px; }
    @media (max-width: 1023px) {
      .takeAStand .actions {
        height: 450px;
        padding: 100px 0 150px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .takeAStand .actions {
      padding: 50px 0 100px; } }
    .takeAStand .actions .button {
      float: left;
      width: 33.33%;
      cursor: pointer; }
      @media (max-width: 767px) {
        .takeAStand .actions .button {
          width: 100%;
          margin: 50px 0; } }
      .takeAStand .actions .button .title {
        position: relative;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .takeAStand .actions .button .title img {
          width: 75px;
          height: auto;
          display: block;
          margin: 0 auto; }
        .takeAStand .actions .button .title h2 {
          color: #ed1b2e;
          font-family: "Playfair Display", serif;
          font-size: 37px;
          font-weight: 400;
          position: absolute;
          left: 0;
          text-align: center;
          top: 35px;
          width: 100%; }
          @media (max-width: 1280px) {
            .takeAStand .actions .button .title h2 {
              font-size: 30px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .takeAStand .actions .button .title h2 {
      font-size: 25px; } }
      .takeAStand .actions .button p {
        color: #ed1b2e;
        font-size: 16px;
        line-height: 24px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        text-align: center;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .takeAStand .actions .button p {
            font-size: 12px;
            line-height: 16px; } }
      .takeAStand .actions .button .ctaLink {
        margin: 25px auto 0;
        display: table;
        background: #f8f7f3;
        padding: 0;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .takeAStand .actions .button .ctaLink span {
          max-width: 0;
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          display: inline-block; }
        .takeAStand .actions .button .ctaLink i {
          color: #ed1b2e; }
      .takeAStand .actions .button.donation .title img {
        width: 130px; }
      .takeAStand .actions .button.volunteer .title img {
        width: 140px; }
      .allowHover .takeAStand .actions .button:hover .title, .mobileDevice .takeAStand .actions .button .title {
        transform: scale(1.4) translateY(-30px); }
        @media (max-width: 1280px) {
          .allowHover .takeAStand .actions .button:hover .title, .mobileDevice .takeAStand .actions .button .title {
            transform: scale(1.3) translateY(-30px); } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .allowHover .takeAStand .actions .button:hover .title, .mobileDevice .takeAStand .actions .button .title {
      transform: scale(1.1) translateY(-30px); } }
      .allowHover .takeAStand .actions .button:hover p, .mobileDevice .takeAStand .actions .button p {
        max-height: 100px;
        opacity: 1; }
      .allowHover .takeAStand .actions .button:hover .ctaLink, .mobileDevice .takeAStand .actions .button .ctaLink {
        background: #ed1b2e;
        padding: 18px 30px 23px 35px;
        transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 1023px) {
          .allowHover .takeAStand .actions .button:hover .ctaLink, .mobileDevice .takeAStand .actions .button .ctaLink {
            padding: 13px 15px 18px 20px; } }
        .allowHover .takeAStand .actions .button:hover .ctaLink span, .mobileDevice .takeAStand .actions .button .ctaLink span {
          max-width: 100px;
          max-height: 100px;
          display: inline;
          opacity: 1; }
        .allowHover .takeAStand .actions .button:hover .ctaLink i, .mobileDevice .takeAStand .actions .button .ctaLink i {
          color: #f8f7f3; }
        .allowHover .allowHover .takeAStand .actions .button:hover .ctaLink:hover, .allowHover .mobileDevice .takeAStand .actions .button .ctaLink:hover {
          background: #ca1727; }
  .takeAStand .onlineTools {
    border-left: 3.5vw solid #f8f7f3;
    border-right: 3.5vw solid #f8f7f3;
    padding: 85px 55px;
    display: inline-block;
    margin: 0 0 50px;
    overflow: hidden;
    width: 100%;
    background: url(../../images/bgOnlineTools.png) top right no-repeat #dfeef4; }
    @media (max-width: 1023px) {
      .takeAStand .onlineTools {
        background-size: 226px 150px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .takeAStand .onlineTools {
      background-size: 113px 75px;
      padding: 35px 25px; } }
    .takeAStand .onlineTools h3 {
      font-size: 24px;
      font-family: "Playfair Display", serif;
      font-weight: 400;
      margin: 0 0 15px; }
      @media (max-width: 767px) {
        .takeAStand .onlineTools h3 {
          font-size: 20px;
          margin: 0 0 10px; } }
    .takeAStand .onlineTools p {
      font-size: 14px;
      line-height: 28px;
      font-weight: 300;
      margin: 0 0 10px 0;
      width: 80%; }
      @media (max-width: 1023px) {
        .takeAStand .onlineTools p {
          width: 90%; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .takeAStand .onlineTools p {
      font-size: 12px;
      line-height: 24px; } }
    .takeAStand .onlineTools > a {
      color: #ed1b2e;
      font-size: 16px;
      display: inline-block;
      transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
      @media (max-width: 767px) {
        .takeAStand .onlineTools > a {
          font-size: 12px; } }
      .takeAStand .onlineTools > a i {
        margin: 0 5px 0 0;
        display: inline-block;
        font-size: 22px;
        transform: translateY(2px);
        transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .takeAStand .onlineTools > a i {
            font-size: 18px; } }
      .allowHover .takeAStand .onlineTools > a:hover {
        color: #ca1727; }
        .allowHover .takeAStand .onlineTools > a:hover i {
          transform: translate(5px, 2px); }

.becomeMember .alternate {
  padding: 90px 0; }
  @media (max-width: 767px) {
    .becomeMember .alternate {
      padding: 25px 0 10px; } }
  .becomeMember .alternate .txt {
    float: left;
    width: 45%; }
    @media (max-width: 767px) {
      .becomeMember .alternate .txt {
        width: 100%; } }
    .becomeMember .alternate .txt h1, .becomeMember .alternate .txt h2 {
      font-family: "Playfair Display", serif;
      font-size: 58px;
      line-height: 63px;
      color: #c4e3f0;
      font-weight: 400;
      margin: 0 0 80px; }
      @media (max-width: 1280px) {
        .becomeMember .alternate .txt h1, .becomeMember .alternate .txt h2 {
          font-size: 48px;
          line-height: 53px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .becomeMember .alternate .txt h1, .becomeMember .alternate .txt h2 {
      font-size: 38px;
      line-height: 43px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .becomeMember .alternate .txt h1, .becomeMember .alternate .txt h2 {
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 45px; } }
      .becomeMember .alternate .txt h1 span, .becomeMember .alternate .txt h2 span {
        display: block;
        color: #ed1b2e; }
    .becomeMember .alternate .txt h2 {
      margin: 50px 0 80px; }
      @media (max-width: 1280px) {
        .becomeMember .alternate .txt h2 {
          margin: 30px 0 50px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .becomeMember .alternate .txt h2 {
      margin: 20px 0 40px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .becomeMember .alternate .txt h2 {
      margin: 15px 0 25px; } }
    .becomeMember .alternate .txt ul li {
      float: left;
      width: 50%;
      font-size: 18px;
      line-height: 26px;
      color: #c4e3f0;
      margin: 0 0 70px; }
      @media (max-width: 1280px) {
        .becomeMember .alternate .txt ul li {
          width: 100%;
          margin: 0 0 40px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .becomeMember .alternate .txt ul li {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 30px; } }
      .becomeMember .alternate .txt ul li span {
        display: block;
        color: #ed1b2e; }
      .becomeMember .alternate .txt ul li a {
        transform: translateY(-8px); }
    .becomeMember .alternate .txt p {
      font-size: 15px;
      line-height: 28px;
      color: #ed1b2e;
      font-weight: 300;
      width: 80%; }
      @media (max-width: 767px) {
        .becomeMember .alternate .txt p {
          font-size: 12px;
          line-height: 19px;
          width: 100%; } }
  .becomeMember .alternate .image {
    width: 55%;
    float: left; }
    .becomeMember .alternate .image img {
      float: right;
      width: auto;
      display: block;
      max-width: 100%;
      transform: translateX(70px); }
      @media (max-width: 1280px) {
        .becomeMember .alternate .image img {
          transform: translateX(35px); } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .becomeMember .alternate .image img {
      transform: translateX(0);
      margin: 25px 0 20px 0; } }
  .becomeMember .alternate .scrollTo {
    color: #ed1b2e;
    cursor: pointer;
    text-align: center;
    width: 25px;
    height: 70px;
    margin: 0 auto -60px;
    clear: both;
    position: relative;
    overflow: hidden; }
    @media (max-width: 767px) {
      .becomeMember .alternate .scrollTo {
        display: none; } }
    .becomeMember .alternate .scrollTo i {
      display: block;
      font-size: 28px;
      transform: translateX(-10px); }
      .becomeMember .alternate .scrollTo i.icon-arrow {
        font-size: 24px;
        transform: rotate(90deg); }

.becomeMember .container:first-child .alternate .txt {
  width: 55%; }
  @media (max-width: 767px) {
    .becomeMember .container:first-child .alternate .txt {
      width: 100%; } }

.becomeMember .container:first-child .alternate .image {
  width: 45%; }
  @media (max-width: 767px) {
    .becomeMember .container:first-child .alternate .image {
      display: none; } }

.becomeMember .beigeBg .alternate .txt {
  text-align: right; }
  @media (max-width: 767px) {
    .becomeMember .beigeBg .alternate .txt {
      text-align: left; } }
  .becomeMember .beigeBg .alternate .txt p {
    float: right; }
    @media (max-width: 767px) {
      .becomeMember .beigeBg .alternate .txt p {
        float: left; } }

.becomeMember .beigeBg .alternate .image img {
  float: left;
  transform: translateX(-70px); }
  @media (max-width: 1280px) {
    .becomeMember .beigeBg .alternate .image img {
      transform: translateX(-35px); } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .becomeMember .beigeBg .alternate .image img {
      transform: translateX(0); } }

.becomeMember .theForm {
  border-left: 3.5vw solid #ffffff;
  border-right: 3.5vw solid #ffffff;
  width: 100%;
  float: left;
  clear: both;
  background: #dfeef4;
  padding: 50px 7% 70px;
  margin: 90px 0; }
  @media (max-width: 767px) {
    .becomeMember .theForm {
      border: none;
      margin: 45px 0;
      padding: 30px 7% 35px; } }
  .becomeMember .theForm h3 {
    color: #ed1b2e;
    font-size: 56px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    text-align: center;
    width: 100%;
    margin: 0 0 5px; }
    @media (max-width: 767px) {
      .becomeMember .theForm h3 {
        font-size: 23px; } }
  .becomeMember .theForm p {
    font-size: 22px;
    line-height: 38px;
    font-family: "Playfair Display", serif;
    text-align: center;
    width: 90%;
    margin: 0 auto 5px; }
    @media (max-width: 767px) {
      .becomeMember .theForm p {
        width: 100%;
        font-size: 14px;
        line-height: 22px; } }
  .becomeMember .theForm span {
    font-size: 14px;
    display: table;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .becomeMember .theForm span {
        font-size: 12px; } }
  .becomeMember .theForm form {
    padding: 55px 0 0 0; }
    @media (max-width: 767px) {
      .becomeMember .theForm form {
        padding: 35px 0 0 0; } }
    .becomeMember .theForm form .field {
      width: 48%;
      float: left;
      margin: 0 2% 0 0; }
      @media (max-width: 767px) {
        .becomeMember .theForm form .field {
          margin: 0;
          width: 100%; } }
      .becomeMember .theForm form .field label {
        font-size: 16px;
        display: block; }
        @media (max-width: 767px) {
          .becomeMember .theForm form .field label {
            font-size: 12px; } }
        .becomeMember .theForm form .field label sup {
          color: #ed1b2e; }
      .becomeMember .theForm form .field input {
        height: 70px;
        width: 100%;
        border: none;
        font-weight: 300;
        font-size: 15px;
        padding: 0 25px;
        margin: 15px 0 55px;
        border-radius: 0px; }
        .becomeMember .theForm form .field input:disabled {
          background: #ffffff; }
        @media (max-width: 1023px) {
          .becomeMember .theForm form .field input {
            padding: 0 20px;
            font-size: 13px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .becomeMember .theForm form .field input {
      height: 50px;
      padding: 0 15px;
      font-size: 12px;
      margin: 5px 0 20px; } }
        .becomeMember .theForm form .field input.parsley-error {
          border: 1px solid red; }
      .becomeMember .theForm form .field .parsley-errors-list {
        display: none; }
      .becomeMember .theForm form .field .filter {
        margin: 15px 0 55px; }
        @media (max-width: 767px) {
          .becomeMember .theForm form .field .filter {
            margin: 5px 0 20px; } }
      .becomeMember .theForm form .field.radio {
        width: 100%; }
      .becomeMember .theForm form .field.address {
        width: 48%; }
        @media (max-width: 767px) {
          .becomeMember .theForm form .field.address {
            width: 100%; } }
      .becomeMember .theForm form .field.noApp, .becomeMember .theForm form .field.nocivique {
        width: 23%; }
        @media (max-width: 767px) {
          .becomeMember .theForm form .field.noApp, .becomeMember .theForm form .field.nocivique {
            width: 100%; } }
      @media (max-width: 1280px) {
        .becomeMember .theForm form .field.birthday {
          width: 73%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .becomeMember .theForm form .field.birthday {
      width: 100%; } }
      .becomeMember .theForm form .field.birthday .filter {
        width: 33.33%;
        border-right: 1px solid #dfeef4; }
        .becomeMember .theForm form .field.birthday .filter:last-child {
          border: none; }
      @media (max-width: 1280px) {
        .becomeMember .theForm form .field.title {
          width: 23%; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .becomeMember .theForm form .field.title {
      width: 100%; } }
      .becomeMember .theForm form .field.leftM {
        margin: 0 0 0 2%; }
        @media (max-width: 767px) {
          .becomeMember .theForm form .field.leftM {
            margin: 0; } }
      @media (max-width: 1280px) {
        .becomeMember .theForm form .field.community {
          margin: 0 2% 0 0; } }
      .becomeMember .theForm form .field.radio div {
        display: inline-block;
        width: auto;
        position: relative;
        margin: 0 30px 50px 0; }
        @media (max-width: 767px) {
          .becomeMember .theForm form .field.radio div {
            margin: 0 10px 35px 0; } }
        .becomeMember .theForm form .field.radio div .fakeRadio {
          width: 14px;
          height: 14px;
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
          background: #ffffff;
          border-radius: 7px; }
          .becomeMember .theForm form .field.radio div .fakeRadio:after {
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            background: #252525;
            border-radius: 3px;
            top: 4px;
            left: 4px;
            display: none; }
          .becomeMember .theForm form .field.radio div .fakeRadio.selected:after {
            display: block; }
        .becomeMember .theForm form .field.radio div label {
          padding: 0 0 0 22px;
          display: inline-block;
          position: relative;
          z-index: 10;
          cursor: pointer; }
        .becomeMember .theForm form .field.radio div input {
          display: none;
          width: auto;
          height: 15px;
          margin: 0;
          border-radius: 0px; }
      .becomeMember .theForm form .field.error input {
        border: 1px solid #ed1b2e; }
      .becomeMember .theForm form .field.error .filter > a {
        border: 1px solid #ed1b2e; }
      .becomeMember .theForm form .field.hide {
        display: none; }
    .becomeMember .theForm form .submit-message {
      display: inline; }
      .becomeMember .theForm form .submit-message.hidden {
        display: none; }
  .becomeMember .theForm .ctaLink {
    clear: both;
    display: table;
    margin: 0 auto; }

.generic {
  margin: -120px 0 0 0; }
  .generic .genericHeader {
    padding: 110px 15% 25px;
    text-align: center; }
    @media (max-width: 1280px) {
      .generic .genericHeader {
        padding: 110px 10% 25px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .generic .genericHeader {
      padding: 70px 10% 25px; } }
  @media (max-width: 1280px) and (max-width: 1023px) and (max-width: 767px) {
    .generic .genericHeader {
      padding: 50px 0 25px;
      text-align: left; } }
    .generic .genericHeader p {
      font-size: 16px; }
      @media (max-width: 767px) {
        .generic .genericHeader p {
          font-size: 14px; } }
  .generic .genericContent {
    padding: 70px 15% 50px; }
    @media (max-width: 1280px) {
      .generic .genericContent {
        padding: 70px 10% 50px; } }
  @media (max-width: 1280px) and (max-width: 767px) {
    .generic .genericContent {
      padding: 40px 0 30px; } }

.page404 .txt {
  text-align: center;
  padding: 50px 0 110px; }
  @media (max-width: 767px) {
    .page404 .txt {
      padding: 20px 0 60px; } }
  .page404 .txt h1 {
    font-size: 95px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    margin: 0 0 5px; }
    @media (max-width: 767px) {
      .page404 .txt h1 {
        font-size: 65px; } }
  .page404 .txt h2 {
    font-size: 37px;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    color: #ed1b2e;
    margin: 0 0 10px; }
    @media (max-width: 767px) {
      .page404 .txt h2 {
        font-size: 27px; } }
  .page404 .txt p {
    font-family: "Playfair Display", serif;
    font-size: 22px;
    margin: 0 0 50px; }
    @media (max-width: 767px) {
      .page404 .txt p {
        font-size: 17px;
        margin: 0 0 35px; } }
  .page404 .txt img {
    width: 330px; }
    @media (max-width: 767px) {
      .page404 .txt img {
        width: 240px; } }

.singleChef .bannerTitle {
  z-index: 10; }
  @media (max-width: 767px) {
    .singleChef .bannerTitle {
      margin-bottom: 0; } }
  @media (max-width: 767px) {
    .singleChef .bannerTitle .txt {
      margin: 20px 0 0 0; } }
  @media (max-width: 767px) {
    .singleChef .bannerTitle .txt > div h1 {
      margin-bottom: 0; } }
  .singleChef .bannerTitle .txt > div img {
    width: 58%; }
    @media (max-width: 767px) {
      .singleChef .bannerTitle .txt > div img {
        width: 100%; } }
  .singleChef .bannerTitle .img {
    width: 73%; }
    @media (max-width: 767px) {
      .singleChef .bannerTitle .img {
        display: none; } }
  .singleChef .bannerTitle span {
    margin-bottom: 20px; }

.singleChef #realisations {
  padding: 40px 0 0 0; }

.singleChef .container article .intro span {
  font-size: 11px;
  line-height: 20px;
  margin: 12px 0 1px;
  font-weight: 300;
  text-transform: uppercase;
  display: block; }
  .singleChef .container article .intro span:first-child {
    margin-top: 0; }

.singleChef .container .realisationList {
  background: #f8f7f3;
  margin: 30px 0;
  padding: 30px 7% 60px;
  width: 100%; }
  @media (max-width: 767px) {
    .singleChef .container .realisationList {
      margin: 0; } }
  .singleChef .container .realisationList > div .blockTitle div {
    top: 58px;
    right: 0;
    text-align: center; }
  .singleChef .container .realisationList > div .blockTitle img {
    width: 240px;
    height: auto;
    margin: 0 auto; }
  .singleChef .container .realisationList ul {
    padding: 40px 0 70px;
    width: 100%; }
    .singleChef .container .realisationList ul li {
      float: left;
      display: block;
      margin: 8px 0 8px 5%;
      width: 20%; }
      @media (max-width: 767px) {
        .singleChef .container .realisationList ul li {
          width: 40%;
          margin: 8px 5%; } }
      .singleChef .container .realisationList ul li a {
        font-size: 12px;
        color: #252525;
        text-decoration: underline; }
        .singleChef .container .realisationList ul li a:hover {
          text-decoration: none; }

.singleChef .container .realisationFeatured .realisation {
  margin: 15px 0; }
  .singleChef .container .realisationFeatured .realisation h3 {
    margin-top: 40px;
    color: #ed1b2e;
    font-size: 37px;
    font-family: "Playfair Display", serif;
    display: inline-block;
    font-weight: 400; }
    @media (max-width: 767px) {
      .singleChef .container .realisationFeatured .realisation h3 {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        display: block; } }
  .singleChef .container .realisationFeatured .realisation > div {
    position: relative;
    width: 92%;
    margin: 30px 4%;
    z-index: 10;
    float: left; }
    .singleChef .container .realisationFeatured .realisation > div span {
      font-family: "Playfair Display", serif;
      color: #666666;
      display: block;
      margin-bottom: 10px; }
    .singleChef .container .realisationFeatured .realisation > div .txt {
      padding: 20px 30px; }
      @media (max-width: 767px) {
        .singleChef .container .realisationFeatured .realisation > div .txt {
          line-height: 16px;
          margin: 0 0 25px;
          font-size: 12px; } }
    .singleChef .container .realisationFeatured .realisation > div .media {
      width: 70%;
      float: left;
      background-color: #e1f1f7;
      font-size: 15px;
      line-height: 28px;
      position: relative; }
      @media (max-width: 767px) {
        .singleChef .container .realisationFeatured .realisation > div .media {
          width: 100%; } }
      .singleChef .container .realisationFeatured .realisation > div .media .openVideo {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        cursor: pointer; }
        .singleChef .container .realisationFeatured .realisation > div .media .openVideo:hover .btnPlay {
          transform: translate(-50%, -50%) scale(1.1); }
          .singleChef .container .realisationFeatured .realisation > div .media .openVideo:hover .btnPlay i {
            color: #ed1b2e; }
        .singleChef .container .realisationFeatured .realisation > div .media .openVideo .btnPlay {
          width: 80px;
          height: 80px;
          position: absolute;
          left: 50%;
          top: 34%;
          transform: translate(-50%, -50%);
          transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          .singleChef .container .realisationFeatured .realisation > div .media .openVideo .btnPlay i {
            display: inline-block;
            color: #ffffff;
            font-size: 78px;
            margin: -8px 0 0 -13px;
            transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
    .singleChef .container .realisationFeatured .realisation > div .excerpt {
      width: 30%;
      position: absolute;
      top: 60%;
      right: 0;
      transform: translateY(-58%);
      font-size: 13px;
      line-height: 25px; }
      @media (max-width: 767px) {
        .singleChef .container .realisationFeatured .realisation > div .excerpt {
          display: none; } }
      .singleChef .container .realisationFeatured .realisation > div .excerpt img {
        width: 100%; }
  .singleChef .container .realisationFeatured .realisation:nth-child(2n) .media {
    float: right; }
  .singleChef .container .realisationFeatured .realisation:nth-child(2n) .excerpt {
    right: auto;
    left: 0; }

.singleChef .container .pagination {
  width: 100%;
  float: left;
  clear: both;
  padding: 55px 0;
  border-top: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
  margin: 0 0 70px;
  position: relative; }
  @media (max-width: 767px) {
    .singleChef .container .pagination {
      margin: 0 0 50px;
      padding: 15px 0 10px; } }
  .singleChef .container .pagination:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 30%;
    height: 40%;
    width: 1px;
    background: #b2b2b2;
    transform: translateX(-50%); }
    @media (max-width: 767px) {
      .singleChef .container .pagination:after {
        display: none; } }
  .singleChef .container .pagination > div {
    width: 50%;
    float: left;
    position: relative; }
    @media (max-width: 767px) {
      .singleChef .container .pagination > div {
        margin: 8px 0 12px; } }
    .singleChef .container .pagination > div .thumb {
      width: 125px;
      display: inline-block;
      position: relative;
      z-index: 10; }
      @media (max-width: 1023px) {
        .singleChef .container .pagination > div .thumb {
          width: 100px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleChef .container .pagination > div .thumb {
      display: none; } }
      .singleChef .container .pagination > div .thumb img {
        width: 100%;
        height: auto;
        display: inline-block; }
    .singleChef .container .pagination > div .txt {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      padding: 0 25px 0 150px;
      transform: translateY(-50%); }
      @media (max-width: 1023px) {
        .singleChef .container .pagination > div .txt {
          padding: 0 20px 0 120px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleChef .container .pagination > div .txt {
      padding: 0; } }
      .singleChef .container .pagination > div .txt a {
        display: inline-block; }
        .singleChef .container .pagination > div .txt a span {
          font-size: 14px;
          color: #666666;
          font-family: "Playfair Display", serif;
          margin: 0 0 5px;
          display: inline-block; }
          @media (max-width: 767px) {
            .singleChef .container .pagination > div .txt a span {
              font-size: 12px;
              margin: 0 0 5px 5px; } }
        .singleChef .container .pagination > div .txt a p {
          font-size: 17px;
          line-height: 24px;
          font-weight: 300;
          color: #252525;
          transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 1023px) {
            .singleChef .container .pagination > div .txt a p {
              font-size: 15px;
              line-height: 22px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleChef .container .pagination > div .txt a p {
      display: none; } }
        .singleChef .container .pagination > div .txt a i {
          display: inline-block;
          color: #ed1b2e;
          font-size: 22px;
          transform: rotate(180deg);
          transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
          @media (max-width: 767px) {
            .singleChef .container .pagination > div .txt a i {
              float: left;
              font-size: 18px;
              transform: rotate(180deg); } }
        .allowHover .singleChef .container .pagination > div .txt a:hover p {
          opacity: 0.6; }
        .allowHover .singleChef .container .pagination > div .txt a:hover i {
          transform: rotate(180deg) translateX(3px); }
    .singleChef .container .pagination > div.next {
      text-align: right;
      float: right; }
      .singleChef .container .pagination > div.next .txt {
        padding: 0 150px 0 25px; }
        @media (max-width: 1023px) {
          .singleChef .container .pagination > div.next .txt {
            padding: 0 120px 0 20px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .singleChef .container .pagination > div.next .txt {
      padding: 0; } }
        @media (max-width: 767px) {
          .singleChef .container .pagination > div.next .txt a span {
            margin: 0 0 6px 0; } }
        .singleChef .container .pagination > div.next .txt a i {
          transform: rotate(0deg); }
          @media (max-width: 767px) {
            .singleChef .container .pagination > div.next .txt a i {
              float: none;
              transform: rotate(0deg) translateY(3px); } }
        .allowHover .singleChef .container .pagination > div.next .txt a:hover i {
          transform: rotate(0deg) translate(3px, 0); }
          @media (max-width: 767px) {
            .allowHover .singleChef .container .pagination > div.next .txt a:hover i {
              transform: rotate(0deg) translate(3px, 3px); } }

.elections {
  margin: -120px 0 0 0; }
  .elections .bannerTitle {
    text-align: center;
    margin-bottom: 100px; }
    @media (max-width: 1023px) {
      .elections .bannerTitle {
        margin-bottom: 40px; } }
    .elections .bannerTitle img {
      width: 600px;
      max-width: 100%; }
  .elections .actions {
    padding: 60px 0 120px;
    height: 620px; }
    @media (max-width: 1023px) {
      .elections .actions {
        height: 450px;
        padding: 100px 0 150px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .elections .actions {
      padding: 50px 0 100px; } }
    .elections .actions .button {
      float: left;
      width: 33.33%;
      cursor: pointer; }
      @media (max-width: 1023px) {
        .elections .actions .button {
          width: 50%;
          margin: 0 0% 35px; } }
      @media (max-width: 767px) {
        .elections .actions .button {
          width: 100%;
          margin: 50px 0; } }
      .elections .actions .button .title {
        position: relative;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .elections .actions .button .title img {
          width: 75px;
          height: auto;
          display: block;
          margin: 0 auto; }
        .elections .actions .button .title h2 {
          color: #ed1b2e;
          font-family: "Playfair Display", serif;
          font-size: 28px;
          font-weight: 400;
          position: absolute;
          left: 0;
          text-align: center;
          top: 35px;
          width: 100%; }
          @media (max-width: 1280px) {
            .elections .actions .button .title h2 {
              font-size: 16px; } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .elections .actions .button .title h2 {
      font-size: 25px; } }
      .elections .actions .button p {
        color: #ed1b2e;
        font-size: 16px;
        line-height: 24px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        text-align: center;
        transition: opacity 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        @media (max-width: 767px) {
          .elections .actions .button p {
            font-size: 12px;
            line-height: 16px; } }
      .elections .actions .button .ctaLink {
        margin: 25px auto 0;
        display: table;
        background: #f8f7f3;
        padding: 0;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
        .elections .actions .button .ctaLink span {
          max-width: 0;
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          display: inline-block; }
        .elections .actions .button .ctaLink i {
          color: #ed1b2e; }
      .elections .actions .button.candidates .title img {
        width: 116px; }
      .elections .actions .button.calendar .title img {
        width: 85px; }
      .elections .actions .button.communications .title img {
        width: 70px; }
      .elections .actions .button.support .title img {
        width: 86px; }
      .allowHover .elections .actions .button:hover .title, .mobileDevice .elections .actions .button .title {
        font-size: 16px;
        transform: scale(1.4) translateY(-30px); }
        @media (max-width: 1280px) {
          .allowHover .elections .actions .button:hover .title, .mobileDevice .elections .actions .button .title {
            transform: scale(1.3) translateY(-30px); } }
  @media (max-width: 1280px) and (max-width: 1023px) {
    .allowHover .elections .actions .button:hover .title, .mobileDevice .elections .actions .button .title {
      transform: scale(1.1) translateY(-30px); } }
        .allowHover .elections .actions .button:hover .title img, .mobileDevice .elections .actions .button .title img {
          max-width: 100%; }
      .allowHover .elections .actions .button:hover p, .mobileDevice .elections .actions .button p {
        max-height: 0px; }
  .elections .actions-alt {
    height: auto !important;
    padding: 0 0 60px !important; }
    @media (max-width: 1023px) {
      .elections .actions-alt {
        padding: 0 !important; } }
  @media (max-width: 480px) {
    .elections .container-alt {
      padding: 60px 7% 0 7% !important; } }
  .elections .elus {
    margin: 0; }
    .elections .elus .container.beigeBg:first-child {
      padding-top: 0; }
    .elections .elus .filterTools {
      padding-top: 20px; }
