body {
    .tiny{
        h1{
            font-size:58px;
            line-height:63px;
            font-family:$play;
            margin:0 0 25px;
            font-weight:400;
            color:$red;
            @media (max-width: 767px){
                font-size:38px;
                line-height:43px;
                margin:0 0 20px;
            }
        }
        h2{
            font-size:37px;
            line-height:49px;
            font-family:$play;
            margin:0 0 10px;
            font-weight:400;
            @media (max-width: 767px){
                font-size:27px;
                line-height:35px;
                margin:0 0 10px;
            }
        }
        h3{
            font-size:20px;
            line-height:32px;
            margin:0 0 45px;
            font-weight:400;
            @media (max-width: 767px){
                font-size:15px;
                line-height:24px;
                margin:0 0 30px;
            }
        }
        h4{
            color:$red;
            font-weight:400;
            font-family:$play;
            font-size:23px;
            line-height:38px;
            @media (max-width: 767px){
                font-size:18px;
                line-height:30px;
            }
        }
        h5{
            font-size:19px;
            font-weight:400;
            font-family:$play;
            margin:20px 0 0 0;
            @media (max-width: 767px){
                font-size:15px;
                margin:15px 0 0 0;
            }
        }
        h6{
            font-size: 14px;
            font-weight:400;
            font-family:$play;
            color:$charcoal;
            margin:5px 0 5px 0;
            @media (max-width: 767px){
               font-size: 12px;
            }
        }
        p{
            font-size:14px;
            line-height:28px;
            margin:0 0 45px;
            font-weight:300;
            @media (max-width: 767px){
               font-size: 12px;
               line-height:22px;
               margin:0 0 30px;
            }
            a{
                color:$red;
                border-bottom:1px solid $red;
                transition:opacity $delay $easing;
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
            span{
                &.blue-question{
                    color:#007bc3;
                    font-weight:bold;
                    font-size:16px;
                }
                &.gray-answer{
                    color:#797979;
                }
            }
        }
        blockquote{
            color:$greyBlue;
            padding:0 16%;
            font-family:$play;
            font-style:italic;
            margin:0 0 45px;
            position:relative;
            @media (max-width: 767px){
               font-size: 14px;
               line-height:26px;
               margin:0 0 30px;
               padding:0 8%;
            }
            &:before{
                font-style:normal;
                position:absolute;
                content:"«";
                color:$red;
                top:-25px;
                left:8%;
                font-size:50px;
                font-weight:700;
                @media (max-width: 767px){
                    font-size:32px;
                    left:1%;
                    top:-5px;
                }
            }
            p{
                font-size:18px;
                line-height:32px;
                @media (max-width: 767px){
                   font-size: 14px;
                   line-height:26px;
                }
            }
            a{
               color:$greyBlue;
               text-decoration:underline;
               transition:opacity $delay $easing;
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
        }
        ul,ol{
            margin:0 0 55px;
            @media (max-width: 767px){
                margin:0 0 40px;
            }
            li{
                padding:0 0 0 25px;
                margin:0 0 15px;
                font-size:14px;
                line-height:28px;
                position:relative;
                font-weight:300;
                @media (max-width: 767px){
                    font-size:12px;
                    line-height:22px;
                    margin:0 0 10px;
                    padding:0 0 0 20px;
                }
                &:before{
                    position:absolute;
                    content:"";
                    top:12px;
                    left:5px;
                    width:4px;
                    height:4px;
                    background:$red;
                    border-radius:2px;
                    @media (max-width: 767px){
                        top:9px;
                        left:0px;
                    }
                }
                a{
                    color:$red;
                    text-decoration:underline;
                    transition:opacity $delay $easing;
                    .allowHover &:hover{
                        opacity:0.6;
                    }
                }
                span{
                    &.gray-answer{
                        color:#797979;
                    }
                }
            }
        }
        ol{
            padding:0 0 0 15px;
            counter-reset: item;
            list-style:none;
            li{
                padding:0 0 0 10px;
                &:before{
                    display:none;
                }
                &:after{
                    content: counter(item) ". ";
                    counter-increment: item;
                    color:$red;
                    position:absolute;
                    top:0;
                    left:-15px;
                }
            }
        }
        >a{
            color:$red;
            border-bottom:1px solid $red;
            font-size:13px;
            transition:opacity $delay $easing;
            .allowHover &:hover{
                opacity:0.6;
            }
            @media (max-width: 767px){
                font-size:11px;
            }
        }
        img{
            max-width:100%;
            display:block;
            margin:0 0 45px;
            @media (max-width: 767px){
                margin:0 0 30px;
            }
        }
        iframe{
            max-width:100%;
        }
        hr{
            border-color:$black;
            margin:55px 0 45px;
        }
        .ctaSliderImage{
            position:relative;
            margin:0 0 50px;
            cursor:pointer;
            background:$black;
            @media (max-width: 767px){
                margin:0 0 35px;
            }
            img{
                width:100%;
                height:auto;
                display:block;
                opacity:0.6;
                -webkit-backface-visibility: hidden;
                transition:opacity $delay $easing;
            }
            .allowHover &:hover{
                img{
                   opacity:0.3;
                }
            }
        }
        div.half{
            float:left;
            width:calc(50% - 20px);
            @media (max-width: 1023px){
                float:none;
                width:100%;
            }
        }
        div.half+.half{
            float:right;
            @media (max-width: 1023px){
                float:none;
            }
        }
    }
}
