/* RESPONSIVE
===========================
*/
$screen-xl: 1440px; // Laptop
$screen-lg: 1280px; // iPad Landscape, Old Laptop
$screen-md: 1023px; // iPad Portrait
$screen-sm: 767px; // Phone landscape
$screen-xs: 480px; // iPhone 6

@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: $screen-xs) {
            @content;
        }
    } @else if $class == sm {
        @media (max-width: $screen-sm) {
            @content;
        }
    } @else if $class == md {
        @media (max-width: $screen-md) {
            @content;
        }
    } @else if $class == lg {
        @media (max-width: $screen-lg) {
            @content;
        }
    } @else if $class == xl {
        @media (max-width: $screen-xl) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg, xl";
    }
}