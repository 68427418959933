.takeAStand{
	margin:-120px 0 0 0;
	.actions{
		padding:150px 0;
		height:500px;
		@media (max-width: 1023px){
			height:450px;
			padding:100px 0 150px;
			@media (max-width: 767px){
				padding:50px 0 100px;
			}
		}
		.button{
			float:left;
			width:33.33%;
			cursor:pointer;
			@media (max-width: 767px){
				width:100%;
				margin:50px 0;
			}
			.title{
				position:relative;
				transition:all $delay $easing;
				img{
					width:75px;
					height:auto;
					display:block;
					margin:0 auto;
				}
				h2{
					color:$red;
					font-family:$play;
					font-size:37px;
					font-weight:400;
					position:absolute;
					left:0;
					text-align:center;
					top:35px;
					width:100%;
					@media (max-width: 1280px){
						font-size:30px;
						@media (max-width: 1023px){
							font-size:25px;
						}
					}
				}
			}
			p{
				color:$red;
				font-size:16px;
				line-height:24px;
				max-height:0;
				opacity:0;
				overflow:hidden;
				text-align:center;
				transition:opacity $delay $easing;
				@media (max-width: 767px){
					font-size:12px;
					line-height:16px;
				}
			}
			.ctaLink{
				margin:25px auto 0;
				display:table;
				background:$beige;
				padding:0;
				transition:all $delay $easing;
				span{
					max-width:0;
					max-height:0;
					opacity:0;
					overflow:hidden;
					display:inline-block;
				}
				i{
					color:$red;
				}
			}
			&.donation{
				.title{
					img{
						width:130px;
					}
				}
			}
			&.volunteer{
				.title{
					img{
						width:140px;
					}
				}
			}
			.allowHover &:hover, .mobileDevice &{
				.title{
					transform:scale(1.4) translateY(-30px);
					@media (max-width: 1280px){
						transform:scale(1.3) translateY(-30px);
						@media (max-width: 1023px){
							transform:scale(1.1) translateY(-30px);
						}
					}
				}
				p{
					max-height:100px;
					opacity:1;
				}
				.ctaLink{
					background:$red;
					padding:18px 30px 23px 35px;
					transition:background $delay $easing;
					@media (max-width: 1023px){
						padding:13px 15px 18px 20px;
					}
					span{
						max-width:100px;
						max-height:100px;
						display:inline;
						opacity:1;
					}
					i{
						color:$beige;
					}
					.allowHover &:hover{
						background:$redHover;
					}
				}
			}
		}
	}
	.onlineTools{
		border-left:$extraPadding solid $beige;
		border-right:$extraPadding solid $beige;
		padding:85px 55px;
		display:inline-block;
		margin:0 0 50px;
		overflow:hidden;
		width:100%;
		background:url(../../images/bgOnlineTools.png) top right no-repeat $paleBlue;
		@media (max-width: 1023px){
			background-size:226px 150px;
			@media (max-width: 767px){
				background-size:113px 75px;
				padding:35px 25px;
			}
		}
		h3{
			font-size:24px;
			font-family:$play;
			font-weight:400;
			margin:0 0 15px;
			@media (max-width: 767px){
				font-size:20px;
				margin:0 0 10px;
			}
		}
		p{
			font-size:14px;
			line-height:28px;
			font-weight:300;
			margin:0 0 10px 0;
			width:80%;
			@media (max-width: 1023px){
				width:90%;
				@media (max-width: 767px){
					font-size:12px;
					line-height:24px;
				}
			}
		}
		>a{
	        color:$red;
	        font-size:16px;
	        display:inline-block;
	        transition:color $delay $easing;
	        @media (max-width: 767px){
	        	font-size:12px;
	        }
	        i{
	            margin:0 5px 0 0;
	            display:inline-block;
	            font-size:22px;
	            transform:translateY(2px);
	            transition:transform $delay $easing;
	            @media (max-width: 767px){
		        	font-size:18px;
		        }
	        }
	        .allowHover &:hover{
	        	color:$redHover;
	        	i{
	        		transform:translate(5px,2px);
	        	}
	        }
		}
	}
}