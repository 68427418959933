.chef{
	.bannerTitle{
	    .txt{
	        >div{
	        	top:50%;
	        }
	    }
	}
	.vision{
		float:left;
		width:100%;
		background:$beige;
		clear:both;
		padding:50px 0 0 0;
		.blockTitle{
			height:130px;
			border-left:$extraPadding solid transparent;
			border-right:$extraPadding solid transparent;
			@media (max-width:767px){
				border:none;
			}
			>img{
				width:214px;
				height:auto;
				margin:0 0 0 30px;
				@media (max-width:767px){
					width:150px;
					margin:0 0 0 10px;
				}
			}
			div{
	        	top:35px;
	        	@media (max-width:767px){
	        		top:20px;
	        	}
	        }
		}
		.videoVision{
			border-left:$extraPadding solid transparent;
			border-right:$extraPadding solid transparent;
			@media (max-width:767px){
				border:none;
			}
		}
		.content{
			width:70%;
			margin:0 auto;
			@media (max-width: 1280px){
				width:80%;
				@media (max-width:767px){
					width:100%;
				}
			}
		}
		.whiteBg{
			width:100%;
			float:left;
			background:$white;
			padding:120px 0 100px;
			margin:-45px 0 0 0;
			border-top:45px solid $beige;
			border-bottom:45px solid $beige;
			@media (max-width:767px){
				padding:60px 0 50px;
			}
			.quote{
				width:70%;
				margin:0 auto;
				text-align:center;
				@media (max-width: 1280px){
					width:80%;
					@media (max-width:767px){
						width:90%;
					}
				}
				blockquote{
					color:$greyBlue;
		            font-family:$play;
		            font-size:58px;
		            line-height:63px;
		            position:relative;
		            @media (max-width: 1280px){
						font-size:48px;
		            	line-height:53px;
		            	@media (max-width:767px){
		            		font-size:26px;
		            		line-height:32px;
		            	}
					}
		            span{
		            	font-size:60px;
		            	color:$red;
		            	margin:0;
		            	display:inline-block;
		            	font-weight:700;
		            	transform:translateY(-30px);
			            	@media (max-width: 1280px){
							font-size:50px;
							@media (max-width:767px){
								font-size:36px;
								transform:translateY(-12px);
							}
						}
		            }
				}
				span{
					display:inline-block;
					font-size:18px;
					margin:45px 0 0 0;
					@media (max-width:767px){
						font-size:14px;
						margin:25px 0 0 0;
					}
				}
			}
		}
	}
}