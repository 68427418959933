.elections {
  margin:-120px 0 0 0;
  .bannerTitle {
    text-align: center;
    margin-bottom: 100px;
    @media (max-width: 1023px){
      margin-bottom: 40px;
    }
    img {
      width: 600px;
      max-width: 100%;
    }
  }
  .actions{
    padding:60px 0 120px;
    height: 620px;
    @media (max-width: 1023px){
      height:450px;
      padding:100px 0 150px;
      @media (max-width: 767px){
        padding:50px 0 100px;
      }
    }
    .button{
      float:left;
      width:33.33%;

      cursor:pointer;
      @media (max-width: 1023px){
        width:50%;
        margin: 0 0% 35px;
      }
      @media (max-width: 767px){
          width:100%;
          margin:50px 0;
        }

      .title{
        position:relative;
        transition:all $delay $easing;
        img{
          width:75px;
          height:auto;
          display:block;
          margin:0 auto;
        }
        h2{
          color:$red;
          font-family:$play;
          font-size:28px;
          font-weight:400;
          position:absolute;
          left:0;
          text-align:center;
          top:35px;
          width:100%;
          @media (max-width: 1280px){
            font-size: 16px;
            @media (max-width: 1023px){
              font-size:25px;
            }
          }
        }
      }
      p{
        color:$red;
        font-size:16px;
        line-height:24px;
        max-height:0;
        opacity:0;
        overflow:hidden;
        text-align:center;
        transition:opacity $delay $easing;
        @media (max-width: 767px){
          font-size:12px;
          line-height:16px;
        }
      }
      .ctaLink{
        margin:25px auto 0;
        display:table;
        background:$beige;
        padding:0;
        transition:all $delay $easing;
        span{
          max-width:0;
          max-height:0;
          opacity:0;
          overflow:hidden;
          display:inline-block;
        }
        i{
          color:$red;
        }
      }
      &.candidates{
        .title{
          img{
            width:116px;
          }
        }
      }
      &.calendar {
        .title{
          img{
            width:85px;
          }
        }
      }
      &.communications{
        .title{
          img{
            width:70px;
          }
        }
      }
      &.support{
        .title{
          img{
            width:86px;
          }
        }
      }
      .allowHover &:hover, .mobileDevice &{
        .title{
          font-size: 16px;
          transform:scale(1.4) translateY(-30px);
          @media (max-width: 1280px){
            transform:scale(1.3) translateY(-30px);
            @media (max-width: 1023px){
              transform:scale(1.1) translateY(-30px);
            }
          }

          img {
            max-width: 100%;
          }
        }
        p{
          max-height:0px;
          // opacity:1;
        }
        // .ctaLink{
        //   background:$red;
        //   padding:12px 10px 12px 10px;
        //   transition:background $delay $easing;
        //   @media (max-width: 1023px){
        //     padding:13px 15px 18px 20px;
        //   }
        //   span{
        //     max-width:100px;
        //     max-height:100px;
        //     display:inline;
        //     opacity:1;
        //   }
        //   i{
        //     color:$beige;
        //   }
        //   .allowHover &:hover{
        //     background:$redHover;
        //   }
        // }
      }
    }
  }
  .actions-alt {
    height: auto !important;
    padding: 0 0 60px !important;
    @include breakpoint(md) {
      padding: 0 !important;
    }
  }
  .container-alt {
    @include breakpoint(xs) {
      padding: 60px 7% 0 7% !important;
    }
  }
  .elus {
        margin: 0;
    .container.beigeBg:first-child{
      padding-top: 0;
    }
    .filterTools {
      padding-top: 20px;
    }
  }
}
