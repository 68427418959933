.histoire{
  .realisation {
    width: 25%;
    float: left;
    display: block;
  }
  .bannerTitle{
    .txt{
      width: 50%;
      @media (max-width: 767px){
				width:100%;
			}

      > div{
        top:45%;
      }
      p{
        width: 67%;
        line-height: 1.9;
        @media (max-width: 767px){
  				width:100%;
  			}
      }
      a{
        margin-top: 40px;
        color: $red;
        font-size: 16px;
        display: inline-block;
        transition: color $delay $easing;
        i{
          display: block;

          margin: 0 5px 0 0;
          display: inline-block;
          font-size: 22px;
          transform: rotate(90deg) translate(0px, 7px);
          transition: transform $delay $easing;
        }
        &:hover{
          color: $redHover;
          i{
            transform: rotate(90deg) translate(7px,7px);
          }
        }
        @media (max-width: 767px){
  				display: none;
  			}
      }
    }
    .img{
      width: 72%;
      @media (max-width: 767px){
          width:100%;
          margin:15px 0;
      }
    }
  }
  .chefList{
    position: relative;
    .chef{
      padding: 100px 0;
      @media (max-width: 767px){
        padding: 30px 0;
      }

      &:nth-child(2n + 1){
        background-color: $beige;
      }

      .container {
        width: 85%;
        @media (max-width: 767px){
          width: 100%;
        }
      }

      .chef-info {
        position: relative;

        .thumb{
          position: relative;
          width: 40%;
          float: left;
          @media (max-width: 767px){
            width: 100%;
            clear: both;
          }
          span{
            font-family: $play;
            color: $red;
            font-size: 58px;
            position: absolute;
            top:-55px;
            width: 100%;
            text-align: center;
            @media (max-width: 767px){
              font-size: 38px;;
              top:-30px;
            }
          }
        }
        .txt{
          left: 40%;
          top: 0;
          width: 60%;
          z-index: 10;
          position: absolute;
          height: 100%;

          @media (max-width: 767px){
            left: 0%;
            width: 100%;
            position: relative;
          }

          > div{
            position: absolute;
            top: 12%;
            width: 100%;
            padding-left: 40px;

            @media (max-width: 767px){
              // width: 100%;
              padding-left: 0;
              position: relative;
            }

          }
          h4{
            a{
              font-family: $play;
              font-size: 37px;
              font-weight: normal;
              margin-bottom: 25px;
              color:$black;
                @media (max-width: 767px){
                  font-size: 28px;
                }
            }

          }
          p {
            line-height: 1.6;
            @media (max-width: 767px){
              font-size: 14px;
              line-height: 25px;
            }
          }
          a {
            color: $red;
            font-size: 16px;
            display: inline-block;
            transition: color $delay $easing;
            margin-top: 6px;
            @media (max-width: 767px){

                font-size: 12px;
              }

            i{
              margin: 0 5px 0 0;
              display: inline-block;
              font-size: 22px;
              transform: translateY(2px);
              transition: transform $delay $easing;
            }
            &:hover{
              color: $redHover;
              i{
                transform: translate(5px, 2px);
              }
            }
          }
        }
      }
      .realisations{
        margin-top: 30px;
        @media (max-width: 767px){
          margin-top: 10px;
        }
        h5{
          font-family: $play;
          color: $red;
          font-size: 23px;
          display: inline-block;
          font-weight: normal;
            @media (max-width: 767px){
              font-size: 25px;
              line-height: 30px;
            }
        }
        > a{
          float: right;
          display: inline-block;
          color: $black;
          margin-top: 12px;
          font-size: 14px;

          @media (max-width: 767px){
            float:none;
          }
        }
        ul {
          padding-top: 15px;
          margin-top: 15px;
          border-top: 1px solid $charcoal;
          li{
            position: relative;
            display: inline-block;
            width: 25%;
            float: left;
            cursor: pointer;
            transition: opacity $delay ease;

            border-right: 1px solid #fff;

            @media (max-width: 767px){
              width: 50%;
              border-bottom: 1px solid #fff;
            }

            &:last-child{
              // border-right: none;
            }

            .hover{
              position: absolute;
              top:0;
              right: 0;
              bottom: 0;
              left:0;
              background: $paleBlue;
              padding: 35px;
              font-size: 15px;
              line-height: 1.6;
              opacity: 0;
              transition: opacity $delay ease;
              font-family: $muli;
              span {
                font-family: $play;
                color: $charcoal;
                font-size: 14px;
                display: block;
                margin-bottom: 10px;;
              }
              >.achievementBtn{
                font-size: 16px;
                position: absolute;
                bottom:25px;
                display: block;
                color:$red;
                font-family: $muli;

              }
            }

            img{
              display: block;
            }



            &:hover {
              .hover {
                opacity: 1;
              }

              .openVideo{
                background-color: rgba(0,0,0,0.4);
              }

            }

            .openVideo{
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              cursor: pointer;

              &:hover .btnPlay {
                transform: translate(-50%, -50%) scale(1.1);
                i{
                  color: $red;
                }
              }

               .btnPlay {
                  width: 81px;
                  height: 74px;
                  position: absolute;
                  left: 50%;
                  top: 48%;
                  transform: translate(-50%, -50%);
                  transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1);

                  i {
                  display: inline-block;
                  color: #ffffff;
                  font-size: 58px;
                  transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1);
                  }
              }
            }

          }
        }
      }
    }
    .sidebarNav{
      // width: 20px;
      // height: 400px;
      // background-color: $charcoal;
      // display: none;
      opacity: 0;
      position: fixed;
      right: 90px;
      z-index: 1;
    top: 25%;
    transition: opacity $delay ease;
    @media (max-width: 767px){
      display: none;
    }

    &.visible{
      opacity: 1
    }

  //   progress{
  //     -webkit-transform-origin: 0 100%;
  //     -webkit-transform: rotate(90deg);
  //     -moz-transform-origin: 0 100%;
  //     -moz-transform: rotate(90deg);
  //     -ms-transform-origin: 0 100%;
  //     -ms-transform: rotate(90deg);
  //     transform-origin: 0 100%;
  //     transform: rotate(90deg);
  // }
    li {

      &:first-child{
        a {
          // padding-top: 0;
        }
      }
      &:last-child{
        a {
          // padding-bottom: 0;
        }
      }
    }

      a{
        position: relative;
        z-index: 1;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        span{

          margin-left: 1px;
          display: block;
          height: 7px;
          width: 7px;
          background-color: #a8a8a8;

          border-radius: 50%;
          transition: transform $delay $easing;

        }

        div {
          transition: opacity $delay ease;
        }

        .sidebarNav__name {
          opacity: 0;
          color: $paleGrey;
          position: absolute;
          right: 26px;
          top: 9px;
          width: 255px;
text-align: right;
        }

        .sidebarNav__date{
          opacity: 0;
          color: $paleGrey;
          position: absolute;
          left: 26px;
      top: 9px;
        }

        &.active {
          span{
            background: $red;
            transform: scale(1.3);
          }
          .sidebarNav__date {
            opacity: 1;
            color: $black;
          }

          &:after{
            content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    /* background-color: #ed1b2e; */
    border: 1px solid $red;
    border-radius: 50%;
    top: 27%;
    left: -4px;

          }
        }

        &:hover {
          span {
            transform: scale(1.3);
            // background: $red;
          }
          .sidebarNav__name{
            opacity: 1;
          }
          .sidebarNav__date {
            opacity: 1;

          }
        }
      }

      &:after{
        content: "";
        position: absolute;
        left: 50%;
        top: 0%;
        height: 100%;
        width: 1px;
        border-right: 1px solid #a8a8a8;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
}
