.eventsSmall{
    width:25%;
    border-right:1px solid $white;
    float:left;
    @media (max-width: 1280px){
        width:33.33%;
        @media (max-width: 767px){
            width:50%;
        }
    }
    img{
        width:100%;
        height:auto;
        display:block;
    }
    .txt{
        padding:25px;
        height:175px;
        position:relative;
        margin:0 0 50px;
        @media (max-width: 1023px){
            height:225px;
            @media (max-width: 767px){
                padding:20px 10px 10px 5px;
                height:175px;
            }
        }
        .dateAndIcon{
            .calendar{
                display:inline-block;
                width:33px;
                height:33px;
                position:relative;
                @media (max-width: 767px){
                    width:25px;
                    height:25px;
                }
                i{
                    position:absolute;
                    left:-6px;
                    top:-9px;
                    color:$iconBlue;
                    font-size:32px;
                    @media (max-width: 767px){
                        font-size:25px;
                        top:-11px;
                    }
                }
                .digit{
                    color:$red;
                    position:absolute;
                    left:50%;
                    top:8px;
                    font-size:13px;
                    transform:translateX(-50%);
                    @media (max-width: 767px){
                        font-size:10px;
                        left:44%;
                        top:2px;
                    }
                }
            }
            .full{
                font-size:14px;
                color:$charcoal;
                display:inline-block;
                font-family:$play;
                margin:0 0 0 5px;
                transform:translateY(-15px);
                @media (max-width: 767px){
                    font-size:11px;
                    margin:0;
                }
            }
        }
        p{
            font-size:17px;
            line-height:26px;
            font-weight:300;
            margin:-5px 0 0 0;
            @media (max-width: 767px){
               font-size:14px;
                line-height:20px; 
                margin:0;
            }
            a{
                color:$black;
                display:inline-block;
                transition:opacity $delay $easing;
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
        }
        >a{
            position:absolute;
            bottom:0;
            left:25px;
            color:$red;
            font-size:16px;
            display:inline-block;
            transition:color $delay $easing;
            @media (max-width: 767px){
               font-size:12px;
               left:10px;
            }
            i{
                margin:0 5px 0 0;
                display:inline-block;
                font-size:22px;
                transform:translateY(2px);
                transition:transform $delay $easing;
                @media (max-width: 767px){
                    font-size:18px;
                }
            }
            .allowHover &:hover{
                color:$redHover;
                i{
                    transform:translate(5px,2px);
                }
            }
        }
    }
}