@font-face {
  font-family: 'fontello';
  src: url('font/fontello.eot?34358135');
  src: url('font/fontello.eot?34358135#iefix') format('embedded-opentype'),
       url('font/fontello.woff2?34358135') format('woff2'),
       url('font/fontello.woff?34358135') format('woff'),
       url('font/fontello.ttf?34358135') format('truetype'),
       url('font/fontello.svg?34358135#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?54150336#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-404:before { content: '\e800'; } /* '' */
.icon-arrow:before { content: '\e801'; } /* '' */
.icon-badge:before { content: '\e802'; } /* '' */
.icon-benevole:before { content: '\e803'; } /* '' */
.icon-calendar-01:before { content: '\e804'; } /* '' */
.icon-calendar:before { content: '\e805'; } /* '' */
.icon-chevron:before { content: '\e806'; } /* '' */
.icon-close:before { content: '\e807'; } /* '' */
.icon-communique:before { content: '\e808'; } /* '' */
.icon-compass:before { content: '\e809'; } /* '' */
.icon-contact:before { content: '\e80a'; } /* '' */
.icon-don:before { content: '\e80b'; } /* '' */
.icon-dossier:before { content: '\e80c'; } /* '' */
.icon-engrenage:before { content: '\e80d'; } /* '' */
.icon-envelope:before { content: '\e80e'; } /* '' */
.icon-facebook:before { content: '\e80f'; } /* '' */
.icon-flag:before { content: '\e810'; } /* '' */
.icon-fullscreen:before { content: '\e811'; } /* '' */
.icon-instagram:before { content: '\e812'; } /* '' */
.icon-law:before { content: '\e813'; } /* '' */
.icon-like:before { content: '\e814'; } /* '' */
.icon-link:before { content: '\e815'; } /* '' */
.icon-microphone:before { content: '\e816'; } /* '' */
.icon-monument:before { content: '\e817'; } /* '' */
.icon-mouse:before { content: '\e818'; } /* '' */
.icon-notification:before { content: '\e819'; } /* '' */
.icon-pin:before { content: '\e81a'; } /* '' */
.icon-play:before { content: '\e81b'; } /* '' */
.icon-plus:before { content: '\e81c'; } /* '' */
.icon-presidents:before { content: '\e81d'; } /* '' */
.icon-reply:before { content: '\e81e'; } /* '' */
.icon-retweet:before { content: '\e81f'; } /* '' */
.icon-search:before { content: '\e820'; } /* '' */
.icon-social_media:before { content: '\e821'; } /* '' */
.icon-tool:before { content: '\e822'; } /* '' */
.icon-twitter:before { content: '\e823'; } /* '' */
.icon-valeurs_appartenance_canadienne:before { content: '\e824'; } /* '' */
.icon-valeurs_developpement_economique:before { content: '\e825'; } /* '' */
.icon-valeurs_equite_intergenerationnelle:before { content: '\e826'; } /* '' */
.icon-valeurs_identification_quebec:before { content: '\e827'; } /* '' */
.icon-valeurs_justice_sociale:before { content: '\e828'; } /* '' */
.icon-valeurs_liberte_individuelle:before { content: '\e829'; } /* '' */
.icon-valeurs_respect_societe:before { content: '\e82a'; } /* '' */
.icon-valeurs_vie_politique:before { content: '\e82b'; } /* '' */
.icon-vision:before { content: '\e82c'; } /* '' */
.icon-web:before { content: '\e82d'; } /* '' */
.icon-youtube:before { content: '\e82e'; } /* '' */
.icon-moins:before { content: '\e82f'; } /* '' */