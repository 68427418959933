.singleEngagements {
	.bannerTitle {
		align-items: center;
		.back {
			margin-top: 0;
			margin-bottom: 0;
			position: absolute;
		    left: calc(45% + 56px);
		    top: 43px;
		}
		.txt, .img {
			position: inherit;
			float: none;
			transform: inherit;
		}
		.img {
			height: 550px;
			background-size: cover;
			background-position: center center;
			padding: 20px;
		    display: flex;
		    align-items: flex-end;
			.img-category-container {
				display: flex;
			    flex-wrap: wrap;
				.img-category {
					position: relative;
				    z-index: 10;
				    padding: 10px;
				    width: 50px;
				    height: 50px;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    background-color: white;
				    margin-right: 1px;
				    margin-bottom: 1px;
				    &:last-child {
				    	margin-right: 0;
				    }
					img {
						max-width: 100%;
					}
				}
			}
		}
		.txt {
			padding-top: 75px;
			padding-bottom: 75px;
			> div {
				position: inherit;
				top: inherit;
				transform: none;
				h1 {
					margin-bottom: 0;
				}
			}
		}
	}
	@include breakpoint(xl) {
		.bannerTitle {
			.img {
				height: 400px;
			}
			.txt {
				> div {
					h1 {
						font-size: 40px;
						line-height: 45px;
					}
				}
			}	
		}
	}
	@include breakpoint(lg) {
		.bannerTitle {
			.back {
				top: 5px;
			}
			.img {
				height: 360px;
			}
			.txt {
				padding-top: 35px;
				padding-bottom: 35px;
			}	
		}
	}
	@include breakpoint(md) {
		.bannerTitle {
			.back {
			    left: calc(45% + 25px);
			}
			.img {
				height: 280px;
				.img-category-container {
					.img-category {
						width: 40px;
						height: 40px;
					}
				}
			}
			.txt {
				> div {
					h1 {
						font-size: 28px;
						line-height: 31px;
					}
				}
			}	
		}
	}
	@include breakpoint(sm) {
		.bannerTitle {
			align-items: flex-start;
			.back {
				position: initial;
				margin-top: 15px;
			}
			.txt, .img {
				flex: auto;
			}
			.txt {
				padding-top: 0;
				padding-bottom: 0;
			}	
		}
	}
	@include breakpoint(xs) {
		.bannerTitle {
			.img {
				height: 180px;
			}
		}
	}
}

.flex-double-container {
	display: flex;
	align-items: flex-start;
	.flex-double-sm, .flex-double-lg {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.flex-double-sm {
		width: 45%;
	}
	.flex-double-lg {
		width: 55%;
		padding-left: 56px;
	}
	@include breakpoint(md) {
		.flex-double-lg {
			padding-left: 25px;
		}
	}
	@include breakpoint(sm) {
		align-items: initial;
		flex-direction: column;
		.flex-double-sm, .flex-double-lg {
			width: 100%;
		}
		.flex-double-lg {
			padding-left: 0;
		}
	}
}