.page404{
	.txt{
		text-align:center;
		padding:50px 0 110px;
		@media (max-width: 767px){
			padding:20px 0 60px;
		}
		h1{
			font-size:95px;
			font-family:$play;
			font-weight:400;
			margin:0 0 5px;
			@media (max-width: 767px){
				font-size:65px;
			}
		}
		h2{
			font-size:37px;
			font-family:$play;
			font-weight:400;
			color:$red;
			margin:0 0 10px;
			@media (max-width: 767px){
				font-size:27px;
			}
		}
		p{
			font-family:$play;
			font-size:22px;
			margin:0 0 50px;
			@media (max-width: 767px){
				font-size:17px;
				margin:0 0 35px;
			}
		}
		img{
			width:330px;
			@media (max-width: 767px){
				width:240px;
			}
		}
	}
}