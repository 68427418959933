.pagination{
    float:right;
    margin:0 0 90px;
    clear:both;
    @media (max-width: 767px){
      margin:0 0 60px;  
    }
    .page{
        width:auto;
        float:left;
        padding:26px 35px 0 0;
        @media (max-width: 1023px){
            padding:21px 25px 0 0;
            @media (max-width: 767px){
                padding:18px 20px 0 0;
            }
        }
        a, span{
            float:left;
            margin:0 10px;
            padding:0 5px;
            color:$black;
            position:relative;
            font-size:13px;
            transition:opacity $delay $easing;
            @media (max-width: 1023px){
                margin:0 3px 30px;
            }
            &.active{
                .allowHover &:hover{
                    opacity:1;
                }
                &:after{
                    position:absolute;
                    bottom:-12px;
                    left:50%;
                    content:"";
                    background:$red;
                    height:3px;
                    width:28px;
                    transform:translateX(-50%);
                }
            }
        }
        a:hover{
            opacity:0.6;
        }
    }
    .controls{
        float:left;
        @media (max-width: 767px){
            float:right;
        }
        a{
            padding:20px;
            background:$red;
            color:$white;
            float:left;
            transition:background $delay $easing;
            @media (max-width: 1023px){
                padding:15px;
            }
            i{
                font-size:22px;
                display:inline-block;
                transition:transform $delay $easing;
                @media (max-width: 767px){
                    font-size:18px;
                }
            }
            &.prev{
                margin:0 1px 0 0;
                i{
                    transform:rotate(180deg);
                }
            }
            .allowHover &:hover{
                background:$redHover;
                i{
                    transform:translate(5px, 0);
                }
                &.prev{
                    i{
                        transform:translateX(-5px) rotate(180deg);
                    }
                }
            }
        }
    }
}