.ailleursWeb,.amisEnFace{
	margin:-120px 0 0 0;
	.headerTitle{
		background:$beige;
		padding:110px 0 45px;
		overflow:hidden;
		position:relative;
		clear:both;
		min-height:275px;
		@media (max-width: 1023px){
			padding:90px 0 45px;
			@media (max-width: 767px){
				padding:30px 0 45px;
				min-height:100px;
			}
		}
		.txt{
			position:relative;
			z-index:10;
			h1{
				font-size:49px;
				line-height:55px;
				color:$red;
				font-family:$play;
				font-weight:400;
				@media (max-width: 767px){
					font-size:38px;
					line-height:45px;
				}
			}
			p{
				margin:20px 0 0 0;
				width:60%;
				font-size:17px;
				line-height:31px;
				font-weight:300;
				@media (max-width: 767px){
					font-size:14px;
					line-height:26px;
					width:100%;
					margin:15px 0 0 0;
				}
			}
		}
		img{
			position:absolute;
			right:20%;
			bottom:-15px;
			@media (max-width: 1023px){
				right:0;
				@media (max-width: 767px){
					width:250px;
				}
			}
		}
	}
	.linkList{
		padding:60px 0;
		.webArticle{
			width:100%;
			border-top:1px solid $paleGrey;
			&:last-child{
				border-bottom:1px solid $paleGrey;
			}
			a{
				display:block;
				padding:20px 40px;
				position:relative;
				@media (max-width: 767px){
					padding:10px 30px 20px;
				}
				.date{
					color:$charcoal;
					font-size:14px;
					font-family:$play;
					display:inline-block;
					margin:0 0 5px;
					@media (max-width: 767px){
						font-size:12px;
					}
				}
				p{
					color:$black;
					font-size:14px;
					line-height:28px;
					padding:0 0 0 40px;
					position:relative;
					width:60%;
					box-sizing:content-box;
					transform:translateX(-40px);
					@media (max-width: 767px){
						font-size:12px;
						line-height:22px;
						width:100%;
					}
					i{
						color:$red;
						display:inline-block;
						font-size:22px;
						position:absolute;
						left:0;
						top:-1px;
						transition:left $delay $easing;
						@media (max-width: 767px){
							font-size:18px;
							left:10px;
						}
					}
				}
				.goTo{
					position:absolute;
					top:50%;
					right:0;
					transform:translateY(-50%);
					background:$grey;
					display:inline-block;
					color:$white;
					text-transform:uppercase;
					font-size:12px;
					padding:7px 10px 10px;
					transition:background $delay $easing;
					@media (max-width: 767px){
						font-size:10px;
						padding:5px 8px 7px;
						position:relative;
						top:auto;
						transform:translateY(0);
						margin:10px 0 0 0;
					}
					i{
						font-size:18px;
						margin:0 2px 0 0;
						display:inline-block;
						@media (max-width: 767px){
							font-size:14px;
						}
					}
				}
				.allowHover &:hover{
					p{
						i{
							left:5px;
							@media (max-width: 767px){
								left:15px;
							}
						}
					}
					.goTo{
						background:$greyBlue;
					}
				}
			}
		}
	}
}