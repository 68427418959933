.listingEvents{
	margin:-120px 0 0 0;
	.filterTools{
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		padding:90px 0 60px;
		@media (max-width: 1023px){
			padding:70px 0 60px;
			@media (max-width: 767px){
				border:none;
				padding:20px 0 60px;
			}
		}
		h1{
			font-weight:400;
			color:$red;
			font-size:49px;
			font-family:$play;
			@media (max-width: 767px){
				font-size:39px;
				line-height:45px;
			}
		}
		.filters{
			margin:40px 0 0 0;
			.filter{
				width:33.33%;
				border-right:1px solid $beige;
				@media (max-width: 1023px){
					width:100%;
					border:none;
					margin:0 0 15px;
				}
			}
			.search{
				width:33.33%;
				float:left;
				position:relative;
				@media (max-width: 1023px){
					width:100%;
				}
				input{
					padding:0 70px 0 40px;
					height:70px;
					width:100%;
					border:none;
					font-weight:300;
					font-size:15px;
					border-radius:0px;
					@media (max-width: 1280px){
						font-size:12px;
						@media (max-width: 1023px){
		                	padding:0 60px 0 20px;
		                	@media (max-width: 767px){
		                		height:50px;
							}
		                }
		            }
				}
				a{
					position:absolute;
					right:25px;
					top:20px;
					font-size:25px;
					color:$black;
					transition:color $delay $easing;
					@media (max-width: 1023px){
						right:20px;
						@media (max-width: 767px){
							font-size:20px;
							top:12px;
						}
					}
					.allowHover &:hover{
						color:$red;
					}
				}
			}
		}
	}
	.listing{
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		padding:60px 0 90px;
		@media (max-width: 767px){
			border:none;
			padding:60px 0 0;
		}
		.noResultMessage{
			margin:0 0 80px;
			font-family:$play;
			font-size:20px;
			@media (max-width: 767px){
				font-size:16px;
				margin:0 0 60px;
			}
		}
		.ctaLink{
			margin:0 auto 60px;
			display:table;
			clear:both;
			i{
				transform:rotate(90deg) translate(3px, 5px);
				transition:transform $delay $easing;
			}
			.allowHover &:hover{
				i{
					transform:rotate(90deg) translate(8px, 5px);
				}
			}
		}
	}
}