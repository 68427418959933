.engagementMedium {
    margin-bottom: 20px;
    .txt {
        padding-top: 10px;
        padding-bottom: 10px;
        h6{
            text-transform:uppercase;
            font-size:11px;
            font-weight:400;
            margin:0 0 15px;
            @media (max-width: 767px){
                font-size:10px;
                margin:0 0 10px;
            }
        }
        .date{
            font-family:$play;
            color:$charcoal;
            font-size:14px;
            margin:0 0 5px;
            display:inline-block;
            @media (max-width: 767px){
                font-size:12px;
            }
        }
        p{
            font-size:17px;
            line-height:30px;
            font-weight:300;
            @media (max-width: 767px){
                font-size:14px;
                line-height:25px;
            }
            a{
                -webkit-font-smoothing: antialiased;
                color:$black;
                display:inline-block;
                transition:opacity $delay $easing;
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
        }
    }
    .engagement-img {
        width: 100%;
        height: 230px;
        background-position: center center;
        background-size: cover;
    }
}

.engagementLarge {
    @extend .engagementMedium;
}

.grid-sizer, .grid-item {
    width: calc(25% - 22.5px);
    @include breakpoint(lg) {
        width: calc(33.33% - 20px);
    }
    @include breakpoint(md) {
        width: calc(50% - 15px);
    }
    @include breakpoint(xs) {
        width: 100%;
    }
}

.gutter-sizer {
    width: 30px;
}