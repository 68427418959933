.singleCommunique{
	.wouldLike{
		border-left:$extraPadding solid $white;
		border-right:$extraPadding solid $white;
		clear:both;
		width:100%;
		float:left;
		padding:10px 0 30px;
		@media (max-width: 767px){
			border:none;
		}
		.list{
			padding:90px 0 0 0;
			@media (max-width: 1280px){
				.newsSmall:nth-child(3){
					display:none;
				}
				@media (max-width: 767px){
					padding:60px 0 0 0;
				}
			}
		}
	}
}