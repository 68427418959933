.equipe{
	margin:-120px 0 0 0;
	.blockTitle{
		padding:60px 0 50px 0;
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		@media (max-width: 767px){
			border:none;
			padding:20px 0 50px 0;
		}
		h1{
			font-size:49px;
			@media (max-width: 1023px){
				font-size:37px;
				line-height:40px;
				@media (max-width: 767px){
					font-size:25px;
					line-height:30px;
				}
			}
		}
		>img{
			width:175px;
			height:auto;
			margin:0 0 0 -30px;
			@media (max-width: 767px){
				margin:0 0 0 -10px;
			}
		}
       	div{
        	top:100px;
        	@media (max-width: 767px){
        		top:50px;
        	}
        }
    }
    .teamList{
    	padding:60px 0 40px;
    	border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		@media (max-width: 767px){
			padding:50px 0 40px;
			border:none;
		}
		.member{
			float:left;
			width:25%;
			height:95px;
			margin:0 0 10px;
			padding:0 20px 0 0;
			@media (max-width: 1023px){
				width:33.33%;
				@media (max-width: 767px){
					width:50%;
				}
			}
			h5{
				font-size:18px;
				font-weight:400;
				@media (max-width: 767px){
					font-size:16px;
				}
			}
			span{
				font-size:14px;
				color:$charcoal;
				font-family:$play;
				@media (max-width: 767px){
					font-size:12px;
				}
			}
			.socialMedia{
				transform:translateX(-12px);
			}
		}
    }
    .president{
    	.blockTitle{
    		>img{
    			width:100px;
    		}
    		p{
    			margin:-35px 0 0 0;
    		}
    	}
    	.teamList{
    		.member{
    			height:115px;
    		}
    	}
    }
}