.listingCommuniques{
	margin:-120px 0 0 0;
	@media (max-width: 1023px){
		margin:-100px 0 0 0;
	}
	.highlight{
		position:relative;
		margin:30px 0 75px;
		@media (max-width: 1023px){
			margin:0 0 75px;
			@media (max-width: 767px){
				margin:0 0 40px;
			}
		}
		>a{
			width:48%;
			float:left;
			@media (max-width: 767px){
				width:100%;
			}
		}
		.txt{
			width:48%;
			position:absolute;
			top:50%;
			right:0;
			transform:translateY(-50%);
			@media (max-width: 767px){
				width:100%;
				position:relative;
				top:auto;
				padding:15px 0 0 0;
				clear:both;
				transform:translateY(0);
			}
			h6{
				text-transform:uppercase;
				font-size:11px;
				font-weight:400;
				margin:25px 0 15px;
				@media (max-width: 767px){
					font-size:10px;
				}
			}
			.date{
				font-family:$play;
				color:$charcoal;
				font-size:14px;
				margin:0 0 10px;
				display:inline-block;
				@media (max-width: 767px){
					font-size:12px;
					margin:0 0 5px;
				}
			}
			h2{
				font-size:38px;
				line-height:48px;
				font-weight:400;
				font-family:$play;
				margin:0 0 15px;
				@media (max-width: 1023px){
					font-size:28px;
					line-height:31px;
				}
				a{
					color:$black;
					transition:opacity $delay $easing;
					.allowHover &:hover{
						opacity:0.6;
					}
				}
			}
			.socialMedia{
				transform:translateX(-12px);
			}
		}
	}
	.filterTools{
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		padding:0 0 190px;
		@media (max-width: 767px){
			border:none;
			padding:0 0 180px;
		}
		h1{
			font-weight:400;
			color:$red;
			font-size:37px;
			font-family:$play;
		}
		.filters{
			margin:40px 0 0 0;
			.filter{
				width:33.33%;
				border-right:1px solid $beige;
				@media (max-width: 1023px){
					width:100%;
					border:none;
					margin:0 0 15px;
				}
			}
			.search{
				width:33.33%;
				float:left;
				position:relative;
				@media (max-width: 1023px){
					width:100%;
				}
				input{
					padding:0 70px 0 40px;
					height:70px;
					width:100%;
					border:none;
					font-weight:300;
					font-size:15px;
					border-radius:0px;
					@media (max-width: 1280px){
						font-size:12px;
						@media (max-width: 1023px){
		                	padding:0 60px 0 20px;
		                	@media (max-width: 767px){
		                		height:50px;
		                	}
		                }
		            }
				}
				a{
					position:absolute;
					right:25px;
					top:20px;
					font-size:25px;
					color:$black;
					transition:color $delay $easing;
					@media (max-width: 1023px){
						right:20px;
						@media (max-width: 767px){
							font-size:20px;
							top:12px;
						}
					}
					.allowHover &:hover{
						color:$red;
					}
				}
			}
		}
	}
	.listing{
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		margin:-125px 0 0 0;
		@media (max-width: 767px){
			border:none;
		}
		.noResultMessage{
			margin:0 0 80px;
			font-family:$play;
			font-size:20px;
			@media (max-width: 767px){
				font-size:16px;
				margin:0 0 60px;
			}
		}
	}
}
