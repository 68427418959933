.heightValues{
	margin:-120px 0 0 0;
	.headerValue{
		position:relative;
		float:left;
		width:100%;
		margin:0 0 50px;
		clear:both;
		@media (max-width: 767px){
			margin:20px 0 40px;
		}
		.txt{
			width:40%;
			position:absolute;
			top:50%;
			left:0;
			z-index:15;
			transform:translateY(-50%);
			@media (max-width: 1280px){
				width:50%;
				@media (max-width: 767px){
					position:relative;
					width:100%;
					top:auto;
					transform:translateY(0);
				}
			}
			span{
				font-size:19px;
				display:inline-block;
				margin:0 0 20px;
				@media (max-width: 767px){
					font-size:15px; 
					margin:0 0 10px;
				}
				i{
					display:inline-block;
					color:$iconBlue;
					font-size:35px;
					transform:translate(-10px, 4px);
					@media (max-width: 767px){
                       font-size:25px; 
                       transform:translate(-5px, 2px);
                    }
				}
			}
			h1{
				font-size:50px;
				line-height:55px;
				font-weight:400;
				font-family:$play;
				margin:0 0 45px;
				@media (max-width: 1280px){
					font-size:40px;
					line-height:45px;
					@media (max-width: 1023px){
						font-size:28px;
						line-height:31px;
						@media (max-width: 767px){
							margin:0 0 20px;
						}
					}
				}
			}
		}
		.image{
			width:83%;
			float:right;
			position:relative;
			&:before{
	            content:"";
	            position:absolute;
	            top:0;
	            left:0;
	            width:100%;
	            height:100%;
	            z-index:10;
	            opacity:0.6;
	            background: linear-gradient(to right, rgba(229,241,246,1) 0%,rgba(255,255,255,0) 100%);
	        }
			@media (max-width: 767px){
				width:100%;
				margin:0 0 20px 0;
				&:before{
					display:none;
				}
			}
			img{
				width:100%;
				display:block;
				height:auto;
			}
		}
	}
	.valuesList{
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		padding:60px 0 0 0;
		@media (max-width: 767px){
			padding:40px 0 0 0;
			border:none;
		}
		.value{
			margin:0 0 80px;
			float:left;
			width:100%;
			@media (max-width: 767px){
				margin:0 0 50px;
			}
			.blockTitle{
				max-height:160px;
		        margin:0 0 0 -40px;
		        @media (max-width: 767px){
		        	max-height:95px;
		        	margin:0 0 0 -10px;
				}
		        img{
		        	margin:0;
		        	@media (max-width: 767px){
		        		width:125px;
		        	}
		        }
		       	div{
		        	top:65px;
		        	@media (max-width: 767px){
		        		top:15px;
		        	}
		        }
		    }
		    .content{
		    	position:relative;
		    	z-index:5;
				.vid{
					float:left;
					width:70%;
					@media (max-width: 767px){
						width:100%;
					}
					>div{
						margin:0;
					}
					.txt{
						border-top:1px solid $white;
						background:$paleBlue;
						padding:50px;
						@media (max-width: 767px){
							padding:20px;
						}
						p{
							font-size:15px;
							line-height:28px;
							font-weight:300;
							@media (max-width: 767px){
								font-size:12px;
								line-height:22px;
							}
						}
					}
				}
				.list{
					border-left:1px solid $white;
					float:left;
					width:30%;
					padding:55px 0 0 0;
					@media (max-width: 767px){
						width:100%;
						padding:25px 0 0 0;
					}
					img{
						display:block;
						width:100%;
						height:auto;
					}
					.txt{
						padding:20px 10px 0 30px;
						@media (max-width: 767px){
							padding:20px;
						}
						h3{
							font-family:$play;
							font-size:19px;
							font-weight:400;
							margin:0 0 20px;
							@media (max-width: 767px){
								font-size:15px;
								margin:0 0 15px;
							}
						}
						ul{
							li{
								font-size:13px;
								line-height:22px;
								position:relative;
								padding:0 0 0 35px;
								margin:0 0 10px;
								@media (max-width: 767px){
									font-size:12px;
									line-height:20px;
								}
								i{
									position:absolute;
									left:0;
									top:0;
									color:$red;
									content:"";
									font-size:22px;
									@media (max-width: 767px){
										font-size:18px;
									}
								}
							}
						}
					}
				}
			}
			&:nth-child(2n){
				.content{
					.list{
						border-left:none;
						border-right:1px solid $white;
					}
				}
			}
		}
	}
}