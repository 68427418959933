.home{
	overflow-x:ddeg;
	.slider{
		margin:0 -70px 30px 0;
		position:relative;
		@media (max-width: 1280px){
			margin:0 -35px 30px 0;
			@media (max-width: 767px){
				margin:0 0 30px 0;
			}
		}
		.bx-wrapper{
			width:100%;
			.bx-viewport{
				@media (max-width: 767px){
					overflow:visible!important;
				}
				ul{
					li{
						position:relative;
						&:before{
				            content:"";
				            position:absolute;
				            top:0;
				            right:0;
				          	width:65%;
				            height:100%;
				            z-index:10;
				            opacity:0.6;
				            background: linear-gradient(to right, rgba(229,241,246,1) 0%,rgba(255,255,255,0) 100%);
				        }
						@media (max-width: 767px){
							padding:0 0 90px;
							&:before{
								display:none;
							}
						}
						img{
							float:right;
							width:65%;
							display:block;
							@media (max-width: 767px){
								width:100%;
							}
						}
						.videoZone{
							width:65%;
							position:absolute;
							right:0;
							top:0;
							height:100%;
							z-index:20;
							@media (max-width: 767px){
								width:100%;
								height:auto;
								&:before{
							        display: block;
							        content: " ";
							        width: 100%;
							        padding-top:86%;
							    }
							}
							.openVideo{
								width:80px;
								height:80px;
								position:absolute;
								left:50%;
								top:50%;
								cursor:pointer;
								transform:translate(-50%,-50%);
								transition:transform $delay $easing;
								@media (max-width: 767px){
					                width:50px;
					                height:50px; 
					            }
								.btnPlay{
									width:100%;
									height:100%;
									transition:transform $delay $easing;
									i{
										display:inline-block;
										color:$white;
										font-size:78px;
										margin:-8px 0 0 -13px;
										transition:color $delay $easing;
										@media (max-width: 767px){
						                    font-size:58px; 
						                }
									}
									.allowHover &:hover{
						                transform:scale(1.1);
						                i{
						                    color:$red;
						                }
							        }
								}
							}
						}
						.bx-caption{
							width:55%;
							top:0;
							padding:95px 0 0 0;
							z-index:15;
							@media (max-width: 1440px){
								padding:30px 0 0 0;
								@media (max-width: 1280px){
									width:60%;
									@media (max-width: 1023px){
										padding:15px 0 0 0;
										@media (max-width: 767px){
											width:100%;
											top:auto;
											padding:20px 0 0 0;
											position:relative;
											clear:both;
										}
									}
								}
							}
							span{
								h2{
									font-size:50px;
									line-height:55px;
									font-family:$play;
									margin:35px 0 55px;
									font-weight:400;
									@media (max-width: 1280px){
										font-size:40px;
										line-height:45px;
										margin:25px 0 45px;
										@media (max-width: 1023px){
											font-size:28px;
											line-height:31px;
											margin:15px 0 25px;
											@media (max-width: 767px){
												margin:20px 0;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.bx-controls-direction{
				width:100%;
				a{
					padding:20px;
					background:$red;
					color:$white;
					bottom:95px;
					transition:background $delay $easing;
					.mobileDevice &{
						padding:20px 20px 18px;
					}
					@media (max-width: 1280px){
						bottom:15px;
						@media (max-width: 1023px){
							padding:15px;
							.mobileDevice &{
								padding:15px 15px 13px;
							}
							@media (max-width: 767px){
								bottom:0px;
							}
						}
					}
					i{
						font-size:22px;
						display:inline-block;
						transition:transform $delay $easing;
						@media (max-width: 767px){
							font-size:18px;
						}
					}
					&.bx-prev{
						left:35%;
						transform:translateX(-101%);
						i{
							transform:rotate(180deg) translate(0, 2px);
							@-moz-document url-prefix() { 
								transform:rotate(180deg) translate(0, 0);
							}
						}
						@media (max-width: 767px){
							left:auto;
							right:0;
							transform:translateX(-100%);
						}
					}
					&.bx-next{
						left:35%;
						right:auto;
						@media (max-width: 767px){
							left:auto;
							right:0;
						}
						.mobileDevice & i{
							transform:translate(0, -1px);
						}
					}
					.allowHover &:hover{
                        background:$redHover;
                        i{
                            transform:translate(5px, 0);
                        }
                        &.bx-prev{
                            i{
                                transform:rotate(180deg) translate(5px, 2px);
                                @-moz-document url-prefix() { 
									transform:rotate(180deg) translate(5px, 0);
								}
                            }
                        }
                    }
				}
			}
		}
		#bx-pager{
			width:22%;
			border-bottom:1px solid $black;
			position:absolute;
			bottom:130px;
			left:0;
			z-index:50;
			@media (max-width: 1280px){
				bottom:50px;
				@media (max-width: 1023px){
					bottom:40px;
					@media (max-width: 767px){
						bottom:25px;
						width:50%;
					}
				}
			}
			a{
				-webkit-font-smoothing: antialiased;
				color:$black;
				font-size:13px;
				padding:15px 12px;
				display:inline-block;
				position:relative;
				transition:opacity $delay $easing;
				@media (max-width: 1023px){
					padding:15px 8px;
					@media (max-width: 767px){
						font-size:11px;
						padding:7px;
					}
				}
				.allowHover &:hover{
					opacity:0.6;
				}
				&.active{
					opacity:1;
				}
				&.active:after{
					position:absolute;
					bottom:-1px;
					width:100%;
					height:2px;
					background:$red;
					left:-2px;
					content:'';
					border-left:2px solid $white;
					border-right:2px solid $white;
				}
			}
		}
	}
	.bigSubject{
		width:100%;
		margin:0 0 40px;
		float:left;
		@media (max-width: 767px){
			margin:0 0 20px;
		}
		.blockTitle{
	        margin:0 0 -25px;
	       	div{
	        	top:30%;
	        	@media (max-width: 1023px){
	        		top:20%;
	        		@media (max-width: 767px){
	        			top:8%;
	        		}
	        	}
	        }
        	img{
        		@media (max-width: 767px){
        			width:175px;
        		}
        	}
	    }
		.subject{
			width:48%;
			position:relative;
			z-index:10;
			float:left;
			@media (max-width: 767px){
				width:100%;
				margin:0 0 15px;
			}
			img{
				width:100%;
				height:auto;
				display:block;
			}
			.txt{
				padding:30px 20px;
				@media (max-width: 767px){
					padding:20px 15px;
				}
				h4{
					font-size:26px;
					line-height:36px;
					font-family:$play;
					margin:30px 0 25px 0;
					font-weight:400;
					@media (max-width: 767px){
						font-size:18px;
						line-height:23px;
						margin:25px 0 20px 0;
					}
					a{
						-webkit-font-smoothing: antialiased;
						color:$black;
						transition:opacity $delay $easing;
						.allowHover &:hover{
							opacity:0.6;
						}
					}
				}
			}
			&:nth-child(3), &.right{
				float:right;
			}
		}
	}
	.register{
		background:$beige;
		border-left:$extraPadding solid $white;
		border-right:$extraPadding solid $white;
		clear:both;
		padding:40px 40px 70px;
		margin:0 0 60px;
		position:relative;
		float:left;
		width:100%;
		@media (max-width: 767px){
			border:none;
			padding:20px 15px 60px;
			margin:0 0 35px;
		}
		.title{
			position:relative;
			margin:0 auto;
			width:100%;
			>img{
				display:block;
				margin:0 auto;
				@media (max-width: 767px){
					width:125px;
				}
			}
			div{
				position:absolute;
				left:50%;
				top:50%;
				width:100%;
				transform:translate(-50%,-50%);
				h3{
					color:$red;
					font-size:56px;
					font-family:$play;
					font-weight:400;
					width:100%;
					text-align:center;
					@media (max-width: 1280px){
						font-size:46px;
						line-height:53px;
						@media (max-width: 767px){
							font-size:28px;
							line-height:31px;
						}
					}
				}
			}
		}
		.find{
			p{
				font-size:22px;
				line-height:38px;
				max-width:725px;
				text-align:center;
				margin:10px auto 40px;
				font-family:$play;
				@media (max-width: 767px){
					font-size:16px;
					line-height:24px;
				}
			}
			.form{
				width:730px;
				margin:0 auto 25px;
				position:relative;
				height:70px;
				@media (max-width: 1280px){
					width:650px;
					@media (max-width: 1023px){
						width:520px;
						@media (max-width: 767px){
							width:100%;
							height:0;
						}
					}
				}
				.filter{
					width:335px;
					@media (max-width: 1280px){
						width:290px;
						@media (max-width: 1023px){
							width:230px;
							@media (max-width: 767px){
								width:100%;
								margin:0 0 25px;
							}
						}
					}
				}
				div.or{
					text-align:center;
					transform:translate(-50%,-50%);
					position:absolute;
					width:28px;
					height:28px;
					left:50%; 
					top:50%;
					@media (max-width: 767px){
						position:relative;
						margin:25px auto;
						left:auto;
						clear:both;
						transform:translate(0,0);
					}
					span{
						display:block;
						color:$white;
						font-size:13px;
						font-weight:300;
						z-index:10;
						position:relative;
						line-height:27px;
					}
					&:after{
						background:$darkBlue;
						width:28px;
						height:28px;
						position:absolute;
						top:0;
						left:0;
						content:"";
						transform:rotate(45deg);
					}
				}
				input{
					height:70px;
					width:335px;
					border:none;
					float:right;
					font-weight:300;
					text-align:center;
					font-size:15px;
					border-radius:0px;
					@media (max-width: 1280px){
						width:290px;
						@media (max-width: 1023px){
							width:230px;
							font-size:13px;
							@media (max-width: 767px){
								width:100%;
								height:50px;
								margin:0 0 25px;
								font-size:12px;
							}
						}
					}
				}
			}
			.ctaLink{
				clear:both;
				margin:0 auto;
				@media (max-width: 767px){
					width:100%;
					text-align:center;
				}
			}
			#response_circonscription{
				text-align:center;
				margin:30px 0 0 0;
				color:$red;
				font-size:16px;
				@media (max-width: 767px){
					margin:25px 0 0 0;
					font-size:14px;
				}
			}
		}
		.info{
			.change{
				position:absolute;
				top:45px;
				right:55px;
				cursor:pointer;
				color:$greyBlue;
				font-size:12px;
				i{
					font-size:18px;
					display:inline-block;
					transform:translateY(3px);
				}
				@media (max-width: 1023px){
					top:25px;
					right:25px;
					@media (max-width: 767px){
						top:10px;
						right:10px;
					}
				}
			}
			.col{
				float:left;
				width:33.33%;
				padding:0 25px;
				margin:20px 0 0 0;
				@media (max-width: 1023px){
					width:100%;
					padding:0px;
				}
				h4{
					color:$red;
					font-size:23px;
					text-align:center;
					font-family:$play;
					font-weight:400;
					margin:0 0 20px;
					@media (max-width: 767px){
						font-size:18px;
						margin:0 0 15px;
					}
				}
				.socialMedia{
					text-align:center;
				}
				&.depute{
					.thumb{
						display:table;
						position:relative;
						border-radius: 75px;
						width:150px;
						height:150px;
						overflow:hidden;
						margin:0 auto 15px;
						transition:opacity $delay $easing;
						img{
							display:block;
						}
						.allowHover &:hover{
							opacity:0.6;
						}
					}
					h5{
						font-size:15px;
						font-weight:300;
						text-align:center;
						width:100%;
						@media (max-width: 767px){
							font-size:12px;
						}
						a{
							color:$black;
							transition:opacity $delay $easing;
							.allowHover &:hover{
								opacity:0.6;
							}
						}
					}
				}
				&.tiny{
					text-align:center;
	                p{
	                    font-weight:300;
	                    margin:0;
	                }
	                >a{
	                    margin:0 auto;
	                    display:table;
	                }
	            }
				&.depLastNews{
					.tag{
						a{
							display:table;
							margin:0 auto;
						}
					}
					h6{
						font-size:11px;
						text-transform:uppercase;
						text-align:center;
						margin:20px 0 0 0;
						@media (max-width: 767px){
							font-size:10px;
							margin:15px 0 0 0;
						}
					}
					.date{
						text-align:center;
						color:$charcoal;
						font-size:14px;
						font-family:$play;
						display:block;
						margin:5px 0 0 0;
						@media (max-width: 767px){
							font-size:12px;
						}
					}
					p{
						text-align:center;
						font-size:15px;
						line-height:28px;
						margin:5px 0 0 0;
						font-weight:300;
						@media (max-width: 767px){
							font-size:14px;
							line-height:25px;
						}
						a{
							color:$black;
							display:inline-block;
							transition:opacity $delay $easing;
							.allowHover &:hover{
								opacity:0.6;
							}
						}
					}
				}
			}
		}
	}
	.lastNews{
		clear:both;
		margin:0 0 10px;
		float:left;
		width:100%;
		border-left:$extraPadding solid $white;
		border-right:$extraPadding solid $white;
		@media (max-width: 767px){
			border:none;
		}
		.blockTitle{
	        margin:0 0 -80px;
	        img{
	        	width:190px;
	        	height:auto;
	        }
	        div{
	        	top:22%;
	        	@media (max-width: 1023px){
	        		top:12%;
	        	}
	        }
	    }
	    @media (max-width: 1280px){
	    	.newsSmall:nth-child(3n){
	    		display:none;
	    	}
	    }
	}
	.banner{
		margin:40px 0 60px 0;
		clear:both;
		float:left;
		width:100%;
		position:relative;
		@media (max-width: 767px){
			margin:20px 0 30px 0;
			overflow:hidden;
		}
		img{
			width:100%;
			display:block;
			@media (max-width: 767px){
				height:350px;
				width:auto;
				max-width:none;
			}
		}
		.txt{
			width:65%;
			position:absolute;
			left:8%;
			top:50%;
			transform:translateY(-50%);
			@media (max-width: 767px){
				width:80%;
			}
			h2{
				font-size:58px;
				line-height:63px;
				color:$white;
				font-weight:400;
				font-family:$play;
				margin:0 0 55px;
				@media (max-width: 1280px){
					font-size:48px;
					line-height:53px;
					@media (max-width: 767px){
						font-size:34px;
						line-height:36px;
						margin:0 0 45px;
					}
				}
			}
		}
	}
	.elsewhere{
		width:48%;
		clear:both;
		float:left;
		margin:0 0 60px;
		@media (max-width: 1280px){
			width:100%;
		}
		.blockTitle{
	        margin:0 0 25px;
	        img{
	        	width:200px;
	        	@media (max-width: 767px){
	        		width:130px;
	        	}
	        }
	        div{
	        	top:40%;
	        	@media (max-width: 1023px){
	        		top:30%;
	        	}
	        }
	    }
		.webArticle{
			width:100%;
			border-top:1px solid $paleGrey;
			&:last-child{
				border-bottom:1px solid $paleGrey;
			}
			a{
				display:block;
				padding:20px 40px;
				@media (max-width: 767px){
					padding:10px 30px 20px;
				}
				.date{
					color:$charcoal;
					font-size:14px;
					font-family:$play;
					display:inline-block;
					margin:0 0 5px;
					@media (max-width: 767px){
						font-size:12px;
					}
				}
				p{
					color:$black;
					font-size:14px;
					line-height:28px;
					padding:0 0 0 40px;
					position:relative;
					width:100%;
					box-sizing:content-box;
					transform:translateX(-40px);
					@media (max-width: 767px){
						font-size:12px;
						line-height:22px;
					}
					i{
						color:$red;
						display:inline-block;
						font-size:22px;
						position:absolute;
						left:0;
						top:-1px;
						transition:left $delay $easing;
						@media (max-width: 767px){
							font-size:18px;
							left:10px;
						}
					}
				}
				.goTo{
					margin:10px 0 0 0;
					background:$grey;
					display:inline-block;
					color:$white;
					text-transform:uppercase;
					font-size:12px;
					padding:3px 12px 6px 8px;
					transition:background $delay $easing;
					@media (max-width: 767px){
						font-size:10px;
						padding:5px 8px 8px;
					}
					i{
						font-size:18px;
						margin:0 2px 0 0;
						display:inline-block;
						@media (max-width: 767px){
							font-size:14px;
						}
					}
				}
				.allowHover &:hover{
					p{
						i{
							left:5px;
							@media (max-width: 767px){
								left:15px;
							}
						}
					}
					.goTo{
						background:$greyBlue;
					}
				}
			}
		}
	}
}