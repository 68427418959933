.reachFollow{
    padding:60px 0;
    width:100%;
    float:left;
    @media (max-width: 1280px){
       padding:60px 0 100px; 
       @media (max-width: 767px){
        padding:30px 0 60px; 
       }
    }
    .onSocialMedia{
        width:60%;
        float:left;
        @media (max-width: 1280px){
            width:100%;
            margin:0 0 60px;
        }
        .blockTitle{
            >img{
                width:271px;
                height:auto;
                margin:0 auto;
                @media (max-width: 767px){
                    width:170px;
                }
            }
            div{
                top:90px;
                width:100%;
                @media (max-width: 767px){
                    top:50px;
                }
                h2{
                    width:100%;
                    text-align:center;
                    padding:0 20px;
                    @media (max-width: 767px){
                        top:50px;
                        padding:0;
                    }
                }
            }
        }
        .socialMosaic{
            margin:30px 0 0 0;
            &.fullWidth{
                >div:first-child{
                    width:100%;
                }
            }
            .twitter{
                height:355px; 
                background:$paleBlue;
                width:50%;
                border-right:1px solid $white;
                border-bottom:1px solid $white;
                padding:40px 50px;
                text-align:center;
                position:relative;
                float:left;
                @media (max-width: 767px){
                    width:100%;
                    height:250px;
                    border-right:none;
                    padding:25px 20px;
                }
                >i{
                    color:$red;
                    font-size:22px;
                    display:inline-block;
                    @media (max-width: 767px){
                        font-size:18px;
                    }
                }
                h5{
                    font-size:24px;
                    font-weight:normal;
                    font-family:$play;
                    margin:0 0 18px;
                    @media (max-width: 767px){
                        font-size:18px;
                        margin:0 0 13px;
                    }
                    a{
                        color:$red;
                        transition:color $delay $easing;
                        .allowHover &:hover{
                            color:$redHover;
                        }
                    }
                }
                p{
                    font-size:17px;
                    line-height:24px;
                    margin:0 0 15px;
                    @media (max-width: 767px){
                        font-size:12px;
                        line-height:18px;
                        margin:0 0 10px;
                    }
                    a{
                        color:$black;
                        text-decoration:underline;
                        transition:opacity $delay $easing;
                        .allowHover &:hover{
                            opacity:0.6;
                        }
                    }
                }
                .action{
                    a{
                        font-size:17px;
                        color:$greyBlue;
                        transition:color $delay $easing;
                        &:nth-child(2){
                            transform:translatey(2px);
                            display:inline-block;
                            font-size:22px;
                        }
                        .allowHover &:hover{
                            color:$greyBlueHover;
                        }
                    }
                }
                >a{
                    position:absolute;
                    width:100%;
                    display:inline-block;
                    left:50%;
                    bottom:40px;
                    font-size:14px;
                    color:$red;
                    transform:translateX(-50%);
                    @media (max-width: 767px){
                        font-size:12px;
                        bottom:25px;
                    }
                    i{
                        display:inline-block;
                        font-size:22px;
                        transform:translateY(3px);
                        transition:transform $delay $easing;
                        @media (max-width: 767px){
                            font-size:18px;
                        }
                    }
                    .allowHover &:hover{
                        i{
                            transform:translate(5px, 3px);
                        }
                    }
                }
            }
            .facebook, .youtube{
                width:50%;
                height:355px;
                float:left;
                background:$darkBlue;
                text-align:center;
                position:relative;
                border-right:1px solid $white;
                border-bottom:1px solid $white;
                transition:background $delay $easing;
                @media (max-width: 767px){
                    width:100%;
                    height:250px;
                    border-right:none;
                }
                a{
                    color:$white;
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    img{
                        width:120px;
                        display:block;
                        margin:70px auto 0;
                        border-radius:60px;
                        @media (max-width: 767px){
                           margin:30px auto 0; 
                           width:90px;
                           border-radius:45px;
                        }
                    }
                    >i{
                        margin:15px 0 0 0;
                        font-size:18px;
                        display:inline-block;
                        @media (max-width: 767px){
                            font-size:16px;
                            margin:10px 0 0 0;
                        }
                        &.icon-youtube{
                            font-size:22px;
                            @media (max-width: 767px){
                                font-size:18px;
                            }
                        }
                    }
                    h5{
                        margin:5px 0 0 0;
                        font-weight:400;
                        font-family:$play;
                        font-size:22px;
                        @media (max-width: 767px){
                            font-size:16px;
                        }
                    }
                    span{
                        position:absolute;
                        width:100%;
                        display:inline-block;
                        left:50%;
                        bottom:40px;
                        font-size:14px;
                        transform:translateX(-50%);
                        @media (max-width: 767px){
                            font-size:12px;
                            bottom:25px;
                        }
                        i{
                            display:inline-block;
                            font-size:22px;
                            transform:translateY(3px);
                            transition:transform $delay $easing;
                            @media (max-width: 767px){
                                font-size:18px;
                            }
                        }
                    }
                }
                .allowHover &:hover{
                    background:$darkBlueHover;
                    a{
                        span{
                            i{
                                transform:translate(5px, 3px);
                            }
                        }
                    }
                }
            }
            .youtube{
                background:$red;
                .allowHover &:hover{
                    background:$redHover;
                }
            }
            .instagram{
                width:50%;
                float:left;
                height:355px;
                text-align:center;
                position:relative;
                overflow:hidden;
                background:$black;
                border-right:1px solid $white;
                border-bottom:1px solid $white;
                box-shadow: inset 0px -60px 90px -40px rgba(0,0,0,0.75);
                @media (max-width: 767px){
                    width:100%;
                    height:250px;
                    border-right:none;
                }
                a{
                    color:$white;
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    &:after{
                        content:"";
                        position:absolute;
                        top:0;
                        left:0;
                        height:100%;
                        width:100%;
                        z-index:5;
                        box-shadow: inset 0px -120px 125px -135px rgba(0,0,0,0.75);
                    }
                    img{
                        position:absolute;
                        display:block;
                        transition:opacity $delay $easing;
                    }
                    >i{
                        margin:260px 0 0 0;
                        font-size:18px;
                        display:inline-block;
                        position:relative;
                        z-index:10;
                        @media (max-width: 767px){
                            font-size:16px;
                            margin:170px 0 0 0;
                        }
                    }
                    span{
                        position:absolute;
                        width:100%;
                        display:inline-block;
                        left:50%;
                        bottom:40px;
                        font-size:14px;
                        z-index:10;
                        transform:translateX(-50%);
                        @media (max-width: 767px){
                            font-size:12px;
                            bottom:25px;
                        }
                        i{
                            display:inline-block;
                            font-size:22px;
                            transform:translateY(3px);
                            transition:transform $delay $easing;
                            @media (max-width: 767px){
                                font-size:18px;
                            }
                        }
                    }
                }
                .allowHover &:hover{
                    a{
                        img{
                            opacity:0.6;
                        }
                        span{
                            i{
                                transform:translate(5px, 3px);
                            }
                        }
                    }
                }
            }
        }
    }
    .otherWay{
        width:36%;
        float:right;
        @media (max-width: 1280px){
            width:100%;
            float:none;
            clear:both;
            margin:0 auto;
            width:65%;
            @media (max-width: 767px){
                width:100%;
            }
        }
        .contact{
            background:$beige;
            padding:65px 0 0 0;
            float:left;
            width:100%;
            @media (max-width: 767px){
                padding:30px 0 0 0;
            }
            .blockTitle{
                >img{
                    width:185px;
                    height:auto;
                    margin:0 auto;
                    @media (max-width: 767px){
                        width:105px;
                    }
                }
                div{
                    top:45px;
                    width:100%;
                    @media (max-width: 767px){
                        top:20px;
                    }
                    h2{
                        width:100%;
                        text-align:center;
                        padding:0 20px;
                    }
                }
            }
            h4{
                width:100%;
                text-align:center;
                font-family:$play;
                font-size:19px;
                padding:0 20px;
                margin:30px 0 0 0;
                font-weight:400;
                @media (max-width: 767px){
                    font-size:15px;
                    margin:20px 0 0 0;
                }
            }
            p{
                margin:15px 0 50px 0;
                text-align:center;
                font-size:13px;
                line-height:22px;
                @media (max-width: 767px){
                    font-size:12px;
                    line-height:20px;
                    margin:10px 0 30px 0; 
                }
                a{
                    color:$black;
                    text-decoration:underline;
                    transition:opacity $delay $easing;
                    .allowHover &:hover{
                        opacity:0.6;
                    }
                }
            }
            .ctaLink{
                display:table;
                margin:0 auto -35px;
                @media (max-width: 1023px){
                    margin:0 auto -30px;
                    @media (max-width: 767px){
                        margin:0 auto -22px;
                    }
                }
            }
        }
        .writeToPrez{
            float:left;
            width:100%;
            margin:90px 0 0 0;
            padding:0 40px;
            @media (max-width: 1023px){
                margin:70px 0 0 0;
                padding:0 20px;
                @media (max-width: 767px){
                    padding:0 15px;
                }
            }
            h2{
                font-family:$play;
                font-size:24px;
                line-height:32px;
                font-weight:400;
                color:$red;
                text-align:center;
                width:80%;
                margin:0 auto 15px;
                @media (max-width: 1023px){
                    font-size:17px;
                    line-height:21px;
                    width:100%;
                }
            }
            .tiny{
                p{
                    font-weight:300;
                    text-align:center;
                    margin:0;
                    @media (max-width: 767px){
                        margin:0 auto;
                        max-width:400px;
                    }
                }
                h5{
                    text-align:center;
                }
                h6{
                    text-align:center;
                }
                >a{
                    margin:0 auto;
                    display:table;
                }
            }
        }
    }
}