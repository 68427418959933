.singleChef {
  .bannerTitle{
		z-index:10;
    @media (max-width: 767px){
      margin-bottom: 0;
    }
		.txt{
			@media (max-width: 767px){
				margin:20px 0 0 0;
			}
      > div{
        h1 {
          @media (max-width: 767px){
            margin-bottom: 0;
          }
        }
        img {
          width: 58%;
          @media (max-width: 767px){
            width: 100%;
          }
        }
      }
		}
    .img{
      width: 73%;
      @media (max-width: 767px){
        display:none;
      }
    }


    span {
      margin-bottom: 20px;
    }
	}
  #realisations {
    padding:40px 0 0 0;
  }
  .container{
    article{
      .intro{
        span{
          font-size: 11px;
          line-height: 20px;
          margin: 12px 0 1px;
          font-weight: 300;
          text-transform: uppercase;
          display: block;
          &:first-child{
            margin-top: 0;
          }
        }
      }
    }
    .realisationList{
          background: #f8f7f3;
      margin: 30px 0;
      padding: 30px 7% 60px;

      width: 100%;
      @media (max-width: 767px){
        margin:0;
      }
      > div{


        .blockTitle{
          div {
            top: 58px;
            right: 0;
            text-align: center;
          }
          img{
            width: 240px;
            height: auto;
            margin: 0 auto;
          }
        }
      }
      ul{
        padding: 40px 0 70px;
        width: 100%;
        li{
          float: left;
          display: block;
          margin: 8px 0 8px 5%;
          width: 20%;

          @media (max-width: 767px){
            width: 40%;
            margin: 8px 5%;
          }

          a{
            font-size: 12px;
            color: $black;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }

          }
        }
      }
    }
    .realisationFeatured{
      .realisation{
        margin: 15px 0;
        h3{
          margin-top: 40px;
          color: $red;
          font-size: 37px;
          font-family: $play;
          display: inline-block;
          font-weight: 400;

          @media (max-width: 767px){
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            display: block;
          }


        }
        > div{
          position: relative;
          width: 92%;
          margin: 30px 4%;
          z-index: 10;
          float: left;

          span {
            font-family: $play;
            color: $charcoal;
            display: block;
            margin-bottom: 10px;;
          }

          .txt {
            padding: 20px 30px;

            @media (max-width: 767px){
              line-height: 16px;
              margin: 0 0 25px;
              font-size: 12px;
            }



          }

          .media{
            width: 70%;
            float: left;
            background-color: #e1f1f7;
            font-size: 15px;
            line-height: 28px;
            position: relative;


            @media (max-width: 767px){
              width: 100%;
            }

            .openVideo{
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              cursor: pointer;

              &:hover .btnPlay {
                transform: translate(-50%, -50%) scale(1.1);
                i{
                  color: $red;
                }
              }

              .btnPlay {
                width: 80px;
                height: 80px;
                position: absolute;
                left: 50%;
                top: 34%;
                transform: translate(-50%, -50%);
                transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1);

                i {
                  display: inline-block;
                  color: #ffffff;
                  font-size: 78px;
                  margin: -8px 0 0 -13px;
                  transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1);
                }
              }
            }

          }
          .excerpt{
            width: 30%;
            position: absolute;
            top: 60%;
            right: 0;
            transform: translateY(-58%);
            font-size: 13px;
            line-height: 25px;

            @media (max-width: 767px){
              display: none;
            }

            img {
              width: 100%;
            }
          }
        }

        &:nth-child(2n){
          .media{
            float: right;
          }
          .excerpt{
            right: auto;
            left:0
          }
        }
      }
    }

    .pagination{
      width:100%;
  		float:left;
  		clear:both;
  		padding:55px 0;
  		border-top:1px solid $paleGrey;
  		border-bottom:1px solid $paleGrey;
  		margin:0 0 70px;
  		position:relative;
  		@media (max-width: 767px){
  			margin:0 0 50px;
  			padding:15px 0 10px;
  		}
  		&:after{
  			content:"";
  			position:absolute;
  			left:50%;
  			top:30%;
  			height:40%;
  			width:1px;
  			background:$paleGrey;
  			transform:translateX(-50%);
  			@media (max-width: 767px){
  				display:none;
  			}
  		}
      >div{
        width:50%;
  			float:left;
  			position:relative;
  			@media (max-width: 767px){
  				margin:8px 0 12px;
  			}

        .thumb{
  				width:125px;
  				display:inline-block;
  				position:relative;
  				z-index:10;
  				@media (max-width: 1023px){
  					width:100px;
  					@media (max-width: 767px){
  						display:none;
  					}
  				}
  				img{
  					width:100%;
  					height:auto;
  					display:inline-block;
  				}
  			}
        .txt{
  				position:absolute;
  				top:50%;
  				left:0;
  				width:100%;
  				padding:0 25px 0 150px;
  				transform:translateY(-50%);
  				@media (max-width: 1023px){
  					padding:0 20px 0 120px;
  					@media (max-width: 767px){
  						padding:0;
  					}
  				}
  				a{
  					display:inline-block;
  					span{
  						font-size:14px;
  						color:$charcoal;
  						font-family:$play;
  						margin:0 0 5px;
  						display:inline-block;
  						@media (max-width: 767px){
  							font-size:12px;
  							margin:0 0 5px 5px;
  						}
  					}
  					p{
  						font-size:17px;
  						line-height:24px;
  						font-weight:300;
  						color:$black;
  						transition:opacity $delay $easing;
  						@media (max-width: 1023px){
  							font-size:15px;
  							line-height:22px;
  							@media (max-width: 767px){
  								display:none;
  							}
  						}
  					}
  					i{
  						display:inline-block;
  						color:$red;
  						font-size:22px;
  						transform:rotate(180deg);
  						transition:transform $delay $easing;
  						@media (max-width: 767px){
  							float:left;
  							font-size:18px;
  							transform:rotate(180deg);
  						}
  					}
  					.allowHover &:hover{
  						p{
  							opacity:0.6;
  						}
  						i{
  							transform:rotate(180deg) translateX(3px);
  						}
  					}
  				}
  			}
        &.next{
  				text-align:right;
          float: right;
  				.txt{
  					padding:0 150px 0 25px;
  					@media (max-width: 1023px){
  						padding:0 120px 0 20px;
  						@media (max-width: 767px){
  							padding:0;
  						}
  					}
  					a{
  						span{
  							@media (max-width: 767px){
  								margin:0 0 6px 0;
  							}
  						}
  						i{
  							transform:rotate(0deg);
  							@media (max-width: 767px){
  								float:none;
  								transform:rotate(0deg) translateY(3px);
  							}
  						}
  						.allowHover &:hover{
  							i{
  								transform:rotate(0deg) translate(3px,0);
  								@media (max-width: 767px){
  									transform:rotate(0deg) translate(3px,3px);
  								}
  							}
  						}
  					}
  				}
  			}
      }

    }
  }
}
