.files{
	margin:-120px 0 0 0;
	.headerTitle{
		background:$beige;
		padding:110px 0 150px;
		overflow:hidden;
		position:relative;
		clear:both;
		@media (max-width: 1023px){
			padding:70px 0 150px;
			@media (max-width: 767px){
				padding:30px 0 150px;
			}
		}
		.txt{
			position:relative;
			z-index:10;
			h1{
				font-size:49px;
				line-height:55px;
				color:$red;
				font-family:$play;
				font-weight:400;
				@media (max-width: 767px){
					font-size:38px;
					line-height:45px;
				}

			}
			p{
				margin:20px 0 0 0;
				width:60%;
				font-size:17px;
				line-height:31px;
				font-weight:300;
				@media (max-width: 767px){
					font-size:14px;
					line-height:26px;
					width:100%;
					margin:15px 0 0 0;
				}
			}
		}
		img{
			position:absolute;
			right:20%;
			bottom:-40px;
			@media (max-width: 1023px){
				right:0;
				@media (max-width: 767px){
					width:250px;
				}
			}
		}
	}
	.listingFiles{
		margin:-90px 0 0 0;
		padding:0 0 90px;
		.file{
			width:33.33%;
			float:left;
			position:relative;
			@media (max-width: 1280px){
				width:50%;
				@media (max-width: 575px){
					width:100%;
				}
			}
			.bgImg{
				position:relative;
				padding:40px 40px 0;
				display:block;
				overflow:hidden;
				@media (max-width: 767px){
					padding:25px 25px 0;
				}
				img{
					position:absolute;
					top:50%;
					width:100%;
					height:auto;
					left:50%;
					transform:translate(-50%,-50%);
				}
				&:before{
			        display: block;
			        content: " ";
			        width: 100%;
			        padding-top: (314 / 274) * 100%;
			    }
			}
			.thumb{
				position:relative;
				background:url(../../images/bgFiles.png) center bottom no-repeat $darkBlue;
				background-size:100%;
				border-top:40px solid $darkBlue;
				border-left:40px solid $darkBlue;
				border-right:40px solid $darkBlue;
				display:block;
				@media (max-width: 767px){
					border-top:25px solid $darkBlue;
					border-left:25px solid $darkBlue;
					border-right:25px solid $darkBlue;
				}
				&:before{
			        display: block;
			        content: " ";
			        width: 100%;
			        padding-top: (314 / 274) * 100%;
			    }
				.name{
					position: absolute;
					top:40%;
					z-index:10;
					left:0;
					padding:0 20px;
					width:100%;
					h2{
						color:$red;
						font-weight:400;
						font-size:37px;
						line-height:38px;
						padding:0 0 10px;
						font-family:$play;
						border-bottom:1px solid $red;
						@media (max-width: 1023px){
							font-size:32px;
							line-height:33px;
							@media (max-width: 767px){
								font-size:28px;
								line-height:30px;
							}
						}
					}
					h3{
						color:$red;
						font-weight:400;
						font-size:18px;
						padding:5px 0 0;
						font-family:$play;
						@media (max-width: 767px){
							font-size:14px;
						}
					}
				}
			}
			.txt{
				height:135px;
				margin:0 0 55px;
				padding:20px 40px 0;
				text-align:center;
				position:relative;
				@media (max-width: 767px){
					height:120px;
					margin:0 0 35px;
					padding:20px 20px 0;
				}
				h4{
					font-weight:300;
					font-size:19px;
					line-height:31px;
					@media (max-width: 767px){
						font-size:15px;
						line-height:24px;
					}
				}
				span{
					color:$charcoal;
					font-family:$play;
					display:inline-block;
					font-size:14px;
					@media (max-width: 767px){
						font-size:12px;
					}
				}
				a{
					display:inline-block;
					color:$red;
					position:absolute;
					font-size:14px;
					bottom:0;
					left:50%;
					border-bottom:1px solid $red;
					transform:translateX(-50%);
					transition:opacity $delay $easing;
					@media (max-width: 767px){
						font-size:12px;
					}
					.allowHover &:hover{
						opacity:0.5;
					}
				}
			}
			&:nth-child(3n-1){ 
	            &:before{
	                position:absolute;
	                content:"";
	                left:-1px;
	                top:0;
	                height:100%;
	                width:1px;
	                background:$white;
	                @media (max-width: 1280px){
	                    display:none;
	                }
	            }
	            &:after{
	                position:absolute;
	                content:"";
	                right:0;
	                top:0;
	                height:100%;
	                width:1px;
	                background:$white;
	                @media (max-width: 1280px){
	                    display:none;
	                }
	            }
		    }
		    @media (max-width: 1280px){
		        &:nth-child(2n){
	                &:before{
	                    position:absolute;
	                    content:"";
	                    left:-1px;
	                    top:0;
	                    height:100%;
	                    width:1px;
	                    background:$white;
	                    display:block;
	                    @media (max-width: 575px){
	                    	display:none;
	                    }
	                }
		        }
		    }
		}
	}
}