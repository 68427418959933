
/*** UTILS ***/
@import 'utils/reset';
@import 'utils/responsive';
@import 'utils/font';
@import 'utils/variable';

/*** LIBS ***/
@import 'libs/bxslider';

/*** CORE ***/
@import 'core/main';
@import 'core/tinyStyle';

/*** PARTIAL ***/
@import 'partial/header';
@import 'partial/footer';
@import 'partial/widget'; 
@import 'partial/lightbox';
@import 'partial/bannerTitle';
@import 'partial/newsSmall';
@import 'partial/eventsSmall';
@import 'partial/articleBy';
@import 'partial/followReach';
@import 'partial/pagination';
@import 'partial/callToAction';

/*** TEMPLATE ***/
@import 'template/home';
@import 'template/listing-communiques';
@import 'template/listing-BBQ';
@import 'template/single-communique';
@import 'template/ailleurs-web';
@import 'template/equipe';
@import 'template/listing-elus';
@import 'template/single-elu';
@import 'template/listing-engagements';
@import 'template/single-engagements';
@import 'template/chef';
@import 'template/histoire';
@import 'template/huit-valeurs';
@import 'template/listing-evenements';
@import 'template/single-event';
@import 'template/documents';
@import 'template/single-dossier';
@import 'template/passez-a-laction';
@import 'template/devenir-membre';
@import 'template/generic';
@import 'template/404';
@import 'template/single-chef';
@import 'template/listing-candidats';
