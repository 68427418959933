.generic{
	margin:-120px 0 0 0;
	.genericHeader{
		padding:110px 15% 25px;
		text-align:center;
		@media (max-width: 1280px){
			padding:110px 10% 25px;
			@media (max-width: 1023px){
				padding:70px 10% 25px;
				@media (max-width: 767px){
					padding:50px 0 25px;
					text-align:left;
				}
			}
		}
		p{
			font-size:16px;
			@media (max-width: 767px){
				font-size:14px;
			}
		}
	}
	.genericContent{
		padding:70px 15% 50px;
		@media (max-width: 1280px){
			padding:70px 10% 50px;
			@media (max-width: 767px){
				padding:40px 0 30px;
			}
		}
	}
}