body {
    width:100%;
    color:$black;
    font-family:$muli;
    overflow-x:hidden;
    max-width:1920px;
    margin:0 auto;
    .wrapper{
    	padding:0 0 0 70px;
        @media (max-width: 1023px){
            padding:0;
        }
    	.container{
    		padding:0 $defaultPadding;
            &.beigeBg{
                background:$beige;
                &:first-child{
                    padding:120px $defaultPadding 0 $defaultPadding;
                }
            }
    	}
    }
    &.stickyNav{
       .wrapper{
        padding:120px 0 0 70px;
        @media (max-width: 1023px){
            padding:60px 0 0 0px;
        }
        }
    }
}
article{
    .intro{
        float:left;
        width:45%;
        padding:0 50px 0 0;
        @media (max-width: 1023px){
            width:100%;
            padding:0 0 20px;
        }
        .hashtags{
            background:$iconBlue;
            padding:45px 40px 40px;
            width:100%;
            margin:0 0 45px;
            @media (max-width: 767px){
                padding:25px 20px 20px;
                margin:0 0 30px;
            }
            p{
                font-size:17px;
                line-height:26px;
                font-weight:300;
                margin:0 0 20px;
                @media (max-width: 767px){
                    font-size:14px;
                    line-height:20px;
                    margin:0 0 15px;
                }
            }
            a{
                display:inline-block;
                color:$red;
                font-size:17px;
                font-weight:300;
                margin:0 15px 10px 0;
                transition:opacity $delay $easing;
                @media (max-width: 767px){
                    font-size:14px;
                    margin:0 10px 10px 0;
                }
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
        }
    }
    .mosaique{
        float:left;
        width:45%;
        padding:140px 50px 0 0;
        @media (max-width: 1023px){
            width:100%;
            padding:0px 0 40px 0;
            @media (max-width: 767px){
                padding:20px 0 40px 0;
            }
        }
        img{
            display:block;
            float:left;
            width:50%;
            height:auto;
            position:relative;
            &:first-child{
                width:100%;
                border-bottom:1px solid $white;
            }
            &:nth-child(2){
                border-right:1px solid $white;
            }
        }
    }
    .content{
        float:right;
        width:55%;
        @media (max-width: 1023px){
            width:100%;
        }
    }
}
.blockTitle{
    position:relative;
    >img{
        display:block;
        margin:0 0 0 30px;
        @media (max-width: 767px){
           margin:0 0 0 15px; 
        }
    }
    div{
        position:absolute;
        left:0;
        h1,h2,h3{
            color:$red;
            font-size:37px;
            font-family:$play;
            display:inline-block;
            font-weight:400;
            @media (max-width: 1023px){
                width:100%;
                @media (max-width: 767px){
                    font-size:25px;
                    line-height:30px;
                }
            }
        }
        a{
            -webkit-font-smoothing: antialiased;
            font-size:13px;
            color:$black;
            display:inline-block;
            border-bottom:1px solid $black;
            margin:0 0 0 30px;
            transform:translateY(-5px);
            @media (max-width: 1023px){
                margin:5px 0 0 0;
                @media (max-width: 767px){
                    margin:10px 0 0 0;
                }
            }
        }
    }
    p{
        width:60%;
        font-size:17px;
        line-height:31px;
        font-weight:300;
        @media (max-width: 767px){
            width:100%;
            font-size:14px;
            line-height:26px; 
        }
    }
    a{
        color:$red;
        font-size:16px;
        display:inline-block;
        margin:10px 0 0 0;
        transition:opacity $delay $easing;
        i{
            display:inline-block;
            font-size:22px;
            transform:translateY(2px);
            transition:transform $delay $easing;
        }
        .allowHover &:hover{
            opacity:0.6;
            i{
                transform:translate(5px,2px);
            }
        }
    }
}
.filter{
    width:100%; 
    float:left; 
    position:relative; 
    > a{
        background:white; 
        height:70px;
        display:block; 
        padding:0 40px 0 25px; 
        width:100%; 
        font-size:15px;
        line-height:70px;
        position:relative;
        overflow:hidden;
        font-weight:300;
        color:$black;
        transition:box-shadow $delay $easing;
        @media (max-width: 1280px){
            font-size:12px;
            @media (max-width: 1023px){
                padding:0 40px 0 20px; 
                @media (max-width: 767px){
                    height:50px;
                    font-size:12px;
                    height:50px;
                    line-height:50px;
                    padding:0 30px 0 15px;
                }
            }
        }
        > span{
            position:relative;
            z-index:15;
            display:inline-block;
            width:100%;
            text-align:left;
        }
        i{
            right:12px;
            position:absolute; 
            z-index:20;
            top:0;
            font-size:22px;
            transform:rotate(90deg);
            @media (max-width: 767px){
                font-size:18px;
                right:7px;
            }
        }
        .allowHover &:hover{
            box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.1);
        }
    }
    &.open{
        z-index:100;
        >a{
            box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.1);
            i{
                transform:rotate(270deg);
            }
        }
        ul{
            box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.1);
        }
    }
    ul{
        clear:both; 
        float:left; 
        width:100%; 
        height:0; 
        top:70px; 
        overflow:hidden; 
        position:absolute; 
        z-index:10; 
        max-height:355px; 
        background:$white; 
        overflow-y:scroll;
        transition:box-shadow $delay $easing;
        @media (max-width: 767px){
            top:50px; 
        }
        li{
            cursor:pointer; 
            padding:0 25px; 
            font-size:14px;
            font-weight:300;
            clear:both;
            float:left;
            width:100%;
            overflow:hidden;
            margin:0 0 20px;
            @media (max-width: 1023px){
                font-size:13px;
                padding:0 20px;
                @media (max-width: 767px){
                    font-size:12px;
                    padding:0 15px;
                }
            }
            a{
                display:block;
                color:$black;
                transition:color $delay $easing;
                .allowHover &:hover{
                    color:$red;
                }
            }
        }
    }
}
.filters-btn-menu {
    .filters-btn-list {
        margin-bottom: 0;
        list-style-type: none;
        padding: 0;
        display: flex;
        .filters-btn-item {
            flex: 1 1;
            margin-right: 1px; 
            &:last-child {
                margin-right: 0;
            } 
            .filters-btn-link {
                background-color: $white;
                color:$black;
                font-size:14px;
                padding: 8px 5px;
                min-height: 50px;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                justify-content: space-evenly;
                position: relative;
                transition: color 500ms ease;
                outline: none !important;
                .filters-btn-logo {
                    margin-bottom: 10px;
                }
                &:after {
                    content: "";
                    height: 2px;
                    width: 0;
                    background-color: $red;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: width 500ms ease, background-color 500ms ease;
                }
                &.active, &:hover, &:focus {
                    color: $red;
                    &:after {
                        width: 100%;
                    }
                }
                &:active, &:active:focus {
                    color: darken($red, 8%);
                    &:after {
                        background-color: darken($red, 8%);
                    }
                }
            }
        }
        &.filters-btn-main {
            margin-bottom: 15px;
            .filters-btn-item {
                .filters-btn-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                }
            }
        }
         &.filters-btn-sub {
            .filters-btn-item {
                .filters-btn-link {
                    font-size: 12px;
                }
            }
         }
    }
    @include breakpoint(lg) {
        .filters-btn-list {
            flex-wrap: wrap;
            .filters-btn-item {
                margin-bottom: 1px;
                min-width: 100px;
                .filters-btn-link {
                    font-size:12px;
                }
            }
            &.filters-btn-sub {
                .filters-btn-item {
                    flex-basis: calc(20% - 1px);
                    .filters-btn-link {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
.tag{
    span, a{
        -webkit-font-smoothing: antialiased;
        font-size:14px;
        padding:7px 12px;
        display:inline-block;
        background:$red;
        color:$white;
        position:relative;
        z-index:10;
        transition:background $delay $easing;
        @media (max-width: 767px){
            padding:5px 7px;
            font-size:11px;
        }
        &:nth-child(2){
            transform:translate(-15px,10px);
            padding:7px 12px 7px 17px;
            @media (max-width: 767px){
                padding:5px 7px 5px 10px;
                transform:translate(-9px,10px);
            }
        }
        &:last-child{
            z-index:5;
            background:$darkBlue;
        }
    }
    a:hover{
        background:$darkBlueHover;
    }
    span{
        &:nth-child(2){
            transform:translate(-10px,10px);
            @media (max-width: 767px){
               transform:translate(-7px,10px); 
            }
        }
    }
}
.follow{
    -webkit-font-smoothing: antialiased;
    font-size:14px;
    color:$black;
    display:inline-block;
    transition:opacity $delay $easing;
    @media (max-width: 767px){
        font-size:12px;
    }
    i{
        font-size:20px;
        display:inline-block;
        margin:0 5px 0 0;
        transform:translateY(1px);
        @media (max-width: 767px){
            font-size:18px;
        }
    }
    .allowHover &:hover{
        opacity:0.6;
    }
}
.socialMedia{
    margin:10px 0 0 0;
    a{
        color:$grey;
        font-size:18px;
        margin:0 2px;
        display:inline-block;
        transition:color $delay $easing;
        .allowHover &:hover{
            color:$greyBlue;
        }
    }
}
.ctaLink{
    background:$red;
    color:$white;
    padding:18px 30px 23px 35px;
    display:table;
    font-size:16px;
    transition:background $delay $easing;
    @media (max-width: 1023px){
        padding:13px 25px 18px 30px;
        @media (max-width: 767px){
            padding:10px 18px 15px 22px;
            font-size:12px;
        }
    }
    i{
        font-size:22px;
        display:inline-block;
        margin:0 0 0 10px;
        transform:translateY(4px);
        transition:transform $delay $easing;
        @media (max-width: 767px){
            font-size:18px;
            transform:translateY(3px);
        }
    }
    .allowHover &:hover{
        background:$redHover;
        i{
            transform:translate(5px, 4px);
        }
    }
}
.newsletter{
    width:48%;
    float:right;
    position:relative;
    margin:0 0 60px;
    -webkit-font-smoothing: antialiased;
    @media (max-width: 1280px){
        float:none;
        width:65%;
        margin:0 auto 60px;
        clear:both;
        @media (max-width: 767px){
            width:100%;
        }
    }
    img{
        width:100%;
        height:auto;
    }
    .txt{
        position:absolute;
        top:50%;
        left:50%;
        width:70%;
        transform:translate(-50%,-50%);
        @media (max-width: 767px){
            width:85%;   
        }
        h2{
            -webkit-transform: translateZ(0);
            font-size:35px;
            line-height:50px;
            text-align:center;
            color:$red;
            font-family:$play;
            margin:0 0 50px;
            font-weight:400;
            @media (max-width:1023px){
                font-size:30px;
                line-height:40px;
                margin:0 0 40px;
                @media (max-width: 767px){
                   font-size:20px;
                    line-height:26px; 
                    margin:0 0 25px;
                }
            }
        }
        .ctaLink{
            -webkit-transform: translateZ(0);
            margin:0 auto;
            display:table;
        }
    }
}
.newsletter-center {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}
.startPlayer{
    position:relative;
    margin:0 0 50px;
    background:$black;
    cursor:pointer;
    img{
        width:100%;
        height:auto;
        display:block;
        opacity:0.5;
    }
    .btnPlay{
        width:80px;
        height:80px;
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        transition:transform $delay $easing;
        @media (max-width: 767px){
            width:50px;
            height:50px; 
        }
        i{
            display:inline-block;
            color:$white;
            font-size:78px;
            margin:-8px 0 0 -13px;
            transition:color $delay $easing;
            @media (max-width: 767px){
                font-size:58px; 
            }
        }
    }
    iframe{
        position:absolute;
        top:0;
        left:0;
    }
    .allowHover &:hover{
        .btnPlay{
            transform:translate(-50%,-50%) scale(1.1);
            i{
                color:$red;
            }
        }
    }
}
body{
    .wrapper{
        .gform_wrapper{
            position:relative;
            width:100%;
            background:$paleBlue;
            padding:65px 5% 75px;
            margin:100px 0 0 0;
            @media (max-width: 767px){
                padding:35px 5% 45px;
                margin:60px 0 0 0;
            }
            form{
                .gform_heading{
                    h3{
                        color:$red;
                        width:100%;
                        font-family:$play;
                        font-size:56px;
                        text-align:center;
                        font-weight:400;
                        @media (max-width: 767px){
                            font-size:32px;
                        }
                    }
                    span{
                        font-size:22px;
                        line-height:38px;
                        font-family:$play;
                        text-align:center;
                        width:100%;
                        margin:0 0 60px;
                        display:block;
                        @media (max-width: 767px){
                            font-size:14px;
                            line-height:22px;
                            margin:10px 0 20px;
                        }
                    }
                }
                .validation_error{
                    border:none;
                    font-size:22px;
                    line-height:38px;
                    font-family:$play;
                    text-align:center;
                    width:100%;
                    margin:0 0 60px;
                    display:block;
                    color:red;
                    @media (max-width: 767px){
                        font-size:14px;
                        line-height:22px;
                        margin:10px 0 20px;
                    }
                }
                .gform_confirmation_wrapper {
                    color:$darkBlue;
                    font-size:22px;
                    line-height:38px;
                    font-family:$play;
                    text-align:center;
                    width:100%;
                    display:block;
                    margin:10px 0 0 0;
                    @media (max-width: 767px){
                        font-size:14px;
                        line-height:22px;
                    }
                }
                .gform_body{
                    >ul{
                        >li{
                            width:46%;
                            float:left;
                            margin:0 2%!important;
                            padding:0;
                            @media (max-width: 767px){
                                width:100%;
                                float:left;
                                margin:0;
                            }   
                            &.gform_hidden{
                                display:none;
                            }
                            &.gsection, &.fullWidth{
                                width:100%;
                                padding:0 4% 0 0;
                                >div{
                                    height:auto;
                                }
                            }
                            &.nameField{
                                div{
                                    margin:15px 0 0 0;
                                    width:100%;
                                    @media (max-width: 767px){
                                        margin:5px 0 0 0;
                                    }
                                    span{
                                        width:48%;
                                        margin:0 2% 0 0!important;
                                        float:left;
                                        padding:0!important;
                                        @media (max-width: 767px){
                                            width:100%;
                                            margin:0; 
                                        }
                                        &.ginput_left{
                                            padding:0!important;
                                        }
                                        label{
                                            margin:5px 0 45px 0;
                                            display:inline-block;
                                            font-size:12px;
                                            width:100%;
                                            text-align:center;
                                            @media (max-width: 767px){
                                               font-size:11px;
                                               margin:5px 0 20px 0;
                                            }
                                        }
                                    }
                                    span:nth-child(even){
                                        margin:0 0 0 2%!important;
                                        @media (max-width: 767px){
                                            margin:0;
                                        }
                                    }
                                }
                            }
                            &.timeField{
                                >div{
                                    margin:0;
                                    width:100%;
                                    div{
                                        width:38%;
                                        margin:15px 2% 0 0;
                                        float:left;
                                        @media (max-width: 767px){
                                            width:100%;
                                            margin:5px 0;
                                        }
                                        i{
                                            display:none;
                                        }
                                        label{
                                            margin:5px 0 45px 0;
                                            display:inline-block;
                                            font-size:12px;
                                            width:100%;
                                            text-align:center;
                                            @media (max-width: 767px){
                                                font-size:11px;
                                                margin:5px 0 20px 0;
                                            }
                                        }
                                        input{
                                            width:100%!important;
                                        }
                                        select{
                                            width:100%!important;
                                        }
                                    }
                                    div:nth-child(3){
                                        width:20%;
                                        margin:15px 0 0 0;
                                        @media (max-width: 767px){
                                            width:100%;
                                            margin:5px 0 20px 0;
                                        }
                                    }
                                }
                            }
                            .gfield_radio, .gfield_checkbox{
                                li{
                                    margin:0 0 10px;
                                    @media (max-width: 767px){
                                        margin:0 0 5px;
                                    }
                                    input{
                                        display:inline-block;
                                        margin:0 10px 0 0;
                                        @media (max-width: 767px){
                                            margin:0 5px 0 0;
                                        }
                                    }
                                    label{
                                        font-size:14px;
                                        @media (max-width: 767px){
                                            font-size:12px;
                                        }
                                    }
                                }
                            }
                            &.gfield_error{
                                background:transparent;
                                border:none;
                                margin-bottom:0!important;
                                label{
                                    color:$black;
                                }
                                .gfield_label{
                                    color:red;
                                }
                                input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]){
                                    border:1px solid red;
                                }
                                textarea{
                                    border:1px solid red;
                                }
                                select{
                                    border:1px solid red;
                                }
                            }
                            .validation_message{
                                display:none;
                            }
                            >div, >h2{
                                height:70px;
                                margin:15px 0 45px;
                                float:left;
                                width:100%;
                                @media (max-width: 767px){
                                    height:50px;
                                    margin:5px 0 20px;
                                }
                            }
                            label{
                                font-size:16px;
                                @media (max-width: 767px){
                                    font-size:12px;
                                }
                                span{
                                    color:$red;
                                }
                            }
                            h2{
                                text-align:center;
                                font-size:22px;
                                line-height:38px;
                                font-family:$play;
                                text-align:center;
                                width:100%;
                                font-weight:normal;
                                @media (max-width: 767px){
                                    font-size:14px;
                                    line-height:22px;
                                }
                            }
                            textarea{
                                width:100%;
                                border:none;
                                border-radius:0px;
                                resize: none;
                            }
                            input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), select{
                                height:70px;
                                width:100%;
                                border:none;
                                font-weight:300;
                                font-size:15px;
                                padding:0 25px;
                                border-radius:0px;
                                @media (max-width: 1023px){
                                    padding:0 20px;
                                    font-size:13px;
                                    @media (max-width: 767px){
                                        padding:0 15px;
                                        font-size:12px;
                                        height:50px;
                                    }
                                }
                            }
                        }
                    }
                }
                .gform_footer{
                    clear:both;
                    input[type="submit"]{
                        background:$red;
                        color:$white;
                        padding:18px 30px;
                        display:table;
                        font-size:16px;
                        margin:0 auto;
                        border:none;
                        transition:background $delay $easing;
                        @media (max-width: 1023px){
                            padding:13px 25px;
                            @media (max-width: 767px){
                                padding:10px 20px;
                                font-size:12px;
                            }
                        }
                        .allowHover &:hover{
                            background:$redHover;
                        }
                    }
                }
            }
        }
    }
}
br.clear{
    clear:both;
}
.mobileOnly{
    display:none;
    @media (max-width: 767px){
        display:block;
    }
}
.desktopOnly{
    display:block;
    @media (max-width: 767px){
        display:none;
    }
}

.mobileDevice{
    @media (max-width:1023px){
        article{
            .mosaique{
                display:none;
            }
        }
    }
}
.share-alt {
    margin-bottom: 80px;
    text-align: center;
    p {
        margin-bottom: 20px;
    }
    @include breakpoint(lg) {
        margin-bottom: 60px;
    }
    @include breakpoint(sm) {
        margin-bottom: 40px;
        text-align: left;
    }
}
.hero {
    .hero-img {
        width: 100%;
        &.mobile {
            display: none;
        }
    }
    @include breakpoint(sm) {
        .hero-img {
            width: 100%;
            &.mobile {
                display: block;
            }
            &.desktop {
                display: none;
            }
        }
    }
}

::-webkit-input-placeholder { 
  color:$black;
  opacity: 1;
}
::-moz-placeholder {
  color:$black;
  opacity: 1;
}
:-ms-input-placeholder {
  color:$black;
  opacity: 1;
}
:-moz-placeholder { 
  color:$black;
  opacity: 1;
}
