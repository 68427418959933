.articleBy{
    width:55%;
    float:right;
    text-align:center;
    padding:10px 0 0 0;
    @media (max-width: 1023px){
        width:100%;
    }
    h3{
        color:$red;
        font-size:23px;
        font-family:$play;
        font-weight:400;
        margin:0 0 25px;
        @media (max-width: 767px){
            margin:0 0 20px;
            font-size:18px;
        }
    }
    .author{
        display:inline-block;
        margin:0 25px 50px;
        width:25%;
        vertical-align:top;
        @media (max-width: 767px){
            margin:0 15px 30px;
        }
        .thumb{
            display:table;
            position:relative;
            border-radius:50px;
            width:100px;
            height:100px;
            overflow:hidden;
            margin:0 auto 15px;
            transition:opacity $delay $easing;
            @media (max-width: 767px){
                width:70px;
                height:70px;
                border-radius:35px;
            }
            img{
                display:block;
            }
            .allowHover &:hover{
                opacity:0.6;
            }
        }
        h5{
            font-size:15px;
            font-weight:300;
            text-align:center;
            width:100%;
            @media (max-width: 767px){
                font-size:12px;
            }
            a{
                color:$black;
                transition:opacity $delay $easing;
                .allowHover &:hover{
                    opacity:0.6;
                }
            }
        }
        span{
            margin:5px 0 0 0;
            display:inline-block;
            color:$charcoal;
            font-size:14px;
            font-family:$play;
            @media (max-width: 767px){
                font-size:12px;
            }
        }
    }
    .share{
        padding:10px 0 60px 0;
        border-top:1px solid $paleGrey;
        @media (max-width: 767px){
           padding:10px 0 40px 0;
        }
        p{
            display:inline-block;
            font-size:12px;
            color:$black;
            text-transform:uppercase;
            @media (max-width: 767px){
               font-size:10px; 
            }
        }
        .socialMedia{
            display:inline-block;
        }
    }
}