.elus{
	margin:-120px 0 0 0;
	.filterTools{
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		padding:110px 0 110px;
		@media (max-width: 1023px){
			padding:70px 0 110px;
			@media (max-width: 767px){
				border:none;
				padding:20px 0 100px;
			}
		}
		h1{
			font-weight:400;
			color:$red;
			font-size:49px;
			font-family:$play;
			@media (max-width: 767px){
				font-size:39px;
			}
		}
		.filters{
			margin:40px 0 0 0;
			.filter{
				width:33.33%;
				border-right:1px solid $beige;
				@media (max-width: 1023px){
					width:100%;
					border:none;
					margin:0 0 15px;
				}
			}
			.search{
				width:33.33%;
				float:left;
				position:relative;
				@media (max-width: 1023px){
					width:100%;
				}
				input{
					padding:0 70px 0 40px;
					height:70px;
					width:100%;
					border:none;
					font-weight:300;
					font-size:15px;
					border-radius:0px;
					@media (max-width: 1280px){
						font-size:12px;
						@media (max-width: 1023px){
		                	padding:0 60px 0 20px;
		                	@media (max-width: 767px){
		                		height:50px;
							}
		                }
		            }
				}
				a{
					position:absolute;
					right:25px;
					top:20px;
					font-size:25px;
					color:$black;
					transition:color $delay $easing;
					@media (max-width: 1023px){
						right:20px;
						@media (max-width: 767px){
							font-size:20px;
							top:12px;
						}
					}
					.allowHover &:hover{
						color:$red;
					}
				}
			}
		}
	}
	.linstings{
		margin:-40px 0 0 0;
		border-left:$extraPadding solid transparent;
		border-right:$extraPadding solid transparent;
		padding:0 0 60px;
		@media (max-width: 767px){
			border:none;
			padding:0;
		}
		.noResultMessage{
			margin:0 0 80px;
			font-family:$play;
			font-size:20px;
			@media (max-width: 767px){
				font-size:16px;
				margin:0 0 60px;
			}
		}
		.elu{
			width:33.33%;
			float:left;
			text-align:center;
			@media (max-width: 1280px){
		        width:50%;

		    }
			>a{
				position:relative;
				display:block;
				img{
					width:100%;
					height:auto;
					display:block;
				}
			}
			&:nth-child(3n-1){
	            >a{
	                &:before{
	                    position:absolute;
	                    content:"";
	                    left:-1px;
	                    top:0;
	                    height:100%;
	                    width:1px;
	                    background:$white;
	                    @media (max-width: 1280px){
					        display:none;
					    }
	                }
	                &:after{
	                    position:absolute;
	                    content:"";
	                    right:0;
	                    top:0;
	                    height:100%;
	                    width:1px;
	                    background:$white;
	                    @media (max-width: 1280px){
					        display:none;
					    }
	                }
	            }
	        }
	        @media (max-width: 1280px){
		        &:nth-child(2n){
		        	>a{
						&:before{
		                    position:absolute;
		                    content:"";
		                    left:-1px;
		                    top:0;
		                    height:100%;
		                    width:1px;
		                    background:$white;
		                    display:block;
		                }
		            }
		        }
		    }
			.txt{
				height:170px;
				padding:20px 0 0 0;
				h5{
					font-size:19px;
					line-height:31px;
					font-weight:300;
					@media (max-width: 767px){
						font-size:16px;
						line-height:24px;
					}
					a{
						color:$black;
						transition:opacity $delay $easing;
						.allowHover &:hover{
	                        opacity:0.6;
	                    }
					}
				}
				span{
					font-size:14px;
					color:$charcoal;
					font-family:$play;
					margin:0 0 5px;
					display:inline-block;
					@media (max-width: 767px){
						font-size:12px;
					}
				}
			}
		}
	}
}